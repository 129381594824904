import { AuditPartition } from 'app/models/auditPartition.model';
import { ActionName } from 'app/shared/constants';
import { RangeFilter } from '../models/index';
import { AppConstants, BaseComponent } from '../util/index';

export class FilterBase extends BaseComponent {
  selectedColor = 'filter-selected';
  rangeFilter: RangeFilter = new RangeFilter();
  auditPartitionData : AuditPartition;
  filterActionName : ActionName = ActionName.NoAction;
  lastObservedMonth: Number;
  pageNo = 0;
  rangeQueryStatus = '';
  queryURL = AppConstants.QueryRangeNdcPageURL;
  patternSearch = '';


  init() {
    this.rangeFilter = new RangeFilter();
    this.lastObservedMonth = null;
    this.rangeQueryStatus = '';
  }

  rangeColumnNames = [
    'termCountry',
    'ccNdc',
    'iso2',
    'code',
    'tos',
    'tosdesc',
    'ndc',
    'locality',
    'provider',
    'supplement'
  ];


  rangeKeys = [
    'termCountry',
    'ccNdc',
    'iso2',
    'code',
    'tos',
    'tosdesc',
    'ndc',
    'locality',
    'provider',
    'supplement'
  ];
  delimiter = '|';


  alphaSearchPattern: Array<SearchPattern> = [
    { pattern: '^[a,b,c]', label: 'A-C', selected: false },
    { pattern: '^[d,e,f]', label: 'D-F', selected: false },
    { pattern: '^[g,h,i]', label: 'G-I', selected: false },
    { pattern: '^[j,k,l]', label: 'J-L', selected: false },
    { pattern: '^[m,n,o]', label: 'M-O', selected: false },
    { pattern: '^[p,q,r]', label: 'P-R', selected: false },
    { pattern: '^[s,t,u]', label: 'S-U', selected: false },
    { pattern: '^[v,w,x,y,z]', label: 'V-Z', selected: false }
  ];

  alphaNumSearchPattern: Array<SearchPattern> = [
    { pattern: '^[^0-9a-z]', label: 'Symb', selected: false },
    { pattern: '^[0-9]', label: '0-9', selected: false },
    { pattern: '^a', label: 'A', selected: false },
    { pattern: '^b', label: 'B', selected: false },
    { pattern: '^c', label: 'C', selected: false },
    { pattern: '^d', label: 'D', selected: false },
    { pattern: '^e', label: 'E', selected: false },
    { pattern: '^f', label: 'F', selected: false },
    { pattern: '^g', label: 'G', selected: false },
    { pattern: '^h', label: 'H', selected: false },
    { pattern: '^i', label: 'I', selected: false },
    { pattern: '^j', label: 'J', selected: false },
    { pattern: '^k', label: 'K', selected: false },
    { pattern: '^l', label: 'L', selected: false },
    { pattern: '^m', label: 'M', selected: false },
    { pattern: '^n', label: 'N', selected: false },
    { pattern: '^o', label: 'O', selected: false },
    { pattern: '^p', label: 'P', selected: false },
    { pattern: '^q', label: 'Q', selected: false },
    { pattern: '^r', label: 'R', selected: false },
    { pattern: '^s', label: 'S', selected: false },
    { pattern: '^t', label: 'T', selected: false },
    { pattern: '^u', label: 'U', selected: false },
    { pattern: '^v', label: 'V', selected: false },
    { pattern: '^w', label: 'W', selected: false },
    { pattern: '^x', label: 'X', selected: false },
    { pattern: '^y', label: 'Y', selected: false },
    { pattern: '^z', label: 'Z', selected: false }
  ];


  tosSearchPattern: Array<SearchPattern> = [
    { pattern: 'f', label: 'F', selected: false },
    { pattern: 'g', label: 'G', selected: false },
    { pattern: 'm', label: 'M', selected: false },
    { pattern: 'p', label: 'P', selected: false },
    { pattern: 's', label: 'S', selected: false },
    { pattern: 't', label: 'T', selected: false },
    { pattern: 'u', label: 'U', selected: false },
    { pattern: 'v', label: 'V', selected: false },
  ];

}

export interface SelectedCounter {
  country: number;
  tos: number;
  provider: number;
  rule: number;
}

export interface SearchPattern {
  pattern: string;
  label: string;
  selected: boolean;
}

export interface SelectedMsrnCounter {
   msrnProvider: number;
}
