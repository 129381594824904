import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { ResponseJson, ListDefinition, ListUploadRequest, ListDetails, PartitionDefinition } from '../models/index';
import { ListsService } from './lists.service';
import { ListDetailComponent } from '../list-detail';
import { AuthService } from '../auth';
import { NotificationService } from '../services/notification.service';
import { ConfirmationService } from 'primeng/api';
import { ConvertToUnderscorePipe } from '../util/convert-to-underscore.pipe';

@Component({
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss'],
  providers: [],
})
export class ListsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('uploadBtn') el: ElementRef;

  blackLists: Array<ListDefinition> = [];
  whiteLists: Array<ListDefinition> = [];
  pollListUpdate: Subscription;
  leftRailChange: Subscription;
  selectedListName = '';
  newListId=null;
  file: File = null;
  acceptLabel = 'Delete List';
  addNewBl = true;
  addNewWl = true;
  showMoreBL = true;
  showMoreWL = true;
  showMoreLimit = AppConstants.ShowMoreLimit;
  tempName = '';
  tempNameBL = '';
  tempNameWL = '';
  isAddMoreBL = true;
  isAddMoreWL = true;
  maxBlackList:number;
  maxWhiteList:number;
  deleteListName = '';
  partitionReferenceMessage = '';
  partitionReferenceList = '';
  selectedPosition = 0;
  showDelConfirm:boolean = false;
  blocking = false;
  isUpdatingRail = false;


  constructor(private log: Logger, private httpService: HttpService, private router: Router,
    public notifService: NotificationService, public listService: ListsService,private confirmationService: ConfirmationService,
    private authService: AuthService) {
    super(notifService);
  }

  ngOnInit() {
    this.getBlackList();
    this.getWhiteList();
    this.queryListUpdate();

  }

  ngOnDestroy() {
    /* put in a flag to help with automated testing - pollListUpdate.unsubscribe*/
    //this.log.debug("guiPollingEnabled: " + this.authService.loginUser.guiPollingEnabled);
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate.unsubscribe();
    }
    this.leftRailChange.unsubscribe();
  }

  queryListUpdate() {
    /* put in a flag to help with automated testing */
    this.log.debug("guiPollingEnabled: " + this.authService.loginUser.guiPollingEnabled);
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate = interval(10000).subscribe(() => {
        if (this.isUpdatingRail === false) {
          //console.log(":::pollList:::");
          this.queryRailUpdate();
        }
      });
    }


    this.leftRailChange = this.listService.leftRailChange.subscribe((newListId) => {
      this.newListId = newListId;
      //console.log(":::NEW LIST:::");
      //Two calls are done so list will refresh faster
      this.queryRailUpdate();
      this.queryRailUpdate();
    });
  }

  queryRailUpdate() {
    this.isUpdatingRail = true;
    this.getBlackList();
    this.getWhiteList();
    this.isUpdatingRail = false;
  }

  onSelect(myList: ListDefinition) {
    this.blackLists.forEach(b => b.selected = false);
    this.whiteLists.forEach(w => w.selected = false);
    this.listService.selectedList = null;
    myList.selected = true;
    if (this.listService.isEditingNameField) {
      this.listService.isSelectDuringNameEdit = true;
      this.listService.isEditingNameField = false;
    } else {
      this.listService.isSelectDuringNameEdit = false;
    }
    this.listService.toggleDeleteIcon(myList.type);
    this.listService.onSelect(myList);
  }
/*
  toggleDeleteIcon(deleteType: string) {
    if (deleteType == 'BL') {
      this.isAllowDeleteBL = true;
      this.isAllowDeleteWL = false;
    } else if (deleteType == 'WL') {
      this.isAllowDeleteBL = false;
      this.isAllowDeleteWL = true;
    } else {
      this.isAllowDeleteBL = false;
      this.isAllowDeleteWL = false;
    }
  }
*/
  //open upload file dialog from list panel
  uploadFile(myList: ListDefinition) {
    this.onSelect(myList);
    this.file = null;
    this.listService.displayUploadWin = true;
  }

  //restore original list item
  onCancel() {
    this.listService.onCancel();
    this.getBlackList();
    this.getWhiteList();
  }

  upload(event) {
    if (this.file)
      this.listService.upload(event, this.file);
  }

  fileChange() {
    this.file = this.el.nativeElement.files[0];
  }

  getBlackList() {
    this.httpService.get(AppConstants.QueryBlackListURL, [this.postQueryBlackListHandler, this.responseCallBack]);
  }

  postQueryBlackListHandler = (responseJson: ResponseJson) => {
    let selectedList: ListDefinition;
    if (responseJson.status === AppConstants.SUCCESS) {
      if (this.blackLists.length > 0) {
        if (this.newListId) {
          selectedList = this.blackLists.find(b => b.id === this.newListId);
        } else if (this.listService.selectedList && this.listService.selectedList.id) {
          selectedList = this.blackLists.find(b => b.id === this.listService.selectedList.id);
        } else {
          selectedList = this.blackLists.find(b => b.selected === true);
        }
        if (selectedList) {
          this.selectedListName = selectedList.listName;
          //console.log("Selected BL : " + selectedList.id + "|" + this.selectedListName);
          if (!this.listService.blockDelete) this.listService.toggleDeleteIcon('BL');
          this.listService.selectedListId=-1;
        }
      }
      this.maxBlackList = responseJson.data.maxBlackList;
      this.blackLists = [];
      const blListArray: Array<ListDefinition> = JsonHelper.parseArray(responseJson.data.lists, ListDefinition);
      if (blListArray.length > 0) {
        this.listService.isBlankList = false;
      }
      for (const item of blListArray) {
        this.blackLists.push(item);
      }

      if (this.blackLists.length >= this.maxBlackList) {
        this.isAddMoreBL = false;
      } else {
        this.isAddMoreBL = true;
      }
      //console.log("getBlackList() " + this.blackLists.length + "|" this.maxBlackList + "|" + this.isAddMoreBL );

      if (this.selectedListName) {
        selectedList = this.blackLists.find(b => b.listName === this.selectedListName);
        if (selectedList)
          selectedList.selected = true;
        this.selectedListName = '';
        this.newListId = null;
      }

      this.tempNameBL = "Block List " + (this.blackLists.length+1);
    }
  }

  getWhiteList() {
    this.httpService.get(AppConstants.QueryWhiteListURL, [this.postQueryWhiteListHandler, this.responseCallBack]);
  }

  postQueryWhiteListHandler = (responseJson: ResponseJson) => {
    //let selectedList: ListDefinition;
    if (responseJson.status === AppConstants.SUCCESS) {
      if (this.whiteLists.length > 0) {
        let selectedList: ListDefinition;
        if (this.newListId) {
          selectedList = this.whiteLists.find(w => w.id === this.newListId);
        } else if (this.listService.selectedList && this.listService.selectedList.id) {
          selectedList = this.whiteLists.find(w => w.id === this.listService.selectedList.id);
        } else {
          selectedList = this.whiteLists.find(w => w.selected === true);
        }
        if (selectedList) {
          this.selectedListName = selectedList.listName;
        }
      }

      this.maxWhiteList = responseJson.data.maxWhiteList;
      this.whiteLists = [];
      const wlListArray: Array<ListDefinition> = JsonHelper.parseArray(responseJson.data.lists, ListDefinition);
      if (wlListArray.length > 0) {
        this.listService.isBlankList = false;
      }
      for (const item of wlListArray) {
        this.whiteLists.push(item);
      }

      if (this.whiteLists.length >= this.maxWhiteList) {
        this.isAddMoreWL = false;
      } else {
        this.isAddMoreWL = true;
      }
      //console.log("getWhiteList() " + this.whiteLists.length + "|" + this.maxWhiteList + "|"+ this.isAddMoreWL );

      if (this.selectedListName) {
        //this.whiteLists.find(w => w.listName === this.selectedListName).selected = true;
        let selectedList = this.whiteLists.find(w => w.listName === this.selectedListName);
        if (selectedList) {
          this.whiteLists.find(w => w.listName === this.selectedListName).selected = true;
          if (!this.listService.blockDelete) this.listService.toggleDeleteIcon('WL');
        }
        this.newListId = null;
        this.selectedListName = '';
      }

      this.tempNameWL = "Allow List " + (this.whiteLists.length+1);
    }
  }

  fillBlankSlot(type: string) {
    if (type === 'black') {
      if (this.blackLists.length < 1) {
        this.blackLists.push(new ListDefinition('Block List 1', 'BL'));
      }
      return;
    }
    if (type === 'white') {
      if (this.whiteLists.length < 1) {
        this.whiteLists.push(new ListDefinition('Allow List 1', 'WL'));
      }
      return;
    }
  }

  newList(type:string){
    let newList = new ListDefinition;
    newList.listName='';
    if (type === 'black') {
      newList.type = 'BL';
      //this.tempName = "Black List " + (this.blackLists.length+1);
      this.tempName = this.tempNameBL;
    }
    if (type === 'white') {
      newList.type = 'WL';
      //this.tempName = "White List " + (this.whiteLists.length+1);
      this.tempName = this.tempNameWL;
    }
    this.listService.toggleDeleteIcon("none");
    this.blackLists.forEach(b => b.selected = false);
    this.whiteLists.forEach(w => w.selected = false);

    if (this.listService.isEditingNameField) {
      this.listService.isSkipDupMsg = true;
      this.listService.isNewList = true;
    } else {
      this.listService.isSelectDuringNameEdit = false;
      this.listService.isSkipDupMsg = false;
    }

    this.listService.onCreateNew(newList,this.tempName);
    //this.httpService.post(JsonHelper.toJson(newList), AppConstants.SaveListDefinition, [this.listService.postCreateListHandler]);
  }

  findSelectedPosition(type:string) {
    this.selectedPosition = -1;
    let listLength = 0;

    if (type == 'BL') {
      if (this.blackLists.length > 0) {
        this.selectedPosition = this.blackLists.findIndex(b => b.selected === true);
        listLength = this.blackLists.length;
      }
    }
    if (type == 'WL') {
      if (this.whiteLists.length > 0) {
        this.selectedPosition = this.whiteLists.findIndex(w => w.selected === true);
        listLength = this.whiteLists.length;
      }
    }

    if (this.selectedPosition > -1) {
      if (this.selectedPosition == (listLength -1)) {
        if (this.selectedPosition == 0) {
          this.selectedPosition = -1;
        } else {
            this.selectedPosition--;
        }
      }
      else
        this.selectedPosition++;
    }


    //console.log("findSelectedPosition FINAL: " + this.selectedPosition + "   listLength: " + listLength);
  }

  getSelectedPosition(type:string) {
    let nextQueryList: ListDefinition;
    if (type == 'BL') {
      if (this.blackLists.length > 0) {
        nextQueryList = this.blackLists[this.selectedPosition];
      }
    }
    if (type == 'WL') {
      if (this.whiteLists.length > 0) {
        nextQueryList = this.whiteLists[this.selectedPosition];
      }
    }

    if (nextQueryList) {
      //console.log("getSelectedPosition nextQueryList  id: " + nextQueryList.id + "   name: " + nextQueryList.id);
      this.onSelect(nextQueryList);
    } else {
      this.router.navigate(['/lists']);
    }
  }

  async onDeleteList() {
    this.listService.resetListName();
    let holdListToDelete:ListDefinition;
    holdListToDelete = this.listService.selectedList;
    //console.log("onDeleteList API: Name|ID: " + this.listService.selectedList.listName + " | " + this.listService.selectedList.id);
    this.listService.listDataQueryStatus = '';
    this.listService.stopBatchLoading = true;
    this.listService.hideMessageBox = true;
    this.httpService.get(AppConstants.QueryPartitionReferences + '/' + this.listService.selectedList.id,
      [this.postPartitionReferences]);
    await AppConstants.delay(1000);
    this.listService.selectedList=holdListToDelete;

    if (this.listService.selectedList.type == 'BL') {
      this.deleteListName = this.listService.selectedList.listName + ' block list';
    }
    if (this.listService.selectedList.type == 'WL') {
      this.deleteListName = this.listService.selectedList.listName + ' allow list';
    }

    if (this.partitionReferenceList) {
      this.partitionReferenceMessage = '  This list is associated with the following partitions: ' + this.partitionReferenceList +
      '. Its removal will change the partition data.'
    } else {
      this.partitionReferenceMessage = '';
    }

    this.showDelConfirm=true;
  }

  postPartitionReferences = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.listService.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);

      const partitionRefArray: Array<PartitionDefinition> = JsonHelper.parseArray(responseJson.data, PartitionDefinition);
      this.partitionReferenceList = "";
      let seperator = "";
      for (const item of partitionRefArray) {
        this.partitionReferenceList = this.partitionReferenceList + seperator + item.name;
        seperator = ", ";
      }
    }
  }

  deleteList(){
    this.blocking=true;
    this.showDelConfirm=false;
    this.isUpdatingRail=true;
    this.listService.stopBatchLoading = true;
    this.httpService.delete(AppConstants.DeleteListDefinition + '?listId=' + this.listService.selectedList.id, [this.postDeleteList, this.responseCallBack]);
  }


  postDeleteList = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.listService.selectedListItem.item.listData = [];
      this.listService.selectedList.listData = [];
      //console.log("postDeleteList: listType: " + this.listService.selectedList.type);
      this.findSelectedPosition(this.listService.selectedList.type);
      //console.log("in postDeleteList() after findSelectedPosition");

      if (this.selectedPosition > -1) {
        //console.log("in postDeleteList() selectedPosition is VALID; going to getSelectedPosition");
        this.getSelectedPosition(this.listService.selectedList.type);
      } else {
        this.selectedPosition = 0;
        if (this.listService.selectedList.type == 'BL')
          this.getSelectedPosition('WL');
        else if (this.listService.selectedList.type == 'WL')
          this.getSelectedPosition('BL');
      }
    }

    this.queryRailUpdate();
    this.blocking=false;
    this.isUpdatingRail=false;
  }

}
