import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmationService, SortMeta } from 'primeng/api';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { AppConstants} from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import {PartitionDataDetails, RuleDefinition, ProviderBillingId, TosTosDesc, Country} from '../models/index';
import { PartitionService } from '../partitions/partition.service';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { FilterList, PartitionDownloader } from 'app/partitions/partition.downloader';

@Component({
	selector: 'app-partition-export-data-detail',
	templateUrl: './partition-export-data-detail.component.html',
	styleUrls: ['./partition-export-data-detail.component.scss']
})

export class PartitionExportDataDetailComponent extends RulePartitionBase implements OnInit {

  showResult = true;
  filteredRuleList: Array<RuleDefinition> = [];
  partitionExportCols: any[];
  filterFieldCols: any[] = [];
  pageNo;
  limit = 10;
  sortField;
  globalFilter;


  constructor(private location: Location, private route: ActivatedRoute, private router: Router, public partitionService: PartitionService, public log: Logger,
    public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService, private confirmationService: ConfirmationService) {
    super(log, httpService, cacheService, notifService);

    this.partitionExportCols = [
      {field: 'dialPattern', header: 'DialPattern', width: '50px'},
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'country', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '70px'},
	    {field: 'supplement', header: 'Supplement', width:'70px'},
	    {field: 'provider', header: 'Provider', width:'70px'},
      {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
	    {field: 'source', header: 'Source', width: '75px', nosort: true},
	    {field: 'rule', header: 'Rule', width: '40px', nosort: true },
	    {field: 'customerDate', header: 'Customer Date', width: '70px'},
	    {field: 'reason', header: 'Reason', width: '70px'},
      {field: 'notes', header: 'Notes', width: '70px'}
    ];

    this.partitionExportCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
  }


  ngOnInit() {

    this.filteredRuleList = this.partitionService.selectedExportPartition.ruleDefinitions;
    this.cacheService.cacheCountry();
    this.partitionService.getExportDataFilters();
    
  }

  goBack() {
    this.partitionService.showDraftDataWin = false;
    this.location.back();
  }

  editRuleById(ruleId: string) {
    this.log.debug('show rule detail: ' + ruleId);

    this.partitionService.selectedPartition.ruleDefinitions.forEach(rule => {
      if (rule.id === +ruleId) {
        this.partitionService.selectedRule = rule;
      }
    });
    this.router.navigate(['partitions/partitionRuleDetail']);
    return false;
  }

  getFilteredData() {
    this.partitionService.filterIso2list = []
    this.partitionService.filterTosList = []
    this.partitionService.filterProviderList = []
    this.partitionService.filterRuleIdList = []
    let selectedCountryList = this.filteredCountryList.filter(item => {
      if (item.selected) {
        this.partitionService.filterIso2list.push(item.iso2)
        return item;
      }
    });

    let selectedTosTosDesc = this.filteredTOSList.filter(item => {
      if (item.selected) {
        this.partitionService.filterTosList.push({ tos: item.tos, tosdesc: item.tosdesc })
        return item;
      }
    });

    let selectedProvider = this.filteredProviderBillingIdList.filter(item => {
      if (item.selected) {
        this.partitionService.filterProviderList.push(item.provider)
        return item;
      }
    });

    let selectedRuleIds: Array<number> = [];
    for (let rule of this.filteredRuleList) {
      if (rule.selected) {
        this.partitionService.filterRuleIdList.push(rule.id)
        selectedRuleIds.push(rule.id);
      }
    }
    return true
  }


  downloader: PartitionDownloader;
  onDownload(){
    if (this.downloader  && this.downloader.isLoading())
      return;

    let filterList: FilterList = {
      iso2list: this.partitionService.filterIso2list, 
      providerList: this.partitionService.filterProviderList,
      tosList: this.partitionService.filterTosList,
      ruleIdList: this.partitionService.filterRuleIdList,
      searchPattern: this.globalFilter
    };  
    // let data = this.partitionService.getPartitionExportColumnNames().join(AppConstants.DELIMITER) + '\n';

    this.downloader = new PartitionDownloader(
      AppConstants.QueryDownloadPartitionDataURL, 
      this.partitionService.selectedPartition, 
      this.partitionService.ruleId,
      filterList,
      this.multiSortMeta,
      // 100000,
      this.httpService,
      // data
      this.partitionService.exportDataTotalCount * 80  //expected file size (approximate)
      );   

      this.downloader.start();
  }

  ngOnDestroy() {
    this.partitionService.exportData = []
    this.partitionService.filteredDraftData = []
    this.partitionService.exportDataTotalCount = 0
    this.partitionService.filterProviderList = []
    this.partitionService.filterIso2list = []
    this.partitionService.filterTosList = []
    this.partitionService.filterRuleIdList = []
    this.filteredCountryList = []
    this.filteredProviderBillingIdList = []
    this.filteredTOSList = []
    this.filteredRuleList = []
    this.globalFilter = []
  }

  closeAllFilter() {
    $('.filter').hide();
    this.partitionService.filteredDraftData = []; //to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.getFilteredData();

    this.multiSortMeta = this.saveEvent.multiSortMeta;
    this.limit = this.saveEvent.rows;
    this.showResult = true;
  }

  @ViewChild('dt') dt: Table;


  totalMessage(): string {
    let actual = this.partitionService.exportDataTotalCount;
    let filtered = this.partitionService.filterIso2list.length || this.partitionService.filterTosList.length || this.partitionService.filterProviderList.length || this.partitionService.filterRuleIdList.length || this.globalFilter;
    return (filtered ? "Filtered Records: " : "Total Records: ") + actual;
  };


  createFilterData(name: string, event: any, overlaypanel: OverlayPanelModule) {
    
    switch (name) {
      case 'country':
        let countries = []
        this.partitionService.iso2list.forEach((iso2) => {
          const country = this.cacheService.findCountriesByIso2(iso2)
          countries.push(...country)
        })
        countries.forEach((country) => {
          if (!this.hasCountry(this.filteredCountryList, country) && !this.hasCountry(this.countryList, country)) {

            this.filteredCountryList.push(country);
            this.countryList.push(country);
          }
        })
        break;
      case 'provider':

        this.partitionService.providerList.forEach((data) => {
          let result = {
            provider: data, billingId: '', selected: ''
          }
          if (data && !this.hasProvider(this.filteredProviderBillingIdList, result) && !this.hasProvider(this.providerBillingIdList, result)) {
            const provider = new ProviderBillingId();
            provider.provider = data || 'Unallocated';
            this.filteredProviderBillingIdList.push(provider);
            this.providerBillingIdList.push(provider);
          }
        })
        break;
      case 'tos':
        this.partitionService.tosList.forEach((data) => {
          if (data && Object.keys(data) && Object.keys(data).length && !this.hasTosTosDesc(this.filteredTOSList, data) && !this.hasTosTosDesc(this.tosList, data)) {
            let tosData = new TosTosDesc()
            tosData.tos = data.tos;
            tosData.tosdesc = data?.tosdesc
            this.filteredTOSList.push(tosData);
            this.tosList.push(tosData);
          }
        })
        break;
      default:
    }

    $('.search-query').val('');
    $('.filter').hide();

    $('#' + name).show();

    //not used
    // this.partitionService.exportData.forEach(item => {
    //   const country = new Country();
    //   country.country = item.country;
    //   country.code = item.code;
    //   country.iso2 = item.iso2;

    //   const tos = new TosTosDesc();
    //   tos.tos = item.tos;
    //   tos.tosdesc = item.tosdesc || 'Unallocated';

    //   const provider = new ProviderBillingId();
    //   provider.provider = item.provider || 'Unallocated';

    //   switch (name) {
    //     case 'country':
    //       if (!this.hasCountry(this.filteredCountryList, country)  && !this.hasCountry(this.countryList, country)) {
    //         this.filteredCountryList.push(country);
    //         this.countryList.push(country);
    //       }

    //       break;
    //     case 'provider':
    //       if (!this.hasProvider(this.filteredProviderBillingIdList, provider) && !this.hasProvider(this.providerBillingIdList, provider)) {
    //         this.filteredProviderBillingIdList.push(provider);
    //         this.providerBillingIdList.push(provider);
    //       }
    //       break;
    //     case 'tos':
    //       if (!this.hasTosTosDesc(this.filteredTOSList, tos) && !this.hasTosTosDesc(this.tosList, tos)) {
    //         this.filteredTOSList.push(tos);
    //         this.tosList.push(tos);
    //       }

    //       break;
    //     default:
    //   }

    // });
    this.sortFilteredData(this.filteredCountryList,'country');
    this.sortFilteredData(this.filteredProviderBillingIdList,'provider');
    this.sortFilteredData(this.filteredTOSList,'tos');
  }

  getSelectedValues(type: string): string {
    let result = '';
    this.createRangeFilter();

    switch (type) {
      case 'country':
        let countries = [];
        this.filteredCountryList.forEach(item => {
          if (item.selected) {
            countries.push(item.country + ' ' + item.code + ' ' + item.iso2);
          }
        });
        result = countries.join(';');
        break;
      case 'provider':
        let providers = [];
        this.filteredProviderBillingIdList.forEach(item => {
          if (item.selected) {
          providers.push(item.provider);
          }
        });
        result = providers.join(';');
        break;
      case 'tos':
        let tosCodes = [];
        this.filteredTOSList.forEach(item => {
          if (item.selected && tosCodes.indexOf(item.tos) < 0) {
            tosCodes.push(item.tos + ':' + (item.tosdesc == "" ? "Unallocated" : item.tosdesc ));
          }
        });
        result = tosCodes.join(';');
        break;
      case 'rule':
        let rules = [];
        let index = 1;
        this.filteredRuleList.forEach(item => {
          if (item.selected) {
            rules.push('Rule ' + index);
          }
          index++;
        });
        result = rules.join(';');
        break;
      default:
    }

    if (result.length > 200) {
      result = result.substring(0, 200) + '...';
    }

    return result;
  }

  clearSelection(type: string) {
    switch (type) {
      case 'country':
        this.filteredCountryList.forEach(item => {
          item.selected = '';
        });

        this.selectedCounter.country = 0;
        this.partitionService.filterIso2list = [];
        break;
      case 'provider':
        this.filteredProviderBillingIdList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.provider = 0;
        this.partitionService.filterProviderList = [];
        break;
      case 'tos':
        this.filteredTOSList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.tos = 0;
        this.partitionService.filterTosList = [];
        break;
      case 'rule':
        this.filteredRuleList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.rule = 0;
        this.partitionService.filterRuleIdList = [];
        break;
      default:
    }
  }

  multiSortMeta: SortMeta[] = [];
  pageLoading=false;
  saveEvent: any;

  loadPartitionDataDetails(event) {
    if (this.pageLoading){
      if ( JSON.stringify(this.saveEvent) === JSON.stringify(event) ) 
        return;
    }
    this.pageLoading = true;   

    const { first, rows, multiSortMeta, globalFilter } = event;

    const pageNo = first / rows;
    // this.pageNo = pageNo;
    // this.limit = rows;
    // this.multiSortMeta = multiSortMeta;
    this.globalFilter = globalFilter;
    console.log("Change partition id old/new", this.partitionService.selectedPartition.id, this.partitionService.selectedExportHistory.partitionId);
    this.partitionService.selectedPartition.id= this.partitionService.selectedExportHistory.partitionId    
    this.saveEvent = event;

    this.partitionService.pagination(pageNo, rows, multiSortMeta, globalFilter, "Exported", () => {this.pageLoading = false;});
  }



}
