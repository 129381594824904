import { Type, Skip } from 'serializer.ts/Decorators';
import { ListUploadRequest} from './listUploadRequest.model';
import {ListDetails} from './listDetails.model';
// map to UserDefinition.java

export class ListDefinition {
  id: number;
  customerName: string;
  listName: string;
  type: string;
  listSize = 0;
  description: string;
  details : string;
  active: boolean;
  createTimestamp: Date;
  createBy: string;
  lastUpdated: Date;
  lastUpdatedBy: string;
  lastUploadStatus = '';
  selected: boolean = false;

  //for handle upload request
  fileName: string;

  //@Type(() => ListUploadRequest)
  listUploadRequests: Array<ListUploadRequest> = [];

  listData: Array<ListDetails> = [];

  constructor(listName?: string, listType?: string) {
      this.listName = listName;
      this.type = listType;
      this.listSize = 0;
   }
}