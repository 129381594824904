<div class="row">
    <h3 class="col-md-12 text-center">
        {{partitionService.selectedPartition.name}} {{ '{' }}{{partitionService.selectedExportHistory.status=="Failed" ? "Failed" : "Exported"}}{{ '}' }} Dial Patterns in Rule {{partitionService.getRuleIndexFromExportedPartition(rule.id.toString())}}
    </h3>
</div>
<div class="row">
    <div class="col-md-2">
        <a class="link" (click)="goBack()"><i class="fa fa-arrow-left fa-lg" aria-hidden="true" style="padding-right: 5px;"></i><font  style="font-family:Open Sans;font-size:12px">Back to Summary</font></a>
    </div>
</div>

<div *ngIf="partitionService.selectedRule">
    <div class="ui-grid ui-grid-responsive ui-fluid" style="font-size:14px;padding:20px;border-bottom:1px solid #D5D5D5;">
        <div class="ui-grid-row">
            <div class="ui-grid-col-2">
                <div class="w3-margin-left">
                    <label class="radio-inline"><input type="radio" name="datasource" disabled [checked]="rule.dataSource==='Range'" >Range</label>
                </div>

                <div class="w3-margin-left">
                    <label class="radio-inline"><input type="radio" name="datasource" disabled [checked]="rule.dataSource==='IPRN'">IPRN</label>
                </div>

                <div class="w3-margin-left" *ngIf="rule.dataSource==='IPRN'">
                    <!-- //TODO Prime-1-->
                    <div class="row w3-margin-left">
                        <label class="radio-inline"><input type="radio" name="dialPatternType" disabled [checked]="rule.dialPatternType === 'PRIME-1'">Prime-1</label>
                    </div>
                    <div class="row w3-margin-left">
                        <label class="radio-inline"><input type="radio" name="dialPatternType" disabled [checked]="rule.dialPatternType === 'PRIME-2'">Prime-2</label>
                    </div>
                    <div class="row w3-margin-left">
                        <label class="radio-inline"><input type="radio" name="dialPatternType" disabled [checked]="rule.dialPatternType === 'PRIME-3'">Prime-3</label>
                    </div>
                    <div class="row w3-margin-left">
                        <label class="radio-inline"><input type="radio" name="dialPatternType" disabled [checked]="rule.dialPatternType === 'PRIME-4'">Prime-4</label>
                    </div>
                </div>
            </div>

            <div class="p-grid-col-8">
                <div class="p-grid p-grid-responsive p-fluid">
                    <div style="cursor: pointer;" class="w3-card-rule col-md-3 w3-center" (click)="openFilterInPartition('country')" *ngIf="mySelectedCounter.country>0">
                        <div class="w3-container margin-top5">
                            <p class="w3-small">Country Code & ISO2</p>
                        </div>

                        <div class="w3-container">
                            <p class="w3-small">{{getTipContents(rule.rangeQueryFilter, 'country')}}</p>
                        </div>
                    </div>
                    <div style="cursor: pointer;" class="w3-card-rule col-md-3 w3-center" (click)="openFilterInPartition('tos')" *ngIf="mySelectedCounter.tos>0">
                        <div class="w3-container margin-top5">
                            <p class="w3-small">TOS & TOS Description</p>
                        </div>

                        <div class="w3-container">
                            <p class="w3-small">{{getTipContents(rule.rangeQueryFilter, 'tos')}}</p>
                        </div>
                    </div>
                    <div style="cursor: pointer;" class="w3-card-rule col-md-2 w3-center" (click)="openFilterInPartition('provider')" *ngIf="mySelectedCounter.provider>0">
                        <div class="w3-container margin-top5">
                            <p class="w3-small">Provider</p>
                        </div>

                        <footer class="w3-container">
                            <p class="w3-small">{{mySelectedCounter.provider}} selected</p>
                        </footer>
                    </div>
                    <div class="w3-card-rule-right col-md-3 w3-center" *ngIf="rangeFilter.numOfMonthsSinceLastObserved">
                        <div class="w3-container margin-top5">
                            <p class="w3-small">Last Observed</p>
                        </div>

                        <footer class="w3-container">
                            <p class="w3-small">{{rangeFilter.numOfMonthsSinceLastObserved}} months</p>
                        </footer>

                    </div>
                </div>
            </div>

        </div>
    </div>


            <app-filtered-table  
                [hasRuleFilter]="false"
                [draftOrExported]="'Exported'"
                [partition]="partitionService.selectedExportPartition"
                [ruleId]="rule.id"
                [cacheservice]="cacheService"
                ></app-filtered-table>
