/**
 * Enum of minimum lengths for standard fields.
 */
 export enum ListTypes {
    BlackList = 'BL',
    WhiteList = 'WL',
    TrustedPartnerList = 'TP'
   }
   
export enum ActionName {
   NoAction = '',
   CreateRule = 'createrule',
   CreateRuleRangeFilter= 'createrulerangefilter',
   CreateRuleIprnFilter = 'createruleiprnfilter',

   NumberingDataRangeIPRNDropdown = 'ndrangeiprnselection',
   NumberingDataMSRNDropdown = 'ndmsrnselection',
   
   NumberingDataRangeFltr = 'ndrangefilter',
   NumberingDataIPRNFltr = 'ndiprnfilter',
   NumberingDataMSRNFltr = 'ndmsrnfilter',
   
   NumberingDataRangeDownloadAll = 'ndrangedownloadall',
   NumberingDataIPRNDownloadAll = 'ndiprndownloadall',
   NUmberingDataMSRNDownloadAll = 'ndmsrndownloadall',

   NumberingDataRangeDownloadFltr = 'ndrangedownloadfilter',
   NumberingDataIPRNDownloadFltr = 'ndiprndownloadfilter',
   NumberingDataMSRNDownloadFltr = 'ndmsrndownloadfilter',

   PartitionDraftDownload = 'partitiondraftdownload',
   PartitionDraftRulesDialPtn ='partitiondraftrulesdialptn',
   PartitionDraftRulesDialPtnDownload='partitiondraftrulesdialptndownload',
   PartitionExportLink ='partitionexportlink',
   PartitionExportDownload ='partitionexportdownload',
   PartitionExportRulesDialPtn = 'partitionexportrulesdialptn',
   PartitionExportRulesDialPtnDownload = 'partitionexportrulesdialptndownload'
}

export enum DataSource{
   Premium = 'IPRN',
   Range = 'Range'
}

export enum PremiumType{
   PrimeMinus1 = 'Prime-1',
   PrimeMinus2 = 'Prime-2',
   PrimeMinus3 = 'Prime-3',
   PrimeMinus4 = 'Prime-4',
}

export enum CustomGroupsType{
   CountryGroup = 'CountryGroup',
   TosGroup = 'TosGroup',
   ProviderGroup = 'ProviderGroup'
} 