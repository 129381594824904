<h3 class="col-md-12 text-center">Create A Rule</h3>
<div class="row">
    <div class="col-md-2 w3-margin-left">
        <p><strong>1. Choose Data Source</strong></p>
        <label [class.datasource-bold]="dataSource == 'Range'">Range</label>
        <label class="switch">
            <input type="checkbox" [checked]="ruleDef.dataSource === 'IPRN'" (change)="selectDataSource($event)">
            <div class="slider round"></div>
        </label>
        <label [class.datasource-bold]="dataSource == 'IPRN'">IPRN</label>

        <div class="w3-margin-left w3-margin-top" *ngIf="ruleDef.dataSource==='IPRN'">
            <span class="help-text">Choose a Dial Pattern Type</span><sup><i class="fa fa-asterisk fa-1" aria-hidden="true"></i></sup>
            <div class="w3-margin-left">
                <p-dropdown [options]="dpTypes" [(ngModel)]="ruleDef.dialPatternType" (onChange)=selectDialPattern($event)></p-dropdown>
            </div>
        </div>
        <p class="w3-margin-top help-text"><em>Decide whether you want to use range or IPRN data</em></p>
    </div>

    <div class="col-md-7">
        <p><strong>2. Build Rule Set</strong></p>
        <div class="col-md-12">
            <div class="w3-card-rule col-md-3 w3-center" style="border-radius: 4px;border: 1px solid #d71b36;cursor: pointer;" (click)="togglePanel(1);showResult=false; openFilter('country');rangeOverlay.toggle($event);">
                <div class="w3-container margin-top5">
                    <p class="w3-small">Country Code & ISO2</p>
                </div>

                <footer class="w3-container" *ngIf="selectedCounter.country && showResult">
                    <p class="w3-small">{{getTipContents(rangeFilter, 'country')}}</p>
                </footer>
            </div>
            <div class="w3-card-rule col-md-3 w3-center" style="border-radius: 4px;border: 1px solid #d71b36;cursor: pointer;" (click)="togglePanel(2);showResult=false; openFilter('tos');rangeOverlay.toggle($event);">
                <div class="w3-container margin-top5">
                    <p class="w3-small">TOS & TOS Description</p>
                </div>

                <footer class="w3-container" *ngIf="selectedCounter.tos && showResult">
                    <p class="w3-small">{{getTipContents(rangeFilter, 'tos')}}</p>
                </footer>
            </div>
            <div class="w3-card-rule col-md-2 w3-center" style="border-radius: 4px;border: 1px solid #d71b36;cursor: pointer;" (click)="togglePanel(3);showResult=false; openFilter('provider');rangeOverlay.toggle($event);">
                <div class="w3-container margin-top5">
                    <p class="w3-small">Provider</p>
                </div>

                <footer class="w3-container" *ngIf="selectedCounter.provider && showResult">
                    <p class="w3-small">{{selectedCounter.provider}} selected</p>
                </footer>
            </div>
            <div class="w3-card-rule-right col-md-3 w3-center" *ngIf="dataSource!=='Range'" style="border-radius: 4px;border: 1px solid #d71b36;cursor: pointer;" (click)="togglePanel(4);openFilter('lastObserved');rangeOverlay.toggle($event);">
                <div class="w3-container margin-top5">
                    <p class="w3-small">Last Observed</p>
                </div>

                <footer class="w3-container" *ngIf="rangeFilter.numOfMonthsSinceLastObserved && showResult">
                    <p class="w3-small">{{rangeFilter.numOfMonthsSinceLastObserved}} months</p>
                </footer>
            </div>
        </div>
    </div>

    <div class="col-md-2">
        <p><strong>3. Apply To Partition</strong></p>
       <p-multiSelect #pt [options]="partitionList" [(ngModel)]="partitionSelection" filter="filter" filterPlaceHolder="Select value(s)" optionLabel="name" placeholder="Select one or more partitions" selectedItemsLabel="{0} selected" (onChange)="assignPartition(partitionSelection)" maxSelectedLabels="1" resetFilterOnHide="true" [style]="{'width':'250px'}"></p-multiSelect>
       <div class="row" style="margin-left:0px; margin-top:10px;">
            <span class="w3-text-gray">Add rules to one or more partitions. Go to <a [routerLink]="['/partitions']">Manage Partitions</a> to make changes to existing rules.</span>
        </div>
        <div class="row" *ngIf="!ruleDef.id">
            <button id="saveRuleButton" type="button" class="btn btn-primary pull-right w3-margin-top no-uppercase no-padding" style="width:70px;height:26px" [disabled]="ruleDef.partitions.length < 1" (click)="saveRule()">Save</button>
            <button id="clearSelectionButton" type="button" class="btn btn-primary pull-right w3-margin-top w3-margin-left no-uppercase no-padding" style="width:70px;height:26px" (click)="newRule()">Clear</button>
        </div>
    </div>
</div>

<p-overlayPanel #rangeOverlay [styleClass]="'range-overlay'">
            <div class="container">
                <div class="w3-bar">
                        <div class="col-md-2"><label class="w3-bar-item w3-margin-right">Filter By: </label></div>
                        <div class="col-md-8">
                            <div>
                                <span class="w3-text-blue w3-small">Selected filters</span>
                            </div>
                            <div class="background-gray">
                                <span class="w3-small"><strong>Country code & ISO2:</strong></span>
                                <div>
                                    <span class="w3-small" style="height:auto">
                                        <span class="w3-small" style="height:auto">{{showMoreCountry ? getSeletionValues('country',true) : getSeletionValues('country') }}</span>
                                    </span>
                                </div>         
                                <a class="link" (click)="toggleMore('country')">{{ showMoreCountry ? "Show Less" : "Show more"}}</a>
                                <a class="link space-left" (click)="clearSelected('country')">Clear All</a>
                            </div>
                            <div class ="background-gray" style="height: 10px"></div>
                            <div class="background-gray">
                                <span class="w3-small"><strong>TOS & TOS Desc:</strong></span>
                                <div *ngIf = "showMoreTos === true">
                                    <span class="w3-small">{{getSeletionValues('tos',true)}}</span>
                                </div>
                                <div *ngIf = "showMoreTos === false">
                                    <span class="w3-small" style="height:auto">{{getSeletionValues('tos')}}</span>
                                </div> 
                                <a class="link" (click)="toggleMore('tos')">{{ showMoreTos ? "Show Less" : "Show More"}}</a>
                                <a class="link space-left" (click)="clearSelected('tos')">Clear All</a>
                            </div>
                            <div class ="background-gray" style="height: 10px"></div>
                            <div class="background-gray">
                                <span class="w3-small"><strong>Provider:</strong></span>
                                <div *ngIf = "showMoreProvider === true">
                                    <span class="w3-small">{{getSeletionValues('provider',true)}}</span>
                                </div>
                                <div *ngIf = "showMoreProvider === false">
                                    <span class="w3-small" style="height:auto">{{getSeletionValues('provider')}}</span>
                                </div> 
                                <a class="link" (click)="toggleMore('provider')">{{ showMoreProvider ? "Show Less" : "Show More"}}</a>
                                <a class="link space-left" (click)="clearSelected('provider')">Clear All</a>
                            </div>
                            <div class="background-gray" *ngIf="ruleDef.dataSource==='IPRN'">
                                <span class="w3-small"><strong>Date:</strong></span>    
                                <span class="w3-small">{{rangeFilter.numOfMonthsSinceLastObserved}}</span>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-primary w3-margin-right no-uppercase no-padding" style="width:70px;height:26px" (click)="closeAllFilter();rangeOverlay.hide();">Apply</button>
                            <button type="button" class="btn btn-primary w3-margin-right no-uppercase no-padding"  style="margin-top: 10px;width:70px;height:26px" (click)="rangeOverlay.hide();">Close</button>
                        </div>
                    </div>
                <div id ="country" *ngIf= "selectedPanel === 1">
                    
                    <div class="col-md-1 w3-margin no-padding">
                        <div class="row"> 
                        <button class="col-md-3   btn  alpha-button no-uppercase w3-margin-right w3-margin-bottom no-padding"  [disabled]="blockingCountrySearch" style="width:120px;height:24px" [ngClass]="{'btn-default': groupSelected, 'btn-inverse': !groupSelected}"  (click)="showCountryGroups()">Country Groups</button>
                        </div>
                        <div class="row">
                        <ng-container *ngFor="let item of alphaSearchPattern">
                            <div class="row w3-margin-left"> 
                            <button class="col-md-2 alpha-button btn w3-margin-left w3-margin-bottom" [disabled]="blockingCountrySearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
                            </div>
                        </ng-container>
                        </div>
                    </div>
                    <div class="row col-md-10 w3-leftbar">
                        <div>
                            <div class="w3-margin-top col-md-4">
                                <input type="text" name="searchText" class="search-query w3-margin-left" #country size="35" placeholder="Search Country, Code or ISO2 ..." (keyup)="filterCountry(country.value)">
                                <span class="clearer pi pi-times-circle" (click)="country.value=''; filterCountry('')"></span>
                            </div>
                            <div class="w3-margin-top col-md-4 col-sm-4 text-center">
                                    <a class="link" (click)="selectAll('country')">SELECT ALL &lt;{{countryFilterLabel}}&gt;</a>
                            </div>
                            <div class="w3-margin-top col-md-4 col-sm-4 text-center">
                                    <a class="link" (click)="clearSelectedFilter('country',countryFilterLabel)">CLEAR ALL &lt;{{countryFilterLabel}}&gt;</a>
                            </div>
                        </div>
                            <div>
                                <ng-container *ngIf= "groupSelected">
                                    <div  class="col-md-8 w3-margin-top">
                                        <table>
                                            <tr>
                                                <td class="w3-margin-bottom col-md-3 top-align country-groups" style="max-width:200px; width:25% ; min-width:200px"><span class="w3-left-align w3-text-blue col-md-5"><strong>Country Groups</strong></span></td>
                                                <td class="w3-margin-bottom col-md-9 top-align">
                                                    <span class="w3-left-align w3-margin-left  w3-text-blue col-md-4">Country</span>
                                                    <span class="w3-left-align w3-text-blue col-md-1">Code</span>
                                                    <span class="w3-left-align w3-margin-left w3-text-blue col-md-1">ISO2</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf= "!groupSelected">
                                    <div  class="w3-margin-top col-md-3 col-sm-4">
                                        <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                                        <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                                        <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
                                    </div>
                                </ng-container>
                            </div>
                                
                            <div class="w3-margin-top; position: relative">
                            <ng-container *ngIf= "groupSelected" >
                                <table>
                                <tr>
                                    <td class="w3-margin-bottom col-3 no-padding aligntop" style="height:60%; max-width:200px; width:25% ; min-width:200px">
                                        <ng-template ngFor let-cGroup [ngForOf]="cGroups">
                                            <div class="col-sm-8 lists-group-horiz-padding" >
                                                <a id="{{cGroup.id}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="filterCountryByGroupSelect(cGroup)" [class.selected]="cGroup.selected">{{cGroup.name}}</a>
                                            </div>
                                                <div [hidden]="!cGroup.selected" class="col-sm-9 lists-group-horiz-padding">
                                                <hr/>
                                            </div>
                                        </ng-template>
                                    </td>
                                    <td class="w3-leftbar w3-margin-bottom col-md-9 no-padding aligntop" style="height:60%; ">
                                        <ul class="list-group list-group-horizontal f32">
                                            <li class="list-group-item col-md-4 col-sm-4" *ngFor="let country of filteredCountryList">
                                                <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                                                    <div class="w3-container">
                                                        <span class="w3-left-align col-md-7">{{ country.country }}</span>
                                                        <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                                        <span class="w3-left-align col-md-1">{{ country.iso2 }}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </table>
                            </ng-container>
                            <ng-container *ngIf= "!groupSelected" >
                                <ul class="list-group list-group-horizontal f32">
                                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of filteredCountryList">
                                        <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                                            <div class="w3-container">
                                                <span class="w3-left-align col-md-8">{{ country.country }}</span>
                                                <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                                <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div> 
                <div id = "tos" *ngIf= "selectedPanel === 2">
                    <div class="col-md-1">
                        <div *ngFor="let item of tosSearchPattern">
                            <button class="col-md-12 btn alpha-button w3-margin-right w3-margin-bottom" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterTos(item.pattern, item.label)">{{item.label}}</button>
                        </div>
                    </div>
                    <div class="col-md-11 w3-leftbar">
                        <div class="row w3-margin-top">
                            <span class="w3-left-align w3-text-blue col-md-4">TOS:TOS Description</span>
                            <div class="col-md-4">
                                    <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #tos placeholder="Search TOS Description ..." (keyup)="filterTosDesc(tos.value)">
                                    <span class="clearer pi pi-times-circle" (click)="tos.value=''; filterTosDesc('')"></span>
                            </div>                    
                            <div class="w3-margin-top col-md-2 col-sm-4 text-center">
                                    <a class="link" (click)="selectAll('tos')">SELECT ALL &lt;{{filterTosLabel}}&gt;</a>
                            </div>
                            <div class="w3-margin-top col-md-2 col-sm-4 text-center">
                                    <a class="link" (click)="clearSelectedFilter('tos',filterTosLabel)">CLEAR ALL &lt;{{filterTosLabel}}&gt;</a>
                            </div>
                        </div>
                        <div class="row w3-margin-top">
                            <ul class="list-group list-group-horizontal f32">
                                <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of filteredTOSList">
                                    <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                                        <div class="w3-container">
                                            <div class="text-start">{{item.tos}}: {{item.tosdesc || "Unallocated"}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="provider" *ngIf= "selectedPanel === 3">
                    <div class="col-md-1">
                        <div *ngFor="let item of alphaNumSearchPattern">
                            <button class="col-md-12 btn alpha-button w3-margin-right w3-margin-bottom" [disabled]="blockingProviderSearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterProvider(item.pattern, item.label)">{{item.label}}</button>
                        </div>
                    </div>
                    <div class="col-md-11 w3-leftbar">
                        <div class="row w3-margin-top">
                            <span class="w3-center-align w3-text-blue w3-margin-left col-md-2">Provider</span>
                            <div class="col-md-4 w3-margin-top">
                                <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #provider placeholder="Search Provider ..." (keyup)="filterProviderSearch(provider.value)">
                                <span class="clearer pi pi-times-circle" (click)="provider.value=''; filterProviderSearch('')"></span>
                            </div>
                            <div class="w3-margin-top col-md-2 text-center">
                                <a class="link" (click)="selectAll('provider')">SELECT ALL &lt;{{filterProviderLabel}}&gt;</a>
                            </div>
                            <div class="w3-margin-top col-md-2 col-sm-4 text-center">
                                <a class="link" (click)="clearSelectedFilter('provider',filterProviderLabel)">CLEAR ALL &lt;{{filterProviderLabel}}&gt;</a>
                            </div>
                        </div>
                        <div class="row w3-margin-top">
                            <ul class="list-group list-group-horizontal f32">
                                <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                                    <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                                        <div class="w3-container">
                                            <div class="text-start">{{item.provider || "Unallocated"}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="lastObserved" *ngIf="ruleDef.dataSource==='IPRN' && selectedPanel === 4">
                    <div class="row">
                        <div class="col-md-3 text-end helperText1">
                            Show Records with fraud from
                        </div>    
                    </div>
                    <div class="row">
                        <div class="col-md-3 text-end helperText2">
                            Rolling Date:
                        </div>
                        <div class="col-md-6 text-start">
                            <input type="number" min="1" max="99" (keyup)="limitMaxValue($event.target)" id="lastObserved" name="lastObserved" [(ngModel)]="rangeFilter.numOfMonthsSinceLastObserved">
                            <span class="w3-margin-left helperText2"># month(s) before the current month</span>
                        </div>
                    </div>        
                    <div class="row">
                        <div class="col-md-3 text-end helperText3">
                            Maximum of 99 months
                        </div>    
                    </div>
                </div>
        </div>
</p-overlayPanel>

    <div class="row w3-margin-top w3-margin-left w3-margin-right" id="dataArea" *ngIf="filteredRangeDataList.length > 0 || showResult">
    <p-table #dt [columns]="tableCols" [value]="filteredRangeDataList" [rows]="10" [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[10,30,50,100,250,500,750,1000]" 
    resizableColumns="true" columnResizeMode="expand" [globalFilterFields]="filterFieldCols" styleClass="p-datatable-striped"
    [style]="{'margin-top':'30px'}" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="caption">
          <div class="p-table-globalfilter-container">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input pInputText #gb id="searchInput" type="text" size="50" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            <span class="pi pi-times-circle" (click)="clearSearch(gb, dt);"></span>
          </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th class="code-tbl-header" [style.width.%]="col.width" *ngFor="let col of columns"
                  [pSortableColumn]="col.field" [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                  tooltipPosition="top">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
              <td class="code-tbl-body" *ngFor="let col of columns">
                <span *ngIf="col.field!='effectiveDate' && col.field!='lastUpdate'">
                    {{rowData[col.field]}}
                </span>
                <span *ngIf="col.field=='effectiveDate'">
                    {{rowData[col.field] | date:'yyyy-MM-dd'}}
                </span>
                <span *ngIf="col.field=='lastUpdate'">
                    {{rowData[col.field] | date:'yyyy-MM'}}
                </span>
              </td>
          </tr>
      </ng-template>
      <ng-template id="dtFooter" pTemplate="footer">
          <tr>
            <td  [attr.colspan]="tableCols.length">
                {{rangeQueryStatus}} <i *ngIf="isLoading()" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
            </td>
          </tr>
      </ng-template>
      <!--
      <ng-template pTemplate="emptymessage" let-rows>
          <tr>
              <td [attr.colspan]="rows.length">
                  No records.
              </td>
          </tr>
      </ng-template>
      -->
    </p-table>
</div>
