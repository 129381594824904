import { Type, Skip } from 'serializer.ts/Decorators';
import { PartitionDefinition, RangeFilter } from './index';
import {JsonHelper} from '../util';
// map to RuleDefinition.java

export class RuleDefinition {
  id: number;

  partitionId: number;
  dataSource: string;
  details: RangeFilter;
  dialPatternType: string;
  active: boolean;

  createTimestamp: Date;
  createdBy: string;

  lastUpdated: Date;
  lastUpdatedBy: string;
  listRefId: string;
  fileName: string;
  delimiter: string;
  status: string;
  errorData: string;

  rangeQueryFilter: string;

  partitions: Array<PartitionDefinition> = [];

  selectedCountry = 0;
  selectedTSO = 0;
  selectedProvider = 0;
  noOfMonth = '';

  selected: string;

  constructor() { }

  addPartition(partition: PartitionDefinition) {
    this.partitions.push(partition);
  }

  removePartition(partition: PartitionDefinition) {
    const index = JsonHelper.findElementPosById(this.partitions, partition.id);
    if (index >= 0) {
      this.partitions.splice(index);
    }
  }

}
