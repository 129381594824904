import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RulePartitionBase } from './rule-partition-base';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, PartitionDefinition, PartitionDataDetails, PartitionExportHistory, RuleDefinition } from '../models/index';
import { ActionName } from 'app/shared/constants';


@Component({
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent extends RulePartitionBase implements OnInit, AfterViewInit {
  ruleDef: RuleDefinition = new RuleDefinition();
  showResult = false;
  showFilterBy = true;
  dpTypes: any[] = [];
  partitionSelection : PartitionDefinition[];
  iprnCols : any[];
  rangeCols : any[];
  tableCols : any[] = [];
  filterFieldCols: any[] = [];
  actionNameFilter: ActionName = ActionName.CreateRuleRangeFilter;
  @ViewChild('rangeOverlay') rangeOverlay: ElementRef;


  constructor(public log: Logger, public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService) {
    super(log, httpService, cacheService, notifService);

    this.iprnCols = [
      {field: 'dialPatternType', header: 'Prime', width: '50px'},
      {field: 'dialPattern', header: 'DialPattern', width: '50px'},
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'termCountry', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '75px'},
      {field: 'supplement', header: 'Supplement', width:'100px'},
      {field: 'provider', header: 'Provider', width:'75px'},
      {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
      {field: 'lastUpdate', header: 'Last Observed', width: '80px'}
    ];
    
    this.rangeCols = [
      {field: 'dialPattern', header: 'DialPattern', width: '50px'},
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'termCountry', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '75px'},
      {field: 'supplement', header: 'Supplement', width:'100px'},
      {field: 'provider', header: 'Provider', width:'75px'},
      {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
      {field: 'effectiveDate', header: 'Effective Date', width: '75px'},
    ];

    this.tableCols=[...this.rangeCols];
    this.tableCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
    
  }
  ngAfterViewInit(): void {
    (this.rangeOverlay as any).__proto__.show = function(event, target) {
      target && event && event.stopPropagation();
      this.target = target || event.currentTarget || event.target;
      this.overlayVisible = true;
      this.render = true;
      this.cd.markForCheck();
  }
  }


  ngOnInit() {
    super.init();
    //TODO Prime-1
    this.dpTypes.push({label:'PRIME-1', value:'PRIME-1'});
    this.dpTypes.push({label:'PRIME-2', value:'PRIME-2'});
    this.dpTypes.push({label:'PRIME-3', value:'PRIME-3'});
    this.dpTypes.push({label:'PRIME-4', value:'PRIME-4'});    
    this.showResult = false;
    this.isCreateRule = true;
    this.getPartitionList(null,ActionName.CreateRule);
    this.ruleDef = new RuleDefinition();
    this.ruleDef.dataSource = 'Range';
    this.dataSource = this.ruleDef.dataSource;
  }

  newRule() {
    super.init();
    this.dpTypes = [];
    this.partitionSelection = [];
    this.ngOnInit();
  }

  saveRule() {
    this.createRangeFilter();

    if ( this.ruleDef.dataSource === 'Range' && !this.rangeFilter.hasDefinedAnyFilter()) {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: 'You must select at least one filter value in order to save a Range rule' });
      return false;
    }

    this.ruleDef.details = this.rangeFilter;

    this.log.debug(JsonHelper.toJson(this.ruleDef));

    this.httpService.post(JsonHelper.toJson(this.ruleDef), AppConstants.CreateRuleURL, [this.postCreateRuleHandler, this.responseCallBack]);
  }

  postCreateRuleHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.newRule();
    }
  }

  assignPartition(selectedPartitions: PartitionDefinition[]) {
    this.ruleDef.partitions = [];
    selectedPartitions.forEach(p => {
      if (p.ruleDefinitions && p.ruleDefinitions.length === 50) {
        this.notifService.add({ severity: 'warn', summary: 'Info', detail: 'Partitions can have a maximum of 50 rules. ' + p.name + ' cannot be selected.' });
        const index = JsonHelper.findElementPosById(selectedPartitions, p.id);
        if (index >= 0) {
          selectedPartitions.splice(index);
        }
      } else {
        this.ruleDef.addPartition(p);
      }
    });
  }


  closeAllFilter() {
    $('.filter').hide(); 
    this.fetchRangeData(this.actionNameFilter,false, this.ruleDef);
    this.showResult = true;
    this.stopFetching();
  }

  cancelFilterUpdate() {
    $('.filter').hide();
    this.showResult = true;
  }


 async selectDataSource(event: any) {
    if (event.currentTarget.checked) {
      this.ruleDef.dataSource = 'IPRN';
      this.tableCols=[...this.iprnCols];
      this.actionNameFilter = ActionName.CreateRuleIprnFilter;
    } else {
      this.ruleDef.dataSource = 'Range';
      this.tableCols=[...this.rangeCols];
      this.actionNameFilter = ActionName.CreateRuleRangeFilter;
    }
    this.dataSource = this.ruleDef.dataSource;
    if (this.dataSource === 'Range') {
      this.ruleDef.dialPatternType = null;
      this.ruleDef.noOfMonth = undefined;
      this.rangeFilter.numOfMonthsSinceLastObserved = undefined;
    } else {
      this.ruleDef.dialPatternType = 'PRIME-2';
    }
    this.tableCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    //Send an empty action because we dont want to log an action every time for selection of toggle
    this.fetchRangeData(ActionName.NoAction,false, this.ruleDef);
    this.showResult = true;
  }

  async selectDialPattern(event) {
    this.ruleDef.dialPatternType = event.value;
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    this.fetchRangeData(this.actionNameFilter,false, this.ruleDef);
    this.showResult = true;
  }


}
