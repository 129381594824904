import { Injectable } from '@angular/core';
import {Message, MessageService} from 'primeng/api';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import '../util/rxjs-operators';
import { JsonHelper } from '../util/json.helper';
import { Logger } from '../services/logger.service';
import { AppConstants, CB } from '../util/index';
import { HttpService } from '../services/http.service';
import { ResponseJson, EditItem, ListDefinition, ListUploadRequest, ListDetails } from '../models/index';

@Injectable()
export class NotificationService {
  checkStatusSubscription: Subscription;
  selectedList: ListDefinition;
  listUploadStatus = '';

  constructor(private log: Logger, private httpService: HttpService, private messageService: MessageService) { };

  //this.notifService.add({ severity: 'info', summary: '', detail: event.message });
  //success, info, warn, error
  async add(msg:Message) {
    if (msg.severity === 'error') {
      this.addSticky(msg);
    } else {
      this.addMessage({severity: msg.severity, summary: msg.summary, detail: msg.detail});
    }
  }

  async addSticky(stickyMsg:Message) {
    this.addMessage({severity: stickyMsg.severity, summary: stickyMsg.summary, detail: stickyMsg.detail, sticky: true});
  }


  addMessage(msg:Message){
    console.debug("addMessage:", msg);
    if (this.filterRemove(msg)){
      console.debug("filterRemove:", msg);
      return;
    }

    this.messageService.add(msg);

  }



  filterRemove(msg: Message):boolean {
    let str = msg.detail;
    if (str.startsWith("Successfully save partition"))
      return true;
      
    if (str.includes("is stale because has different"))
      return true;

    if (str.includes("rule update"))
      return true;
    
    if (str.includes("rule was removed"))
      return true;
  
    if (str.includes("because new rule has been added"))
      return true;
  
    if (str.includes("because new MobileID dataset"))
      return true;

    return false;
  }



  //get list status change event
  queryListStatusUpdate(selectedList: ListDefinition) {
    this.selectedList = selectedList;
    this.checkStatusSubscription = interval(3000).subscribe(() => {
      let url = AppConstants.QueryLatestListUploadRequest;

      if (selectedList.id) {
        url += '?listId=' + selectedList.id;
      } else {
        url += '?listName=' + selectedList.listName + '&listType=' + this.selectedList.type;
      }

      this.log.debug('query upload status: ' + url);
      this.httpService.get(url, [this.postQueryLatestListUploadRequestHandler]);
    }
    );
  }

  postQueryLatestListUploadRequestHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      const listUploadRequest: ListUploadRequest = JsonHelper.parse(responseJson.data, ListUploadRequest);
      if (listUploadRequest && listUploadRequest.status !== this.listUploadStatus) {
        this.listUploadStatus = listUploadRequest.status;
        this.log.debug('list upload status: ' + this.listUploadStatus);

        if (this.listUploadStatus === 'fail') {
          this.add({ severity: 'error', summary: 'List Upload', detail: 'Failed to process upload file for list ' + this.selectedList.listName});
          this.checkStatusSubscription.unsubscribe();
        } else if (this.listUploadStatus === 'complete') {
          this.add({ severity: 'info', summary: 'List Upload', detail: 'Successfully processed upload file for list ' + this.selectedList.listName});
          this.checkStatusSubscription.unsubscribe();
        } else {
          this.add({ severity: 'info', summary: 'List Upload', detail: 'Processing upload list ' + this.selectedList.listName });
        }
      }
    }
  }
}
