import {serialize, deserialize} from 'serializer.ts/Serializer';
import {JsonHelper} from '../util';

export class EditItem<T> {
  editing: boolean;
  origItem: T;

  constructor (public item: T, private myClass: new (name: string) => T) {
    this.origItem = this.convert(item);
  }

  reset() {
    this.item = this.convert(this.origItem);
  }

  update() {
    this.origItem = this.convert(this.item);
  }

  hasChange(): boolean {
    return !(JSON.stringify(this.origItem) === JSON.stringify(this.item));
  }

  private convert(item: T) {
    if (this.myClass) {
      return JsonHelper.parse(JSON.parse(JsonHelper.toJson(item)), this.myClass);
    } else {
        //this does NOT work for complex data object
        return Object.assign({}, item);
    }
  }
}