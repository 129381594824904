import { Component, OnInit } from '@angular/core';
import { ListsService } from '../lists/lists.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import {BaseComponent} from '../util/base.component';
import { AppConstants } from '../util/index';
import { ResponseJson } from '../models/index';

@Component({
  selector: 'app-partition-help',
  templateUrl: './partition-help.component.html',
  styleUrls: ['./partition-help.component.scss']
})
export class PartitionHelpComponent extends BaseComponent implements OnInit {

  maxPartitions:number;

  constructor(public listService: ListsService, public notifService: NotificationService, private httpService: HttpService) {
    super(notifService);
  }
  ngOnInit() {
    this.getMaxValues();
  }

  getMaxValues() {
    this.httpService.get(AppConstants.QueryMaxValues, [this.postGetMaxValues]);
  }

  postGetMaxValues = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.maxPartitions = responseJson.data.maxPartitions;
    }
  }

}
