import { AppConstants } from './app.constant';
import { ResponseJson } from './../models/response.model';
import { NotificationService } from '../services/notification.service';
import { Table } from 'primeng/table';
import * as moment from 'moment';
const FileSaver = require('file-saver');

export class BaseComponent {
  response: ResponseJson;
  style = 'text-success';
  message: string;

  constructor(public notifService: NotificationService) {
  }

  downloadFile(data, fileName, type: string = 'text/csv;charset=utf-8') {
    let blob = new Blob([data], { type: type});
    FileSaver.saveAs(blob, fileName);
  }

  filterStr(origStr: string, searchValue: any, replaceStr: string): string {
    return origStr.replace(searchValue, replaceStr);
  }

  digitOnly(value: string): string {
    return value.replace(/\D/g, '');
  }

  responseMsgInLineCallBack = (response: any) => {
    if (response instanceof ResponseJson) {
      this.$response = response;
    } else {
      this.style = 'text-danger';
      this.message = response;
    }

  }
	/*
		handle application error and server error,
		set status to corresponding bootstrap class text-danger or text-success
	*/
  responseCallBack = (response: any) => {
    if (response instanceof ResponseJson) {
      if (response.status === AppConstants.FAIL) {
        this.notifService.add({ severity: 'error', summary: 'Error', detail: response.message });
      } else {
        if (response.message && response.message.length > 1) {
          this.notifService.add({ severity: 'success', summary: 'Success', detail: response.message });
        }
      }
    } else {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: response });
    }

  }

  init() {
    this.response = null;
    this.message = '';
    this.style = 'text-success';
  }


  public get $response(): ResponseJson {
    return this.response;
  }

  public set $response(value: ResponseJson) {
    this.response = value;
    this.message = this.response.message;

    if (this.response.status === AppConstants.FAIL) {
      this.style = 'text-danger';
    } else {
      this.style = 'text-success';
    }
  }

  public setMessage(status: string, message: string) {
    if (status === AppConstants.FAIL) {
      this.style = 'text-danger';
    } else {
      this.style = 'text-success';
    }
    this.message = message;
  }


  clearSearch(searchCtl: any, dataTable: Table ) {
    searchCtl.value = '';
    dataTable.reset();
  }


  // add some common functions
  removeSpace(value: string): string {
    return value.replace(' ', '');
  }

  makeDownloadFilename(prefix:string, ext:string):string{
    return  prefix.replace(/\s/g, '-') + moment().format('_YYYYMMDD_HHmmss') + ext;

  }


}
