import { OnInit, Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth';
import { User, Country, RangeNDC, ResponseJson } from '../models/index';
import '../util/rxjs-operators';
import { BaseComponent, AppConstants, JsonHelper, CB } from '../util';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { CacheService } from '../services/cache.service';
import { NotificationService } from '../services/notification.service';
import { EventService } from '../services/event.service';
import { ListsService } from '../lists/lists.service';
import jwt_decode from 'jwt-decode';

@Component({
  templateUrl: 'login.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  user: User = new User();
  returnUrl: string;
  private socketSubscription: Subscription;
  responseJson: any;
  showLegalAck: boolean = false;

  constructor(private log: Logger, private route: ActivatedRoute, private router: Router, public notifService: NotificationService,
    private authService: AuthService, 
    private httpService: HttpService, 
    private cacheService: CacheService, 
    private eventService: EventService,
    private listsService: ListsService) {
    super(notifService);
  }

  ngOnInit() {
    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home';
  }

  login() {
    this.log.debug(this.user.userName + ', ' + this.user.password);
    this.authService.login(this.user, (responseJson: ResponseJson) => { this.postLoginHandler(responseJson); }, this.responseMsgInLineCallBack);
  }

  postLoginAction() {
    this.log.debug('Successful logged in');
    this.router.navigate([this.returnUrl]);
  }

  postLoginHandler(responseJson: ResponseJson) {
    if (responseJson.status === 'success') {
      this.responseJson = responseJson;
      this.setupLoginUser();
      if (this.authService.loginUser.showTerms) {
        this.showLegalAck = true;
      } else {
        this.agreed();
      }
    } else {
      const status = responseJson.status;
      const message = responseJson.message;
    }
  }

  setupLoginUser() {
    const token = this.responseJson.data;
    const decoded = jwt_decode(token);
  //this.log.debug('decoded token: ' + decoded);    
    this.authService.loginUser = JsonHelper.parse(decoded, User);
    this.authService.loginUser.token = token;
  }

  async agreed() {
    this.showLegalAck = false;
    sessionStorage.setItem('currentUser', JsonHelper.toJson(this.authService.loginUser));
    this.log.debug('Successful logged in');
    console.log("Going to getGuiPolling");
    this.getGuiPollingEnabledSetting();
    await AppConstants.delay(800);
    console.log("Back from getGuiPolling");
    //this.router.navigate(['/lists']);
    if (this.authService.loginUser.hasList) {
      this.listsService.onSelect(this.authService.loginUser.listDefinition);
      this.router.navigate(['/lists/listDetail']);
    } else {
      //this.router.navigate([this.returnUrl]);
      this.router.navigate(['/lists']);
    }
    
    this.cacheService.cacheCountry();
    this.cacheService.cacheProviders();
    this.cacheService.cacheTos();
    this.cacheService.cacheCountryProvider();
    this.cacheService.queryUserEvents();
    this.eventService.pullUserEvents();
    this.cacheService.cacheMsrnProviders();
    //this.eventService.connect(this.authService.loginUser);
  }
  
  rejected() {
    this.showLegalAck = false;
    const status = this.responseJson.status;
    const message = this.responseJson.message;
  }
    
  showPassword(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  getGuiPollingEnabledSetting() {
    //console.log("STARTED getGuiPollingEnabledSetting");
    this.httpService.get(AppConstants.QueryGuiPollingEnabled, [this.postQueryGuiPollingEnabledHandler]);
    //console.log("AFTER the getGuiPollingEnabledSetting");
  }

  postQueryGuiPollingEnabledHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      if (responseJson.message == 'true') {
        this.authService.loginUser.guiPollingEnabled = true;
      } else {
        this.authService.loginUser.guiPollingEnabled = false;
      }
    } else {
      //this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
      this.authService.loginUser.guiPollingEnabled = true;
    }
    //console.log("postQuery after: " + this.authService.loginUser.guiPollingEnabled);
    
  }

}
