import { Injectable } from '@angular/core';
import { RangeFilter } from 'app/models';
import { AppConstants } from '../util/app.constant';
import { JsonHelper } from 'app/util';
import { HttpService } from './http.service';
import { AuditPartition } from 'app/models/auditPartition.model';

@Injectable()
export class CustomGroupService {

}