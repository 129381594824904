import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { FilterService } from '../filter/filter-service';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, PartitionDefinition, PartitionDataDetails, PartitionExportHistory, RuleDefinition, RangeFilter, ProviderBillingId, TosTosDesc } from '../models/index';
import { PartitionService } from '../partitions/partition.service';
import { ActionName } from 'app/shared/constants';

@Component({
  selector: 'app-partition-rule-detail',
  templateUrl: './partition-rule-detail.component.html',
  styleUrls: ['./partition-rule-detail.component.scss']
})
export class PartitionRuleDetailComponent extends RulePartitionBase implements OnInit {
  rule: RuleDefinition;
  showDataWin = false;
  showFilterWin = false;
  partitionRuleCols: any[];
  filterFieldCols: any[] = [];
  filterBy ='';
  dpTypes: any[] = [];

  constructor(private location: Location, private route: ActivatedRoute, private router: Router, public partitionService: PartitionService, public log: Logger,
    public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService, private confirmationService: ConfirmationService) {
    super(log, httpService, cacheService, notifService);

    this.partitionRuleCols = [
      {field: 'dialPattern', header: 'DialPattern', width: '50px'},
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'termCountry', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '70px'},
	    {field: 'supplement', header: 'Supplement', width:'100px'},
	    {field: 'provider', header: 'Provider', width:'100px'},
      {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
	    {field: 'dataSource', header: 'Source', width: '75px'},
	    {field: 'customerDate', header: 'Customer Date', width: '55px'},
	    {field: 'reason', header: 'Reason', width: '30px'},
      {field: 'notes', header: 'Notes', width: '25px'}
    ];

    this.partitionRuleCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
  }


  ngOnInit() {
    super.init();
    this.rule = this.partitionService.selectedRule;
    this.createFilterFromRule(this.rule);

    this.dataSource = this.rule.dataSource;
    this.fetchRangeData(ActionName.NoAction, true, this.rule);

    this.dpTypes.push({label:'PRIME-1', value:'PRIME-1'});
    this.dpTypes.push({label:'PRIME-2', value:'PRIME-2'});
    this.dpTypes.push({label:'PRIME-3', value:'PRIME-3'});
    this.dpTypes.push({label:'PRIME-4', value:'PRIME-4'});   
  }

  goBack() {
    this.location.back();
  }


  async selectDataSource(event: any) {
    if (event.currentTarget.checked) {
      this.rule.dataSource = 'IPRN';
    } else {
      this.rule.dataSource = 'Range';
    }

    this.dataSource = this.rule.dataSource;

    if (this.dataSource === 'Range') {
      this.rule.dialPatternType = '';
      this.rule.noOfMonth = undefined;
      this.rangeFilter.numOfMonthsSinceLastObserved = undefined;      
    } else {
      this.rule.dialPatternType = 'PRIME-2';
    }
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    this.fetchRangeData(ActionName.NoAction, true, this.rule);
  }

  async refreshRule() {
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    await this.fetchRangeData(ActionName.NoAction, true, this.rule);
    this.copyRangeFilterToRuleDef() //refresh rule.rangeQueryFilter with what resulted on filter box
    this.getTipContents(this.rule.rangeQueryFilter, this.filterBy);
  }
  
  copyRangeFilterToRuleDef() {
    this.rule.rangeQueryFilter['codeList'] = this.rangeFilter.codeList;
    this.rule.rangeQueryFilter['iso2List'] = this.rangeFilter.iso2List;
    this.rule.rangeQueryFilter['tosDescList'] = this.rangeFilter.tosDescList;
    this.rule.rangeQueryFilter['providerList'] = this.rangeFilter.providerList;
  }

  async selectDialPattern(event) {
    this.rule.dialPatternType = event.value;
    console.log(this.rule.dialPatternType);
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    this.fetchRangeData(ActionName.NoAction, true, this.rule);
  }

  saveRule() {
    this.createRangeFilter();
    this.partitionService.selectedRule.details = this.rangeFilter;

    if ( this.dataSource === 'Range' && !this.rangeFilter.hasDefinedAnyFilter()) {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: 'You must select at least one filter value in order to save a Range rule' });
      return false;
    }

    this.log.debug(JsonHelper.toJson(this.partitionService.selectedRule));

    this.httpService.post(JsonHelper.toJson(this.partitionService.selectedRule), AppConstants.UpdateRuleURL, [this.postUpdateRuleHandler, this.responseCallBack]);
  }

  postUpdateRuleHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.getPartitionList();
      this.partitionService.postAction(this.partitionList);

      this.goBack();
    }
  }


  openFilterInPartition(name: string) {
    this.showFilterWin = true;
    this.openFilter(name);
    this.filterBy = name;
  }


}
