<style>
    .hover-menu:hover ul {
        display: block;
    }
</style>

<!-- Header -->
<p-toast></p-toast>

<nav class="navbar navbar-default" id="main-nav" style="padding:0; margin: 0; background-color:black;">

    <!-- Brand and toggle get grouped for better mobile display -->

    <div class="navbar-header">
        <img class="logo" src="/assets/images/iconectiv.png" height="20">
        <!--<img src="/assets/images/iconectiv_Protect.png">-->

        <div *ngIf="authService.isAuthorized()">
            <button type="button" class="navbar-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#navbar-collapse" aria-expanded="false">
                <span class="visually-hidden">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
    </div>
    <span *ngIf="authService.isAuthorized()" class="product-name">TruNumber Protect</span>
    <a *ngIf="authService.isAuthorized()" class="btn btn-inverse margin-xs-top pull-right" style="width:85px;height:35px;" (click)="logout()">Log out</a>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="navbar-collapse" *ngIf="authService.isAuthorized()">
        <ul class="nav navbar-nav hidden-md hidden-lg">
            <li *ngIf="authService.hasRole('user', 'custAdmin') && (authService.isLoggedInAndHasList)">
                <a id="manageListsMenu1" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Manage Lists</a>
                <ul class="dropdown-menu" style="left: 15px;">
                    <li>
                        <a id="wlblMenu1" [routerLink]="['/lists/listDetail']" routerLinkActive="active">Allow & Block Lists</a>
                    </li>
                    <li>
                        <a id="tpMenu1" [routerLink]="['/tplist']" routerLinkActive="active">Trusted Partner Lists</a>
                    </li>
                    <li>
                        <a id="cgMenu1" [routerLink]="['/cglist']" routerLinkActive="active">Country Groups</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="authService.hasRole('user', 'custAdmin') && (!authService.isLoggedInAndHasList)">
                <a id="manageListsMenu1" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Manage Lists</a>
                <ul class="dropdown-menu" style="left: 15px;">
                    <li>
                        <a id="wlblMenu1" [routerLink]="['/lists']" routerLinkActive="active">Allow & Block Lists</a>
                    </li>
                    <li>
                        <a id="tpMenu1" [routerLink]="['/tplist']" routerLinkActive="active">Trusted Partner Lists</a>
                    </li>
                    <li>
                        <a id="cgMenu1" [routerLink]="['/cglist']" routerLinkActive="active">Country Groups</a>
                    </li>
                </ul>
            </li>
            <li class="dropdown" *ngIf="authService.hasRole('user', 'custAdmin')">
                <a id="rulesPartitionMenu1" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Rules & Partitions</a>
                <!--<a [routerLink]="['/rules']" class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Set Rules & Partitions<span class="caret"></span></a>-->

                <ul class="dropdown-menu" style="left: 15px;">
                    <li>
                        <a id="rulesMenu1" [routerLink]="['/rules']" routerLinkActive="active">Set Rules & Partitions</a>
                    </li>
                    <li>
                        <a id="fileLayout1" [routerLink]="['/partitionFileLayout']" routerLinkActive="active">Manage Partition File Layouts</a>
                    </li>
                    <li>
                        <a id="partitionsMenu1" [routerLink]="['/partitions']" routerLinkActive="active">Manage Partitions</a>
                    </li>
                </ul>
            </li>
            <li>
                <a id="numberingDataMenu1" [routerLink]="['/range']" routerLinkActive="active">Numbering Data</a>
                <ul class="dropdown-menu" style="left: 15px;">
                    <li>
                        <a id="rangeIprnMenu1" [routerLink]="['/range']" routerLinkActive="active">Range & IPRN Data</a>
                    </li>
                    <li>
                        <a id="msrnMenu1" [routerLink]="['/msrn']" routerLinkActive="active">MSRN Data</a>
                    </li>
                </ul>
            </li>
            <li>
                <a id="helpMenu1" [routerLink]="['/help']" routerLinkActive="active">Knowledge Base</a>
            </li>
        </ul>
    </div>
    <!-- /.navbar-collapse -->
    <!-- /.container-fluid -->
</nav>

<!--Navbar-->
<nav class="navbar navbar-default visible-md-block visible-lg-block" *ngIf="authService.isAuthorized()" style="padding:0; margin: 0">
    <ul class="nav navbar-nav text-start" style="width:100%">
        <li *ngIf="authService.hasRole('user', 'custAdmin') && (authService.isLoggedInAndHasList)" class="col-md-3">
            <a id="manageListsMenu" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLinkActive="nav-active">Manage Lists</a>
            <ul class="dropdown-menu" style="left: 15px;">
                <li>
                    <a id="wlblMenu" [routerLink]="['/lists/listDetail']" routerLinkActive="active">Allow & Block Lists</a>
                </li>
                <li>
                    <a id="tpMenu" [routerLink]="['/tplist']" routerLinkActive="active">Trusted Partner Lists</a>
                </li>
                <li>
                    <a id="cgMenu" [routerLink]="['/cglist']" routerLinkActive="active">Country Groups</a>
                </li>
            </ul>
        </li>
        <li *ngIf="authService.hasRole('user', 'custAdmin') && (!authService.isLoggedInAndHasList)" class="col-md-3">
            <a id="manageListsMenu" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLinkActive="nav-active">Manage Lists</a>
            <ul class="dropdown-menu" style="left: 15px;">
                <li>
                    <a id="wlblMenu" [routerLink]="['/lists']" routerLinkActive="active">Allow & Block Lists</a>
                </li>
                <li>
                    <a id="tpMenu" [routerLink]="['/tplist']" routerLinkActive="active">Trusted Partner Lists</a>
                </li>
                <li>
                    <a id="cgMenu" [routerLink]="['/cglist']" routerLinkActive="active">Country Groups</a>
                </li>
            </ul>
        </li>
        <li class="dropdown col-md-3" *ngIf="authService.hasRole('user', 'custAdmin')">
            <a id="rulesPartitionMenu" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLinkActive="nav-active">Rules & Partitions</a>
            <!--<a [routerLink]="['/rules']" class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLinkActive="nav-active">Set Rules & Partitions<span class="caret"></span></a>-->
            <ul class="dropdown-menu" style="left: 15px;">
                <li>
                    <a id="rulesMenu" [routerLink]="['/rules']">Set Rules & Partitions</a>
                </li>
                <li>
                    <a id="fileLayout" [routerLink]="['/partitionFileLayout']" routerLinkActive="active">Manage Partition File Layouts</a>
                </li>
                <li>
                    <a id="partitionsMenu" [routerLink]="['/partitions']">Manage Partitions</a>
                </li>
            </ul>
        </li>

        <li *ngIf="authService.hasRole('user', 'custAdmin')" class="col-md-3">
            <a id="numberingDataMenu" class="dropdown-toggle nav-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" routerLinkActive="nav-active">Numbering Data</a>
            <ul class="dropdown-menu" style="left: 15px;">
                <li>
                    <a id="rangeIprnMenu" [routerLink]="['/range']">Range & IPRN Data</a>
                </li>
                <li>
                    <a id="msrnMenu" [routerLink]="['/msrn']">MSRN Data</a>
                </li>
            </ul>
        </li>
        <li class="col-md-3">
            <a id="helpMenu" [routerLink]="['/help']" routerLinkActive="nav-active">
               Knowledge Base
            </a>
        </li>
    </ul>
</nav>


<router-outlet></router-outlet>


<footer class="footer" id="footer">
    <div class="container-fluid">
        <p class="ta-c">
            <a target="_blank" href="https://iconectiv.com"><img class="logomark" src="/assets/images/icon-logomark.png" height="40" width="40"></a>
            <span class="ms-1 legal">
                Copyright <span id="year">{{year}}</span> iconectiv, LLC. All rights reserved.
            </span>
            <span class="float-end me-5">
                <!--
                <span class="link"><a i18n class="link" href="#">Privacy Policy</a></span>
            <span class="link"><a i18n class="link" href="#">Terms &amp; Conditions</a></span> -->
            <span class="link"><a i18n class="link" href="mailto:ProtectSupport@iconectiv.com">Customer Support</a></span>
            </span>
        </p>
    </div>
</footer>

<script>
    $(document).ready(function() {
        $('[data-bs-toggle="tooltip"]').tooltip();
    });
</script>
