import { Type } from 'serializer.ts/Decorators';

export class ResponseJson {
    status: string;
    message: string;
    first: boolean;
    last: boolean;
    totalCount: number;
    totalPage: number;
    pageNumber: number;
    count: number;
    data: any;

}
