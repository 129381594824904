<div class="row">
    <h3 class="col-md-12 text-center">
        {{partitionService.selectedPartition.name}} {{ '{' }}{{partitionService.selectedExportHistory.status=="Failed" ? "Failed" : "Exported"}}{{ '}' }}
    </h3>
</div>

<div class="row w3-margin-top">
    <div class="col-md-6" *ngIf="partitionService.selectedExportPartition">
        <div class="row">
            <div class="col-md-10"><div class="list-label col-md-6 col-lg-4">Allow List</div> <div id="partitionWL" class="list-value col-md-6">{{partitionService.selectedExportPartition.wlName}}&nbsp;</div></div>
        </div>
        <div class="row w3-margin-top">
            <div class="col-md-10"><div class="list-label col-md-6 col-lg-4">Block List</div> <div
              id="partitionBL" class="list-value col-md-6">{{partitionService.selectedExportPartition.blName}}&nbsp;</div></div>
        </div>
        <div class="row w3-margin-top">
            <div class="col-md-10"> <div class="list-label col-md-6 col-lg-4">Trusted Partner List</div> <div class="list-value col-md-6" id="partitionTP" >{{partitionService.selectedExportPartition.tpName}}&nbsp;</div></div>
        </div>
    </div>
    <div class="btn-group col-md-6" data-bs-toggle="buttons" role="group">
        <div class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-2 padding-5" style="min-width: 135px;">
                <button id="allDialPatternsBtnExportDetails" class='btn btn-primary no-uppercase no-padding partition-btn' (click)="viewAll()" [disabled]="partitionService.loadingExportData">All Dial Patterns</button>
            </div>
            <div class="col-md-2 padding-5" style="min-width: 80px;">
                <button id="downloadBtnExportDetails" class='btn btn-info no-uppercase no-padding partition-btn' (click)="downloadBlockingFile()" [disabled]="partitionService.loadingExportData">Download</button>
            </div>
            <div class="col-md-2 padding-5" style="display: flex; justify-content: center; flex-wrap: wrap; min-width: 80px">
                <button id="exportButtonExportDetails" class='btn btn-default no-uppercase no-padding partition-btn' [disabled]="partitionService.selectedExportHistory?.status==='Processing' || partitionService.selectedExportHistory?.status==='Sending' || partitionService.exportInProgress" (click)="onResend()">Export</button>
                <div class="fa fa-spinner fa-spin" style="font-size:48px;color:red" *ngIf="partitionService.exportInProgress"></div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4 pull-right" style="padding-left:50px">
            <span  id="timeStampElement"></span>
    </div>
</div>





<div class="row col-md-11 w3-margin-top" *ngIf="partitionService.selectedExportPartition && partitionService.selectedExportPartition.ruleDefinitions.length > 0">
    <p-dataView [value]="partitionService.selectedExportPartition.ruleDefinitions">
        <ng-template let-rule pTemplate="listItem" let-i="rowIndex">
            <div class="ui-grid ui-grid-responsive ui-fluid" style="font-size:16px;padding:20px;border-bottom:1px solid #D5D5D5;">
                <div class="ui-grid-row">
                    <div class="ui-grid-col-2 w3-left w3-margin-top">
                        <span id="rule{{i+1}}Label" class="rule-label">Rule {{i+1}}</span>
                        <p class="rule-source"><span id="rule{{i+1}}DataSource">{{rule.dataSource}}</span> <span id="rule{{i+1}}DialPatternType">{{rule.dialPatternType}}</span></p>
                    </div>
                    <div class="ui-grid-col-9">
                        <div class="ui-grid ui-grid-responsive ui-fluid">
                            <div style="cursor: pointer;" class="w3-card-rule col-md-3 w3-center" (click)="openFilterInPartition(rule, 'country')">
                                <div class="w3-container margin-top5">
                                    <p class="attribute-label">Country Code & ISO2</p>
                                </div>

                                <div class="w3-container">
                                    <p class="attribute-value">{{getTipContents(rule.rangeQueryFilter, 'country')}}</p>
                                </div>
                            </div>
                            <div style="cursor: pointer;" class="w3-card-rule col-md-3 w3-center" (click)="openFilterInPartition(rule, 'tos')">
                                <div class="w3-container margin-top5">
                                    <p class="attribute-label">TOS & TOS Description</p>
                                </div>

                                <div class="w3-container">
                                    <p class="attribute-value">{{getTipContents(rule.rangeQueryFilter, 'tos')}}</p>
                                </div>
                            </div>
                            <div style="cursor: pointer;" class="w3-card-rule col-md-2 w3-center" (click)="openFilterInPartition(rule, 'provider')">
                                <div class="w3-container margin-top5">
                                    <p class="attribute-label">Provider</p>
                                </div>

                                <footer class="w3-container">
                                    <p class="attribute-value">{{rule.selectedProvider}} selected</p>
                                </footer>
                            </div>
                            <div class="w3-card-rule-right col-md-3 w3-center" *ngIf="rule.dataSource==='IPRN'">
                                <div class="w3-container margin-top5">
                                    <p class="attribute-label">Last Observed</p>
                                </div>

                                <footer class="w3-container">
                                    <p class="attribute-value">{{rule.noOfMonth}} months</p>
                                </footer>

                            </div>
                        </div>
                    </div>
                    <div class="ui-grid-col-1 w3-right">
                        <div class="row w3-margin-top">
                            <button id="rule{{i+1}}DialPatternButton" class="btn btn-link" (click)="viewRuleById(rule)" [disabled]="partitionService.loadingExportData">DIAL PATTERNS</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>

<!-- read only filter panel -->
<p-dialog [(visible)]="showReadOnlyFilterWin" modal="modal" [style]="{width: '80vw'}" [contentStyle]="{'overflow-y': 'auto','height': '60vw'}" [resizable]="true">
    <div id="rcountry" *ngIf="filterBy==='country'" class="w3-container w3-margin-top filter">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3 col-sm-4">
                    <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                    <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                    <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
                </div>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of filteredCountryList">
                        <div class="w3-card-4 no-shadow" [ngClass]="country.selected">
                            <div class="w3-container">
                                <span class="w3-left-align col-md-8">{{ country.country }}</span>
                                <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="rtos" *ngIf="filterBy==='tos'" class="w3-container w3-margin-top filter">
        <div class="col-md-12">
            <div class="row">
                <span class="w3-left-align w3-text-blue col-md-8">TOS:TOS Description</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of filteredTOSList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected">
                            <div class="w3-container">
                                <div class="text-start">{{item.tos}}: {{item.tosdesc || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="rprovider" *ngIf="filterBy==='provider'" class="w3-container w3-margin-top filter">
        <div class="col-md-12">
            <div class="row">
                <span class="w3-center-align w3-text-blue w3-margin-left col-md-2">Provider</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected">
                            <div class="w3-container">
                                <div class="text-start">{{item.provider || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</p-dialog>
