import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { AuthService } from '../auth';
import { Router, ActivatedRoute } from '@angular/router';
import { EditItem,CustomGroups, ResponseJson, ValueList} from '../models/index';
import { NotificationService } from '../services/notification.service';
import { FilterService} from '../filter/filter-service';
import { CacheService } from '../services/cache.service';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { EditableFieldComponent } from '../editable-field/editable-field.component';
import { ConvertToUnderscorePipe } from '../util/convert-to-underscore.pipe';
import { CustomGroupsType } from '../shared/constants';

@Component({
  selector: 'app-country-group',
  templateUrl: './country-group.component.html',
  styleUrls: ['./country-group.component.scss'],
  })
export class CountryGroupComponent extends FilterService implements OnInit {

  @ViewChild('countryGroupNameField') inputGroupName:EditableFieldComponent;
  cGroups: Array<CustomGroups> = [];
  selectedGroup: CustomGroups;
  selectedGroupItem: EditItem<CustomGroups>;
  placeholderValue = 'Name the group';
  styleClass = 'inline';
  addNewCG:boolean = false;
  showMoreCG = true;
  showMoreLimit = AppConstants.ShowMoreLimit;
  isNewGroup = false;
  isAddMoreCG = true;
  isAllowDelete = false;
  blocking = false;

  showDelConfirm:boolean = false;
  selectedPosition = 0;

  updatedContent = false;
  selectedCountries = [];
  maxCountryGroups: number;
  remainingCountryGroups: number;

  first:boolean = false;
  pollListUpdate: Subscription;
  
  isSkipDupMsg = false;
  isSelectDuringNameEdit = false;

  showMessage = false;

  showThresholdMessage = false;
  showDuplicateMessage = false;

  selectedGroupName = '';
  showDescriptionDialog :boolean = false;
  isEditDesc: boolean = false;
  constructor(public log: Logger, public httpService: HttpService, 
    public cacheService: CacheService, public notifService: NotificationService, private authService: AuthService ) {
    super(log, httpService, cacheService, notifService);
  }

  ngOnInit() {
     super.init();
     this.first = true;
     this.getAllCountryGroups();
     this.addNewCG = true;
     this.selectedCountries = [];
     this.queryListUpdate();
  }

  queryListUpdate() {
    /* put in a flag to help with automated testing */
    this.log.debug("guiPollingEnabled: " + this.authService.loginUser.guiPollingEnabled);
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate = interval(10000).subscribe(() => {
        this.getAllCountryGroups();
      });
    }
  }

  ngOnDestroy() {
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate.unsubscribe();
    }
  } 

  newGroup(){
  let customGroup = new CustomGroups;
  customGroup.type = CustomGroupsType.CountryGroup;
  customGroup.name='';

  customGroup.selected = true;
  this.cGroups.forEach(b => b.selected = false);
  if (this.inputGroupName && this.inputGroupName.isEditing) {
    this.isSkipDupMsg = true;
    console.log("inside newList and isEditing=true");
  } else {
    this.isSelectDuringNameEdit = false;
    this.isSkipDupMsg = false;
  }
  this.onCreateNew(customGroup);
  }

  async onCreateNew(myGroup: CustomGroups) {
    this.showThresholdMessage = false;
    this.isAllowDelete = false;
    await AppConstants.delay(1000);
    this.selectedGroup = myGroup;
    this.selectedGroup.selected = true;
    this.selectedGroup.type = CustomGroupsType.CountryGroup;
    this.selectedGroupItem = new EditItem(this.selectedGroup, CustomGroups);
    this.clearSelected('country');
    this.selectedCountries = [];

    this.isNewGroup = true;
    this.getAllCountryGroups();
  }

  queryCountryGroup(myList: CustomGroups) {
    let url = AppConstants.QueryCountryGroupURL;
    url += '/' + myList.id;
    this.log.debug('query list definition: ' + url);
    this.httpService.get(url, [this.postQueryCountryGroupByIdRequestHandler]);
  }

  updateCountryGroupName(name: string) {
    if (this.isSelectDuringNameEdit && this.isNewGroup) {
      //used when do new list->dup->select
      this.isSelectDuringNameEdit = false;
      this.isNewGroup = false;
      return;
    }
    this.isSelectDuringNameEdit = false;

    if (name && this.selectedGroup.id) {
      this.selectedGroupItem.item.name = name;
      this.httpService.post(JsonHelper.toJson(this.selectedGroup), AppConstants.SaveCountryGroup, [this.postUpdateCountryGroupNameHandler]);
    } else {
      
      if (!this.showThresholdMessage) {
        if(name){
          //Name is give by the user
          this.selectedGroupItem.item.name = name;
          this.httpService.post(JsonHelper.toJson(this.selectedGroup), AppConstants.CreateCountryGroup, [this.postCreateGroupHandler]);
        } else {
          //Name is not given by the user    
          this.selectedGroupItem.item.name = this.generateUniqueName(this.cGroups.length);
          this.httpService.post(JsonHelper.toJson(this.selectedGroup), AppConstants.CreateCountryGroup, [this.postCreateGroupHandler]);
        }
      }
    }
  }

  generateUniqueName(listSize: number) : string{
    let newName;
    let number = this.cGroups.length;
    do{
      number++;
      newName = 'Country Group ' + number;
    }while(this.cGroups.find(item => item.name === newName) !== undefined)
    return newName;
  }

  async checkThresholdLimit() {
    this.remainingCountryGroups = this.maxCountryGroups - this.cGroups.length-1 ;
    if ( this.remainingCountryGroups <= AppConstants.ThresholdWarningLimit) {
      this.showThresholdMessage = true;
      await AppConstants.delay(7000);
    }

    if (this.showThresholdMessage)
      this.closeMessage();
  }

  closeMessage(){
    this.showThresholdMessage = false;
  }

  hideMessages() {
    this.showDescriptionDialog = false;
    this.showThresholdMessage = false;
    this.showDuplicateMessage = false;
  }

  getAllCountryGroups() {
    this.httpService.get(AppConstants.QueryCountryGroupsURL, [this.postQueryCountryGroupHandler, this.responseCallBack]);
  }

  onSelect(myList: CustomGroups) {
    this.isAllowDelete = true;
    this.cGroups.forEach(b => b.selected = false);
    //this.showThresholdMessage = false;
    myList.selected = true;
    this.isNewGroup = false;
    this.selectedGroup = myList;
    if (this.inputGroupName && this.inputGroupName.isEditing) {
      this.isSelectDuringNameEdit = true;
      this.inputGroupName.isEditing=false;
    } else {
      this.isSelectDuringNameEdit = false;
    }
    this.hideMessages();
    this.selectedCountries = [];
    if(myList.id)
      this.queryCountryGroup(myList);
    else 
        this.httpService.post(JsonHelper.toJson(myList), AppConstants.CreateCountryGroup, [this.postCreateGroupHandler]);
  }

  showDescDialog()  {
    setTimeout(() => {
      this.showDescriptionDialog = true;
    }, 400);
  };

  onUpdateDesc() {
    this.isEditDesc = false;
    this.selectedGroupItem.item.name = this.selectedGroup.name;
    if (this.selectedGroup['value']) {
      this.selectedGroup.value['description'] = this.selectedGroup.description;
    }
    else {
      this.selectedGroup.value = new ValueList();
      this.selectedGroup.value['description'] = this.selectedGroup.description;
    }
    this.selectedGroup.description = undefined;
    this.httpService.post(JsonHelper.toJson(this.selectedGroup), AppConstants.SaveCountryGroup, [this.postUpdateCountryGroupNameHandler]);
  }

 closeDuplicateMessage(){
    this.showDuplicateMessage = false;
    this.inputGroupName.isEditing=true;  
    this.inputGroupName.origItem = this.inputGroupName.prevName;
    this.inputGroupName.setFocus();
  }

  // HANDLERS
  // 1. GET THE WHOLE LIST OF GROUPS 
  //****************************************************************************************
  postQueryCountryGroupHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      let setGroup: CustomGroups;
      this.cGroups = [];
      const cGroupArray: Array<CustomGroups> = JsonHelper.parseArray(responseJson.data.customGroups, CustomGroups);
      this.maxCountryGroups = responseJson.data.maxAllotment;
      for (const item of cGroupArray) {
        this.cGroups.push(item);
      }

      if (this.cGroups.length > 0) {
        if (this.selectedGroup ) {
          this.cGroups.forEach(b =>{ if(b.id === this.selectedGroup.id) b.selected = true;});
        } 
        if (this.selectedGroup && !this.showDuplicateMessage)
          this.isAllowDelete = true;
      }
      if (this.cGroups.length >= this.maxCountryGroups) {
        this.isAddMoreCG = false;
      } else {
        this.isAddMoreCG = true;
      }

      if (this.first && this.cGroups.length>0) {
        this.selectedGroup = this.cGroups[0];
        //set the first list selected
        this.cGroups[0].selected = true; 
        this.selectedGroup.selected = true;
        this.selectedGroup.type=CustomGroupsType.CountryGroup; 
        this.first = false;
        this.selectedGroupItem = new EditItem(this.selectedGroup, CustomGroups);
        this.selectedGroupItem.item = this.selectedGroup;
        this.selectedGroupItem.origItem = this.selectedGroup;
        this.selectedGroup.description = this.selectedGroup.value?.description;
        this.selectedGroupItem.item.description = this.selectedGroup.description;
        this.clearSelected('country');
        this.initializeSelectedCountriesFromDB(this.selectedGroup);
      }
    }
   
  }

  //2. QUERY BY ID
  //****************************************************************************************
  postQueryCountryGroupByIdRequestHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedGroup = JsonHelper.parse(responseJson.data.customGroups, CustomGroups);
      this.selectedGroup.description = this.selectedGroup.value?.description
      this.selectedGroupItem = new EditItem(this.selectedGroup, CustomGroups);
      this.selectedGroupItem.item.description = this.selectedGroup.description;
      this.inputGroupName.item = this.selectedGroup.name;
      this.inputGroupName.origItem = this.selectedGroup.name;
      this.isNewGroup = false;
      this.inputGroupName.isEditing = false;
      this.clearSelected('country');
      this.initializeSelectedCountriesFromDB(this.selectedGroup);
    } else {
      this.showMessage = true;
    }
  }


  //3. CREATE A NEW GROUP
  //****************************************************************************************
  postCreateGroupHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedGroup = JsonHelper.parse(responseJson.data.customGroups, CustomGroups);
      this.selectedGroupItem = new EditItem(this.selectedGroup, CustomGroups);
      if (this.isNewGroup) {
        this.checkThresholdLimit();
      }
      this.isNewGroup = false;
      this.selectedGroup.selected = true;
      this.getAllCountryGroups();
    } else {
      //this.messageRegularText=responseJson.message;
      console.log("errorMsg: " + responseJson.message);
      if (responseJson.message.includes("Custom Group with name")) {
        //skip displaying duplicate message if onSelect in progress
        if (!this.isSkipDupMsg) {
          this.showDuplicateMessage = true;
          this.isAllowDelete = false;
        }
      } else
        this.responseCallBack(responseJson);
    };
  }

  //4. Either UPDATE THE NAME of group OR UPDATE the content - like description / Value etc
  //****************************************************************************************
  postUpdateCountryGroupNameHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedGroup = JsonHelper.parse(responseJson.data.customGroups, CustomGroups);
      this.selectedGroupItem = new EditItem(this.selectedGroup, CustomGroups);
      this.selectedGroupItem.item.id = this.selectedGroup.id;
      this.responseCallBack(responseJson);
      this.selectedGroupName = this.selectedGroup.name;
      this.selectedGroup.description = this.selectedGroup.value?.description;
      this.selectedGroupItem.item.description = this.selectedGroup.description;
      if (this.isNewGroup) {
        this.checkThresholdLimit();
      }
      this.isNewGroup = false;
      this.selectedGroup.selected = true;
      if (this.updatedContent) {
        this.updatedContent = false;
        this.onSelect(this.selectedGroup);
      }
      this.getAllCountryGroups();
    } else {
      //this.messageRegularText=responseJson.message;
      console.log("errorMsg: " + responseJson.message);
      if (responseJson.message.includes("Custom Group with name")) {
        //skip displaying duplicate message if onSelect in progress
        if (!this.isSkipDupMsg) {
          this.showDuplicateMessage = true;
          this.isAllowDelete = false;
        }
        this.inputGroupName.isEditing = true;
        this.selectedGroupItem.item.name = this.selectedGroup.name;
      } else
        this.responseCallBack(responseJson);
    }
    if (this.isSkipDupMsg)
      this.isSkipDupMsg = false;
  }

  findSelectedPosition() {
    if (this.cGroups.length > 0) {
      this.selectedPosition = this.cGroups.findIndex(b => b.selected === true);

      if (this.selectedPosition > -1) {
        if (this.selectedPosition == (this.cGroups.length -1)) {
          if (this.selectedPosition == 0) {
            this.selectedPosition = -1;
          } else {
              this.selectedPosition--;
          }
        }
        else
          this.selectedPosition++;
      }
    } else {
      this.selectedPosition = -1;
    }
  }

  async onDeleteList() {
    this.isSelectDuringNameEdit = false;
    this.hideMessages();
    this.stopBatchLoading = true;
    
    this.showDelConfirm=true;
  }

  deleteCountryGroup(){
    this.blocking = true;
    this.showDelConfirm=false;
    this.httpService.delete(AppConstants.DeleteCountryGroup + '/' + this.selectedGroup.id, [this.postDeleteList, this.responseCallBack]);
  }

  postDeleteList = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {

      this.findSelectedPosition();
      if (this.selectedPosition > -1 && this.cGroups.length>0) {
        this.selectedGroup = this.cGroups[this.selectedPosition];
        this.cGroups.forEach(b => b.selected = false); 
        this.selectedGroup.selected = true;
        this.selectedGroup.type=CustomGroupsType.CountryGroup; 
        this.selectedGroupItem = new EditItem(this.selectedGroup, CustomGroups);
        this.onSelect(this.selectedGroup);
        this.getAllCountryGroups();
      } else {
        this.selectedGroup=null;
      }
    }
    this.blocking = false;
  }

  initializeSelectedCountriesFromDB(myGroup: CustomGroups) {
    let cgIso2List = [];
    if (myGroup['value'] && myGroup['value']['iso2List']) {
      cgIso2List = myGroup['value']['iso2List'];
      cgIso2List.forEach(element => {
        let countryItem = this.countryList.find(c => c.iso2 === element);
        countryItem.selected = this.selectedColor;
        this.selectedCountries.push(countryItem);
      });
    }
  }

  updateSelectedCountries() {
    // Call the getSelectedCountries() function in the filter-service.ts module to get the updated selected countries
    this.selectedCountries = this.getSelectedCountries();
  }

  onSave() {
    let updatedIso2List = [];
    this.selectedCountries.forEach(val => updatedIso2List.push(val.iso2));
    if (this.selectedGroup['value']) {
      this.selectedGroup.value['iso2List'] = updatedIso2List;
    }
    else {
      this.selectedGroup.value = new ValueList();
      this.selectedGroup.value['iso2List'] = updatedIso2List;
    }
    this.updatedContent = true;
    this.httpService.post(JsonHelper.toJson(this.selectedGroup), AppConstants.SaveCountryGroup, [this.postUpdateCountryGroupNameHandler]);
  }

  onCancel() {
    // Basically reset everything
    this.onSelect(this.selectedGroup);
  }
}