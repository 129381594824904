import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { DataViewModule } from 'primeng/dataview'; //using since DataList was deprecated
import { TableModule } from 'primeng/table';  //useing since DataTable was deprecated
import { TreeTableModule } from 'primeng/treetable';
import { ConfirmationService } from 'primeng/api'
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PickListModule } from 'primeng/picklist';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import {CarouselModule} from 'primeng/carousel';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {PanelModule} from 'primeng/panel';
import {RadioButtonModule} from 'primeng/radiobutton';
import { EditableFieldComponent } from '../editable-field/editable-field.component';
import { AutofocusDirective } from '../directives/auto-focus.directive';
import { ConvertToUnderscorePipe } from './convert-to-underscore.pipe';
import { FilteredTableComponent } from 'app/filtered-table/filtered-table.component';


/*
declare common module(s) so that we don't need import individually
*/
@NgModule({
  imports: [
    CommonModule, FormsModule, BrowserModule, BrowserAnimationsModule, HttpClientModule, BlockUIModule,
    TableModule, TreeTableModule, DialogModule, FileUploadModule, ToastModule, MessagesModule, MessageModule, ContextMenuModule,
    ConfirmDialogModule, CalendarModule, DropdownModule, ListboxModule,MultiSelectModule, InputSwitchModule, DataViewModule,
    OverlayPanelModule, PanelModule, CheckboxModule, TooltipModule, CarouselModule,PickListModule, RadioButtonModule,
  ],
  declarations: [
    EditableFieldComponent,
    AutofocusDirective,
    ConvertToUnderscorePipe,
    FilteredTableComponent
  ],
  exports: [
    CommonModule, FormsModule, BrowserModule, BrowserAnimationsModule, HttpClientModule, BlockUIModule,
    TableModule, TreeTableModule, DialogModule, FileUploadModule, ToastModule, MessagesModule, MessageModule, ContextMenuModule,
    ConfirmDialogModule, CalendarModule, DropdownModule, ListboxModule,MultiSelectModule, InputSwitchModule, DataViewModule,
    OverlayPanelModule, PanelModule, CheckboxModule, TooltipModule, CarouselModule, PickListModule,
    EditableFieldComponent,AutofocusDirective,RadioButtonModule,ConvertToUnderscorePipe, FilteredTableComponent

  ],
  providers: [MessageService, ConfirmationService]
})
export class AppSharedModule { }