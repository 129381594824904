<div class="row">
    <h3 class="col-md-12 text-center">
        {{partitionService.selectedPartition.name}} Dial Patterns in Rule
        {{partitionService.getRuleIndex(rule.id.toString())}}
    </h3>
</div>
<div>
    <div class="col-md-12">
        <div class="row">
            <a class="link col-md-2" (click)="goBack()"><i class="fa fa-arrow-left fa-lg" aria-hidden="true"
                    style="padding-right: 5px;"></i>
                <font style="font-family:Open Sans;font-size:12px">Back to Summary</font>
            </a>
        </div>
    </div>
</div>
<div class="w3-panel w3-boarder w3-border-red w3-margin-top"  *ngIf="partitionService.stopFetching">
    <div class="w3-bar">
        <label class="w3-bar-item w3-margin-right">Filter By: </label>
        <button class="w3-bar-item btn w3-margin-right"
            [ngClass]="{'btn-default': filterBy === 'country', 'btn-inverse-no-border': filterBy !== 'country'}"
            (click)="showResult=false; createFilterData('country')">Country Code & ISO2</button>
        <button class="w3-bar-item btn w3-margin-right"
            [ngClass]="{'btn-default': filterBy === 'tos', 'btn-inverse-no-border': filterBy !== 'tos'}"
            (click)="showResult=false; createFilterData('tos')">TOS & TOS Description</button>
        <button class="w3-bar-item btn w3-margin-right"
            [ngClass]="{'btn-default': filterBy === 'provider', 'btn-inverse-no-border': filterBy !== 'provider'}"
            (click)="showResult=false; createFilterData('provider')">Provider</button>
            <div class="pull-right">  
                <button class='btn btn-info download-btn no-uppercase no-padding'  [disabled]="downloader?.isLoading()" (click)="onDownload()">Download</button>
                <p-progressBar  [value]="downloader.progressValue"  *ngIf="downloader?.isLoading()"></p-progressBar>
            </div> 
    </div>
    <div class="w3-bar" *ngIf="partitionService.exportInProgress">
        <div class="fa fa-spinner fa-spin pull-right" style="align:right;font-size:48px;color:red" *ngIf="partitionService.exportInProgress"></div>
      </div>
</div>

<div id="country" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query w3-margin-left" #country size="35"
                        placeholder="Search Country, Code or ISO2 ..." (keyup)="filterCountry(country.value)">
                    <span class="clearer pi pi-times-circle"
                        (click)="country.value=''; filterCountry('')"></span>
                </div>
                <div class="col-md-6" *ngIf="selectedCounter.country>0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected Country Code(s) and ISO2 will be applied</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{getSelectedValues('country')}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right"
                            (click)="clearSelection('country')">Clear</button>
                    </div>
                </div>

                <button type="button" class="btn btn-primary pull-right w3-margin-left"
                    (click)="closeAllFilter()">Apply</button>
            </div>
        </div>

        <div class="row">
            <div class="w3-margin-top col-md-3 col-sm-4">
                <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
            </div>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of filteredCountryList">
                    <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                        <div class="w3-container">
                            <span class="w3-left-align col-md-8">{{ country.country }}</span>
                            <span class="w3-left-align col-md-2">{{ country.code }}</span>
                            <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="tos" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #tos
                        placeholder="Search TOS Description ..." (keyup)="filterTosDesc(tos.value)">
                    <span class="clearer pi pi-times-circle"
                        (click)="tos.value=''; filterTosDesc('')"></span>
                </div>
                <div class="col-md-6" *ngIf="selectedCounter.tos>0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected TOS or TOS Descriptions will be applied</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{getSelectedValues('tos')}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right"
                            (click)="clearSelection('tos')">Clear</button>
                    </div>
                </div>

                <button type="button" class="btn btn-primary pull-right w3-margin-left"
                    (click)="closeAllFilter()">Apply</button>
            </div>
        </div>
        <div class="row w3-margin-top">
            <span class="w3-left-align w3-text-blue col-md-8">TOS:TOS Description</span>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of filteredTOSList">
                    <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                        <div class="w3-container">
                            <div class="text-start">{{item.tos}}: {{item.tosdesc || "Unallocated"}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="provider" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #provider
                        placeholder="Search Provider ..." (keyup)="filterProviderSearch(provider.value)">
                    <span class="clearer pi pi-times-circle"
                        (click)="provider.value=''; filterProviderSearch('')"></span>
                </div>

                <div class="col-md-6" *ngIf="selectedCounter.provider>0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected Providers will be applied.</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{getSelectedValues('provider')}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right"
                            (click)="clearSelection('provider')">Clear</button>
                    </div>
                </div>
                <button type="button" class="btn btn-primary pull-right w3-margin-left"
                    (click)="closeAllFilter()">Apply</button>
            </div>
        </div>
        <div class="row w3-margin-top">
            <span class="w3-center-align w3-text-blue w3-margin-left col-md-2">Provider</span>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                    <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                        <div class="w3-container">
                            <div class="text-start">{{item.provider || "Unallocated"}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</div>


<div class="row w3-margin-top w3-margin-left w3-margin-right" id="dataArea" *ngIf="showResult">
<p-table #dt [columns]="partitionRuleCols"  [value]="partitionService.filteredDraftData"  [rows]="limit" [paginator]="true"
    [pageLinks]="5" [rowsPerPageOptions]="[10,30,50,100,250,500,750,1000]" resizableColumns="true"
    columnResizeMode="expand" [lazy]="true" sortMode="multiple" [multiSortMeta]="multiSortMeta"
    (onLazyLoad)="loadRuleDataDetails($event)" [totalRecords]="partitionService.draftDataTotalCount" [globalFilterFields]="filterFieldCols" [responsive]="true"
    [style]="{'margin-top':'30px'}" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="caption">
        <div class="ui-table-globalfilter-container">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input pInputText #gb id="searchInput" type="text" size="50"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Number Search" />
            <span class="pi pi-times-circle" (click)="clearSearch(gb, dt);"></span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        {{dataSource}}
        <tr>
            <ng-container  *ngFor="let col of columns"> 
                <th class="code-tbl-header" [style.width.%]="col.width"  *ngIf="!col.nosort"
                  [pSortableColumn]="col.field" [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                  tooltipPosition="top">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th class="code-tbl-header" [style.width.%]="col.width"  *ngIf="col.nosort"
                   [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                   tooltipPosition="top">
                  {{col.header}}
                  
                </th>
              </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td class="code-tbl-body" *ngFor="let col of columns">
                <span *ngIf="col.field!='customerDate' && col.field!='source' && col.field!='lemin' && col.field!='lemax'">
                    {{rowData[col.field]}}
                </span>
                <span *ngIf="col.field=='customerDate' ">
                    {{rowData[col.field] | date:'yyyy-MM-dd'}}
                </span>
                <span *ngIf="col.field=='source'">
                    <span *ngIf="rowData.type==='R' || rowData.type==='U'">{{partitionService.getRuleSetting(rowData.reference)}}</span>
                    <span *ngIf="rowData.type==='I'">{{partitionService.getIPRNSourceForDisplay(rowData.dialPatternType)}}</span>
                    <span *ngIf="rowData.type==='W' || rowData.type==='B'">{{rowData.reference}}</span>
                    <span *ngIf="rowData.type==='M'">MSRN</span>
                </span>
                <span *ngIf="col.field=='lemin' || col.field=='lemax'">
                    <span *ngIf="rowData.type==='W' || rowData.type==='B' || rowData.type==='R' || rowData.type==='U' || rowData.type==='I'">{{rowData[col.field]}}</span>
                    <span *ngIf="rowData.type==='M'">n&#47;a</span>
                </span>
            </td>
        </tr>
    </ng-template>
    <ng-template id="dtFooter" pTemplate="footer">
        <tr>
            <td [attr.colspan]="partitionRuleCols.length">
                {{partitionService.draftDataQueryStatus}}  {{totalMessage()}}<i *ngIf="isLoading()" class="fa fa-spinner fa-spin"
                    style="font-size:48px;color:red"></i>
            </td>
        </tr>
    </ng-template>
    <!--
  <ng-template pTemplate="emptymessage" let-rows>
      <tr>
          <td [attr.colspan]="rows.length">
              No partition records.
          </td>
      </tr>
  </ng-template>
  -->
</p-table>
</div>