import { Component, OnInit } from '@angular/core';
import { CacheService } from '../services/cache.service';
import { FilterService} from '../filter/filter-service';
import { Logger } from '../services/logger.service';
import { AppConstants} from '../util/index';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { RangeFilter} from '../models/index';
import { ActionName } from 'app/shared/constants';
import { AuditService } from 'app/services/audit.service';


@Component({
  selector: 'app-msrn-data',
  templateUrl: './msrn-data.component.html',
  styleUrls: ['./msrn-data.component.scss']
})
export class MsrnDataComponent  extends FilterService implements OnInit {
  isDownloading = false;
  showFilterBy = true;
  filteredCountryCount = 0;
  filteredProviderCount = 0 ;
  msrnCols: any[];
  filterFieldCols: any[] = [];

  constructor(public log: Logger, public httpService: HttpService,public cacheService: CacheService,public notifService: NotificationService, public auditService: AuditService) {
    super(log, httpService,cacheService,notifService);

    this.msrnCols = [
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'msrnNdc', header: 'MSRN NDC', width: '30px'},
      {field: 'msrnRangeStart', header: 'MSRN Range Start', width: '30px'},
      {field: 'msrnRangeEnd', header: 'MSRN Range End', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'termCountry', header: 'Country', width: '75px'},
      {field: 'provider', header: 'Provider', width: '100px'},
      {field: 'tadig', header: 'TADIG', width: '30px'},
      {field: 'mccMnc', header: 'MCC_MNC', width: '30px'}
    ];

    this.msrnCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
  }

  ngOnInit() {
    super.init();
    this.rangeFilter = new RangeFilter();
    this.fetchMsrnData(ActionName.NumberingDataMSRNDropdown);
    this.filteredCountryCount = 0;
    this.filteredProviderCount = 0;
  }

  downloadMsrnData() {
    let msrnData = this.getMsrnExportColumnNames().join(AppConstants.DELIMITER) + '\n';
    let action = ActionName.NumberingDataMSRNDownloadFltr;
    this.filteredMsrnDataList.forEach(item => {
      let result = [];
      result.push(item.termCountry);
      result.push(item.code);
      result.push(item.msrnNdc);
      result.push(item.msrnRangeStart);
      result.push(item.msrnRangeEnd);
      result.push(item.iso2);
      result.push(item.provider);
      result.push(item.tadig);
      result.push(item.mccMnc);
      msrnData += result.join(AppConstants.DELIMITER) + '\n';
    });

    this.downloadFile([msrnData],  this.makeDownloadFilename('MID_MSRN', '.csv')); 

    if (!this.hasFilter) {
      this.createRangeFilter();
    }
    this.auditService.auditLog(action,this.rangeFilter)
  }

  downloadMsrnDataFile() {
    this.isDownloading = true;
    this.httpService.getStream(AppConstants.DownloadMobileIdDataURL + "msrn", this.downloadFileHandler);
    return false;
  }

  downloadFileHandler = (data: Response) => {
    this.isDownloading = false;
    const fileName = "MID_MSRN" + '_' + this.cacheService.lastMobileDateSetDate + '.csv';
    this.downloadFile(data.body, fileName, 'application/octet-stream');
  }

  applyFilter() {
    this.fetchMsrnData(ActionName.NumberingDataMSRNFltr);
  }
  getFilteredCount (){
    this.filteredCountryCount = 0;
    if (this.filteredCountryList.length > 0) {
        this.filteredCountryList.forEach(item => {
          if (item.selected) {
            this.filteredCountryCount++;
          }
        });
    } 
    this.filteredProviderCount = 0;
    if (this.filteredProviderBillingIdList.length > 0) {
        this.filteredProviderBillingIdList.forEach(item => {
          if (item.selected) {
            this.filteredProviderCount++;
          }
        });
    }
  } 
}
