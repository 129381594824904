import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {AppConstants, LOGLEVEL} from './app/util/app.constant';
if (environment.production) {
  enableProdMode();
  AppConstants.LogLevel = LOGLEVEL.INFO;
}

platformBrowserDynamic().bootstrapModule(AppModule);
