<div class="offset-md-2 col-md-6">
    <h2 class="text-center">
        Get started by uploading your Allow Lists & Block Lists
    </h2>
    <p>
        Start by uploading your telephone number lists to the system. You are allowed up to {{ maxWhiteList }}
      separate allow lists and {{ maxBlackList }} separate block lists.
        Each telephone number list has an upload limit of 50 MB. These numbers are used to provide specific telephone number exceptions and inclusions along with your partitions.
    </p>

</div>

<div class="offset-md-2 col-md-6 w3-margin-top">
    <a class="link" href="#" (click)="downloadSampleListFile()">
        <h6 class="offset-md-1 w3-text-red text-center w3-margin-top">SAMPLE UPLOAD TELEPHONE NUMBER LIST</h6>
    </a>
</div>

<!--
<div class="offset-md-4 col-md-2" *ngIf="listService.isBlankList">
    <button class="btn offset-md-3 align-center w3-red" (click)="uploadFile()">UPLOAD FILE</button>
    <p class="w3-text-gray offset-md-4">CSV file type only</p>
</div>
-->
