import { Component, Input, OnInit } from '@angular/core';
import { FilterList, PartitionDownloader } from 'app/partitions/partition.downloader';
import { AppConstants, JsonHelper } from 'app/util';
import { Country, PartitionDataDetails, PartitionDefinition, ProviderBillingId, ResponseJson, RuleDefinition, TosAndTosDescType, TosTosDesc } from '../models';
import { SortMeta } from 'primeng/api';
import { CacheService, HttpService } from 'app/services';
import { SelectedCounter } from 'app/filter/filter-base';
import { CountryFilterData, CountryFilterObject, FilterDataModel, FilterDataModelGroup, FilterObject, ProviderFilterData, RulesFilterData, TosFilterData, TosTosdescFilterObject } from './filter-data-model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-filtered-table',
  templateUrl: './filtered-table.component.html',
  styleUrls: ['./filtered-table.component.scss']
})
export class FilteredTableComponent  implements OnInit {
  
  constructor(private httpService: HttpService) { 
    console.log("in FilteredTableComponent", this.hasRuleFilter, this.ruleId);

  }

  ngOnInit(): void {
    console.info("in ngOnInit", this.hasRuleFilter, this.ruleId);
    this.partitionCols = [
      { field: 'dialPattern', header: 'DialPattern', width: '50px'},
      { field: 'code', header: 'Code', width: '30px' },
      { field: 'ndc', header: 'NDC', width: '30px' },
      { field: 'iso2', header: 'ISO2', width: '30px' },
      { field: 'country', header: 'Country', width: '50px' },
      { field: 'tos', header: 'TOS', width: '30px' },
      { field: 'tosdesc', header: 'TOS Description', width: '70px' },
      { field: 'supplement', header: 'Supplement', width: '70px' },
      { field: 'provider', header: 'Provider', width: '70px' },
      { field: 'lemin', header: 'LEMin', width: '62px' },
      { field: 'lemax', header: 'LEMax', width: '64px' },
      { field: 'source', header: 'Source', width: '75px', nosort: true },
      { field: 'rule', header: 'Rule', width: '40px', nosort: true },
      { field: 'customerDate', header: 'Customer Date', width: '70px' },
      { field: 'reason', header: 'Reason', width: '70px' },
      { field: 'notes', header: 'Notes', width: '70px' }
    ].filter(col => this.hasRuleFilter || col.field != 'rule');


    this.getFilters();
  }

  @Input('hasRuleFilter')   hasRuleFilter:boolean;
  @Input('partition')       partition: PartitionDefinition;
  @Input('ruleId')          ruleId: any;
  @Input('draftOrExported')   draftOrExported: "Draft" | "Exported";
  @Input('cacheservice')      cacheService: CacheService;
  


  filteredData: PartitionDataDetails[] = [];
  partitionCols: ({ field: string; header: string; width: string; nosort?: boolean; toolTip?: string; } )[];
   

  //????
  selectedCounter: SelectedCounter = { country: 0, tos: 0, provider: 0, rule: 0 };
  filteredRuleList: Array<RuleDefinition> = [];
  filteredProviderBillingIdList: Array<ProviderBillingId> = [];
  filteredTOSList: Array<TosTosDesc> = [];
  filteredCountryList: Array<Country> = [];
  //????
  
  showData = true;
  loadingFilterData = true;  //set to false when initial filter loading is finished

  searchPattern: string;
  multiSortMeta: SortMeta[]; 
  dataTotalCount: number;

  pageLoading=false;
  limit = 10;
  saveEvent: any;
  
  
  //????
  downloader: PartitionDownloader;
  filters: FilterDataModelGroup;


  
  onDownload(){
    if (this.downloader  && this.downloader.isLoading())
      return;

    let filterList: FilterList = {
      iso2list: this.filterIso2list(), 
      providerList: this.filterProviderList(),
      tosList: this.filterTosList(),
      ruleIdList: this.filterRuleIdList(),
      searchPattern: this.searchPattern
    };  
    // let data = this.partitionService.getPartitionExportColumnNames().join(AppConstants.DELIMITER) + '\n';

    this.downloader = new PartitionDownloader(
      AppConstants.QueryDownloadPartitionDataURL, 
      this.partition, 
      this.ruleId,
      filterList,
      this.multiSortMeta,
      // 100000,
      this.httpService,
      // data
      this.dataTotalCount * 100  //expected file size (approximate)
      );   

      this.downloader.start();
  }

  filterRuleIdList(): string[] {
    return this.rules?.filterRuleIdList();
  }

  filterTosList(): TosTosDesc[] {
    return this.tosTosdesc?.filterTosList();
  }
  filterProviderList(): string[] {
    return this.providers?.filterProviderList();;
  }
  filterIso2list(): string[] {
    return this.countries?.filterIso2list();
  }

  closeAllFilter() {
    $('.filter').hide();
    this.resetTable();
    this.showData = true;
  }

  resetTable(){
    this.multiSortMeta = this.saveEvent.multiSortMeta;
    this.limit = this.saveEvent.rows;
    
  }



  loadDataDetails(event) {
    if (this.pageLoading){
      if ( JSON.stringify(this.saveEvent) === JSON.stringify(event) ) 
        return;
    }
     this.pageLoading = true;   

    const { first, rows, multiSortMeta,  globalFilter } = event;

    console.log("+++ loadDataDetails:", Date(), event, multiSortMeta, rows);
   
    const pageNo = first / rows;
    // this.pageNo = pageNo;
    // this.limit = rows;
    // this.multiSortMeta = multiSortMeta;
    this.searchPattern = globalFilter;
    this.saveEvent = event;

    this.pagination(pageNo, rows, multiSortMeta, globalFilter, this.draftOrExported, () => {this.pageLoading = false;});
  }



  pagination(page, limit, sortMeta:SortMeta[], globalFilter, status, func){
    this.filteredData = [];
    this.dataTotalCount = undefined;


    let orderBy = sortMeta ?  sortMeta.map(s =>{ return  {property: s.field, dir: s.order == 1 ?'ASC':'DESC'} }) : null; 
    // , 
    const pageParams={
      page:page,
      size:limit,
      orderBy : orderBy
    }
    const filterParams={
      iso2list:this.filterIso2list(),
      tosList: this.filterTosList(),
      providerList: this.filterProviderList(),
      ruleIdList : this.filterRuleIdList(),
      searchPattern: globalFilter
    }
    
    const body={
      id: this.partition.id,
      ruleId: this.ruleId,
      filter: filterParams,
      page: pageParams
    } 
    
    if(status === "Draft"){
      this.httpService.post(JSON.stringify(body),AppConstants.QueryDraftDataURL, 
        [this.postQueryDataHandler, func]);

    }else if(status === "Exported"){
      this.httpService.post(JSON.stringify(body),AppConstants.QueryExportDataURL, 
      [this.postQueryDataHandler, func]);
    }
  }



  postQueryDataHandler = async(responseJson: ResponseJson) => {
    console.info('in postQueryDataHandler 1', Date());
    if (responseJson.status === AppConstants.SUCCESS) {
      if (!responseJson.data) {
        console.warn('in postQueryDataHandler', Date(), responseJson.message, 'no-data');
        return;
      }

      const result: Array<PartitionDataDetails> = JsonHelper.parseArray(responseJson.data, PartitionDataDetails);
      this.dataTotalCount=responseJson.totalCount;
      this.filteredData = result;
      console.info('in postQueryDataHandler 2', Date(), this.filteredData.length);
    }
  }


  totalMessage(): string {
    let actual = this.dataTotalCount;
    let filtered =  
      this.countries?.selectedCount 
      || this.tosTosdesc?.selectedCount 
      || this.providers?.selectedCount 
      || this.rules?.selectedCount 
      || this.searchPattern;
    return (filtered ? "Filtered Records: " : "Total Records: ") + actual;


  };


  getRuleSetting(ruleId: string) {
    for (let rule of this.partition.ruleDefinitions) {
      if (rule.id === Number(ruleId)) {
        let result = rule.dataSource;

        if (rule.dialPatternType) {
          result += ' ' + rule.dialPatternType;
        }

        return result;
      }
    }

    return '';
  }

  getIPRNSourceForDisplay(dialPatternType: string) {
    return 'IPRN ' + dialPatternType;
  }

  getRuleIndex(ruleId: string) {
    if (this.partition.ruleIds) {
      const ruleIds = this.partition.ruleIds.split(',');
      return ruleIds.indexOf(ruleId) + 1;
    } else {
      return '';
    }
  }

  // draft data filters
  getFilters=async()=>{
    this.loadingFilterData = true;
    const ruleId=this.ruleId
    
    let url = this.draftOrExported == 'Draft' ? AppConstants.QueryDraftDataFilterURLv2 :  AppConstants.QueryExportDataFilterURLv2;
    this.httpService.get(url + '?id=' + this.partition.id +  (ruleId ?  `&ruleId=${ruleId}` : ""),
      [this.postQueryFilterHandler]);
  }



  postQueryFilterHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      if (!responseJson.data) {
        console.warn("No data in postQueryFilterHandler",responseJson)
        return;
      }
      const result=responseJson.data;

      this.initFilter(result);

    }else{
      console.error("Error:",responseJson)
    }

    this.loadingFilterData = false;
  
  }

  countries:CountryFilterData;
  providers:ProviderFilterData;
  tosTosdesc:TosFilterData;
  rules: RulesFilterData;


  initFilter(result: string[][]) {
    this.countries = new CountryFilterData('country', result, this.cacheService.getIso2CountryMap());
    this.providers = new ProviderFilterData('provider', result);
    this.tosTosdesc = new TosFilterData('tos', result);
    if (this.hasRuleFilter)
      this.rules = new RulesFilterData('rules', result);  

    this.filters =  this.hasRuleFilter ?  
                    new FilterDataModelGroup([this.countries, this.tosTosdesc, this.providers, this.rules], result)
                  : new FilterDataModelGroup([this.countries, this.tosTosdesc, this.providers], result)
                    ;  

  }

  toggleSelection(fo:FilterObject){
    // console.log("in toggleSelection");
    fo.isSelected = !fo.isSelected;
  }


  createFilterData(filter:FilterDataModel<any>) {
    console.log('in createFilterData');

    this.filters.createFiterData(filter);

    $('.search-query').val('');
    $('.filter').hide();

    $('#' + filter.name).show();
  
  } 

  getNgClass(obj: FilterObject): string{

    let ngc = obj.isSelected ? "filter-selected" : "";
    // console.log("ngClass=", ngc, country.country);

    return ngc;

  }

  clearNumberSearch(gb, dt: Table){
    if (gb.value) {
      gb.value=''; 
      this.resetTable();
      dt.reset();
    }    
  }

}
