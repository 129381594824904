import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmationService } from 'primeng/api';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { AppConstants, JsonHelper} from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import {PartitionDataDetails, RuleDefinition, RangeFilter,ProviderBillingId, TosTosDesc, Country} from '../models/index';
import { PartitionService } from '../partitions/partition.service';
import { ActionName, DataSource } from 'app/shared/constants';
import { AuditService } from 'app/services/audit.service';
import { AuditPartition } from 'app/models/auditPartition.model';


@Component({
  selector: 'app-partition-export-rule',
  templateUrl: './partition-export-rule.component.html',
  styleUrls: ['./partition-export-rule.component.scss']
})
export class PartitionExportRuleComponent extends RulePartitionBase implements OnInit {
  filteredExportRuleData: Array<PartitionDataDetails> = [];
  rule: RuleDefinition;
  showFilterWin = false;
  mySelectedCounter = { country: 0, tos: 0, provider: 0 };
  myfilteredCountryList: Array<Country> = [];

  mytosList: Array<TosTosDesc> = [];
  myfilteredTOSList: Array<TosTosDesc> = [];
  myproviderBillingIdList: Array<ProviderBillingId> = [];
  myfilteredProviderBillingIdList: Array<ProviderBillingId> = [];

  partitionExportCols: any[];
  filterFieldCols: any[] = [];
  filterBy = '';

  constructor(private location: Location, private route: ActivatedRoute, private router: Router, public partitionService: PartitionService, public log: Logger,
    public httpService: HttpService, public cacheService: CacheService, 
    public notifService: NotificationService, private confirmationService: ConfirmationService, public auditService: AuditService) {
    super(log, httpService, cacheService, notifService);

    this.partitionExportCols = [
      {field: 'dialPattern', header: 'DialPattern', width: '50px'},
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'country', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '70px'},
	    {field: 'supplement', header: 'Supplement', width:'70px'},
	    {field: 'provider', header: 'Provider', width:'70px'},
	    {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
	    {field: 'source', header: 'Source', width: '75px'},
	    {field: 'customerDate', header: 'Customer Date', width: '70px'},
	    {field: 'reason', header: 'Reason', width: '70px'},
      {field: 'notes', header: 'Notes', width: '70px'}
    ];

    this.partitionExportCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
  }

  //share the same component for rule details view and all data view
  ngOnInit() {
    super.init();

    if (this.partitionService.selectedRule) {
      this.rule = this.partitionService.selectedRule;

      this.rangeFilter = JsonHelper.parse(this.rule.rangeQueryFilter, RangeFilter);
      this.createFilterFromRule(this.rule);

      Object.assign(this.mySelectedCounter, this.selectedCounter);

      this.partitionService.exportData.forEach(entry => {
        if ((entry.type === 'R' || entry.type === 'U' || entry.type === 'I' ) && +entry.reference === this.rule.id) {
          this.filteredExportRuleData.push(entry);
        }
      });

      this.auditPartitionData = new AuditPartition();
      this.auditPartitionData.partitionId = this.partitionService.selectedExportPartition.id;
      this.auditPartitionData.ruleId = this.rule?.id;
      if(this.rule.dataSource === DataSource.Range)
        this.auditPartitionData.ruleType = DataSource.Range;
      else 
        this.auditPartitionData.ruleType = this.rule.dialPatternType;
    }
    this.auditService.auditLog(ActionName.PartitionExportRulesDialPtn, null, this.auditPartitionData); //US2036 #6
  }

  onDownload() {
    let data = this.partitionService.getPartitionExportColumnNames().join(AppConstants.DELIMITER) + '\n';

    this.filteredExportRuleData.forEach(item => {
      data += item.toDownloadString(AppConstants.DELIMITER, this.partitionService.selectedExportPartition.ruleDefinitions) + '\n';
    });

    this.downloadFile([data],   this.makeDownloadFilename( this.partitionService.selectedPartition.name , '.csv'));
    this.auditService.auditLog(ActionName.PartitionExportRulesDialPtnDownload, null,this.auditPartitionData ) //US2036 #7
  }

  filterExportRuleData() {
    this.filteredExportRuleData = [];
    let selectedCountryList = this.myfilteredCountryList.filter(item => {
      if (item.selected) {
        return item;
      }
    });

    let selectedTosTosDesc = this.myfilteredTOSList.filter(item => {
      if (item.selected) {
        return item;
      }
    });

    let selectedProvider = this.myfilteredProviderBillingIdList.filter(item => {
      if (item.selected) {
        return item;
      }
    });

    this.partitionService.exportData.filter(item => {
      if ((item.type !== 'R' && item.type !== 'U' && item.type !== 'I') || +item.reference !== this.rule.id) {
        return false;
      }
      const country = new Country();
      country.country = item.country;
      country.code = item.code;
      country.iso2 = item.iso2;

      const tos = new TosTosDesc();
      tos.tos = item.tos;
      tos.tosdesc = item.tosdesc;

      const provider = new ProviderBillingId();
      provider.provider = item.provider;

      if (selectedCountryList.length > 0) {
        if (!this.hasCountry(selectedCountryList, country)) {
          return false;
        }
      }

      if (selectedTosTosDesc.length > 0) {
        if (!this.hasTosTosDesc(selectedTosTosDesc, tos)) {
          return false;
        }
      }

      if (selectedProvider.length > 0) {
        if (!this.hasProvider(selectedProvider, provider)) {
          return false;
        }
      }

      this.filteredExportRuleData.push(item);
    });
  }

  closeAllFilter() {
    $('.filter').hide();
    this.filterExportRuleData();
  }

  createFilterData(name: string, event: any, overlaypanel: OverlayPanelModule) {
    $('.search-query').val('');
    $('.filter').hide();

    $('#f' + name).show();

    this.partitionService.exportData.forEach(item => {
      if (!((item.type === 'R' || item.type === 'U' || item.type === 'I')  && +item.reference === this.rule.id)) {
        return;
      }
      const country = new Country();
      country.country = item.country;
      country.code = item.code;
      country.iso2 = item.iso2;

      const tos = new TosTosDesc();
      tos.tos = item.tos;
      tos.tosdesc = item.tosdesc;

      const provider = new ProviderBillingId();
      provider.provider = item.provider;

      switch (name) {
        case 'country':
          if (!this.hasCountry(this.myfilteredCountryList, country)) {
            this.myfilteredCountryList.push(country);
          }

          break;
        case 'provider':
          if (!this.hasProvider(this.myfilteredProviderBillingIdList, provider)) {
            this.myfilteredProviderBillingIdList.push(provider);
          }
          break;
        case 'tos':
          if (!this.hasTosTosDesc(this.myfilteredTOSList, tos)) {
            this.myfilteredTOSList.push(tos);
          }

          break;
        default:
      }

    });
  }

  getSelectedValues(type: string): string {
    let result = '';
    this.createRangeFilter();

    switch (type) {
      case 'country':
        let countries = [];
        this.myfilteredCountryList.forEach(item => {
          if (item.selected) {
            countries.push(item.country + ' ' + item.code + ' ' + item.iso2);
          }
        });
        result = countries.join(';');
        break;
      case 'provider':
        let providers = [];
        this.myfilteredProviderBillingIdList.forEach(item => {
          if (item.selected) {
            providers.push(item.provider);
          }
        });
        result = providers.join(';');
        break;
      case 'tos':
        let tosCodes = [];
        this.myfilteredTOSList.forEach(item => {
          if (item.selected && tosCodes.indexOf(item.tos) < 0) {
            tosCodes.push(item.tos + ':' + (item.tosdesc == "" ? "Unallocated" : item.tosdesc ));
          }
        });
        result = tosCodes.join(';');
        break;

      default:
    }

    if (result.length > 200) {
      result = result.substring(0, 200) + '...';
    }

    return result;
  }

  clearSelection(type: string) {
    switch (type) {
      case 'country':
        this.myfilteredCountryList.forEach(item => {
          item.selected = '';
        });

        this.selectedCounter.country = 0;
        break;
      case 'provider':
        this.myfilteredProviderBillingIdList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.provider = 0;
        break;
      case 'tos':
        this.myfilteredTOSList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.tos = 0;
        break;

      default:
    }
  }


  openFilterInPartition(name: string) {
    this.showFilterWin = true;
    this.openFilter(name, true);
    this.showSelected(name);
    this.filterBy=name;
  }

  goBack() {
    this.location.back();
  }

}
