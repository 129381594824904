import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToUnderscore'
})
export class ConvertToUnderscorePipe implements PipeTransform {

  transform(value: string, listType: string): string {
    return listType + "_" + value.replace(/\s/g, '_');
  }
}