import { Component, OnInit, OnDestroy, Input, DoCheck, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants, JsonHelper, TableComponent } from '../util';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { ResponseJson } from '../models/index';
import { ListDefinition } from '../models/listDefinition.model';
import { ListDetails, EditItem } from '../models/index';
import * as moment from 'moment';
import { ListsService } from '../lists/lists.service';
import { NotificationService } from '../services/notification.service';
import { ConfirmationService } from 'primeng/api';
import { EditableFieldComponent } from '../editable-field/editable-field.component';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';


declare var $: any;

class KeyValuePair {
  label: string;
  value: any;
}

@Component({
  selector: 'app-list-detail',
  templateUrl: './list-detail.component.html',
  styleUrls: ['./list-detail.component.scss']
})
export class ListDetailComponent extends TableComponent<ListDefinition> implements OnInit, DoCheck, OnDestroy {
  @ViewChild('uploadBtn') el: ElementRef;
  @ViewChild('listNameField') inputListName:EditableFieldComponent;
  @ViewChild('dt') dataTable: Table;
  listChange: Subscription;
  selectedList: ListDefinition;
  selectedRecords: Array<ListDetails> = [];
  selectedListDetail: ListDetails;
  newItemIndex = 0;
  styleClass = 'inline';
  placeholderValue = 'Name the list';
  selectedDetailItem: EditItem<ListDetails>;
  uploadErrorMessage: String;
  showUploadErrorWin = false;
  showListDetailWin = false;
  showNewDetailWin = false;
  showAddNewWin = false;
  newListRecords: Array<ListDetails> = [];
	editField: string;
  currentVal: string;
  file: File = null;
  acceptLabel = 'Delete List';
  isEditDesc = false;
  showDialog = false;
  showThresholdMessage = false;
  showDuplicateMessage = false;
  maxWhiteList: number;
  maxBlackList: number;
  remainingAllotment: number;
  messageRegularText = "";
  tableEmptyMessage = "";
  listCols: any[]; 
  
  listColumnNames = [
    'Country',
    'DialPattern',
    'CustomerDate',
    'Reason',
    'Notes',
    'NDC',
    'ISO2',
    'CountryCode',
    'TOS',
    'TOSDesc',
    'Provider',
    'LEMin',
    'LEMax',
  ];

  statusChoices: Array<KeyValuePair> = [];

  constructor(private log: Logger, private httpService: HttpService, private route: ActivatedRoute, private router: Router, public notifService: NotificationService,
    public listService: ListsService, private renderer: Renderer2, private confirmationService: ConfirmationService) {
    super(notifService);

    this.listCols = [
      {field: 'dialPattern', header: 'Dial Pattern', width: '85px'},
      {field: 'reason', header: 'Reason', width: '150px'},
      {field: 'code', header: 'Code', width: '38px'},
      {field: 'ndc', header: 'NDC', width: '55px'},
      {field: 'iso2', header: 'ISO2', width: '41px'},
      {field: 'termCountry', header: 'Country', width: '135px'},
      {field: 'tos', header: 'TOS', width: '33px'},
      {field: 'tosdesc', header: 'TOS Description', width: '135px'},
      {field: 'lemin', header: 'LEMin', width: '53px'},
      {field: 'lemax', header: 'LEMax', width: '53px'},
      {field: 'customerDate', header: 'Customer Date', width: '100px'},
      {field: 'notes', header: 'Notes', width: '120px'},
      {field: 'status', header: 'Status', width: '89px'}
    ];
  }

  ngOnInit() {
    this.statusChoices = [
      {label: 'Active', value: 'Active'},
      {label: 'Inactive', value: 'Inactive'}
    ];
    //console.log("ngOnInit() showDuplicateMessage: " + this.showDuplicateMessage);
    //this.listService.hasUploadError();
  }

  ngOnDestroy() {
    this.listService.stopBatchLoading = true;
    this.listChange?.unsubscribe();
  }

  ngDoCheck() {
    if (this.selectedList !== this.listService.selectedList) {
      this.selectedRecords = [];
      this.selectedList = this.listService.selectedList;
      this.listChange = this.listService.listChange.subscribe((value : boolean) => {
        if (value == true) {
          if (this.dataTable)
            this.dataTable.reset();
        }
      });
    }
    if (this.listService.hideMessageBox) {
      this.showDialog=false;
      this.showThresholdMessage=false;
      this.showDuplicateMessage=false;
      this.listService.hideMessageBox=false;
    }
  }

  onOpen() {
    this.file = null;
    this.listService.resetListName();
    this.listService.displayUploadWin = true;
  }

  onOpenAdd() {
    this.newListRecords = [];
    this.message = '';
    this.editNewNumber();
    return;
  }

  validateDialPattern(listDetail: ListDetails) {
    if (listDetail.dialPattern == '') {
      this.message = 'Enter valid dial pattern';
      if (this.selectedDetailItem && this.selectedDetailItem.item) {
        this.selectedDetailItem.item.dialPattern = '';
      }
      return;
    }
 /*    if (this.hasDuplicate(listDetail.dialPattern)) {
      this.message = 'Dial pattern ' + listDetail.dialPattern + ' already exists';
      this.selectedDetailItem.item.dialPattern = '';
      return;
    } */

    this.listService.selectedListItem.item.listData.forEach(item => {
      if (item.dialPattern === listDetail.dialPattern) {
        this.message = 'Dial pattern ' + listDetail.dialPattern + ' already exists';
        listDetail.dialPattern = '';
        return;
      }
    });

    if (listDetail.dialPattern) {
      this.message = '';
    }

   /*  if (!listDetail.id) {
      this.getDialPatternDetails(listDetail);
    } */
  }

  getDialPatternDetails(listDetail: ListDetails) {
    this.httpService.post(JsonHelper.toJson(listDetail), AppConstants.GetDialPatternDetails, [this.postGetDialPatternDetail]);
  }

  postGetDialPatternDetail = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      const result: ListDetails = JsonHelper.parse(responseJson.data, ListDetails);
      if (result) {
        result.customerDate = this.newListRecords[this.newItemIndex].customerDate;
        result.reason = this.newListRecords[this.newItemIndex].reason;
        result.notes = this.newListRecords[this.newItemIndex].notes;
        result.active = this.newListRecords[this.newItemIndex].active;
        this.newListRecords[this.newItemIndex] = result;
      }
    }
  }

  validateDialPattern_db(listDetail: ListDetails) {
    this.httpService.get(AppConstants.HasListDetailRecord + this.selectedList.id + '/' + listDetail.dialPattern,
      [this.postValidateDialPattern]);
  }

  postValidateDialPattern = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      const result: ListDetails = JsonHelper.parse(responseJson.data, ListDetails);
      if (result) {
        this.message = 'Dial pattern ' + result.dialPattern + ' already exists';
        this.selectedDetailItem.item.dialPattern = '';
      }
    }
  }

 /*  hasDuplicate(dialPattern: string): boolean {
    let size = 0;
    this.newListRecords.forEach(item => {
      if (item.dialPattern === dialPattern) {
        size++;
      }
    });

    return size > 1;
  } */

  updateListName(listName: string) {
    if (this.listService.isSelectDuringNameEdit && this.listService.isNewList) {
      this.listService.isSelectDuringNameEdit = false;
      this.listService.isNewList = false;
      return;
    }
    if (this.listService.isSkipDupMsg && this.listService.isNewList) {
      this.listService.isSkipDupMsg = false;
      return;
    }
    this.listService.isSelectDuringNameEdit = false;
    this.listService.isEditingNameField = false;

    if (listName) {
      this.listService.selectedList.listName = listName;
      this.httpService.post(JsonHelper.toJson(this.listService.selectedList), AppConstants.SaveListDefinition, [this.postUpdateListName]);

    } else {
      this.listService.selectedList.listName = this.listService.tempListName;
      this.httpService.post(JsonHelper.toJson(this.listService.selectedList), AppConstants.SaveListDefinition, [this.postUpdateListName]);
    }
  }

  postUpdateListName = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.listService.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);
      this.listService.selectedListItem = new EditItem(this.selectedList, ListDefinition);
      this.listService.selectedListItem.item.id = this.listService.selectedList.id;
      this.responseCallBack(responseJson);
      if (this.listService.isNewList) {
        this.getMaxValues();
        this.listService.queryRemainingListAllotment(this.listService.selectedList.type);
        this.checkThresholdLimit();
      }

      this.listService.isNewList = false;
      this.listService.selectedList.selected=true;
      this.selectedList.selected=true;

      this.listService.alertLeftRailUpdate();
      //this.listService.selectedListId=this.listService.selectedList.id;
      //this.listService.onSelect(this.selectedList);
    } else {
      //this.messageRegularText=responseJson.message;
      console.log("errorMsg: " + responseJson.message);
      if (responseJson.message.includes("same name")) {
        //skip displaying duplicate message if onSelect in progress
        if (!this.listService.isSkipDupMsg) {
        //if ( (!this.listService.isSkipDupMsg && this.listService.isNewList) || (!this.listService.isSelectDuringNameEdit) ) {
          this.showDuplicateMessage=true;
          this.listService.hideMessageBox=false;
          this.listService.toggleDeleteIcon("");
        }
      }
      else
        this.responseCallBack(responseJson);
    }
    if (this.listService.isSkipDupMsg)
      this.listService.isSkipDupMsg = false;
    if (this.listService.isSelectDuringNameEdit)
      this.listService.isSelectDuringNameEdit = false;

  }

  async checkThresholdLimit() {
    await AppConstants.delay(1000);

    this.remainingAllotment = this.listService.remainingAllotment;
    //console.log("checkThresholdLimit remaining: " + this.remainingAllotment + " | maxBL: " + this.maxBlackList + " | maxWL: " + this.maxWhiteList + " | threshold: " + AppConstants.ThresholdWarningLimit);
    if ( this.remainingAllotment <= AppConstants.ThresholdWarningLimit) {
      this.showThresholdMessage = true;
      await AppConstants.delay(7000);
    }

    if (this.showThresholdMessage)
      this.closeMessage();
      
  }

  getMaxValues() {
    this.httpService.get(AppConstants.QueryMaxValues, [this.postGetMaxValues]);
  }

  postGetMaxValues = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.maxBlackList = responseJson.data.maxBlackList;
      this.maxWhiteList = responseJson.data.maxWhiteList;
    }
  }

  onUpdateDesc() {
     this.isEditDesc = false;
     this.httpService.post(JsonHelper.toJson(this.listService.selectedList), AppConstants.SaveListDefinition, [this.responseCallBack]);
  }

 /*  addNewNumber() {
    let item = new ListDetails();
    item.active = true;
    item.reason = '';
    item.notes = '';
    item.listRefId = this.listService.selectedListItem.item.id;
    this.newListRecords.push(item);
  } */

  editNewNumber(index?: number) {
    this.showNewDetailWin = true;
    let listDetail: ListDetails;
    if (index >= 0) {
      this.newItemIndex = index;
      listDetail = this.newListRecords[this.newItemIndex];
    }

    if (!listDetail) {
      listDetail = new ListDetails();
      listDetail.dialPattern = '';
      listDetail.reason = '';
      listDetail.notes = '';
      listDetail.active = true;
      listDetail.listRefId = this.listService.selectedListItem.item.id;

      const size = this.newListRecords.push(listDetail);
      this.newItemIndex = size - 1;
    }
    this.selectedListDetail = this.newListRecords[this.newItemIndex];
  }

  /* removeNewNumber(index: number) {
    this.newListRecords.splice(index, 1);
    return false;
  } */

  /* updateAddedRecord() {
    this.newListRecords[this.newItemIndex].dialPattern = this.selectedListDetail.dialPattern;
    this.newListRecords[this.newItemIndex].customerDate = this.selectedListDetail.customerDate;
    this.newListRecords[this.newItemIndex].reason = this.selectedListDetail.reason;
    this.newListRecords[this.newItemIndex].notes = this.selectedListDetail.notes;
    this.newListRecords[this.newItemIndex].active = this.selectedListDetail.active;

    this.closeEditNew();
  } */

  closeEditNew() {
    this.showNewDetailWin = false;
    this.message = '';

    //start from add new number flow
    if (!this.selectedListDetail.dialPattern) {
      this.newListRecords.pop();
    }
  }

  saveNewNumber(listDetail: ListDetails) {
    const myNewList: Array<ListDetails> = [];
    this.validateDialPattern(listDetail);
    listDetail.listRefId = this.selectedList.id;
    if (listDetail.dialPattern) {
      if(listDetail.customerDate==null) {
         listDetail.customerDate = new Date();
      }
      myNewList.push(listDetail);
      this.httpService.post(JsonHelper.toJson(myNewList), AppConstants.CreateListDetail, [this.postSaveListRecord, this.responseCallBack]);
      this.showNewDetailWin = false;
    }
  }

  postSaveListRecord = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      const result: Array<ListDetails> = JsonHelper.parseArray(responseJson.data, ListDetails);
      this.listService.selectedListItem.item.listData = result.concat(this.listService.selectedListItem.item.listData);
      this.notifService.add({ severity: 'success', summary: 'Success', detail: 'Added ' + result.length + ' number(s) to list' });
    };

  }


  onDeleteList() {
    this.acceptLabel = 'Delete Records';
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Are you sure you want to permanently delete the records in this list? The records can not be restored after this action.',
      accept: () => {
        this.listService.stopBatchLoading = true;
        this.httpService.delete(AppConstants.DeleteAllListRecords + '/' + this.selectedList.id, [this.postDeleteList, this.responseCallBack]);
      }
    });
  }

  postDeleteList = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.listService.selectedListItem.item.listData = [];
      this.listService.selectedList.listData = [];
      this.listService.queryListDefinition();
    }
  }

  onClearSelection() {
    this.selectedRecords.forEach(record => {
      record.bgHighlight = false;
    });
    this.selectedRecords = [];
  }

  onDeleteSelected() {
    /*
    this.acceptLabel = 'Delete Selected';
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Are you sure to delete selected list records?',
      accept: () => {
        this.httpService.delete(AppConstants.DeleteListDetail, [this.postDeleteListData, this.responseCallBack], JsonHelper.toJson(this.selectedRecords));
      }
    }); */
    
    this.httpService.delete(AppConstants.DeleteListDetail, [this.postDeleteListData, this.responseCallBack], JsonHelper.toJson(this.selectedRecords));

    return;
  }

  postDeleteListData = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedRecords.forEach(record => {
        const index = JsonHelper.findElementPosById(this.listService.selectedListItem.item.listData, record.id);

        if (index >= 0) {
        this.listService.selectedListItem.item.listData.splice(index, 1);
        }
      });

      this.listService.selectedListItem.update();
      this.listService.selectedListItem.item.listData = this.listService.selectedListItem.item.listData.slice();
      this.selectedRecords = [];
    }
  }

  activateRows() {
    this.selectedRecords.forEach(record => { record.active = true; });
    this.httpService.post(JsonHelper.toJson(this.selectedRecords), AppConstants.ToggleActivity, [this.responseCallBack]);
  }
  
  deactivateRows() {
    this.selectedRecords.forEach(record => { record.active = false; });
    this.httpService.post(JsonHelper.toJson(this.selectedRecords), AppConstants.ToggleActivity, [this.responseCallBack]);
  }  


  onEdit(event) {
    this.showListDetailWin = true;
    this.message = '';
    this.selectedDetailItem = new EditItem(event.data, ListDetails);
  }

  onEditListEntry(eventData) {
    let newVal = '';
    if (this.editField === 'customerDate') {
      newVal = moment(eventData[this.editField]).format('YYYY-MM-DD');
    } else {
      newVal = eventData[this.editField];
    }
    if (newVal !== this.currentVal) {
      const items: Array<ListDetails> = [];
      items.push(eventData);
      this.httpService.post(JsonHelper.toJson(items), AppConstants.UpdateListDetail, [this.responseCallBack]);
      this.selectedDetailItem = new EditItem(eventData, ListDetails);
    }
  }

  onToggleActivity(eventData: ListDetails) {
    const newVal = eventData[this.editField];
    if (newVal !== this.currentVal) {
      const items: Array<ListDetails> = [];
      items.push(eventData);
      this.httpService.post(JsonHelper.toJson(items), AppConstants.ToggleActivity, [this.responseCallBack]);    
      this.selectedDetailItem = new EditItem(eventData, ListDetails);
    }
  } 

  storeCurrentVal(event) {
    this.editField = event.field;
    if (event.field === 'customerDate') {
      this.currentVal = moment(event.data[event.field]).format('YYYY-MM-DD');
    } else {
      this.currentVal = event.data[event.field];
    }
  }

  updateListRecord() {
    const items: Array<ListDetails> = [];
    items.push(this.selectedDetailItem.item);

    this.httpService.post(JsonHelper.toJson(items), AppConstants.UpdateListDetail, [this.postUpdateListRecord, this.responseCallBack]);
  }

  postUpdateListRecord = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.FAIL) {
      this.selectedDetailItem.reset();
    }
    this.showListDetailWin = false;
  }


  resetListRecord() {
    this.selectedDetailItem.reset();
    this.showListDetailWin = false;
  }

  showUploadError() {
    this.showUploadErrorWin = true;
    if (this.listService.selectedList.listUploadRequests && this.listService.selectedList.listUploadRequests.length > 0) {
      if (this.listService.selectedList.listUploadRequests[0].errorData) {
        this.uploadErrorMessage = this.listService.selectedList.listUploadRequests[0].errorData;
      }
    }
  }

  downloadList() {
    this.listService.resetListName();
    let listData = this.listColumnNames.join(AppConstants.DELIMITER) + '\n';
    this.listService.selectedList.listData.forEach(item => {
      if (item.active) {
        listData += item.toString(AppConstants.DELIMITER) + '\n';
      }
    });
    this.downloadFile(listData,   this.makeDownloadFilename(this.listService.selectedList.listName, '.csv'));
  }

  downloadError() {
    this.listService.resetListName();
    this.uploadErrorMessage = this.listService.selectedList.listUploadRequests[0].errorData.replace(/</g, ',').replace(/>/g, ',');

    this.downloadFile(this.uploadErrorMessage,  this.makeDownloadFilename(this.listService.selectedList.listName + '_error', '.csv'));
  }

  getDownloadData(): Array<ListDetails> {
    if (this.listService.selectedList) {
      return this.listService.selectedList.listData;
    }
    return;
  }

  highlightSelected (row: ListDetails) {
    if (row.bgHighlight) {
      return "highlight";
    } else {
      return "";
    }
  }
  onRowClick(event) {
    this.selectedListDetail = event.data;
    this.selectedListDetail.bgHighlight = !this.selectedListDetail.bgHighlight;
    let position : number = this.selectedRecords.indexOf(this.selectedListDetail);
    if (position === -1) {
      this.selectedRecords.push(event.data);
    } else {
      this.selectedRecords.splice(position, 1);
    }
  }

  onRowUnselect(event) {
    this.selectedRecords.splice(event.data);
  }

  upload(event) {
    if (this.file)
      this.listService.upload(event, this.file);
    this.el.nativeElement.value = '';
  }
   
  fileChange() {
    this.file = this.el.nativeElement.files[0];
  }

  //restore original list item
  onCancel() {
    this.listService.onCancel();
    this.selectedList = this.listService.selectedList;
    this.el.nativeElement.value = '';
  }

  // mouseenter event
  showToolTip()  {
    setTimeout(() => {
      this.showDialog = true;
    }, 400);
  };

  closeMessage(){
    this.showThresholdMessage = false;
  }


  closeDuplicateMessage(){
    this.showDuplicateMessage = false;
    this.inputListName.isEditing=true;  
    this.listService.isEditingNameField=true;
    this.inputListName.origItem = this.inputListName.prevName;
    this.inputListName.setFocus();
    this.listService.blockDelete = false;
  }

  onSpaceKeyDown(event: any, rowData: any, key: string){
    let value = event.target.value;
    value = value.slice(0, event.target.selectionStart) + ' ' + value.slice(event.target.selectionStart);
    rowData[key] = value;
  }

  disableNavigation(event: any, rowData) {
    if(event.key === 'ArrowDown' || event.key === 'Down' ||
       event.key === 'ArrowUp' || event.key === 'Up' ||
       event.key === 'ArrowLeft' || event.key === 'Left' ||
       event.key === 'ArrowRight' || event.key === 'Right')
       event.stopPropagation(); 
    if(event.key === 'Enter') {
      this.onEditListEntry(rowData);
    }   
  }
}
