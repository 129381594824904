<div class="container-fluid">
    <div class="col-md-2">
        <div id = "cg-list" class="groups-menu">
            <div class="row">
                <div class="col-sm-8" style="padding-left: 5px;">
                    <h6><strong>Country Groups</strong></h6>
                </div>    
                <div class="col-sm-1" *ngIf = "isAddMoreCG">
                    <i id="cgAddIcon" class="fa fa-plus-circle fa-2x" style="margin-top: 10px;  color: #d71b36; cursor: pointer;" aria-hidden="true" (mousedown)="newGroup()" ></i>
                </div>
                <div class="col-sm-1" *ngIf = "!isAddMoreCG">
                    <i class="fa fa-plus-circle fa-2x" style="margin-top: 10px;  color: rgba(72,76,85,.5);" aria-hidden="true" ></i>
                </div>
                <div class="col-sm-1">
                    <div *ngIf = "cGroups.length > 0 && isAllowDelete">
                        <i id="cgDeleteIcon" class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color: #33647f; cursor: pointer;" aria-hidden="true" (click)="onDeleteList()"></i>
                    </div>
                    <div *ngIf = "cGroups.length < 1 || !isAllowDelete">
                        <i class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color:rgba(72,76,85,.5);"></i>
                    </div>
                </div>   
            </div>
            <div id = "cgArea">
                <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "cGroups.length <= showMoreLimit">
                    <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let cGroup of cGroups; let i=index">
                        <div class="col-sm-10 lists-group-horiz-padding">
                            <a id="{{cGroup.id}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(cGroup)" [class.selected]="cGroup.selected">{{cGroup.name}}</a>
                        </div>
                        <div [hidden]="!cGroup.selected" class="col-sm-12 lists-group-horiz-padding">
                            <hr/>
                        </div>
                    </li>
                </ul>
                <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "cGroups.length > showMoreLimit && showMoreCG">
                    <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let cGroup of cGroups | slice:0:showMoreLimit; let i=index">
                        <div class="col-sm-10 lists-group-horiz-padding">
                            <a id="{{cGroup.id }}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(cGroup)" [class.selected]="cGroup.selected">{{cGroup.name}}</a>
                        </div>
                        <div [hidden]="!cGroup.selected" class="col-sm-12 lists-group-horiz-padding">
                            <hr/>
                        </div>
                    </li>
                </ul>
                <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "cGroups.length > showMoreLimit && !showMoreCG">
                    <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let cGroup of cGroups; let i=index">
                        <div class="col-sm-10 lists-group-horiz-padding">
                            <a id="{{cGroup.id}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(cGroup)" [class.selected]="cGroup.selected">{{cGroup.name}}</a>
                        </div>
                        <div [hidden]="!cGroup.selected" class="col-sm-12 lists-group-horiz-padding">
                            <hr/>
                        </div>
                    </li>
                </ul>
                <div *ngIf = "cGroups.length > showMoreLimit" class="text-center">
                    <a class="showMoreLess-link" style="margin-right: 5px;" (click)="showMoreCG = !showMoreCG">{{ showMoreCG ? "Show More" : "Show Less"}}
                        <i *ngIf = "showMoreCG" class="fa fa-chevron-down" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                        <i *ngIf = "!showMoreCG" class="fa fa-chevron-up" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </div> 
    <div class="col-md-9 leftrail-leftbar">
        <div class="offset-md-2 col-md-6" *ngIf="cGroups.length == 0 && maxCountryGroups>0 && !isNewGroup && !selectedGroup">
            <h2 class="text-center">
                Get started by creating a <br> Country Group
            </h2>
            <p>
                You are allowed {{maxCountryGroups}} Country Groups.  Create and name your first by clicking the + icon to the left.  Then select countries to add to your Country Group.
            </p>
        </div>
        <div *ngIf="cGroups.length >0 || selectedGroup || isNewGroup">
            <div class="col-sm-12">
                <h3 class="text-center">
                    <app-editable-field [origItem]="selectedGroupItem.item.name" [styleClass]="styleClass" [maxLength]="25" [placeholderText]="placeholderValue" (dateModelChange)="updateCountryGroupName($event)" id="countryGroupNameField" #countryGroupNameField></app-editable-field>                
                    <small><i class="fa fa-info-circle" style="cursor: pointer;" (click)="showDescDialog();"></i></small>   
                </h3>
                <p class="text-end" *ngIf="!isNewGroup && selectedGroup">Last updated {{selectedGroup.lastUpdated | date: 'yyyy-MM-dd HH:mm'}} </p>
            </div>
            <!--Tooltip for list description -->
            <p-dialog [(visible)]="showDescriptionDialog" 
                modal="modal" 
                [showHeader]="false" 
                position="top" width="600" 
                [contentStyle]="!isEditDesc ? {'background-color':'#33647f'} :{'background-color':'white'}">
            <div>
                <div id = "dialog-close" class="dialog-close">
                    <div class="row">
                        <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: -10px;" *ngIf="isEditDesc">
                            Group Description
                        </div>
                        <div class="col-sm-10 toolTipText" style="padding-left:0px;color:white;margin-top: -10px;"  *ngIf="!isEditDesc">
                            Group Description
                        </div>
                        <div class="col-sm-2" style="margin-top: -15px;">
                            <span class="fa-stack fa-lg close-button" *ngIf="!isEditDesc">
                                <i class="fa fa-times fa-stack-1x fa-inverse" style="cursor: pointer;" (click)="isEditDesc = false;hideMessages()"></i>
                            </span>
                            <span class="fa-stack fa-lg close-button" *ngIf="isEditDesc">
                                <i class="fa fa-times fa-stack-1x fa-inverse" style="color: black;cursor: pointer;" (click)="isEditDesc = false;hideMessages()"></i>
                            </span>
                        </div>
                    </div>
                        <div class="row" style="margin-top: 0px;"> 
                        <div id = "toolTipText" *ngIf="!isEditDesc" class="toolTipText">
                            <span><font color="white" [style]= "{'word-break': 'break-word','padding-left': '0px'}">{{selectedGroupItem.item.description ? selectedGroupItem.item.description: 'edit to enter your description'}}</font></span>
                            <br>
                            <a (click)="isEditDesc = true"><font color="white" style="padding-left: 175px;cursor: pointer;">Edit</font></a>
                        </div> 
                        </div>
                        <div class="row" style="margin-top: 20px;">
                            <div *ngIf="isEditDesc">
                                <textarea style="padding-left: 0px;" type="text" class="col-sm-12 toolTipText" maxlength="100" id="listDesc" name="listDesc" [(ngModel)]="selectedGroupItem.item.description" rows="4" cols="25" > </textarea>
                                <br>
                                <a (click)="onUpdateDesc();hideMessages()"><font color="#1d3a49" style="padding-left: 175px;cursor: pointer;">Save</font></a> 
                            </div>
                        </div>
                </div>
            </div>
            </p-dialog>
            <p-dialog [(visible)] = "showThresholdMessage" [showHeader]="false" width="800" position="top" contentStyleClass="warningDialog">
                <!--<span class="fa-stack fa-lg close-button">
                    <i id="closeThresholdMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeMessage();"></i>
                </span>-->
                <span>
                    <font color="white" style="font-size:13px;font-weight:bold">
                        You are allowed up to {{maxCountryGroups}} Country Groups.
                    </font>
                    <font color="white" style="font-size:13px;font-weight:regular">
                        You have {{remainingCountryGroups}} Country Groups remaining.
                    </font>
                </span>       
            </p-dialog> 
            <div *ngIf="selectedGroup">
                <p-dialog [(visible)] = "showDuplicateMessage" modal="modal" [showHeader]="false" [style]="{width: '40vw'}" position="top" contentStyleClass="errorDialog" closable="true" closeOnEscape="true">
                    <span class="col-sm-11">
                        <font color="white" style="font-size:13px;font-weight:bold">
                            Duplicate Name!
                        </font>
                        <font color="white" style="font-size:13px;font-weight:regular">
                            There is already a country group with that name.  Please use a different name.
                        </font>
                    </span>
                    <span class="fa-stack fa-lg close-button col-sm-1" style="cursor: pointer;">
                        <i id="closeDuplicateMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeDuplicateMessage()"></i>
                    </span>       
                </p-dialog> 
            </div>
            <div class="row">
                <div class="col-md-4  w3-margin-left" style="height:auto; max-width:230px ; width:25%">
                    <div class="row">
                        <div>
                            <h6><b>Selected Countries</b></h6>
                        </div>
                    </div>
                    <div class="row">
                        <table style="padding-left:0px;margin-left: -5px;">
                            <tr>
                                <td class="w3-left-align w3-text-blue col-md-6" style="height:25px;width:140px;font-size:11px">Country</td>
                                <td class="w3-text-blue col-md-2" style="height:25px;width:50px;font-size:11px">Code</td>
                                <td class="w3-text-blue col-md-2" style="height:25px;width:40px;font-size:11px">ISO2</td>
                            </tr>
                        </table>
                    </div>
                    <div class="row" *ngIf="!isNewGroup ">
                        <table style="padding-left:0px;margin-left: -5px;">
                            <tr *ngFor="let country of selectedCountries ; let i = index">
                                <td class="col-md-6" style="height:20px;width:140px;font-size:11px">{{ country.country }}</td>
                                <td class="col-md-3" style="height:20px;width:50px;font-size:11px;">{{ country.code }}</td>
                                <td class="col-md-2" style="height:20px;width:40px;font-size:11px">{{ country.iso2 }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-md-9" style="height:auto;" >
                <div class="row w3-margin-left">
                    
                    <div class="w3-margin-left col-6 text-center"><h6><b>Add countries to your Country Group</b></h6></div>
                    <div class="col-2 w3-right-align" ><button id="saveChangesButton" class='btn btn-default no-uppercase no-padding list-btn' type="button" (click)="onSave()">Save</button></div>
                    <div class="col-1 text-center"><button id="cancelButton" class='btn btn-info no-uppercase no-padding list-btn' type="button"  (click)="onCancel()">Cancel</button></div>
                    
                </div>
                
                <div class="row w3-margin-top">
                    <div class="w3-border-top ">
                        <div class="col-md-1 w3-margin-right w3-margin-top">
                            <div *ngFor="let item of alphaSearchPattern">
                                <button class="col-md-10 alpha-button btn w3-margin-right w3-margin-bottom" [disabled]="blockingCountrySearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
                            </div>
                        </div>
    
                        <div class="col-md-10 w3-leftbar">
                            <div class="row  w3-margin-top">
    
                                <div class="col-md-6">
                                    <input type="text" name="searchText" class="search-query" #country size="35" placeholder="Search Country, Code or ISO2 ..." (keyup)="filterCountry(country.value)">
                                    <span class="clearer pi pi-times-circle" (click)="country.value=''; filterCountry('')"></span>
                                </div>
                                <div class="col-md-3 w3-right-align">
                                    <a class="link" (click)="selectAll('country'); updateSelectedCountries();">SELECT ALL&lt;{{countryFilterLabel}}&gt;</a>
                                </div>
                                <div class="col-md-3 text-center">
                                    <a class="link" (click)="clearSelectedFilter('country',countryFilterLabel); updateSelectedCountries();">CLEAR ALL&lt;{{countryFilterLabel}}&gt;</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="w3-margin-top col-md-4 col-sm-4">
                                <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                                <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                                <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
                            </div>
                            </div>
                            <div class="row w3-margin-top">
                                <ul class="list-group list-group-horizontal f32">
                                    <li class="list-group-item col-md-4 col-sm-4" *ngFor="let country of filteredCountryList">
                                        <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country); updateSelectedCountries();">
                                            <div class="w3-container">
                                                <span class="w3-left-align col-md-8">{{ country.country }}</span>
                                                <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                                <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <!-- delete confirmation dialog-->
                <div *ngIf="selectedGroup">
                    <p-dialog header="Confirmation" [(visible)]="showDelConfirm" modal="modal" [width]="350" [minWidth]="200" dynamic=true>
                        <span style="font-size:13px;font-weight:bold">Are you sure you want to permanently delete {{ selectedGroup.name }} Country Group?</span>
                        <span style="font-size:13px;font-weight:regular">The Country Group cannot be restored after this action.</span>
                        <p-footer>
                            <button id="deleteGroupConfirmationButton" type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:80px;height:26px;margin-left:10px;" (click)="deleteCountryGroup();">Delete Group</button>
                            <button id="deleteGroupCancelButton" type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:70px;height:26px;margin-left:10px;" (click)="showDelConfirm=false">Cancel</button>
                        </p-footer>
                    </p-dialog>
                </div>
            </div>
        </div>
    </div>
    </div>
    <p-blockUI [blocked]="blocking"></p-blockUI>
    <div class="center" *ngIf="blocking">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
    </div>