import { NgModule } from '@angular/core';
import { AppSharedModule } from './util/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AuthService } from './auth/index';
import { Logger } from './services/logger.service';
import { HttpService } from './services/http.service';
import { CacheService } from './services/cache.service';
import { EventService } from './services/event.service';
import { CustomGroupService } from './services/customGroup.service';
import { NotificationService } from './services/notification.service';
import { routing } from './app.routing';
import { StompService } from 'ng2-stomp-service';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ListsModule } from './lists/lists.module';
import {PartitionModule} from './partitions/partition.module';
import { RulesComponent } from './rules';
import { HelpComponent } from './help';
import { MobileidDataComponent } from './mobileid-data/mobileid-data.component';
import { MsrnDataComponent } from './msrn-data/msrn-data.component';
import { TrustedPartnerComponent } from './trusted-partner/trusted-partner.component';
import { CountryGroupComponent } from './country-group/country-group.component';
import { PartitionFileLayoutComponent } from './partition-file-layout/partition-file-layout.component';
import { CanDeactivateGuard } from './shared/can-deactivate-guard.service';
import { AuditService } from './services/audit.service';

@NgModule({
    imports: [
        AppSharedModule,
        HttpClientModule,
        
        routing,
        ListsModule,
        PartitionModule,
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        RulesComponent,
        HelpComponent,
        MobileidDataComponent,
        MsrnDataComponent,
        TrustedPartnerComponent,
        CountryGroupComponent,
        PartitionFileLayoutComponent,
        // FilteredTableComponent,
        // Foo24Component
    ],
    providers: [
        StompService,
        Logger,
        HttpService,
        CacheService,
        AuthGuard,
        AuthService,
        CanDeactivateGuard,
        NotificationService,
        EventService,
        AuditService,
        CustomGroupService
    ],
    // exports: [FilteredTableModule],
    bootstrap: [AppComponent]
})
export class AppModule {
}