import { Component } from '@angular/core';
import { Logger } from '../services/logger.service';
import {AuthService} from '../auth';

@Component({
  templateUrl: 'home.html',
})
export class HomeComponent {

  constructor() {
  }

}

