import {FileLayoutField} from '../models/index';

export class PartitionFileLayout {
  id: number;
  customerName: string; //??
  name: string;
  description: string;
  layoutFields: Array<FileLayoutField>;
  fileDelimiter: string;
  includeHeader: string;
  encloseFieldQuotes: string;
  createTimestamp: Date;
  createBy: string;
  lastUpdated: Date;
  lastUpdatedBy: string;
  selected: boolean = false;


  //listData: Array<ListDetails> = [];

  constructor(layoutName?: string) {
      this.name = layoutName;
   }

   equals(tmp) {
      if (this.description != tmp.description)
         return false;
      if (this.fileDelimiter != tmp.fileDelimiter)
         return false;
      if (this.includeHeader != tmp.includeHeader)
         return false;
      if (this.encloseFieldQuotes != tmp.encloseFieldQuotes)
         return false;
      if (JSON.stringify(this.layoutFields) != JSON.stringify(tmp.layoutFields) )
         return false; 
      return true;
   }
}
