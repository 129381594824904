import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { FilterService } from '../filter/filter-service';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, PartitionDefinition, PartitionDataDetails, PartitionExportHistory, RuleDefinition, RangeFilter, ProviderBillingId, TosTosDesc } from '../models/index';
import { PartitionService } from '../partitions/partition.service';
import { ActionName, DataSource } from 'app/shared/constants';
import { AuditService } from 'app/services/audit.service';
import { AuditPartition } from 'app/models/auditPartition.model';

@Component({
  selector: 'app-partition-export-detail',
  templateUrl: './partition-export-detail.component.html',
  styleUrls: ['./partition-export-detail.component.scss']
})
export class PartitionExportDetailComponent extends RulePartitionBase implements OnInit {
  showReadOnlyFilterWin = false;
  filterBy ='';

  constructor(private route: ActivatedRoute, private router: Router, public partitionService: PartitionService, public log: Logger,
    public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService, private confirmationService: ConfirmationService, public auditService: AuditService) {
    super(log, httpService, cacheService, notifService);
  }

  ngOnInit() {
    this.partitionService.getExportPartitionDetail(this.partitionService.selectedExportHistory.partitionId, ActionName.PartitionExportLink);
    this.auditPartitionData = new AuditPartition();
    this.auditPartitionData.partitionId = this.partitionService.selectedExportHistory.partitionId
  }


  downloadBlockingFile() {
    this.httpService.getStream(AppConstants.DownloadBlockingFile + this.partitionService.selectedExportHistory.id, this.downloadFileHandler);
    this.auditService.auditLog(ActionName.PartitionExportDownload, null,this.auditPartitionData ) //US2036 #5
    return false;
  }

  downloadFileHandler = (data: Response) => {
    let fileName = data.headers.get('content-disposition');
    fileName = fileName.replace('attachment; filename=', '');
    this.downloadFile(data.body, fileName, 'application/octet-stream');

  }


  openFilterInPartition(rule: RuleDefinition, name: string) {
    super.init();
    this.showReadOnlyFilterWin = true;
    this.createFilterFromRule(rule, true);
    this.openFilter(name, true);
    this.showSelected(name);
    this.filterBy=name;
  }

  viewRuleById(rule: RuleDefinition) {
    this.log.debug('show rule detail: ' + rule.id);
    this.partitionService.selectedRule = rule;

    this.router.navigate(['/partitionExportRule']);
    return false;
  }

  viewAll() {
    this.partitionService.selectedRule = null;
    this.partitionService.getExportPartitionDetail(this.partitionService.selectedExportHistory.partitionId, ActionName.PartitionExportLink);
    this.partitionService.selectedPartition.id= this.partitionService.selectedExportHistory.partitionId
    //this.router.navigate(['/partitionDataDetail']);
    this.router.navigate(['/partitionExportDataDetail']);
    return false;
  }

  onResend() {
    this.partitionService.exportInProgress=true;
    this.httpService.get(AppConstants.ResendPartitionURL + this.partitionService.exportHistoryId, [this.responseCallBack]);
    return false;
  }

}
