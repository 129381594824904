import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { AppConstants, JsonHelper} from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import {ResponseJson, PartitionDefinition, RuleDefinition, RangeFilter} from '../models/index';
import { PartitionService } from '../partitions/partition.service';
import { EditableFieldComponent } from '../editable-field/editable-field.component';
import { ActionName, ListTypes } from 'app/shared/constants';
import { AuditService } from 'app/services/audit.service';
import { AuditPartition } from 'app/models/auditPartition.model';

@Component({
  selector: 'app-partition-detail',
  templateUrl: './partition-detail.component.html',
  styleUrls: ['./partition-detail.component.scss']
})
export class PartitionDetailComponent extends RulePartitionBase implements OnInit {
  @ViewChild('partitionNameField') inputPartitionName:EditableFieldComponent;
  showDataWin = false;
  showFilterWin = false;
  showReadOnlyFilterWin = false;
  acceptLabel = "Delete Rule";
  placeholderValue = 'Name the partition';
  styleClass = 'inline';
  showDialog:boolean = false;
  //isEditDesc:boolean  = false;
  showThresholdMessage = false;
  showDuplicateMessage = false;
  hideMessageBox = false;
  remainingAllotment = -1;
  filterBy ='';


  constructor(private route: ActivatedRoute, private router: Router, public partitionService: PartitionService, public log: Logger,
    public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService,
    private confirmationService: ConfirmationService, public auditService: AuditService) {
    super(log, httpService, cacheService, notifService);
  }

  ngOnInit() {

      console.log("fe" +this.partitionService.enableCreateDeltaFile)
      console.log("fe" + this.partitionService.enableAutoExport )
      console.log("fe" +this.partitionService.enableConsolidatedTargetList)

  }

  ngDoCheck() {
    if (this.partitionService.hideMessageBox) {
      this.showDialog=false;
      this.showThresholdMessage=false;
      this.showDuplicateMessage=false;
      this.partitionService.hideMessageBox=false;
    }
  }


  updateList(type: string) {
    switch (type) {
      case ListTypes.BlackList : //BlackList
          if (this.partitionService.selectedPartition.blId === 0 && (
            this.partitionService.blListOptions.filter(blItem => blItem.listName === '') ||
            this.partitionService.blListOptions.filter(blItem => blItem.listName === 'Select ...')
            )
          ){
            this.partitionService.selectedPartition.blId = undefined;
          }
          this.httpService.post(
            JsonHelper.toJson(this.partitionService.selectedPartition),
            AppConstants.UpdatePartitionBlackListURL,
            [this.responseCallBack, this.partitionService.updateListOptions]
          );
          break;
      case ListTypes.WhiteList : //Whitelist
          if (this.partitionService.selectedPartition.wlId === 0 && (
            this.partitionService.wlListOptions.filter(wlItem => wlItem.listName === '') ||
            this.partitionService.wlListOptions.filter(wlItem => wlItem.listName === 'Select ...')
            )
          ){
            this.partitionService.selectedPartition.wlId = undefined;
          }
          this.httpService.post(
            JsonHelper.toJson(this.partitionService.selectedPartition),
            AppConstants.UpdatePartitionWhiteListURL,
            [this.responseCallBack, this.partitionService.updateListOptions]
          );
          break;
      case ListTypes.TrustedPartnerList : // TP LIST
          if (this.partitionService.selectedPartition.tpId === 0 && (
            this.partitionService.tpListOptions.filter(tpItem =>tpItem.listName === '') ||
            this.partitionService.tpListOptions.filter(tpItem => tpItem.listName === 'Select ...')
            )
          ){
            this.partitionService.selectedPartition.tpId = undefined;
          }
          this.httpService.post(
            JsonHelper.toJson(this.partitionService.selectedPartition),
            AppConstants.UpdatePartitionTrustedPartitionURL,
            [this.responseCallBack, this.partitionService.updateListOptions]
          );
          break;
    }
  }

  viewRule(rule: RuleDefinition) {
    this.partitionService.selectedRule = rule;
    this.router.navigate(['/partitionRuleDataDetail']);
    return false;
  }

  onRefresh() {
    this.httpService.get(AppConstants.RefreshPartitionURL + this.partitionService.selectedPartition.id, [this.responseCallBack]);
  }

  updateName(name: string) {
    //console.log("cagl: updateName: (selectDuring|skip|isNew): " + this.partitionService.isSelectDuringNameEdit + "|" + this.partitionService.isSkipDupMsg + "|" + this.partitionService.isNewPartition);
    if (this.partitionService.isSelectDuringNameEdit && this.partitionService.isNewPartition) {
      this.partitionService.isSelectDuringNameEdit = false;
      this.partitionService.isNewPartition = false;
      return;
    }
    if (this.partitionService.isSkipDupMsg && this.partitionService.isNewPartition) {
      this.partitionService.isSkipDupMsg = false;
      return;
    }
    this.partitionService.isSelectDuringNameEdit = false;
    this.partitionService.isWaitingUpdateNameResponse = true;

    if (name) {
      this.partitionService.selectedPartition.name = name;
    } else {
      this.partitionService.selectedPartition.name = this.partitionService.tempPartitionName;
    }
    this.httpService.post(JsonHelper.toJson(this.partitionService.selectedPartition), AppConstants.UpdatePartitionURL, [this.postSavePartitionHandler]);
  }

  postSavePartitionHandler = (responseJson: ResponseJson) => {
    this.partitionService.isWaitingUpdateNameResponse = false;
    //console.log("cagl: postSave: (selectDuring|skip|isNew): " + this.partitionService.isSelectDuringNameEdit + "|" + this.partitionService.isSkipDupMsg + "|" + this.partitionService.isNewPartition);
    if (responseJson.status === AppConstants.SUCCESS) {
      this.responseCallBack(responseJson);
      //this.selectedPartitionName = responseJson.data.name;
      this.partitionService.selectedPartition = JsonHelper.parse(responseJson.data, PartitionDefinition);
      this.selectedPartitionId = responseJson.data.id;
      if (this.partitionService.isNewPartition) {
        this.httpService.get(AppConstants.QueryRemainingPartitionAllotmentURL, [this.postQueryRemainingPartitionAllotment]);
        this.checkThresholdLimit();
      }
      this.partitionService.isNewPartition = false;
      this.getPartitionList();
      //console.log("Resetting isUpdatingRail flag - Success");
      this.isUpdatingRail=false;
      this.partitionService.fileLayoutOptions = this.partitionService.getFileLayoutOptions(this.partitionService.fileLayouts, this.partitionService.selectedPartition.flId);
      console.log('After FL set options', Date());

      this.partitionService.isEditingNameField = false; //be sure this flag is false after successful update
    } else {
      //this.messageRegularText=responseJson.message;
      console.log("errorMsg: " + responseJson.message);
      if (responseJson.message.includes("same name")) {
        //skip displaying duplicate message if onSelect in progress
        if (!this.partitionService.isSkipDupMsg) {
        //if ( (!this.partitionService.isSkipDupMsg && this.partitionService.isNewPartition) || (!this.partitionService.isSelectDuringNameEdit) ) {
          this.showDuplicateMessage=true;
          this.hideMessageBox=false;
          if (!this.partitionService.isNewPartition) {
            //console.log("Tripping isUpdatingRail flag - rename Dup ERROR");
            this.isUpdatingRail=true;
          }
        }
      }
      else
        this.responseCallBack(responseJson);
    }
    if (this.partitionService.isSkipDupMsg)
      this.partitionService.isSkipDupMsg = false;
    if (this.partitionService.isSelectDuringNameEdit)
      this.partitionService.isSelectDuringNameEdit = false;
  }

  postQueryRemainingPartitionAllotment  = (responseJson: ResponseJson) => {
     if (responseJson.status === AppConstants.SUCCESS) {
       this.remainingAllotment = responseJson.data.remainingAllotment;
     }
  }

  async checkThresholdLimit() {
    await AppConstants.delay(1000);

    //console.log("checkThresholdLimit remaining: " + this.remainingAllotment + " | maxPartitions: " + this.maxPartitions + " | threshold: " + AppConstants.ThresholdWarningLimit);
    if ( this.remainingAllotment <= AppConstants.ThresholdWarningLimit) {
      this.showThresholdMessage = true;
      await AppConstants.delay(7000);
    }

    if (this.showThresholdMessage)
      this.closeThresholdMessage();

  }

  onDeletePartitionRules() {
    this.acceptLabel = 'Delete All';
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Are you sure you want to permanently delete all the rules in this partition? \nThe rule cannot be restored after this action.',
      accept: () => {
        this.httpService.delete(AppConstants.DeleteAllPartitionRulesURL + this.partitionService.selectedPartition.id, [this.postDeletePartitionRules, this.responseCallBack]);
      }
    });
  }


  postDeletePartitionRules = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionService.selectedPartition.ruleDefinitions = [];
    }
  }

  onExport() {
    this.partitionService.selectedPartition.status==='Processing';
    this.partitionService.exportInProgress=true;
    this.httpService.get(AppConstants.ExportPartitionURL + this.partitionService.selectedPartition.id, [this.responseCallBack]);
  }


  editRule(rule: RuleDefinition) {
    this.partitionService.selectedRule = rule;
    this.router.navigate(['partitions/partitionRuleDetail']);
    return false;
  }

  openFilterInPartition(rule: RuleDefinition, name: string) {
    super.init();
    this.showReadOnlyFilterWin = true;
    this.createFilterFromRule(rule);
    this.openFilter(name, true);
    this.showSelected(name);
    this.filterBy=name;
  }

  deleteRule(rule: RuleDefinition) {
    if (this.partitionService.selectedPartition.status==='Processing') {
      this.notifService.add({ severity: 'info', summary: 'Delete Rule', detail: 'Can not delete rule while partition is processing'});
      return false;
    }

    this.acceptLabel = 'Delete Rule';
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Are you sure you want to permanently delete this rule?  \nThe rule cannot be restored after this action.',
      accept: () => {
        this.httpService.delete(AppConstants.RemoveRuleFromPartitionURL + this.partitionService.selectedPartition.id + '/' + rule.id,
          [this.postRemoveRule, this.responseCallBack]);
      }
    });

    return false;
  }

  postRemoveRule = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionService.selectedPartition = JsonHelper.parse(responseJson.data, PartitionDefinition);
      this.partitionService.selectedPartition.ruleDefinitions.forEach(rule => {
        const filter = JsonHelper.parse(rule.rangeQueryFilter, RangeFilter);

        rule.selectedCountry = filter.iso2List.length;
        rule.selectedTSO = filter.tosDescList.length;
        rule.selectedProvider = filter.providerList.length;
        rule.noOfMonth = filter.numOfMonthsSinceLastObserved;
      });
    }
  }

  onCreateDraftData() {
    console.info('In onCreateDraftData() status=', this.partitionService.selectedPartition.status);

    this.partitionService.createPartitionDraftData();
  }

  onQueryDraftData() {
    this.partitionService.showDraftDataWin = true;
    console.info('in onQueryDraftData() status=', this.partitionService.selectedPartition.status);
    if (this.partitionService.selectedPartition.status === 'Draft') {
      this.router.navigate(['/partitionDataDetail']);
      return;
    }

    this.partitionService.getPartitionDraftData();
    //
  }

  // mouseenter event
showToolTip()  {
  this.partitionService.fileLayoutOptions = this.partitionService.getFileLayoutOptions(this.partitionService.fileLayouts, this.partitionService.selectedPartition.flId);
  console.log('In showToolTip: Another FL set options', Date());

  setTimeout(() => {
    this.showDialog = true;
  }, 400);
};

onUpdatePartitionConfig() {
  //this.isEditDesc = false;
  this.showDialog = false;
  this.partitionService.selectedPartition.description = this.partitionService.pDescription;
  this.partitionService.selectedPartition.flId = this.partitionService.fileLayoutId;
  this.partitionService.selectedPartition.autoExport = this.partitionService.enableAutoExport ? 1 : 0;
  if (this.partitionService.selectedPartition.autoExport)
    this.partitionService.selectedPartition.exportDestination='MID FTP';
  else
    this.partitionService.selectedPartition.exportDestination=null;
  this.partitionService.selectedPartition.createDeltaFile = this.partitionService.enableCreateDeltaFile ? "Y" : "N";
  this.partitionService.selectedPartition.consolidatedList = this.partitionService.enableConsolidatedTargetList ? "Y" : "N";

  this.httpService.post(JsonHelper.toJson(this.partitionService.selectedPartition), AppConstants.UpdatePartitionURL, [this.postSavePartitionHandler, this.responseCallBack]);
}

closePartitionConfig() {
  this.showDialog = false;
  this.partitionService.pDescription = this.partitionService.selectedPartition.description;
  if (this.partitionService.selectedPartition.flId==undefined)
    this.partitionService.fileLayoutId = null;
  else
    this.partitionService.fileLayoutId = this.partitionService.selectedPartition.flId;
  this.partitionService.enableAutoExport = this.partitionService.selectedPartition.autoExport === 1? true : false;
  this.partitionService.enableCreateDeltaFile = this.partitionService.selectedPartition.createDeltaFile === "Y"? true : false;
  this.partitionService.enableConsolidatedTargetList = this.partitionService.selectedPartition.consolidatedList  === "Y"? true : false;

  // this.partitionService.selectedPartition = null;
  console.log("this.partitionService.selectedPartition " + JSON.stringify(this.partitionService.selectedPartition))
  console.log("this.partitionService.selectedPartition.currentSelected " + this.partitionService.selectedPartition?.currentSelected)

}

closeThresholdMessage(){
  this.showThresholdMessage = false;
}

closeDuplicateMessage(){
  this.showDuplicateMessage = false;
  this.inputPartitionName.isEditing=true;
  this.partitionService.isEditingNameField=true;
  this.inputPartitionName.origItem = this.inputPartitionName.prevName;
  this.inputPartitionName.setFocus();
}

}
