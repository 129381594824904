import { Type, Skip } from 'serializer.ts/Decorators';
import { DatePipe } from '@angular/common';

export class ListDetails {
  id: string;
  listRefId: number;
  upLoadRefId: number;
  dialPattern; string;
  reason: string;
  notes: string;
  customerDate: Date;
  active: boolean;
  private _status: string;
  lastUpdated: Date;
  lastUpdatedBy: string;
  lemin:number;
  lemax:number;
  
  // join from range_ndc table
  termCountry: string;
  ccNdc: string;
  iso2: string;
  code: string;
  tos: string;
  tosdesc: string;
  ndc: string;
  provider: string;
  billingId: string;
  supplement: string;
 
  //for GUI use
  bgHighlight : boolean;

  constructor() { }

  set status(myStatus: string) {
    this._status = myStatus;
    if (/^inactive$/i.test(myStatus)) {
      this.active = false;
    } else {
      this.active = true;
    }
  }

  get status(): string {
    if (this.active) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  toString(delimiter: string): string {
    let result = [];
    if (!this.active) {
      return '';
    }
    result.push(this.termCountry);
    result.push(this.dialPattern);
    if (this.customerDate) {
      result.push(new DatePipe('en-US').transform(this.customerDate, 'MMddyyyy'));
    } else {
      result.push('');
    }
    result.push(this.reason);
    result.push(this.notes);
    result.push(this.ndc);
    result.push(this.iso2);
    result.push(this.code);
    result.push(this.tos);
    result.push(this.tosdesc);
    result.push(this.provider);
    result.push(this.lemin);
    result.push(this.lemax);
    return result.join(delimiter);
  }
}
