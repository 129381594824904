import {Base} from './base.model';
import { DatePipe } from '@angular/common';
import { RuleDefinition } from './index';

export class RangeNDC extends Base{
	id: string;
	termCountry: string;
	dataSource: string;
	dialPattern: string;
	dialPatternType: string;
	ccNdc: string;
	iso2: string;
	code: string;
	tos: string;
	tosdesc: string;
	ndc: string;
	locality: string;
	provider: string;
	supplement: string;
	effectiveDate: Date;
	lemin:number;
	lemax:number;

	//add for download
	primeMinus1: string;
	primeMinus2: string;
	primeMinus3: string;
	primeMinus4: string;
	lastUpdate: string;

	toString(delimiter: string): string {
		let result = [];

    result.push(this.termCountry);

		if (this.primeMinus1 || this.primeMinus2 || this.primeMinus3 || this.primeMinus4) {
			result.push(this.primeMinus1);
			result.push(this.primeMinus2);
			result.push(this.primeMinus3);
			result.push(this.primeMinus4);
		}
		result.push(this.code);
		result.push(this.iso2);
		result.push(this.tos);
		result.push(this.tosdesc);
		result.push(this.ndc);
		result.push(this.provider);
		result.push(this.supplement);
		result.push(this.lemin);
		result.push(this.lemax);

		if (this.primeMinus1 || this.primeMinus2 || this.primeMinus3 || this.primeMinus4) {
			if (this.lastUpdate) {
				result.push(this.lastUpdate.replace('-', ''));
			} else {
				result.push('');
			}
		} else {
			if (this.effectiveDate) {
				result.push(new DatePipe('en-US').transform(this.effectiveDate, 'MM/dd/yyyy'));
			} else {
				result.push('');
			}    			
		}
		return result.join(delimiter);
	}

  toPartitionFormat(delimiter: string): string {
		let result = [];
		if (this.dataSource.startsWith('IPRN')) {
			result.push('IPRN');
		} else {
			result.push(this.dataSource);
		}
		result.push(this.termCountry);
		result.push(this.dialPattern);
		result.push(this.dialPatternType);
		result.push(''); //customer date
		result.push('');   //reason
		result.push('');   //note
		result.push(this.code);
		result.push(this.iso2);
		result.push(this.tos);
		result.push(this.tosdesc);
		result.push(this.ndc);
		result.push(this.provider);
		result.push(this.supplement);
		result.push(this.lemin);
		result.push(this.lemax);
	    return result.join(delimiter);
  }
}

