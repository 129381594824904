
import { CustomGroupsType } from 'app/shared/constants';
export class CustomGroups {
    id: number;
    type: CustomGroupsType;
    name: string;
    value: ValueList;
    createTimestamp: Date;
    createdBy: string;
    lastUpdated: Date;
    lastUpdatedBy: string;
    description: string;
    selected: boolean = false;
	constructor( name?: string,type?: CustomGroupsType, value?: ValueList) {
		this.name = name;
		this.type = type;	
		this.value = value;
        this.description = value?.description;
	}

	display() {
		console.log("CustomGroups: name: " + this.name + " | id: " + this.id + " | value: " + this.value.iso2List.toString + " | type: "  + this.type);
 }
}

export class ValueList{
    iso2List: string[];
    description: string;
} 