import { Type, Skip } from 'serializer.ts/Decorators';
import { ListDefinition } from './index';
// map to ListUploadRequest.java

export class ListUploadRequest {
  id: string;
  listRefId: string;
  fileName: string;
  delimiter: string;
  status: string;
  errorData: string;
  lastUpdated: Date;
  lastUpdatedBy: string;

  //@Type(() => ListDefinition)
  //listDefinition: ListDefinition;

  constructor() { }

}