import { Routes, RouterModule } from '@angular/router';

import { AuthService, AuthGuard } from './auth';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import {ListsComponent} from './lists';
import {PartitionsComponent} from './partitions';
import {RulesComponent} from './rules';
import {HelpComponent} from './help';
import {ListHelpComponent} from './list-help/list-help.component';
import { ListDetailComponent } from './list-detail/list-detail.component';
import { MobileidDataComponent } from './mobileid-data/mobileid-data.component';
import { PartitionHelpComponent } from './partition-help/partition-help.component';
import { PartitionDetailComponent } from './partition-detail/partition-detail.component';
import {PartitionDataDetailComponent} from './partition-data-detail/partition-data-detail.component';
import {PartitionRuleDetailComponent} from './partition-rule-detail/partition-rule-detail.component';
import {PartitionRuleDataDetailComponent} from './partition-rule-data-detail/partition-rule-data-detail.component';
import { PartitionExportDetailComponent } from './partition-export-detail/partition-export-detail.component';
import { PartitionExportRuleComponent } from './partition-export-rule/partition-export-rule.component';
import {PartitionExportDataDetailComponent} from './partition-export-data-detail/partition-export-data-detail.component';
import {MsrnDataComponent} from './msrn-data/msrn-data.component';
import { TrustedPartnerComponent } from './trusted-partner/trusted-partner.component';
import { CountryGroupComponent } from './country-group/country-group.component';
import { PartitionFileLayoutComponent } from './partition-file-layout/partition-file-layout.component';
import { CanDeactivateGuard } from './shared/can-deactivate-guard.service';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent },
    { path: 'lists', component: ListsComponent,
        children: [
      { path: '', component: ListHelpComponent},
      { path: 'listDetail', component: ListDetailComponent},
        ]
    },
    { path: 'partitions',  component: PartitionsComponent,
        children: [
      { path: '', component: PartitionDetailComponent},
      { path: 'partitionHelp', component: PartitionHelpComponent},
      { path: 'partitionDetail', component: PartitionDetailComponent},
      { path: 'partitionExportDetail', component: PartitionExportDetailComponent},
      { path: 'partitionRuleDetail', component: PartitionRuleDetailComponent},
        ]
    },
    { path: 'partitionDataDetail', component: PartitionDataDetailComponent},
    { path: 'partitionRuleDataDetail', component: PartitionRuleDataDetailComponent},
    { path: 'partitionExportDataDetail', component: PartitionExportDataDetailComponent},
    { path: 'partitionExportRule', component: PartitionExportRuleComponent},
    { path: 'rules', component: RulesComponent},
    { path: 'range', component: MobileidDataComponent},
    { path: 'help', component: HelpComponent},
    { path: 'msrn', component: MsrnDataComponent},
    { path: 'tplist', component: TrustedPartnerComponent},
    { path: 'cglist', component: CountryGroupComponent},
    { path: 'partitionFileLayout', component: PartitionFileLayoutComponent, canDeactivate: [CanDeactivateGuard]},

    // otherwise redirect to home
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

export const routing = RouterModule.forRoot(appRoutes);