<div class="w3-panel w3-boarder w3-border-red w3-margin-top">
    <div class="w3-bar">
        <ng-container   *ngIf="!loadingFilterData">    
            <label class="w3-bar-item w3-margin-right">Filter By: </label>
            <button class="w3-bar-item btn w3-margin-right" [ngClass]="{'btn-default': filterBy === 'country', 'btn-inverse-no-border': filterBy !== 'country'}" (click)="showData=false; createFilterData(countries)">Country Code & ISO2</button>
            <button class="w3-bar-item btn w3-margin-right" [ngClass]="{'btn-default': filterBy === 'tos', 'btn-inverse-no-border': filterBy !== 'tos'}" (click)="showData=false; createFilterData(tosTosdesc)">TOS & TOS Description</button>
            <button class="w3-bar-item btn w3-margin-right" [ngClass]="{'btn-default': filterBy === 'provider', 'btn-inverse-no-border': filterBy !== 'provider'}" (click)="showData=false; createFilterData(providers)">Provider</button>
            <button class="w3-bar-item btn w3-margin-right" *ngIf="hasRuleFilter" [ngClass]="{'btn-default': filterBy === 'rule', 'btn-inverse-no-border': filterBy !== 'source'}" (click)="showData=false; createFilterData(rules)">Rule#</button>
        </ng-container>
        <div class="pull-right">  
            <button class='btn btn-info no-uppercase no-padding' style="height:26px;width:95px;margin-right:5px"  [disabled]="downloader?.isLoading()" (click)="onDownload()">Download</button>
            <p-progressBar  [value]="downloader.progressValue"  *ngIf="downloader?.isLoading()"></p-progressBar>
        </div>    

    </div>
</div>

<!-- filter panel -->
<div id="country" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query ms-3" #country size="35" placeholder="Search Country, Code or ISO2 ..." (keyup)="countries?.filter(country.value)">
                    <span class="clearer pi pi-times-circle" (click)="country.value=''; countries?.filter('')"></span>
                </div>
                <div class="col-md-6" *ngIf="countries?.selectedCount > 0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected Country Code(s) and ISO2 will be applied</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{countries.getSelectedValues()}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right" (click)="countries.clearSelection()">Clear</button>
                    </div>
                </div>

                <button type="button" class="btn btn-primary pull-right ms-3" (click)="closeAllFilter()">Apply</button>
            </div>
        </div>

        <div class="row">
            <div class="w3-margin-top col-md-3 col-sm-4">
                <span class="text-start w3-text-blue col-md-8">Country</span>
                <span class="text-start w3-text-blue col-md-2">Code</span>
                <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
            </div>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of countries?.viewList">
                    <div class="w3-card-4 no-shadow" [ngClass]="getNgClass(country)" (click)="countries.toggleSelected(country)">
                        <div class="w3-container">
                            <span class="text-start col-md-8">{{ country.country }}</span>
                            <span class="text-start col-md-2">{{ country.code }}</span>
                            <span class="text-start col-md-2">{{ country.iso2 }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="tos" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query ms-3" size="35" #tos placeholder="Search TOS Description ..." (keyup)="tosTosdesc?.filter(tos.value)">
                    <span class="clearer pi pi-times-circle" (click)="tos.value=''; tosTosdesc?.filter('')"></span>
                </div>
                <div class="col-md-6" *ngIf="tosTosdesc?.selectedCount > 0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected TOS or TOS Descriptions will be applied</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{tosTosdesc.getSelectedValues()}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right" (click)="tosTosdesc.clearSelection()">Clear</button>
                    </div>
                </div>

                <button type="button" class="btn btn-primary pull-right ms-3" (click)="closeAllFilter()">Apply</button>
            </div>
        </div>
        <div class="row w3-margin-top">
            <span class="text-start w3-text-blue col-md-8">TOS:TOS Description</span>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of tosTosdesc?.viewList">
                    <div class="w3-card-4 no-shadow" [ngClass]="getNgClass(item)" (click)="tosTosdesc.toggleSelected(item)">
                        <div class="w3-container">
                            <div class="text-start">{{item.display}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="provider" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query ms-3" size="35" #provider placeholder="Search Provider ..." (keyup)="providers?.filter(provider.value)">
                    <span class="clearer pi pi-times-circle" (click)="provider.value=''; providers?.filter('')"></span>
                </div>

                <div class="col-md-6" *ngIf="providers?.selectedCount> 0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected Providers will be applied.</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{providers.getSelectedValues()}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right" (click)="providers.clearSelection()">Clear</button>
                    </div>
                </div>
                <button type="button" class="btn btn-primary pull-right ms-3" (click)="closeAllFilter()">Apply</button>
            </div>
        </div>
        <div class="row w3-margin-top">
            <span class="w3-center-align w3-text-blue ms-3 col-md-2">Provider</span>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of providers?.viewList">
                    <div class="w3-card-4 no-shadow" [ngClass]="getNgClass(item)" (click)="providers.toggleSelected(item)">
                        <div class="w3-container">
                            <div class="text-start">{{item.provider}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</div>

<div id="rule" class="collapse w3-container w3-margin-top filter">
    <div class="col-md-12">
        <div class="row">
            <div class="input-append span12">
                <div class="col-md-6" *ngIf="rules?.selectedCount > 0">
                    <div class="row">
                        <span class="w3-text-blue w3-small">The selected rule number will be applied.</span>
                    </div>
                    <div class="row background-gray">
                        <span class="w3-small">{{rules.getSelectedValues()}}</span>
                    </div>
                    <div class="row background-gray">
                        <button type="button" class="btn btn-info btn-reverse pull-right" (click)="rules.clearSelection()">Clear</button>
                    </div>
                </div>
                <button type="button" class="btn btn-primary pull-right ms-3" (click)="closeAllFilter()">Apply</button>
            </div>
        </div>
        <div class="row w3-margin-top">
            <ul class="list-group list-group-horizontal f32">
                <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredRuleList; let i=index">
                    <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                        <div class="w3-container">
                            <div class="text-start">Rule {{i+1}} {{item.dataSource}} {{item.dialPatternType}}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</div>


<div class="row w3-margin-top ms-3 w3-margin-right" id="dataArea" *ngIf="showData">
    <p-table #dt 
        [columns]="partitionCols" 
        [value]="filteredData" 
        [rows]="limit" 
        [paginator]="true" 
        [pageLinks]="5" 
        [rowsPerPageOptions]="[10,30,50,100,250,500,750,1000]"
        resizableColumns="true" 
        columnResizeMode="expand" 
        [globalFilterFields]="['dialPattern']" 
        [lazy]="true" 
        sortMode="multiple"
        [multiSortMeta]="multiSortMeta"
        (onLazyLoad)="loadDataDetails($event)" 
        [totalRecords]="dataTotalCount"
        styleClass="p-datatable-gridlines"
        [style]="{'margin-top':'30px'}">
      <ng-template pTemplate="caption">
          <div class="p-table-globalfilter-container">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input pInputText #gb id="searchInput" type="text" size="50" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Number Search" />
              <span class="pi pi-times-circle" (click)="clearNumberSearch(gb, dt)" [disabled]="!gb.value"></span>
          </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container  *ngFor="let col of columns"> 
                <th class="code-tbl-header" [style.width.%]="col.width"  *ngIf="!col.nosort"
                  [pSortableColumn]="col.field" [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                  tooltipPosition="top">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th class="code-tbl-header" [style.width.%]="col.width"  *ngIf="col.nosort"
                   [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                   tooltipPosition="top">
                  {{col.header}}

                </th>
              </ng-container>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
              <td class="code-tbl-body" *ngFor="let col of columns">
                <span *ngIf="col.field!='customerDate' && col.field!='source' && col.field!='lemin' && col.field!='lemax' && col.field!='rule'">
                    {{rowData[col.field]}}
                </span>
                <span *ngIf="col.field=='customerDate'">
                    {{rowData[col.field] | date:'yyyy-MM-dd'}}
                </span>
                <span *ngIf="col.field=='source'">
                    <span *ngIf="rowData.type==='R' || rowData.type==='U'">{{getRuleSetting(rowData.reference)}}</span>
                    <span *ngIf="rowData.type==='I'">{{getIPRNSourceForDisplay(rowData.dialPatternType)}}</span>
                    <span *ngIf="rowData.type==='W' || rowData.type==='B'">{{rowData.reference}}</span>
                    <span *ngIf="rowData.type==='M'">MSRN</span>
                </span>
                <span *ngIf="col.field=='lemin' || col.field=='lemax'">
                    <span *ngIf="rowData.type==='W' || rowData.type==='B' || rowData.type==='R' || rowData.type==='U' || rowData.type==='I'">{{rowData[col.field]}}</span>
                    <span *ngIf="rowData.type==='M'">n&#47;a</span>
                </span>
                <span *ngIf="col.field=='rule'">
                    <span *ngIf="rowData.type==='R' || rowData.type==='U' || rowData.type==='I'">Rule #{{getRuleIndex(rowData.reference)}}</span>
                    <span *ngIf="rowData.type==='M'">n&#47;a</span>
                </span>
              </td>
          </tr>
      </ng-template>
      <ng-template id="dtFooter" pTemplate="footer">
          <tr>
            <td  [attr.colspan]="partitionCols.length">
                {{totalMessage()}}
            </td>
        </tr>
      </ng-template>
      <!--
      <ng-template pTemplate="emptymessage" let-rows>
          <tr>
              <td [attr.colspan]="rows.length">
                  No partition records.
              </td>
          </tr>
      </ng-template>
      -->
    </p-table>
</div>