import { Component, OnInit } from '@angular/core';
import { AppConstants} from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { RangeNDC, Premium, RangeFilter} from '../models/index';
import { FilterService} from '../filter/filter-service';
import { ActionName } from 'app/shared/constants';
import { AuditService } from 'app/services/audit.service';

@Component({
  selector: 'app-mobileid-data',
  templateUrl: './mobileid-data.component.html',
  styleUrls: ['./mobileid-data.component.scss']
})
export class MobileidDataComponent extends FilterService implements OnInit {
  isDownloading = false;
  showFilterBy = true;
  iprnCols: any[];
  rangeCols: any[];
  tableCols : any[] = [];
  filterFieldCols: any[] = [];

  constructor(public log: Logger, public httpService: HttpService, 
    public cacheService: CacheService, public notifService: NotificationService, public auditService:AuditService) {
    super(log, httpService, cacheService, notifService, );

    this.iprnCols = [
      {field: 'primeMinus1', header: 'Prime-1', width: '50px'},
      {field: 'primeMinus2', header: 'Prime-2', width: '50px'},
      {field: 'primeMinus3', header: 'Prime-3', width: '40px'},
      {field: 'primeMinus4', header: 'Prime-4', width: '40px'},
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'termCountry', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '75px'},
      {field: 'supplement', header: 'Supplement', width:'100px'},
      {field: 'provider', header: 'Provider', width:'75px'},
      {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
      {field: 'lastUpdate', header: 'Last Observed', width: '80px'}
    ];
    
    this.rangeCols = [
      {field: 'code', header: 'Code', width: '30px'},
      {field: 'ndc', header: 'NDC', width: '30px'},
      {field: 'iso2', header: 'ISO2', width: '30px'},
      {field: 'termCountry', header: 'Country', width: '50px'},
      {field: 'tos', header: 'TOS', width: '30px'},
      {field: 'tosdesc', header: 'TOS Description', width: '75px'},
      {field: 'supplement', header: 'Supplement', width:'100px'},
      {field: 'provider', header: 'Provider', width:'75px'},
      {field: 'lemin', header: 'LEMin', width: '62px'},
      {field: 'lemax', header: 'LEMax', width: '64px'},
      {field: 'effectiveDate', header: 'Effective Date', width: '75px'},
    ];

    this.tableCols=[...this.rangeCols];
    this.tableCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
  }

  ngOnInit() {
    super.init();
    this.rangeFilter = new RangeFilter();
    this.fetchRangeData(ActionName.NumberingDataRangeIPRNDropdown);
  }

  applyFilter() {
    if (this.dataSource === 'IPRN')
    this.fetchRangeData(ActionName.NumberingDataIPRNFltr);
    else {
      this.fetchRangeData(ActionName.NumberingDataRangeFltr);
    }
  }

  downloadRangeData() {
    let data = this.getExportColumnNames().join(AppConstants.DELIMITER) + '\n';
    let action = ActionName.NumberingDataRangeDownloadFltr;
    this.filteredRangeDataList.forEach(item => {
      let myItem;
      if (this.dataSource === 'IPRN') {
        myItem = item as Premium;
        action = ActionName.NumberingDataIPRNDownloadFltr
      } else {
        myItem = item as RangeNDC;
      }
      data += myItem.toString(AppConstants.DELIMITER) + '\n';
    });

    this.downloadFile([data],  this.makeDownloadFilename(this.getFileName(), '.csv'));
    if (!this.hasFilter) {
      this.createRangeFilter();
    }
    this.auditService.auditLog(action,this.rangeFilter)
  }

  downloadMobileIdFile() {
    this.isDownloading = true;
    this.httpService.getStream(AppConstants.DownloadMobileIdDataURL + this.dataSource, this.downloadFileHandler);
    return false;
  }

  downloadFileHandler = (data: Response) => {
    this.isDownloading = false;
    const fileName = this.dataSource + '_' + this.cacheService.lastMobileDateSetDate + '.csv';
    this.downloadFile(data.body, fileName, 'application/octet-stream');
  }

  toggleTableCols() {
    if (this.dataSource == 'IPRN') {
      this.tableCols=[...this.iprnCols];
    } else {
      this.tableCols=[...this.rangeCols];
    }
    this.tableCols.forEach( c=> {
      this.filterFieldCols.push(c.field);
    });
  }
}

