import { Type, Skip } from 'serializer.ts/Decorators';
import { RuleDefinition, PartitionExportHistory } from './index';
// map to PartitionDefinition.java

export class PartitionDefinition {
  id: number;
  origPartitionId: number;
  customerName: string;
  name: string;
  description: string;
  flId : number;
  wlId: number;
  blId: number;
  wlName: string;
  blName: string;
  tpId : number;
  tpName :string;
  ruleIds: string;
  status: string;
  draftDate: Date;
  lastExportDate: Date;
  lastUpdated: Date;
  lastUpdatedBy: string;
  currentSelected: boolean = false;
  autoExport : number;
  exportDestination : string;
  createDeltaFile : string;
  consolidatedList : string;
  exportable : boolean = false;
  partitionExportHistories: Array<PartitionExportHistory> = [];

  //@Type(() => RuleDefinition)
  ruleDefinitions: Array<RuleDefinition> = [];

  //GUI PTB - push to blocking
  disabledPTB : boolean = false;
  constructor(name?: string) {
    this.name = name;
  }

}
