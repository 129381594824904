<p-confirmDialog width="425" rejectIcon="" rejectLabel="Cancel" acceptIcon="" acceptLabel="{{acceptLabel}}"></p-confirmDialog>
<div class="container-fluid">
  <div class="col-md-2">
      <div id = "pf-layout" class="lists-menu">
          <div class="row">
              <div class="col-sm-7" style="padding-left: 5px;">
                  <h6><strong>Partition File Layouts</strong></h6>
              </div>    
              <div class="col-sm-1" *ngIf = "isAddMore && !isLayoutChange">
                  <i id="pflAddIcon" class="fa fa-plus-circle fa-2x" style="margin-top: 10px;  color: #d71b36; cursor: pointer;" aria-hidden="true" (mousedown)="onNewLayout()" ></i>
              </div>
              <div class="col-sm-1" *ngIf = "!isAddMore || isLayoutChange">
                  <i class="fa fa-plus-circle fa-2x" style="margin-top: 10px;  color: rgba(72,76,85,.5); cursor: not-allowed;" aria-hidden="true" ></i>
              </div>
              <div class="col-sm-1">
                  <div *ngIf = "pfLayouts.length > 0 && isAllowDelete">
                      <i id="pflDeleteIcon" class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color: #33647f; cursor: pointer;" aria-hidden="true" (click)="onDeleteLayout()"></i>
                  </div>
                  <div *ngIf = "pfLayouts.length < 1 || !isAllowDelete">
                      <i class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color:rgba(72,76,85,.5); cursor: not-allowed;"></i>
                  </div>
              </div>   
          </div>
          <div id = "pflArea">
            <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "pfLayouts.length <= showMoreLimit">
                <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let pfLayoutItem of pfLayouts; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{pfLayoutItem.name | convertToUnderscore: 'PFL'}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(pfLayoutItem)" [class.selected]="pfLayoutItem.selected">{{pfLayoutItem.name}}</a>
                    </div>
                    <div [hidden]="!pfLayoutItem.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "pfLayouts.length > showMoreLimit && showMorePFL">
                <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let pfLayoutItem of pfLayouts | slice:0:showMoreLimit; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{pfLayoutItem.name | convertToUnderscore: 'PFL'}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(pfLayoutItem)" [class.selected]="pfLayoutItem.selected">{{pfLayoutItem.name}}</a>
                    </div>
                    <div [hidden]="!pfLayoutItem.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "pfLayouts.length > showMoreLimit && !showMorePFL">
                <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let pfLayoutItem of pfLayouts; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{pfLayoutItem.name | convertToUnderscore: 'PFL'}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(pfLayoutItem)" [class.selected]="pfLayoutItem.selected">{{pfLayoutItem.name}}</a>
                    </div>
                    <div [hidden]="!pfLayoutItem.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <div *ngIf = "pfLayouts.length > showMoreLimit" class="text-center">
                <a class="showMoreLess-link" style="margin-right: 5px;" (click)="showMorePFL = !showMorePFL">{{ showMorePFL ? "Show More" : "Show Less"}}
                    <i *ngIf = "showMorePFL" class="fa fa-chevron-down" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                    <i *ngIf = "!showMorePFL" class="fa fa-chevron-up" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                </a>
            </div>
        </div>
      </div>
  </div> 
  <div class="col-md-10 leftrail-leftbar">
    <div class="offset-md-2 col-md-6" *ngIf="pfLayouts.length == 0 && maxPartitionFileLayouts>0 && !isNewLayout && !selectedLayout">
        <h2 class="text-center">
            Get started by creating a <br> Partition File Layout
        </h2>
        <p>
            You are allowed {{maxPartitionFileLayouts}} layouts.  Create and name your first layout by clicking the + icon to the left.  Then go to the Manage Partitions screen to assign a layout to a Partition by clicking the Partition's configuration icon.
        </p>
    </div>
    <div *ngIf="pfLayouts.length>0 || selectedLayout || isNewLayout">
        <div class="col-sm-12">
            <h3 class="text-center">
                <app-editable-field [origItem]="selectedLayoutItem.item.name" [styleClass]="styleClass" [maxLength]="25" [placeholderText]="placeholderValue" (dateModelChange)="updateLayoutName($event)" id="partitionFileLayoutNameField" #partitionFileLayoutNameField></app-editable-field>
            </h3>
            <p class="text-end" *ngIf="!isNewLayout && selectedLayout">Last updated {{selectedLayout.lastUpdated | date: 'yyyy-MM-dd HH:mm'}} </p>
        </div>
        <!--Pop-up Messages -->
        <p-dialog [(visible)] = "showThresholdMessage" [showHeader]="false" width="800" position="top" contentStyleClass="warningDialog">
            <!--<span class="fa-stack fa-lg close-button">
                <i id="closeThresholdMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeMessage();"></i>
            </span>-->
            <span>
                <font color="white" style="font-size:13px;font-weight:bold">
                    You are allowed up to {{maxPartitionFileLayouts}} partition file layouts.
                </font>
                <font color="white" style="font-size:13px;font-weight:regular">
                    You have {{remainingLayoutCount}} partition file layouts remaining.
                </font>
            </span>       
        </p-dialog> 
        <div *ngIf="selectedLayout">
            <p-dialog id="duplicateMessageBox" [(visible)]="showDuplicateMessage" modal="modal" [showHeader]="false" [style]="{width: '40vw'}" position="top" contentStyleClass="errorDialog" closable="true" closeOnEscape="true">
                <span class="col-sm-11">
                    <font color="white" style="font-size:13px;font-weight:bold">
                        Duplicate Name!
                    </font>
                    <font color="white" style="font-size:13px;font-weight:regular">
                        There is already a partition file layout with that name.  Please use a different name.
                    </font>
                </span>
                <span class="fa-stack fa-lg close-button col-sm-1" style="cursor: pointer;">
                    <i id="closeDuplicateMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeDuplicateMessage()"></i>
                </span>       
            </p-dialog> 
        </div>
        <div *ngIf="selectedLayout">
            <p-dialog id="requiredFieldMessageBox" [(visible)] = "showRequiredFieldMessage" modal="modal" [showHeader]="false" [style]="{width: '40vw'}" position="top" contentStyleClass="errorDialog" closable="true" closeOnEscape="true">
                <span class="col-sm-11">
                    <font color="white" style="font-size:13px;font-weight:regular">
                        Dial Pattern is a required layout field and must be selected for output.  Please update and save again.
                    </font>
                </span>
                <span class="fa-stack fa-lg close-button col-sm-1" style="cursor: pointer;">
                    <i id="closeRequiredFieldMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeRequiredFieldMessage()"></i>
                </span>       
            </p-dialog> 
        </div>
        <!--Page Fields -->
        <div *ngIf="selectedLayout" id="pflInputArea" #pflInputArea class="row">
            <div class="col-5 col-md-4 pull-right">
                <button id="saveChangesButton" class='btn btn-default no-uppercase no-padding list-btn' type="button" style="margin-right:10px" [disabled]="!isLayoutChange" (click)="onSaveChanges()">Save Changes</button>
                <button id="cancelButton" class='btn btn-info no-uppercase no-padding list-btn' type="button" style="margin-right:10px" (click)="onCancel()">Cancel</button>
            </div>
            <div class="col-9 col-md-7" style="height:auto;padding-left: 20px;">
                <div class="row  w3-margin-top">
                    <div class="col-md-9" style="padding-left:0px;margin-left: 30px; text-align: left">
                        <div class="mb-3 row">
                            <font style="font-size:13px;font-family:Open Sans;font-weight:normal">Description </font>
                            <br>
                            <textarea style="padding-left: 0px; resize: none;" type="text" class="col-sm-10 toolTipText" maxlength="100" id="layoutDesc" name="layoutDesc" [(ngModel)]="selectedLayout.description" (change)="onChange();" rows="2" cols="50" > </textarea>
                            <br>
                        </div>
                        <div class="row" style="padding-left: 0px;"><h6>Layout Options:</h6></div>
                        <!--<div class="row">
                            <h6><b>Layout Options:</b></h6>
                        </div>-->
                        <div class="mb-3 row" style="margin-left:0px">
                            <span style="font-size:13px;font-family:Open Sans;font-weight:normal">CSV File Delimiter<br>
                                <font style="font-size:16px;font-family:Open Sans;font-weight:normal">
                                <select id="fileDelimiterDropdown" [(ngModel)]="selectedLayout.fileDelimiter" (change)="onChange();" style="width:65">
                                    <option *ngFor="let item of fileDelimiters" id="fileDelimiterSelect_{{item}}" value="{{item}}">{{item}}</option>
                                </select>
                                </font>
                            </span>
                        </div>
                        <div class="mb-3 row" style="margin-left:0px">
                                <label for="headerRecord" class="form-label"><font style="font-size:13px;">Include Header Record</font></label>
                                <div class="form-check">
                                    <label class="form-check-label form-radio" for="headerRecordRadio_Y">
                                        Yes
                                        <input type="radio" class="form-check-input" name="headerRecord" id="headerRecordRadio_Y" (change)="selectedLayout.includeHeader='Y';onChange();" [checked]="selectedLayout.includeHeader==='Y'">
                                        <div class="form-indicator"></div>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label form-radio" for="headerRecordRadio_N">
                                         No
                                        <input type="radio" class="form-check-input" name="headerRecord" id="headerRecordRadio_N" (change)="selectedLayout.includeHeader='N';onChange();" [checked]="selectedLayout.includeHeader==='N'">
                                        <div class="form-indicator"></div>
                                    </label>
                                </div>
                            </div>

                        <div class="mb-3 row" style="margin-left:0px">
                            <label for="useQuotes" class="form-label"><font style="font-size:13px;">Enclose all fields in quotes</font></label>
                            <div class="form-check">
                                <label class="form-check-label form-radio" for="useQuotesRadio_Y">
                                    Yes
                                    <input type="radio" class="form-check-input" name="useQuotes" id="useQuotesRadio_Y" (change)="selectedLayout.encloseFieldQuotes='Y';onChange();" [checked]="selectedLayout.encloseFieldQuotes==='Y'">
                                    <div class="form-indicator"></div>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label form-radio" for="useQuotesRadio_N">
                                     No
                                    <input type="radio" class="form-check-input" name="useQuotes" id="useQuotesRadio_N" (change)="selectedLayout.encloseFieldQuotes='N';onChange();" [checked]="selectedLayout.encloseFieldQuotes==='N'">
                                    <div class="form-indicator"></div>
                                </label>
                            </div>
                        </div>
                        <!-- CUSTOM FIELDS -->
                        <div class="row"> 
                            <div class="col-sm-5" style="padding-left: 0px;"><h6>Custom Filler Fields:</h6></div>
                            <div class="col-sm-4"><button id="addFieldButton" class='btn btn-default no-uppercase no-padding list-btn' style="margin-right:10px" type="button" [disabled]="customLayoutFieldList.length>=10" (click)="onAddFieldDialog();">Add Field</button></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <table class="row col-md-11">
                                <div *ngIf="customLayoutFieldList.length>0">
                                    <tr>
                                        <td class="col-sm-2" style="height:34px;min-width:60px;font-size:12px;font-weight:bold;border-bottom:0px solid">Action</td>
                                        <td class="col-sm-4" style="height:34px;min-width:170px;font-size:12px;font-weight:bold;border-bottom:0px solid">Field Name</td>
                                        <td class="col-sm-6" style="height:34px;min-width:374px;font-size:12px;font-weight:bold;border-bottom:0px solid">Field Value</td>
                                    </tr>
                                </div>
                                <div *ngFor="let item of customLayoutFieldList; let i=index">
                                    <tr>
                                        <td class="col-sm-2" style="height:34px;min-width:60px;font-size:12px">
                                            <i id="editFieldIcon_{{i}}" class="fa fa-pencil-alt" style="color: red; cursor: pointer;" (click)="onEditFieldDialog(i);"></i>
                                            <i id="deleteFieldIcon_{{i}}" class="fa fa-trash" style="color: #33647f;cursor: pointer;" (click)="onDeleteCustomField(i);"></i>
                                        </td>
                                        <td id="customFieldName_{{i}}" class="col-sm-4" style="height:34px;min-width:170px;font-size:12px">{{item.displayName}}</td>
                                        <td id="customFieldValue_{{i}}" class="col-sm-6" style="height:34px;min-width:374px;font-size:12px;white-space:pre">{{item.customFieldValue}}</td>
                                    </tr>
                                </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9 col-md-5" style="height:auto;margin-left: -80px;" >
                <!--<div class="row offset-xs-1">
                    <h6><b>Layout Fields:</b></h6>
                </div>-->
                <div class="row offset-xs-2"> <h6>Record Field Selection:</h6></div>
                <!-- PICKLIST-->
                <div class="row offset-xs-2">
                        <p-pickList id="picklist" [source]="layoutFieldsSource" [target]="layoutFieldsTarget" sourceHeader="Available" targetHeader="Selected" [responsive]="false"
                        dragdrop="true" [showSourceControls]="false" [showTargetControls]="true" [sourceStyle]="{height:'480px', width: '180px'}" [targetStyle]="{height:'480px', width: '180px'}" 
                        (onMoveToTarget)="onChange();" (onMoveToSource)="onChange();" (onTargetReorder)="onChange();" (onMoveAllToSource)="onChange()" (onMoveAllToTarget)="onChange()" [style]="" [class]="">
                            <ng-template let-pickListFields pTemplate="item">
                                <div id="{{pickListFields.displayName | convertToUnderscore: 'PK'}}" class="ui-helper-clearfix">
                                    {{pickListFields.displayName}}
                                </div>
                            </ng-template>
                        </p-pickList>
                    <br>
                    <br>
                </div>
            </div>
            <!-- delete confirmation dialog-->
            <div *ngIf="selectedLayout">
                <p-dialog header="Confirmation" [(visible)]="showDelConfirm" modal="modal" [width]="350" [minWidth]="200" dynamic=true>
                    <span style="font-size:13px;font-weight:bold">Are you sure you want to permanently delete {{ selectedLayout.name }} partition file layout?</span>
                    <span style="font-size:13px;font-weight:regular">The layout cannot be restored after this action.
                    <p><br>{{ partitionReferenceMessage }}</p></span>
                            <p-footer>
                                <button id="deleteLayoutConfirmationButton" type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:90px;height:26px;margin-left:10px;" (click)="deleteLayout();">Delete Layout</button>
                                <button id="deleteLayoutCancelButton" type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:90px;height:26px;margin-left:10px;" (click)="showDelConfirm=false">Cancel</button>
                            </p-footer>
                </p-dialog>
            </div>
            <!-- custom filler field dialog-->
            <p-dialog header="Custom Filler Field" styleClass="custom" [(visible)]="showCustomFieldDialog" modal="modal" width="420" (onHide)="closeCustomFieldDialog()" [style]="{'background-color':'white'}">
                <div class="row">
                    <div style="color:red;font-size:13px;font-weight:bold;">
                        {{customFieldErrorMsg}}
                    </div>
                </div>
                <div class="dialog-close">
                    <div class="row">
                        <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: -7px;margin-bottom: 5px;font-weight:bold;">
                            Field Name
                        </div>
                    </div>
                    <div class="row" style="margin-top: 0px;">
                        <div>
                            <input autofocus type="text" id="inputFieldName" name="inputFieldName" class="form-control" [(ngModel)]="inputCustomField.displayName" required autofocus maxlength="20" />
                        </div>
                    </div> 
                </div>
                <div class="dialog-close">
                    <div class="row">
                        <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: -7px;margin-bottom: 5px;font-weight:bold;">
                            Field Value
                        </div>
                    </div>
                    <div class="row" style="margin-top: 0px;">
                        <div>
                            <input type="text" id="inputFieldValue" name="inputFieldValue" class="form-control" [(ngModel)]="inputCustomField.customFieldValue"  maxlength="50" />
                        </div>
                    </div> 
                </div>
                <p-footer>
                    <div class="offset-md-6 col-md-6">
                        <button id="cancelAddFieldConfigButton" class="btn btn-info no-uppercase no-padding pull-right" type="button" style= "width:55px;height:26px" (click)="closeCustomFieldDialog()">Cancel</button>
                        <button id="saveCustomFieldConfigButton" class="btn btn-default no-uppercase no-padding pull-right" style= "width:55px;height:26px;margin-right:10px;" type="button" (click)="onAddEditField()">Ok</button>
                    </div>
                </p-footer>
            </p-dialog>
            <!-- delete custom field confirmation dialog-->
            <div>
                <p-dialog header="Confirmation" [(visible)]="showDelFieldConfirm" modal="modal" [width]="350" [minWidth]="200" dynamic=true>
                    <span style="font-size:13px;font-weight:bold">Are you sure you want to delete custom field {{deleteCustomFieldName}}?</span>
                        <p-footer>
                            <button id="deleteFieldConfirmationButton" type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:90px;height:26px;margin-left:10px;" (click)="deleteCustomField();">Delete</button>
                            <button id="deleteFieldCancelButton" type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:90px;height:26px;margin-left:10px;" (click)="showDelFieldConfirm=false">Cancel</button>
                        </p-footer>
                </p-dialog>
            </div>
            <!-- unsaved changes confirmation dialog-->
            
            <div>
                <p-dialog header="Confirmation" [(visible)]="showUnsavedChangesConfirm" modal="modal" [width]="350" [minWidth]="200" dynamic=true>
                    <span style="font-size:13px;font-weight:bold">Are you sure you want to discard your unsaved changes?</span>
                    <span style="font-size:13px;font-weight:regular">The data cannot be restored after this action.</span>
                        <p-footer>
                            <button id="unsavedChangesConfirmationButton" type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:90px;height:26px;margin-left:10px;" (click)="onContinueSelect();">Ok</button>
                            <button id="unsavedChangesCancelButton" type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:90px;height:26px;margin-left:10px;" (click)="showUnsavedChangesConfirm=false;">Cancel</button>
                        </p-footer>
                </p-dialog>
            </div>

        </div>
    </div>
  </div>
</div>
<p-blockUI [blocked]="blocking"></p-blockUI>
<div class="center" *ngIf="blocking">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
</div>