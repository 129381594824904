import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { ListsService } from './lists.service';
import { ListHelpComponent } from '../list-help/list-help.component';
import { ListsComponent } from '../lists/lists.component';
import { ListDetailComponent } from '../list-detail/list-detail.component';
import { ListDefinition } from '../models/index';
import {ConfirmationService} from 'primeng/api';
import {AppSharedModule} from '../util/shared.module';
import { EditableFieldComponent } from '../editable-field/editable-field.component';

@NgModule({
  imports: [
    AppSharedModule,
    RouterModule,
  ],
  declarations: [
    ListsComponent,
    ListDetailComponent,
    ListHelpComponent
    //EditableFieldComponent,
  ],
  providers: [
    ListsService,
    ConfirmationService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ListsModule {
}
