export class FileLayoutField {
	displayName: string;
	customField: boolean;
	customFieldValue: string;
	//for GUI use
	//selected: string;
	required: boolean;
	checked: boolean;

	constructor(displayName?: string, customField?: boolean, customFieldValue?: string, required?: boolean, checked?: boolean) {
		this.displayName = displayName;
		this.customField = customField;
		this.customFieldValue = customFieldValue;	
		this.required = required;
		this.checked = checked;
	}

	display() {
		console.log("FLF Values: displayName: " + this.displayName + " | customField: " + this.customField + " | customFieldValue: " + this.customFieldValue + " | checked: "  + this.checked + " | required: " + this.required);
 }
}