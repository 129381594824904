
   import { Injectable } from '@angular/core';
   import { RangeFilter } from 'app/models';
   import { AppConstants } from '../util/app.constant';
   import { JsonHelper } from 'app/util';
import { HttpService } from './http.service';
import { AuditPartition } from 'app/models/auditPartition.model';
   
   @Injectable()
    export class AuditService {


    constructor(public httpService?: HttpService) {
        
    }
    
        auditLog( action: String, filterData?: RangeFilter, auditPartitionData?: AuditPartition){
            if (action == undefined || action == null)
                return;
            let logActivityURL = AppConstants.LogUIActivityURL;   
            let dataObj = {
                action: action,
            } 
            if (filterData) { dataObj['data' ]= filterData;}
            if (auditPartitionData) { dataObj['auditPartitionData'] = auditPartitionData;}
            this.httpService.post(JsonHelper.toJson(dataObj), logActivityURL, []);
        }
    }
    
