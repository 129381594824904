<p-confirmDialog width="425" rejectIcon="" rejectLabel="Cancel" acceptIcon="" acceptLabel="{{acceptLabel}}"></p-confirmDialog>
<p-blockUI [blocked]="listService.blocking"></p-blockUI>
<div class="center" *ngIf="listService.blocking">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
</div>
<div *ngIf="listService.selectedList">
    <div class="col-sm-12">
        <h3 class="text-center">
            <app-editable-field [origItem]="listService.selectedListItem.item.listName" [styleClass]="styleClass" [maxLength]="25" [placeholderText]="placeholderValue" (dateModelChange)="updateListName($event)" id="listNameField" #listNameField ></app-editable-field>
            <small><i class="fa fa-info-circle" style="cursor: pointer;" (click)="showToolTip();"></i></small>
        </h3>
        <p id="timeStampElement" class="text-end" *ngIf="!listService.isNewList">Last updated {{listService.selectedList.lastUpdated | date: 'yyyy-MM-dd HH:mm'}} </p>
    </div>
    <p-dialog [(visible)] = "showThresholdMessage && !listService.hideMessageBox"  [showHeader]="false" contentStyleClass="warningDialog" width="900" position="top">
        <!--<span class="fa-stack fa-lg close-button">
            <i id="closeThresholdMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeMessage();"></i>
        </span>-->
        <span>
            <font style="font-size:13px;font-weight:bold">
                You are allowed up to {{ maxWhiteList }} separate allow lists and {{ maxBlackList }} separate block lists.
            </font>
            <font style="font-size:13px;font-weight:regular">
                You have {{ remainingAllotment }} {{listService.selectedList.type=='BL'?'block lists':'allow lists'}}  remaining.
            </font>
        </span>
    </p-dialog>
    <div *ngIf="listService.selectedList">
        <p-dialog [(visible)] = "showDuplicateMessage && !listService.hideMessageBox" modal="modal" [showHeader]="false" [style]="{width: '40vw'}" position="top" contentStyleClass="errorDialog" closable="true" closeOnEscape="true">
            <span class="col-sm-11">
                <font style="font-size:13px;font-weight:bold">
                    Duplicate Name!
                </font>
                <font style="font-size:13px;font-weight:regular">
                    There is already a {{listService.selectedList.type=='BL'?'block list':'allow list'}} with that name.  Please use a different name.
                </font>
            </span>
            <span class="fa-stack fa-lg close-button col-sm-1" style="cursor: pointer;">
                <i id="closeDuplicateMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeDuplicateMessage()"></i>
            </span>
        </p-dialog>
    </div>
    <div class="btn-group col-sm-12" data-bs-toggle="buttons" role="group">
        <div class="row">
            <div class="col-sm-6">
                <button id="addNumberButton" class="btn btn-default no-uppercase no-padding list-btn" style= "margin-right:10px;" type="button" data-bs-toggle="collapse" data-bs-target="#newNumber" aria-expanded="false" aria-controls="filterArea" (click)="onOpenAdd()">Add Number</button>
                <button id="appendFileButton" class='btn btn-default no-uppercase no-padding list-btn' style= "margin-right:10px;" (click)="onOpen()">Append File</button>
                <button class='btn btn-warning no-uppercase no-padding list-btn' (click)="showUploadError()" *ngIf="listService.hasUploadError()">Show Error</button>
            </div>
            <div class="col-sm-6 pull-right">
                <div class="pull-right" *ngIf="listService.selectedListItem.item.listData && listService.selectedListItem.item.listData.length > 0 && !listService.listDataQueryStatus.startsWith('Loading')">
                    <button id="deleteAllButton" class='btn btn-info no-uppercase no-padding list-btn'  [disabled]="listService.selectedList.id==null" style= "margin-right:10px;" (click)="onDeleteList()">Delete All</button>
                    <button id="downloadListButton" class='btn btn-primary no-uppercase no-padding list-btn' (click)="downloadList()">Download</button>
                </div>
                <div class="pull-right" *ngIf="selectedRecords.length > 0">
                    <button id="clearSelectionButton" class='btn btn-default no-uppercase no-padding list-btn' style= "margin-right:10px;" (click)="onClearSelection()">Clear</button>
                    <button id="deleteSelectedButton" class='btn btn-info no-uppercase no-padding' style="width:110px;height:26px;margin-right:10px" (click)="onDeleteSelected()">Delete Selected</button>
                    <div class="btn-group">
                        <button id="changeStatusButton" type="button" class="btn btn-default dropdown-toggle no-uppercase no-padding" style="height:26px;width:120px;margin-right:10px" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Change Status <!--<span class="caret"></span>-->
                        </button>
                        <ul id="changeStatusDropdown" class="dropdown-menu">
                            <li><a id="activateRowsOption" class="link" (click)="activateRows()">Active</a></li>
                            <li><a id="deactivateRowsOption" class="link" (click)="deactivateRows()">Inactive</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
   </div>

    <!--Tooltip for list description -->
    <p-dialog [(visible)]="showDialog && !listService.hideMessageBox" modal="modal" [showHeader]="false" position="top" width="600" [contentStyle]="!isEditDesc ? {'background-color':'#33647f'} :{'background-color':'white'}">
        <div>
            <div class="dialog-close">
                <div class="row">
                <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: -10px;" *ngIf="isEditDesc">
                        List Description
                    </div>
                    <div class="col-sm-10 toolTipText" style="padding-left:0px;color:white;margin-top: -10px;"  *ngIf="!isEditDesc">
                        List Description
                    </div>
                    <div class="col-sm-2" style="margin-top: -15px;">
                        <span class="fa-stack fa-lg close-button" *ngIf="!isEditDesc">
                            <i class="fa fa-times fa-stack-1x fa-inverse" style="cursor: pointer;" (click)="isEditDesc = false;listService.hideMessageBox=true"></i>
                        </span>
                        <span class="fa-stack fa-lg close-button" *ngIf="isEditDesc">
                            <i class="fa fa-times fa-stack-1x fa-inverse" style="color: black;cursor: pointer;" (click)="isEditDesc = false;listService.hideMessageBox=true"></i>
                        </span>
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                <div id = "toolTipText" *ngIf="!isEditDesc" class="toolTipText">
                    <div *ngIf="!isEditDesc" class="toolTipText">
                            <span><font color="white" [style]= "{'word-break': 'break-word'}">{{listService.selectedListItem.item.description  ? listService.selectedListItem.item.description : 'edit to enter your description'}}</font></span>
                            <br>
                            <a (click)="isEditDesc = true"><font color="white" style="padding-left: 175px;cursor: pointer;">Edit</font></a>
                    </div>
                </div>
                </div>
                <div class="row" style="margin-top: 0px;">
                    <div *ngIf="isEditDesc">
                        <textarea style="padding-left: 0px;" type="text" class="col-sm-12 toolTipText" maxlength="100" id="listDesc" name="listDesc" [(ngModel)]="listService.selectedListItem.item.description" rows="4" cols="25" > </textarea>
                        <br>
                        <a (click)="onUpdateDesc();listService.hideMessageBox=true"><font color="#1d3a49" style="padding-left: 175px;cursor: pointer;">Save</font></a>
                    </div>
                </div>

            </div>
            </div>
    </p-dialog>

    <!-- && listService.selectedListItem.item.listData.length > 0-->
    <div class="col-sm-12 w3-margin-top" *ngIf="listService.selectedListItem.item.listData">
        <p-table #dt id="listDataTable" [columns]="listCols" [value]="listService.selectedListItem.item.listData" rowHover="true"
        expandableRows="true" [editable]="true" [globalFilterFields]="dialPattern" selectionMode="multiple" [(selection)]="selectedRecords" [rows]="250" [paginator]="true"
        [pageLinks]="10" [rowsPerPageOptions]="[100,250,500,750,1000]" resizableColumns="true" dataKey="dialPattern"
        [responsive]="true" [style]="{'margin-top':'10px'}" (onRowClick)="onRowClick($event);" (onEditInit)="storeCurrentVal($event)"
        emptyMessage="No items match your search criteria" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <div class="p-table-globalfilter-container">
                    Number Search:
                    <input pInputText #gb id="numberSearchField" type="text" size="50" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Number Search" />
                    <span class="pi pi-times-circle" (click)="clearSearch(gb, dt)"></span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em"></th>
                    <th class="code-tbl-header" [style.width.%]="col.width" *ngFor="let col of columns"
                        [pSortableColumn]="col.field" [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                        tooltipPosition="top">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
                <tr [pSelectableRow]="rowData" [class.p-highlight]="(rowData.isEditable)">
                    <td>
                        <div>
                            <a href="#" [pRowToggler]="rowData"><i
                                    [ngClass]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i></a>
                        </div>
                    </td>

                   <td class="code-tbl-body" [style]="{'width':'85px'}">{{rowData.dialPattern}}</td>
                    <td [pEditableColumn]="rowData" [pEditableColumnField]="'reason'" class="code-tbl-body" [style]="{'width':'150px'}">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" maxlength="100"  [(ngModel)]="rowData.reason"  (focusout)="onEditListEntry(rowData)" (keydown.space)="onSpaceKeyDown($event, rowData, 'reason')" (keydown)="disableNavigation($event, rowData)">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.reason}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td class="code-tbl-body" [style]="{'width':'38px'}">{{rowData.code}}</td>
                    <td class="code-tbl-body" [style]="{'width':'55px'}">{{rowData.ndc}}</td>
                    <td class="code-tbl-body" [style]="{'width':'41px'}">{{rowData.iso2}}</td>
                    <td class="code-tbl-body" [style]="{'width':'135px'}">{{rowData.termCountry}}</td>
                    <td class="code-tbl-body" [style]="{'width':'33px'}">{{rowData.tos}}</td>
                    <td class="code-tbl-body" [style]="{'width':'135px'}">{{rowData.tosdesc}}</td>
                    <td class="code-tbl-body" [style]="{'width':'53px'}" >{{rowData.lemin}}</td>
                    <td class="code-tbl-body" [style]="{'width':'53px'}" >{{rowData.lemax}}</td>
                    <td [pEditableColumn]="rowData" [pEditableColumnField]="'customerDate'" class="code-tbl-body" [style]="{'width':'150px'}">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-calendar appendTo="body" (onSelect)="onEditListEntry(rowData)" [(ngModel)]="rowData.customerDate"></p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData['customerDate']|date}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td [pEditableColumn]="rowData" [pEditableColumnField]="'notes'" class="code-tbl-body" [style]="{'width':'120px'}">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" maxlength="100" [(ngModel)]="rowData.notes" (focusout)="onEditListEntry(rowData)" (keydown.space)="onSpaceKeyDown($event, rowData, 'notes')" (keydown)="disableNavigation($event, rowData)">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.notes}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td [pEditableColumn]="rowData" [pEditableColumnField]="'status'" class="code-tbl-body"  [style]="{'width':'89px'}">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-dropdown (onChange)="onToggleActivity(rowData)" [(ngModel)]="rowData.status" [options]="statusChoices" [style]="{'width':'100%'}" required="true" appendTo="body"></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.status}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>

            <ng-template let-row pTemplate="rowexpansion" >
                <tr>
                    <td [attr.colspan]="listCols.length+1">
                        <div class="ui-grid ui-grid-responsive ui-fluid"  [class]="highlightSelected(row)">
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-6">
                                    <div class="ui-grid ui-grid-pad">
                                        <div class="ui-grid-row">
                                            <div class="ui-grid-col-4" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;margin-left:55px">Supplement:</font> </div>
                                            <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.supplement}}</font></div>
                                        </div>
                                        <div class="ui-grid-row">
                                            <div class="ui-grid-col-2" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;margin-left:55px">Provider:</font> </div>
                                            <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.provider}}</font></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ui-grid-col-6">
                                    <div class="ui-grid ui-grid-responsive ui-grid-pad">
                                            <div class="ui-grid-row">
                                                <div class="ui-grid-col-2" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;">Updated By:</font> </div>
                                                <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.lastUpdatedBy}}</font></div>
                                            </div>
                                            <div class="ui-grid-row">
                                                <div class="ui-grid-col-2" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;">Updated Time:</font> </div>
                                                <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.lastUpdated | date: 'yyyy-MM-dd'}}</font></div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template id="listDataTableFooter" pTemplate="footer">
                <tr>
                    <td [attr.colspan]="listCols.length+1">
                        {{listService.listDataQueryStatus}} Selected {{selectedRecords.length}} of {{listService.selectedListItem.item.listData.length}}
                    </td>
                </tr>
            </ng-template>
            <!--
            <ng-template pTemplate="emptymessage" let-rows>
                <tr>
                    <td [attr.colspan]="rows.length">
                        No list records added.
                    </td>
                </tr>
            </ng-template>
            -->
        </p-table>

        <!--- OLD DEPRECATED DATATABLE -->
        <!--
        <p-dataTable #dt id="listDataTable" [value]="listService.selectedListItem.item.listData" rowHover="true" expandableRows="true" [editable]="true" [globalFilter]="gb" [(selection)]="selectedRecords" [rows]="250" [paginator]="true" [pageLinks]="10" [rowsPerPageOptions]="[100,250,500,750,1000]" resizableColumns="true"
            [responsive]="true" [style]="{'margin-top':'10px'}" (onRowClick)="onRowClick($event);" (onEditInit)="storeCurrentVal($event)" emptyMessage="No items match your search criteria">
            <p-footer id="dataTableFooter">{{listService.listDataQueryStatus}} Selected {{selectedRecords.length}} of {{listService.selectedListItem.item.listData.length}}</p-footer>
            <p-column expander="true" styleClass="col-icon" [style]="{'width':'30px'}"></p-column>
            <ng-template let-row pTemplate="rowexpansion" >
            <div class="ui-grid ui-grid-responsive ui-fluid"  [class]="highlightSelected(row)">
                <div class="ui-grid-row">
                    <div class="ui-grid-col-6">
                        <div class="ui-grid ui-grid-pad">
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-4" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;margin-left:55px">Supplement:</font> </div>
                                <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.supplement}}</font></div>
                            </div>
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-2" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;margin-left:55px">Provider:</font> </div>
                                <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.provider}}</font></div>
                            </div>
                        </div>
                    </div>
                    <div class="ui-grid-col-6">
                        <div class="ui-grid ui-grid-responsive ui-grid-pad">
                                <div class="ui-grid-row">
                                    <div class="ui-grid-col-2" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;">Updated By:</font> </div>
                                    <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.lastUpdatedBy}}</font></div>
                                </div>
                                <div class="ui-grid-row">
                                    <div class="ui-grid-col-2" style="width:auto"><font style="font-size:10px;font-family:Open Sans;font-weight: bold;">Updated Time:</font> </div>
                                    <div class="ui-grid-col-6" style="width:auto"><font style="font-size:10px;font-family:Open Sans;">{{row.lastUpdated | date: 'yyyy-MM-dd'}}</font></div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            </ng-template>
            <p-column field="dialPattern" header="Dial Pattern" [style]="{'width':'85px'}" [sortable]="false"></p-column>
            <p-column field="reason" header="Reason" [editable]="true" [sortable]="false" [style]="{'width':'150px'}">
                <ng-template let-col let-row="rowData" pTemplate="editor">
                    <input style="font-size:11px" maxlength="100" (blur)="onEditListEntry(row)" [(ngModel)]="row[col.field]">
                </ng-template>
            </p-column>
            <p-column field="code" header="Code" [sortable]="false" [style]="{'width':'38px'}"></p-column>
            <p-column field="ndc" header="NDC" [sortable]="false" [style]="{'width':'55px'}"></p-column>
            <p-column field="iso2" header="ISO2" [sortable]="false" [style]="{'width':'41px'}"></p-column>
            <p-column field="termCountry" header="Country" [sortable]="false" [style]="{'width':'135px'}"></p-column>
            <p-column field="tos" header="TOS" [sortable]="false" [style]="{'width':'33px'}"></p-column>
            <p-column field="tosdesc" header="TOS Description" [style]="{'width':'135px'}"></p-column>
            <p-column field="lemin" header="LEMin" [sortable]="false" [style]="{'width':'53px'}" ></p-column>
            <p-column field="lemax" header="LEMax" [sortable]="false" [style]="{'width':'53px'}"></p-column>
            <p-column field="customerDate" header="Customer Date" [editable]="true" [sortable]="false" styleClass="customer-date">
                <ng-template let-col let-row="rowData" pTemplate="body">
                    {{row[col.field]|date}}
                </ng-template>
                <ng-template let-col let-row="rowData" pTemplate="editor">
                    <p-calendar appendTo="body" (onSelect)="onEditListEntry(row)" [(ngModel)]="row[col.field]"></p-calendar>
                </ng-template>
            </p-column>
            <p-column field="notes" header="Notes" [editable]="true" [sortable]="false" [style]="{'width':'120px'}">
                    <ng-template let-col let-row="rowData" pTemplate="editor">
                        <input style="font-size:11px" maxlength="100" (blur)="onEditListEntry(row)" [(ngModel)]="row[col.field]">
                    </ng-template>
                </p-column>
            <p-column field="status" header="Status" [editable]="true" [sortable]="false" [style]="{'width':'89px'}">
                <ng-template let-col let-row="rowData" pTemplate="editor">
                    <p-dropdown (onChange)="onToggleActivity(row)" [(ngModel)]="row[col.field]" [options]="statusChoices" [autoWidth]="false" [style]="{'width':'100%'}" required="true" appendTo="body"></p-dropdown>
                </ng-template>
            </p-column>

        </p-dataTable>
        -->

    </div>

    <div class="col-sm-12">
        <p-dialog [(visible)]="listService.displayUploadWin" modal="modal" width="575" [closable]="false" [showHeader]="false">
            <div class="fileUpload btn btn-default">
                <span>Choose File</span>
                <input #uploadBtn id="uploadBtn" type="file" (change)="fileChange()" class="upload" />
            </div>
            <span>CSV file type only.</span>
            <div class="h4 fw-bold col-sm-12">{{file?.name}}</div>
            <div class="col-sm-12 margin-top">
                <div class="col-sm-5"><span class="h5 fw-bold">List Name</span></div>
                <div class="offset-sm-1 col-sm-6"><span class="h5 fw-bold">List Description</span></div>
            </div>
            <div class="col-sm-12">
                <div class="col-sm-5">
                    <input type="text" class="col-sm-12" maxlength="25" id="listName" name="listName" [(ngModel)]="listService.selectedListItem.item.listName" placeholder="enter text here" disabled>
                    <span class="col-sm-12 padding-zero-horz">25 character max</span>
                </div>
                <div class="offset-sm-1 col-sm-6">
                    <textarea type="text" class="col-sm-12" maxlength="100" id="listDesc" name="listDesc" [(ngModel)]="listService.selectedListItem.item.description" rows="4" cols="25" placeholder="enter text here">
                    </textarea>
                    <span class="col-sm-12 padding-zero-horz">Optional description of your list, 100 char max.</span>
                </div>
            </div>

            <div class="col-sm-12 margin-top text-center">
                <button id="uploadFileButton" type="button" [disabled]="!file || listService.selectedListItem.item.listName.trim() === ''" class="btn btn-default margin-sm-horz" (click)="upload($event)">Upload</button>
                <button id="cancelUploadButton" type="button" class="btn btn-info margin-sm-horz" (click)="onCancel()">Cancel</button>
            </div>
        </p-dialog>
    </div>

    <div class="col-sm-12">
        <p-dialog [(visible)]="showUploadErrorWin" modal="modal" width="550" resizable="true">
            <ng-template pTemplate="header">
                <div class="col-sm-5"><span>File Formatting Errors</span></div>
                <div class="col-sm-3">
                    <button id="downloadErrorButton" class='btn btn-default' (click)="downloadError()">Download</button>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
            <textarea id="uploadErrorMessage" rows="30" cols="70" disabled>{{uploadErrorMessage}}</textarea>
            </ng-template>
        </p-dialog>
    </div>

     <div class="col-sm-12" *ngIf="selectedListDetail">
        <p-dialog header="Add Dial Pattern" styleClass="custom" [(visible)]="showNewDetailWin" modal="modal" width="800" resizable="true">
            <h4 id="addDialPatternMessage" class="text-danger" *ngIf="message">{{message}}</h4>
            <div class="row">
                    <div class="mb-3 col-md-4">
                        <label for="dialPattern" class="form-label"><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Dial Pattern</font></label><i class="fa fa-asterisk" aria-hidden="true" color="#d71b36"></i>
                        <input type="text" class="form-control" required id="dialPattern" name="dialPattern" maxlength="15" [(ngModel)]="selectedListDetail.dialPattern" (keyup)="selectedListDetail.dialPattern=digitOnly(selectedListDetail.dialPattern)" (keyup.enter)="validateDialPattern(selectedListDetail)"
                            (keyup.tab)="validateDialPattern(selectedListDetail)" (blur)="validateDialPattern(selectedListDetail)" placeholder="enter number">
                    </div>
                    <div class="mb-3 col-md-2">
                        <label for="status" class="form-label"><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Status</font></label><i class="fa fa-asterisk" aria-hidden="true" color="#d71b36"></i>
                        <div><p-radioButton name="status" [value]="true" [(ngModel)]="selectedListDetail.active"></p-radioButton><label><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Active</font></label></div>
                        <div><p-radioButton name="status" [value]="false"[(ngModel)]="selectedListDetail.active"></p-radioButton><label><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Inactive</font></label></div>
                     </div>
                    <div class="mb-3 col-md-6">
                        <div class="row" style="margin-top: 0px">
                            <div class="col-md-6">
                                <label for="customerDate" class="form-label"><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Customer Date</font></label>
                                <p-calendar id="customerDate" [showIcon]="true" [(ngModel)]="selectedListDetail.customerDate" dateFormat="yy-mm-dd" placeholder="YYYY-MM-DD"></p-calendar>
                              </div>
                            <div class="col-md-6">
                                <font style="font-size:9px;font-family:Open Sans;font-weight:normal">
                                    <p>Optional date information for note/reason.<br>
                                     The system will generate an entry timestamp for this record.</p>
                                </font>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label for="reason" class="form-label"><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Reason</font></label>
                        <textarea class="form-control" id="reason" name="reason" maxlength="100" [rows]="5" [cols]="5" placeholder="Reason the number is included" [(ngModel)]="selectedListDetail.reason" (keyup)="selectedListDetail.reason=filterStr(selectedListDetail.reason, '|', '')"></textarea>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label for="reason" class="form-label"><font style="font-size:13px;font-family:Open Sans;font-weight:normal">Note</font></label>
                        <textarea class="form-control" id="notes" name="notes" maxlength="100"  [rows]="5" [cols]="5" placeholder="Notes on the entry"  [(ngModel)]="selectedListDetail.notes" (keyup)="selectedListDetail.notes=filterStr(selectedListDetail.notes, '|', '')"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="offset-md-6 col-md-6">
                    <button id="cancelAddDialPatternButton" class="btn btn-info no-uppercase no-padding pull-right" type="button" style= "width:55px;height:26px" (click)="closeEditNew()">Cancel</button>
                    <button id="saveDialPatternButton" class="btn btn-default no-uppercase no-padding pull-right" style= "width:55px;height:26px;margin-right:10px;" type="button" (click)="saveNewNumber(selectedListDetail)">Save</button>
                </div>
            </div>
        </p-dialog>
    </div>
</div>
