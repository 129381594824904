import { AppConstants, JsonHelper } from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson,  Country, ProviderBillingId, RangeNDC, Premium, RangeFilter, TosTosDesc, RuleDefinition, MsrnProviderBillingId, FileLayoutField, CustomGroups } from '../models/index';
import { FilterBase, SelectedCounter, SelectedMsrnCounter} from './filter-base';
import * as $ from 'jquery';
import { MsrnData } from '../models/msrnData.model';
import { ActionName } from 'app/shared/constants';
import { AuditPartition } from 'app/models/auditPartition.model';

export class FilterService extends FilterBase {
  stopBatchLoading = false;
  blockingCountrySearch = false;
  blockingProviderSearch = false;
  blockingMsrnProviderSearch = false;
  dataSource: string;
  selectedTos: string;
  rule: RuleDefinition;
  regPattern:string;
  showMoreCountry = false;
  showMoreProvider = false;
  showMoreTos = false;
  selectedPanel = -1;
 
  selectedCountry = 0;
  selectedCounter: SelectedCounter = { country: 0, tos: 0, provider: 0, rule: 0 };
  selectedMsrnCounter: SelectedMsrnCounter = {msrnProvider: 0 };

  filteredRawDataList: Array<RangeNDC | Premium> = [];
  filteredRangeDataList: Array<RangeNDC | Premium> = [];
  filteredMsrnDataList:Array<MsrnData>=[];

  countryList: Array<Country> = [];
  filteredCountryList: Array<Country> = [];

  tosList: Array<TosTosDesc> = [];
  filteredTOSList: Array<TosTosDesc> = [];

  fullTOSList: Array<string> = [];
  providerBillingIdList: Array<ProviderBillingId> = [];
  filteredProviderBillingIdList: Array<ProviderBillingId> = [];

  msrnProviderBillingIdList: Array<MsrnProviderBillingId> = [];
  msrnFilteredProviderBillingIdList: Array<MsrnProviderBillingId> = [];

  countryFilterLabel: string = '';
  filterTosLabel: string = '';
  filterProviderLabel: string = '';

  filterBy: string;

  allTos:string;

  groupSelected = false;
  cGroups: Array<CustomGroups> = [];
  selectedCountryGroupName='';
  constructor(public log: Logger, public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService) {
    super(notifService);
  }

  init() {
    super.init();
    this.stopBatchLoading = false;
    this.dataSource = 'Range';
    this.selectedCountry = 0;
    this.selectedPanel = -1;
    this.showMoreCountry = false;
    this.showMoreProvider = false;
    this.showMoreTos = false;
    this.allTos = "";
    this.selectedCounter = { country: 0, tos: 0, provider: 0, rule: 0 };
    this.selectedMsrnCounter = {  msrnProvider: 0 };
    this.filteredRangeDataList = [];
    this.filteredRawDataList = [];
    this.filteredMsrnDataList = [];
    
    
    this.cacheService.countryList.forEach(item => { item.selected = ''; });
    this.cacheService.providerBillingIdList.forEach(item => { item.selected = ''; });
    this.cacheService.tosList.forEach(item => { item.selected = ''; });
    this.cacheService.msrnProviderBillingIdList.forEach(item => {item.selected = '';});

    this.countryList = JsonHelper.cloneArray(this.cacheService.countryList, Country);
    this.filteredCountryList = this.countryList;
    this.providerBillingIdList = JsonHelper.cloneArray(this.cacheService.providerBillingIdList, ProviderBillingId);
    this.filteredProviderBillingIdList = this.providerBillingIdList;
    this.tosList = JsonHelper.cloneArray(this.cacheService.tosList, TosTosDesc);
    this.filteredTOSList = this.tosList;
    this.msrnProviderBillingIdList = JsonHelper.cloneArray(this.cacheService.msrnProviderBillingIdList, MsrnProviderBillingId);
    this.msrnFilteredProviderBillingIdList = this.msrnProviderBillingIdList;
    this.getAllCountryGroups();
  }

  isLoading() {
    return this.rangeQueryStatus && this.rangeQueryStatus.startsWith('Loading');
  }

  limitMaxValue(event: any) {
    if (event.value > 99) {
      event.value = 99;
    }
  }

  async toggleDataSource(event: any) {
    if (event.currentTarget.checked) {
      this.dataSource = 'IPRN';
    } else {
      this.dataSource = 'Range';
      this.rangeFilter.numOfMonthsSinceLastObserved = undefined;
    }
    this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    this.fetchRangeData(ActionName.NoAction);
  }

  async stopFetching() {
    await AppConstants.delay(2000);
    this.stopBatchLoading = true;
  }

  async fetchRangeData(actionName?: ActionName, hasFilter?: boolean, 
                      rule?: RuleDefinition, auditData? : AuditPartition) : Promise <void> {
    if (!hasFilter) {
      this.createRangeFilter();
    }
    this.rule = rule;
    this.pageNo = 0;
    this.filteredRangeDataList = [];
    this.filteredRawDataList = [];
    this.rangeQueryStatus = '';
    this.rangeFilter.pageNo = this.pageNo;
    this.rangeFilter.limit = AppConstants.pageSize;
    this.log.debug(JsonHelper.toJson(this.rangeFilter));
   // console.log(JsonHelper.toJson(auditData))
    this.stopBatchLoading = false;
    var dataObj = {
      action: actionName,
      data: this.rangeFilter
    };
    this.filterActionName = actionName;
    if (this.dataSource === 'IPRN') {
      this.queryURL = AppConstants.QueryPremiumPageURL;
    } else {
      this.queryURL = AppConstants.QueryRangeNdcPageURL;
    }
    //#2 US2036
    if(auditData) {
      dataObj['auditPartitionData'] = auditData;
      this.auditPartitionData = auditData;
    }
  this.httpService.post(JsonHelper.toJson(dataObj), this.queryURL, [this.postQueryRangeNDCHandler]);
  }

  postQueryRangeNDCHandler = (responseJson: ResponseJson) => {
    let actionName =  this.filterActionName;
    var dataObj = {
      action: this.filterActionName,
      data: this.rangeFilter
    };
    //#2 US2036
    if(this.auditPartitionData) {
      dataObj['auditPartitionData'] = this.auditPartitionData;
    }

    if (responseJson.status === AppConstants.SUCCESS) {
      this.log.debug('response: ' + responseJson.last + ',' + responseJson.totalCount +
        ',' + responseJson.totalPage + ',' + responseJson.pageNumber);
      const rangeNDCList: Array<RangeNDC> = JsonHelper.parseArray(responseJson.data, RangeNDC);
      //add dial pattern field
      if (this.rule) {
        rangeNDCList.forEach(item => {
          item.dataSource = this.rule.dataSource;
          if (this.rule.dataSource === 'Range') {
            item.dialPattern = item.ccNdc;
          } else {
            switch (this.rule.dialPatternType) {
              case 'PRIME-1':
                item.dialPatternType = 'Prime-1';
                item.dialPattern = item.primeMinus1;
                break;
              case 'PRIME-2':
                if (item.primeMinus2) {
                  item.dialPatternType = 'Prime-2';
                  item.dialPattern = item.primeMinus2;
                } else {
                  item.dialPatternType = 'Prime-1';
                  item.dialPattern = item.primeMinus1;
                }
                break;
              case 'PRIME-3':
                if (item.primeMinus3) {
                  item.dialPatternType = 'Prime-3';
                  item.dialPattern = item.primeMinus3;
                } else if (item.primeMinus2) {
                  item.dialPatternType = 'Prime-2';
                  item.dialPattern = item.primeMinus2;
                } else {
                  item.dialPatternType = 'Prime-1';
                  item.dialPattern = item.primeMinus1;
                }
                break;
              case 'PRIME-4':
                if (item.primeMinus4) {
                  item.dialPatternType = 'Prime-4';
                  item.dialPattern = item.primeMinus4;
                } else if (item.primeMinus3) {
                  item.dialPatternType = 'Prime-3';
                  item.dialPattern = item.primeMinus3;
                } else if (item.primeMinus2) {
                  item.dialPatternType = 'Prime-2';
                  item.dialPattern = item.primeMinus2;
                } else {
                  item.dialPatternType = 'Prime-1';
                  item.dialPattern = item.primeMinus1;
                }
                break;
              default:
            }
          }
          if (item.dialPatternType) {
            item.dataSource = this.rule.dataSource + ' ' + item.dialPatternType;
          }
        })
      }

      this.filteredRangeDataList = this.filteredRangeDataList.concat(rangeNDCList);
      this.filteredRawDataList = this.filteredRawDataList.concat(rangeNDCList);

      this.log.debug('query range ndc:' + this.filteredRangeDataList.length);
      if (responseJson.last) {
        this.log.debug('reached the last page: ' + this.filteredRangeDataList.length);
        this.rangeQueryStatus = 'Total records ' + this.filteredRangeDataList.length;
        this.stopBatchLoading = true;
      }
      else if (!responseJson.last && this.stopBatchLoading) {
        this.rangeQueryStatus = this.filteredRangeDataList.length + ' of ' + responseJson.totalCount;
      }
      else if (!this.hasFilter()) {
        this.rangeQueryStatus = 'No filter defined, only show ' + this.filteredRawDataList.length + ' of ' + responseJson.totalCount;
      }
      else if (responseJson && responseJson.pageNumber >= 0 && !this.stopBatchLoading) {
        this.rangeFilter.pageNo = responseJson.pageNumber + 1;
        this.httpService.post(JsonHelper.toJson(dataObj), this.queryURL, [this.postQueryRangeNDCHandler]); //TODO
        this.rangeQueryStatus = 'Loading ....' + this.filteredRangeDataList.length + ' of ' + responseJson.totalCount;
        console.log("Loading Status: Records: " + this.filteredRangeDataList.length + " of " + responseJson.totalCount + "  -->  Pages: " + this.rangeFilter.pageNo + " of " + responseJson.totalPage);
      }
    }
  }

  hasFilter(): boolean {
    if (this.rangeFilter && (this.rangeFilter.codeList.length > 0 || this.rangeFilter.iso2List.length > 0
      || this.rangeFilter.providerList.length > 0 || this.rangeFilter.tosDescList.length > 0 || this.rangeFilter.numOfMonthsSinceLastObserved)) {
      return true;
    }

    return false;
  }

  createRangeFilter() {
    this.rangeFilter.removeAllCountry();
    this.rangeFilter.removeAllCode();
    this.rangeFilter.removeAllISO2();
    this.rangeFilter.removeAllTos();
    this.rangeFilter.removeAllProvider();

    this.countryList.forEach(item => {
      if (item.selected) {
        this.rangeFilter.addCountry(item);
        this.rangeFilter.addIso2(item.iso2);
      }
    });

    if (this.providerBillingIdList.length > 0 ) {// && this.filteredProviderBillingIdList.length > 0) {
      this.providerBillingIdList.forEach(item => {
        if (item.selected) {
          let myProvider = JsonHelper.clone(item, ProviderBillingId);
          myProvider.selected = null;
          this.rangeFilter.addProvider(myProvider);
        }
      });
    } 
    if (this.msrnProviderBillingIdList.length > 0 ) { //&& this.msrnFilteredProviderBillingIdList.length > 0) {
      this.msrnProviderBillingIdList.forEach(item => {
        if (item.selected) {
          let myProvider = JsonHelper.clone(item, MsrnProviderBillingId);
          myProvider.selected = null;
          this.rangeFilter.addProvider(myProvider);
        }
      });
    }

    this.tosList.forEach(item => {
      if (item.selected) {
        let myTOS = JsonHelper.clone(item, TosTosDesc);
        myTOS.selected = null;
        this.rangeFilter.addTos(myTOS);
      }
    });

    return;
  }

  filterCountry(countryFilter: string) {
    if (this.selectedCounter.provider || this.selectedCounter.country < 1) {
      this.filteredCountryList = [];
    }
   const filter = new RegExp('^'+countryFilter, 'i');
   this.searchCountry(filter);
  }

  filterCountryOnly(countryFilter: string, countryFilterLabel: string) {
    this.patternSearch = countryFilter;
    this.countryFilterLabel = countryFilterLabel;
    this.alphaSearchPattern.forEach(e => e.selected = false);
    this.groupSelected = false;

    if (this.selectedCounter.provider || this.selectedCounter.country < 1) {
      this.filteredCountryList = [];
    }
    if (this.selectedCounter.provider) {
      countryFilter = '';
    } else {
      this.alphaSearchPattern.find(e => e.pattern === countryFilter).selected = true;
    }

    const filter = new RegExp(countryFilter, 'i');
    this.searchCountry(filter, true);
  }
  searchCountry(filter: any, searchCountryOnly?: boolean) {
    let validCountryList = [];
    this.filteredCountryList = [];
    this.blockingCountrySearch = false;

    if (this.selectedCounter.provider > 0) {
      this.providerBillingIdList.forEach(item => {
        this.cacheService.countryProviderList.forEach(rangeItem => {
          if (item.selected && item.provider === rangeItem.provider) {
            validCountryList.push(rangeItem.iso2);
          }
        });
      });
    }

    if (validCountryList.length > 0) {
      this.blockingCountrySearch = true;
      this.countryList.forEach(item => {
        if (item.selected) {
          this.filteredCountryList.push(item);
        }
        else if (validCountryList.indexOf(item.iso2) >= 0) {
          if (searchCountryOnly) {
            if (filter.test(item.country) && this.filteredCountryList.indexOf(item) < 0) {
              this.filteredCountryList.push(item);
            }
          } else {
            if (this.filteredCountryList.indexOf(item) < 0 && (filter.test(item.country) || filter.test(item.iso2) || filter.test(item.code))) {
              this.filteredCountryList.push(item);
            }
          }
        }
      });
    } else {
      this.countryList.forEach(item => {
        if (searchCountryOnly) {
          if (filter.test(item.country) && this.filteredCountryList.indexOf(item) < 0) {
            this.filteredCountryList.push(item);
          }
        } else {
          if (this.filteredCountryList.indexOf(item) < 0 && (filter.test(item.country) || filter.test(item.iso2) || filter.test(item.code))) {
            this.filteredCountryList.push(item);
          }
        }
      });
    }
    this.sortFilteredData(this.filteredCountryList,'country')
  }

  filterProvider(value: string, filterProviderLabel: string) {
    this.patternSearch = value;
    this.filterProviderLabel = filterProviderLabel;
    this.alphaNumSearchPattern.forEach(e => e.selected = false);

    if (this.selectedCounter.country || this.selectedCounter.provider < 1) {
      this.filteredProviderBillingIdList = [];
    }
    // Comment the if loop for bug #  - 2994
    /* if (this.selectedCounter.country) {
      value = '';
    } else {
      this.alphaNumSearchPattern.find(e => e.pattern === value).selected = true;
    } */
    this.alphaNumSearchPattern.find(e => e.pattern === value).selected = true;
    const filter = new RegExp(value, 'i');

    this.searchProvider(filter);
  }

  filterProviderSearch(value: string) {
    if (this.selectedCounter.country || this.selectedCounter.provider < 1) {
      this.filteredProviderBillingIdList = [];
    }
    const filter = new RegExp(value, 'i');
    this.searchProvider(filter);
  }

  searchProvider(filter: any) {
    let validProviderList = [];
    this.filteredProviderBillingIdList = [];
    this.blockingProviderSearch = false;
    if (this.selectedCounter.country > 0) {
      this.countryList.forEach(item => {
        this.cacheService.countryProviderList.forEach(rangeItem => {
          if (item.selected && item.iso2 === rangeItem.iso2) {
            validProviderList.push(rangeItem.provider);
          }
        });
      });
    }

    if (validProviderList.length > 0) {
      this.blockingProviderSearch = false;
      this.providerBillingIdList.forEach(item => {
        // if (item.selected) {
        //   this.filteredProviderBillingIdList.push(item);
        // }
        // else 
        if (validProviderList.indexOf(item.provider) >= 0 && this.filteredProviderBillingIdList.indexOf(item) < 0) {
          if (filter.test(item.provider)) {
            this.filteredProviderBillingIdList.push(item);
          }
        }
      });
    } else {
      this.providerBillingIdList.forEach(item => {
        if (filter.test(item.provider) && this.filteredProviderBillingIdList.indexOf(item) < 0) {
          this.filteredProviderBillingIdList.push(item);
        }
      });
    }
    this.sortFilteredData(this.filteredProviderBillingIdList,'provider')
  }


  filterTos(value: string, filterTosLabel: string) {
    this.patternSearch = value;
    this.filterTosLabel = filterTosLabel;
    this.tosSearchPattern.forEach(e => e.selected = false);
    this.tosSearchPattern.find(e => e.pattern === value).selected = true;
    this.selectedTos = value.toLowerCase();
    this.filteredTOSList = [];
    const filter = new RegExp(value, 'i');

    this.tosList.forEach(item => {
      if (filter.test(item.tos)) {
        this.filteredTOSList.push(item);
      }
    });
    this.sortFilteredData(this.filteredTOSList,'tos')
  }


  hasTosItem(tosList: Array<TosTosDesc>, myTos: TosTosDesc): boolean {
    let hasItem = false;
    tosList.forEach(item => {
      if (item.tos === myTos.tos && item.tosdesc === myTos.tosdesc) {
        hasItem = true;
        return true;
      }
    });

    return hasItem;
  }

  filterTosDesc(value: string) {
    this.filteredTOSList = [];
    const filter = new RegExp(value, 'i');
    const tosFilter = new RegExp(this.patternSearch, 'i');

    this.tosList.forEach(item => {
      if (filter.test(item.tosdesc)) {
        this.filteredTOSList.push(item);
      }
    });
    this.sortFilteredData(this.filteredTOSList,'tos')
  }

  createFilterFromRule(rule: RuleDefinition, exported: boolean =false ) {
    this.selectedCounter = { country: 0, tos: 0, provider: 0, rule: 0 };
    this.rangeFilter = JsonHelper.parse(rule.rangeQueryFilter, RangeFilter);

    //Country list doesn't change, so exported partitions can still use the master list  to display
    //Look for DFT 300 for comments on country list
    this.countryList.forEach(item => {
      if (this.rangeFilter.hasISO2(item.iso2)) {
        this.toggleSelection(item);
      } else {
        item.selected = null;
      }
    });
    //TOS: TOS description changes as well as provider list also changes often, so refer to
    //rule definition only (not the master list for exported partition - DFT 300, 299)
    if(!exported) {
      this.tosList.forEach(item => {
        if (this.rangeFilter.hasTOSDesc(item)) {
          this.toggleSelection(item);
        } else {
          item.selected = null;
        }
      });
      this.providerBillingIdList.forEach(item => {
        if (this.rangeFilter.hasProvider(item.provider)) {
          this.toggleSelection(item);
        } else {
          item.selected = null;
        }
      });
    }else {
      this.tosList =[];
      this.providerBillingIdList = [];
      let exportedTosList : TosTosDesc[] = [];
      let exportedProviderBillingIdList: ProviderBillingId[] = [];

      //Tos: Tosdesc list
      exportedTosList = this.rangeFilter.tosDescList.filter(item => this.tosList.indexOf(item) == -1)
      exportedTosList.forEach(rs=>{
        rs['selected'] = this.selectedColor;
        this.tosList.push(rs);
      });
      // Provider list
      this.selectedCounter.tos = this.selectedCounter.tos + exportedTosList.length;
      exportedProviderBillingIdList = this.rangeFilter.providerList;
      exportedProviderBillingIdList.forEach(rs=>{
        rs['selected']  = this.selectedColor;
        this.providerBillingIdList.push(rs);
      });
      this.selectedCounter.provider = this.selectedCounter.provider + exportedProviderBillingIdList.length;
    }
  }

  toggleSelection(item: any, isActive = true) {
    if (!isActive) {
      return;
    }

    item.selected = item.selected === this.selectedColor ? '' : this.selectedColor;

    if (item instanceof Country) {
      if (item.selected) {
        this.selectedCounter.country++;
      } else {
        this.selectedCounter.country--;
      }
    }
    else if (item instanceof ProviderBillingId) {
      if (item.selected) {
        this.selectedCounter.provider++;
      } else {
        this.selectedCounter.provider--;
      }
    }
    else if (item instanceof MsrnProviderBillingId) {
      if (item.selected) {
        this.selectedMsrnCounter.msrnProvider++;
      } else {
        this.selectedMsrnCounter.msrnProvider--;
      }
    }
    else if (item instanceof TosTosDesc) {
      if (item.selected) {
        this.selectedCounter.tos++;
      } else {
        this.selectedCounter.tos--;
      }
    } else if (item instanceof FileLayoutField) {
      //console.log('inside toggle for item: ' + item.displayName + '|' + item.selected);
    }
    else {
      if (item.selected) {
        this.selectedCounter.rule++;
      } else {
        this.selectedCounter.rule--;
      }
    }

    return false;
  }

  selectAll(type: String) {
    switch (type) {
      case 'country':
        this.filteredCountryList.forEach(item => {
          if (!item.selected) {
            item.selected = this.selectedColor;
            this.selectedCounter.country++;
          }
        });
        break;
      case 'provider':
        this.filteredProviderBillingIdList.forEach(item => {
          if (!item.selected) {
            item.selected = this.selectedColor;
            this.selectedCounter.provider++;
          }
        });
        break;
      case 'tos':
        this.filteredTOSList.forEach(item => {
          if (!item.selected) {
            item.selected = this.selectedColor;
            this.selectedCounter.tos++;
          }
        });
        break;
      default:
    }

  }

  showSelected(type: String) {
    switch (type) {
      case 'country':
        this.filteredCountryList = [];
        this.countryList.forEach(item => {
          if (item.selected) {
            this.filteredCountryList.push(item);
          }
        });
        break;
      case 'provider':
        this.filteredProviderBillingIdList = [];
        this.providerBillingIdList.forEach(item => {
          if (item.selected) {
            this.filteredProviderBillingIdList.push(item);
          }
        });
        break;
      case 'tos':
        this.filteredTOSList = [];
        this.tosList.forEach(item => {
          if (item.selected) {
            this.filteredTOSList.push(item);
          }
        });
        break;
      default:
    }
  }

  clearSelected(type: string) {
    switch (type) {
      case 'country':
        this.countryList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.country = 0;
        this.rangeFilter.removeAllCode();
        this.rangeFilter.removeAllISO2(); // when clear all on country is done, not clearing ISO2 display in filter - Fixed as defect 3036 or DTSK 2069
        this.filteredCountryList = this.countryList;
        this.filterCountryOnly('^[a,b,c]', 'A-C');
        break;
      case 'provider':
        //if (this.filteredProviderBillingIdList.length > 0) {
          this.providerBillingIdList?.forEach(item => {
            item.selected = '';
          });
          this.selectedCounter.provider = 0;
          this.rangeFilter.removeAllProvider();
          this.filteredProviderBillingIdList = this.providerBillingIdList;
          this.filterProvider('^[0-9]', '0-9');
        //}
        //if (this.msrnFilteredProviderBillingIdList.length > 0){
          this.msrnProviderBillingIdList?.forEach(item => {
            item.selected = '';
          });
          this.selectedMsrnCounter.msrnProvider = 0;
          this.rangeFilter.removeAllProvider();
          this.msrnFilteredProviderBillingIdList = this.msrnProviderBillingIdList;
          this.msrnFilterProvider('^[0-9]', '0-9');
        //}
        break;
      case 'tos':
        this.tosList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.tos = 0;
        this.rangeFilter.removeAllTos();
        this.filteredTOSList = this.tosList;
        this.filterTos('f', 'F');
        break;
      default:
    }
  }


  clearAll() {
    this.rangeFilter = new RangeFilter();
    this.clearSelected('country');
    this.clearSelected('tos');
    this.clearSelected('provider');
  }

  openFilter(name: string, readOnly: boolean = false) {
    this.filterBy = name;
    if (this.countryList.length < 1) {
      this.refreshCache();
    }
    $('.search-query').val('');
    $('.filter').hide();

    if (readOnly) {
      $('#r' + name).show();
    } else {
      $('#' + name).show();
    }

    switch (name) {
      case 'country':
        if(this.groupSelected)
          this.filterCountryByGroupSelect(this.cGroups[0]);
        else 
          this.filterCountryOnly('^[a,b,c]', 'A-C');
        break;
      case 'provider':
        this.filterProvider('^[0-9]', '0-9');
        break;
      case 'tos':
        this.filterTos('f', 'F');
        break;
      default:
    }
  }

  getTipContents(filterValue: string, type: string): string {
    let result = '';
    const rangeQueryFilter = JsonHelper.parse(filterValue, RangeFilter);

    switch (type) {
      case 'country':
        result = rangeQueryFilter.iso2List.join(',');
        break;
      case 'provider':
        let providers = [];
        rangeQueryFilter.providerList.forEach(item => {
          providers.push(item.provider);
        });
        result = providers.join(',');
        break;
      case 'tos':
        let tosCodes = [];
        rangeQueryFilter.tosDescList.forEach(item => {
          if (tosCodes.indexOf(item.tos) < 0) {
            tosCodes.push(item.tos);
          }
        });
        result = tosCodes.join(',');
        break;
      default:
    }

    if (result.length > 15) {
      result = result.substring(0, 15) + '...';
    }

    if (result.length < 1) {
      result = '0 selected';
    }
    return result;
  }

  getSeletionValues(type: string , toggle : boolean = false, selectAll : boolean =false): string {
    let result = '';
    this.createRangeFilter();

    switch (type) {
      case 'country':
        let countries = [];
        this.rangeFilter.countryList.forEach(item => {
          countries.push(item.country + ' ' + item.code + ' ' + item.iso2);
        });
        if (toggle){
          result = countries.join(';');
        } else {
          if(countries.length <= 6)
          result = countries.join(';');
            else
          result = countries[0] + ';' + countries[1] + ';' + countries[2] + ';' + countries[3] + ';' + countries[4]  + ';' +
                    countries[5] + '  and   ' +(countries.length - 6) + '    more...';
        }
        
        break;
      case 'provider':
        let providers = [];
        this.rangeFilter.providerList.forEach(item => {
          providers.push(item.provider);
        });
        if (toggle) {
            result = providers.join(';');
        } else {
          if(providers.length <= 6)
          result = providers.join(';');
             else
          result = providers[0] + ';' + providers[1] + ';' + providers[2] + ';' + providers[3] + ';' + providers[4]  + ';' +
                   providers[5] +  '  and   ' + (providers.length - 6 )  +'    more...';
        }
       
        break;
      case 'tos':
        let tosCodes = [];
        let filterTosSplitList = this.rangeFilter.tosDescList.reduce( (acc, obj) => {
          acc[obj.tos] = acc[obj.tos] || [];
          acc[obj.tos].push(obj);
          return acc;
        }, {});
          //[{F,abc}, {F,def}, {F,ghi},{M,abc}, {M,def}, {M,ghi}] --> {F: [{F,abc}, {F,def}, {F,ghi}] ,M: [{M,abc}, {M,def}, {M,ghi}] }
          let fullTosSplitList = this.tosList.reduce( (acc, obj) => {
            acc[obj.tos] = acc[obj.tos] || [];
            acc[obj.tos].push(obj);
            return acc;
          }, {});


          // console.log('filterTosSplitList' + JSON.stringify(filterTosSplitList));
          // console.log('fullTosSplitList' + JSON.stringify(fullTosSplitList));
        this.rangeFilter.tosDescList.forEach(item => {
          let selectedTosXDescList = filterTosSplitList[(item.tos)]
          let fullTosXDescList = fullTosSplitList[(item.tos)]
          if(selectedTosXDescList?.length === fullTosXDescList?.length)
            if (this.fullTOSList.indexOf(item.tos) < 0) {
              this.fullTOSList.push(item.tos)
            }
            //TOS selected but not complete list
          if (selectedTosXDescList?.length < fullTosXDescList?.length) {
            const index = this.fullTOSList.indexOf(item.tos);
            if (index !== -1) {
              this.fullTOSList.splice(index, 1);
            }
          }
        });
        //TOS got completely unselected
        this.fullTOSList.forEach(item => {
          let selectedTosXDescList = filterTosSplitList[(item)]
          if (selectedTosXDescList == undefined || selectedTosXDescList?.length === 0 ) {
            const index = this.fullTOSList.indexOf(item);
            this.fullTOSList.splice(index, 1);
          }
        });
        //console.log('fullTOSList' + JSON.stringify(this.fullTOSList));
        this.rangeFilter.tosDescList.forEach(item => {
          if (tosCodes.indexOf(item.tos) < 0 && !this.fullTOSList.includes(item.tos)) {
              tosCodes.push(item.tos + ':' + (item.tosdesc == "" ? "Unallocated" : item.tosdesc ));
          }
        });
        if(tosCodes.length == 0)
          tosCodes = this.fullTOSList;
        else
          tosCodes = tosCodes.concat(this.fullTOSList);
        if (toggle) {
          result = tosCodes.join(';') 
        } else {
          if(tosCodes.length <= 6)
          result = tosCodes.join(';');
        else
          result = tosCodes[0] + ';' + tosCodes[1] + ';' + tosCodes[2] + ';' + tosCodes[3] + ';' + tosCodes[4]  + ';' + 
                   tosCodes[5] + '  and   ' + (tosCodes.length - 6 )  +'    more...';
        }
        
        break;
      default:
    }

    /*if (result.length > 200) {
      result = result.substring(0, 200) + '...';
    }*/

    return result;
  }

  //handle browser refresh
  async refreshCache() {
    this.cacheService.cacheCountry();
    this.cacheService.cacheProviders();
    this.cacheService.cacheTos();
    this.cacheService.cacheCountryProvider();
    this.cacheService.cacheMsrnProviders();
    await AppConstants.delay(2000);

    this.countryList = this.cacheService.countryList;
    this.filteredCountryList = this.countryList;
    this.providerBillingIdList = this.cacheService.providerBillingIdList;
    this.filteredProviderBillingIdList = this.providerBillingIdList;
    this.tosList = this.cacheService.tosList;
    this.filteredTOSList = this.tosList;
    this.msrnProviderBillingIdList = this.cacheService.msrnProviderBillingIdList;
    this.msrnFilteredProviderBillingIdList = this.msrnProviderBillingIdList;
  }

  getFileName(): string {
    if (this.dataSource === 'IPRN') {
      return 'MID_IPRN';
    } else {
      return 'MID_GlobalRange';
    }
  }

  getExportColumnNames(): Array<String> {
    let columns: Array<String> = [];

    if (this.dataSource === 'IPRN') {
      columns = columns.concat([
        'TermCountry',
        'Prime_Minus_1',
        'Prime_Minus_2',
        'Prime_Minus_3',
        'Prime_Minus_4'
      ]);
    } else {
      columns.push('Country');
    }

    columns = columns.concat([
      'CountryCode',
      'ISO2',
      'TOS',
      'TOSDesc',
      'NDC',
      'Provider',
      'Supplement',
      'LEMin',
      'LEMax',
    ]);

    if (this.dataSource === 'IPRN') {
      columns.push('LastObserved');
    } else {
      columns.push('EffDate');
    }

    return columns;
  }

  getKeys(): Array<String> {
    let keys: Array<String> = [];
    keys.push('termCountry');
    if (this.dataSource === 'IPRN') {
      keys = keys.concat([
        'primeMinus1',
        'primeMinus2',
        'primeMinus3',
        'primeMinus4'
      ]);
    }

    keys = keys.concat([
      'code',
      'iso2',
      'tos',
      'tosdesc',
      'ndc',
      'provider',
      'supplement',
      'LEMin',
      'LEMax',
    ]);

    if (this.dataSource === 'IPRN') {
      keys.push('lastUpdate');
    } else {
      keys.push('effectiveDate');
    }


    return keys;
  }

  hasCountry(countryList: Array<Country>, myCountry: Country) {
    for (let item of countryList) {
      if (item.country === myCountry.country) {
        return true;
      }
    }
    return false;
  }

  hasProvider(providerList: Array<ProviderBillingId>, myProvider: ProviderBillingId) {
    for (let item of providerList) {
      if (item.provider === myProvider.provider) {
        return true;
      }
    }
    return false;
  }

  hasMsrnProvider(msrnProviderList: Array<MsrnProviderBillingId>, myProvider: MsrnProviderBillingId) {
    for (let item of msrnProviderList) {
      if (item.provider === myProvider.provider) {
        return true;
      }
    }
    return false;
  }

  hasTosTosDesc(tosList: Array<TosTosDesc>, myTOS: TosTosDesc) {
    for (let item of tosList) {
      if (item.tos === myTOS.tos && item.tosdesc === myTOS.tosdesc) {
        return true;
      }
    }
    return false;
  }

  togglePanel(index) {
    this.selectedPanel = index;
  }

  toggleMore(type:string){
    switch (type) {
      case 'country':
          this.showMoreCountry = !this.showMoreCountry;
          break;
      case 'provider':
        this.showMoreProvider = !this.showMoreProvider;
        break;  
      case 'tos':
        this.showMoreTos = ! this.showMoreTos;  
        break;   
    }
  }

  clearSelectedFilter(type: string , filterLabel :string){
    let regPattern;
    switch (type) {
      case  'country' :
        if(this.groupSelected) {
          let cGroup = this.cGroups.find(cg => cg.name === filterLabel);
          let iso2List = cGroup.value['iso2List'];
          if(iso2List && iso2List.length >0) {
            let totalCountriesRemoved = 0
            iso2List.forEach ( iso2 => {
              let cList = this.filteredCountryList.filter(item => {
                if (item?.selected === 'filter-selected' && iso2 === item.iso2) 
                { 
                  totalCountriesRemoved++; item.selected = ''; return item; 
                }
              });
            });
            this.selectedCounter.country = this.selectedCounter.country - totalCountriesRemoved;
          } 
          this.rangeFilter.removeAllCode();
          this.countryFilterLabel = this.cGroups[0]? this.cGroups[0].name : '';
          this.filterCountryByGroupSelect(this.cGroups[0]);
        } else {
          if (filterLabel === 'A-C') {
            regPattern = new RegExp("^[ABCabc]");
          } else if (filterLabel ===  'D-F') {
            regPattern = new RegExp("^[DEFdef]");
          }else if (filterLabel ===  'G-I') {
            regPattern = new RegExp("^[GHIghi]");
          }else if (filterLabel ===  'J-L') {
            regPattern = new RegExp("^[JKLjkl]");
          }else if (filterLabel ===  'M-O') {
            regPattern = new RegExp("^[MNOmno]");
          }else if (filterLabel ===  'P-R') {
            regPattern = new RegExp("^[PQRpqr]");
          }else if (filterLabel ===  'S-U') {
            regPattern = new RegExp("^[STUstu]");
          }else if (filterLabel ===  'V-Z') {
            regPattern = new RegExp("^[VWXYZvwxyz]");
          }
          let totalCountriesRemoved = 0
          this.countryList.forEach(item => {
            if (regPattern.test (item.country)){ 
              if (item?.selected === 'filter-selected') 
              { 
                totalCountriesRemoved++; item.selected = '';
              }
            }
          });
          this.selectedCounter.country = this.selectedCounter.country - totalCountriesRemoved;
          this.rangeFilter.removeAllCode();
          if (this.selectedCounter.country === 0)
            this.rangeFilter.removeAllISO2();
          this.filteredCountryList = this.countryList;
          this.filterCountryOnly('^[a,b,c]', 'A-C');
        }
        if ( this.selectedCounter.country <= 0) this.selectedCounter.country = 0;
        //console.log(this.selectedCounter.country)
        break;
      case 'tos':
        if (filterLabel === 'F') {
          regPattern = new RegExp("^[Ff]");
        }else if (filterLabel === 'G') {
          regPattern = new RegExp("^[Gg]");
        }else if (filterLabel === 'M') {
          regPattern = new RegExp("^[Mm]");
        }else if (filterLabel === 'P') {
          regPattern = new RegExp("^[Pp]");
        }else if (filterLabel === 'S') {
          regPattern = new RegExp("^[Ss]");
        }else if (filterLabel === 'T') {
          regPattern = new RegExp("^[Tt]");
        }else if (filterLabel === 'U') {
          regPattern = new RegExp("^[Uu]");
        }else if (filterLabel === 'V') {
          regPattern = new RegExp("^[Vv]");
        }  
        let totalTosRemoved = 0;
        this.tosList.forEach(item => {
          if (regPattern.test (item.tos)){
            if (item?.selected === 'filter-selected')
              {
                item.selected = '';
                totalTosRemoved++;
                const index: number = this.rangeFilter.tosDescList.indexOf(item);
                if (index !== -1) {
                  this.rangeFilter.tosDescList.splice(index, 1);
                }
              }
            }
        });
        this.selectedCounter.tos = this.selectedCounter.tos - totalTosRemoved;
        this.filteredTOSList = this.tosList;
        this.filterTos('f', 'F');
        if ( this.selectedCounter.tos<= 0){
          this.selectedCounter.tos = 0;
          this.rangeFilter.removeAllTos();
        }
        break;
      case 'provider':
        //console.log(filterLabel);
      if (filterLabel === 'Symb') {
         regPattern = new RegExp("^[^0-9a-zA-Z]");
      } else if (filterLabel === '0-9') {
        regPattern = new RegExp("^[0-9]");
      } else if (filterLabel === 'A') {
        regPattern = new RegExp("^[Aa]");
      } else if (filterLabel === 'B'){
        regPattern = new RegExp("^[Bb]");
      }else if (filterLabel === 'C'){
        regPattern = new RegExp("^[Cc]");
      } else if (filterLabel === 'D'){
        regPattern = new RegExp("^[Dd]");
      }else if (filterLabel === 'E'){
        regPattern = new RegExp("^[Ee]");
      }else if (filterLabel === 'F') {
        regPattern = new RegExp("^[Ff]");
      }else if (filterLabel === 'G') {
        regPattern = new RegExp("^[Gg]");
      }else if (filterLabel === 'H'){
        regPattern = new RegExp("^[Hh]");
      }else if (filterLabel === 'I'){
        regPattern = new RegExp("^[Ii]");
      }else if (filterLabel === 'J') {
        regPattern = new RegExp("^[Jj]");
      }else if (filterLabel === 'K'){
        regPattern = new RegExp("^[Kk]");
      }else if (filterLabel === 'L'){
        regPattern = new RegExp("^[Ll]");
      }else if (filterLabel === 'M'){
        regPattern = new RegExp("^[Mm]");
      }else if (filterLabel === 'N') {
        regPattern = new RegExp("^[Nn]");
      }else if (filterLabel === 'O') {
        regPattern = new RegExp("^[Oo]");
      }else if (filterLabel === 'P'){
        regPattern = new RegExp("^[Pp]");
      }else if (filterLabel === 'Q'){
        regPattern = new RegExp("^[Qq]");
      }else if (filterLabel === 'R'){
        regPattern = new RegExp("^[Rr]");
      }else if (filterLabel === 'S'){
        regPattern = new RegExp("^[Ss]");
      }else if (filterLabel === 'T') {
        regPattern = new RegExp("^[Tt]");
      }else if (filterLabel === 'U'){
        regPattern = new RegExp("^[Uu]");
      }else if (filterLabel === 'V') {
        regPattern = new RegExp("^[Vv]");
      }else if (filterLabel === 'W') {
        regPattern = new RegExp("^[Ww]");
      }else if (filterLabel === 'X') {
        regPattern = new RegExp("^[Xx]");
      }else if (filterLabel === 'Y'){
        regPattern = new RegExp("^[Yy]");
      }else if (filterLabel === 'Z') {
        regPattern = new RegExp("^[Zz]");
      }
      let totalProvidersRemoved = 0;
      this.providerBillingIdList.forEach(item => {
        if (regPattern.test (item.provider)){
          if (item?.selected === 'filter-selected')
          {
            totalProvidersRemoved++; item.selected = '';
            item.selected = '';
            const index: number = this.rangeFilter.providerList.indexOf(item);
            if (index !== -1) {
              this.rangeFilter.providerList.splice(index, 1);
            }
          }
        }
      });
      this.selectedCounter.provider = this.selectedCounter.provider - totalProvidersRemoved;
      this.filteredProviderBillingIdList = this.providerBillingIdList;
      this.filterProvider('^[0-9]', '0-9');
      if ( this.selectedCounter.provider <= 0){
        this.selectedCounter.provider = 0;
        this.rangeFilter.removeAllProvider();
      }
      break;
      default:   
    }
  }

  fetchMsrnData(actionName?: string, hasFilter?: boolean, rule?: RuleDefinition) {
    if (!hasFilter) {
      this.createRangeFilter();
    }
    this.rule = rule;
    this.pageNo = 0;
    this.filteredMsrnDataList = [];
    this.rangeFilter.pageNo = this.pageNo;
    this.rangeFilter.limit = AppConstants.pageSize;
    this.log.debug(JsonHelper.toJson(this.rangeFilter));

    this.stopBatchLoading = false;
    var dataObj = {
      action: actionName,
      data: this.rangeFilter
    };
    this.queryURL = AppConstants.QueryMsrnPageURL;
    this.httpService.post(JsonHelper.toJson(dataObj), this.queryURL, [this.postQueryMsrnHandler]);
  }

  postQueryMsrnHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.filteredMsrnDataList = responseJson.data;
    }
  }

  getMsrnExportColumnNames(): Array<String> {
    let columns: Array<String> = [];

    columns.push('Country');
    columns = columns.concat([
      'CountryCode',
      'MSRN_NDC',
      'MSRN_Range_start',
      'MSRN_Range_End',
      'ISO2',
      'Provider',
      'TADIG',
      'MCC_MNC'
    ]);

    return columns;
  }
  filterMsrnProviderSearch(value: string) {
    if (this.selectedCounter.country || this.selectedMsrnCounter.msrnProvider < 1) {
      this.msrnFilteredProviderBillingIdList = [];
    }
    const filter = new RegExp(value, 'i');
    this.searchMsrnProvider(filter);
  }

  searchMsrnProvider(filter: any) {
    let validMsrnProviderList = [];
    this.msrnFilteredProviderBillingIdList = [];
    this.blockingMsrnProviderSearch = false;
    if (this.selectedCounter.country > 0) {
      this.countryList.forEach(item => {
        this.cacheService.countryProviderList.forEach(rangeItem => {
          if (item.selected && item.iso2 === rangeItem.iso2) {
            validMsrnProviderList.push(rangeItem.provider);
          }
        });
      });
    }

    if (validMsrnProviderList.length > 0) {
      this.blockingMsrnProviderSearch = false;
      this.msrnProviderBillingIdList.forEach(item => {
        if (item.selected) {
          this.msrnFilteredProviderBillingIdList.push(item);
        }
        else if (validMsrnProviderList.indexOf(item.provider) >= 0 && this.msrnFilteredProviderBillingIdList.indexOf(item) < 0) {
          if (filter.test(item.provider)) {
            this.msrnFilteredProviderBillingIdList.push(item);
          }
        }
      });
    } else {
      this.msrnProviderBillingIdList.forEach(item => {
        if (filter.test(item.provider) && this.msrnFilteredProviderBillingIdList.indexOf(item) < 0) {
          this.msrnFilteredProviderBillingIdList.push(item);
        }
      });
    }
  }
  msrnFilterProvider(value: string, filterProviderLabel: string) {
    this.patternSearch = value;
    this.filterProviderLabel = filterProviderLabel;
    this.alphaNumSearchPattern.forEach(e => e.selected = false);

    if (this.selectedCounter.country || this.selectedMsrnCounter.msrnProvider < 1) {
      this.msrnFilteredProviderBillingIdList = [];
    }
    this.alphaNumSearchPattern.find(e => e.pattern === value).selected = true;
    const filter = new RegExp(value, 'i');

    this.searchMsrnProvider(filter);
  }


   //handle browser refresh
   async refreshMsrnCache() {
    this.cacheService.cacheMsrnProviders();
    await AppConstants.delay(1000);
    this.msrnProviderBillingIdList = this.cacheService.msrnProviderBillingIdList;
    this.msrnFilteredProviderBillingIdList = this.msrnProviderBillingIdList;
  }
 
  getAllCountryGroups() {
    this.httpService.get(AppConstants.QueryCountryGroupsURL, [this.postQueryCountryGroupHandler, this.responseCallBack]);
  }

  postQueryCountryGroupHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      let selectedGroup: CustomGroups;
      this.cGroups = [];
      const cGroupArray: Array<CustomGroups> = JsonHelper.parseArray(responseJson.data.customGroups, CustomGroups);
      for (const item of cGroupArray) {
        this.cGroups.push(item);
      }
  }
}

filterCountryByGroupSelect(myList: CustomGroups) {
  this.filteredCountryList = [];
  this.countryFilterLabel = ''; //start with empty label
  if( !myList) //No groups available
    return;
  this.selectedCountryGroupName=myList.name;
  this.countryFilterLabel = this.selectedCountryGroupName;
  this.cGroups.forEach(b => b.selected = false);
  myList.selected = !myList.selected;
  let cgIso2List = [];
  //console.log(JSON.stringify(myList));
  if(myList['value'] && myList['value']['iso2List']) {
    cgIso2List = myList['value']['iso2List']
    //console.log(JSON.stringify(cgIso2List));
  }

  //filter through country list for the given iso
  //this.countryList;
  cgIso2List.forEach(element => {
    let countryItem = this.countryList.find( c => c.iso2 === element);
    this.filteredCountryList.push(countryItem);
  });
 // console.log(JSON.stringify(this.filteredCountryList));
  
}

showCountryGroups() {
  this.groupSelected  = true;
  this.filteredCountryList = [];
  this.countryFilterLabel = ''
  this.alphaSearchPattern.forEach(b => b.selected = false);
  if(this.cGroups && this.cGroups.length >0) {
      this.selectedCountryGroupName=this.cGroups[0].name;
      this.countryFilterLabel = this.selectedCountryGroupName;
      this.filterCountryByGroupSelect(this.cGroups[0]);
  }
}

getSelectedCountries(): Array<Country> {
  this.createRangeFilter();

  let selectedCountries = [];
  this.rangeFilter.countryList.forEach(item => {
    selectedCountries.push(item);
  });

  return selectedCountries;
}

sortFilteredData(data,type){
  if(type){
    data.sort(function(a, b) {
      let item1 = '';
      let item2 = '';
      if(type === 'country'){
        item1 = a.country;
        item2 = b.country;
      }else if(type === 'provider'){
        item1 = a.provider;
        item2 = b.provider;
      }else if(type === 'tos'){
        item1 = a.tos;
        item2 = b.tos;
      }
      //sort
      if (item1.toLowerCase() < item2.toLowerCase()) {
        return -1;
      }
      if (item1.toLowerCase() > item2.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }
}

}