import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import '../util/rxjs-operators';
import { JsonHelper } from '../util/json.helper';
import { Logger } from '../services/logger.service';
import { AppConstants} from '../util/index';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, RuleDefinition, PartitionDefinition, PartitionDataDetails, PartitionExportHistory, ListDefinition, PartitionFileLayout } from '../models/index';
import { RangeFilter} from '../models/index';
import { ActionName } from 'app/shared/constants';
import { SortMeta } from 'primeng/api';

//const Stream = require('ts-stream').Stream;

@Injectable()
export class PartitionService {
  isActivePartition = true;
  stopFetching = false;
  showDraftDataWin = true;
  loadingExportData = false;
  selectedRule: RuleDefinition;
  selectedPartition: PartitionDefinition;
  selectedExportPartition: PartitionDefinition;
  exportHistoryId: number;
  selectedExportHistory: PartitionExportHistory;
  fileLayouts: Array<PartitionFileLayout> = [];
  blackLists: Array<ListDefinition> = [];
  whiteLists: Array<ListDefinition> = [];
  tpLists : Array<ListDefinition> = [];
  // draftData: Array<PartitionDataDetails> = [];
  filteredDraftData: Array<PartitionDataDetails> = [];
  exportData: Array<PartitionDataDetails> = [];
  fileLayoutOptions: Array<PartitionFileLayout> = [];
  blListOptions: Array<ListDefinition> = [];
  wlListOptions: Array<ListDefinition> = [];
  tpListOptions: Array<ListDefinition> = [];
  pDescription = '';
  fileLayoutId = null;
  isNewPartition = false;
  tempPartitionName = '';
  hideMessageBox = false;
  exportInProgress = false;
  exportedFromViewDialPatterns = false;
  sftpConfigured = false;
  enableAutoExport = false; //0;
  enableCreateDeltaFile = true; // 'Y'
  enableConsolidatedTargetList = false; //'N'
  dataLoadInProgress = false;
  isSelectDuringNameEdit = false;
  isEditingNameField = false;
  isSkipDupMsg = false;
  isWaitingUpdateNameResponse = false;
  exportable = false;
  draftDataTotalCount = 0;
  iso2list = [];
  tosList = [];
  providerList = [];
  ruleIdList = [];
  filterIso2list = [];
  filterTosList = [];
  filterProviderList = [];
  filterRuleIdList = [];
  ruleId = null;
  searchPattern = null;
  exportDataTotalCount = 0;
  constructor(private router: Router, private httpService: HttpService, private log: Logger, private notifService: NotificationService) { };

  getListOptions(myLists: Array<ListDefinition>, listId?) {
    const result: Array<ListDefinition> = [];
    const blankList = new ListDefinition();
    blankList.id = 0;
    if (!listId || listId === '0' || listId === 0) {
      blankList.listName = 'Select ...';
      blankList.id = listId;
    } else {
      blankList.listName = '';
    }

    result.push(blankList);

    return result.concat(myLists);
  }

  updateListOptions = () => {
    this.blListOptions = this.getListOptions(this.blackLists, this.selectedPartition.blId);
    this.wlListOptions = this.getListOptions(this.whiteLists, this.selectedPartition.wlId);
    this.tpListOptions = this.getListOptions(this.tpLists,this.selectedPartition.tpId)
    console.log("updateListOptions: After update list options", Date());                                          

  }

  getFileLayoutOptions(myLayouts: Array<PartitionFileLayout>, layoutId?) {
    const result: Array<PartitionFileLayout> = [];
    const blankLayout = new PartitionFileLayout();
    blankLayout.id = null;
    
    // This will evaluate to true for null and undefined
    if (layoutId == null) {
      blankLayout.name = 'Select ...';
    } else {
      blankLayout.name = '';
    }

    result.push(blankLayout);

    return result.concat(myLayouts);
  }

  async postAction(partitionList: Array<PartitionDefinition>) {
    await AppConstants.delay(1000);
    partitionList.forEach(partition => {
      if (this.selectedPartition && this.selectedPartition.id === partition.id) {
        this.updateSelectedPartition(partition);
      }
    });

  }

  updateSelectedPartition(partition) {
    if (partition.id) {
      this.selectedPartition.status = partition.status;
      this.selectedPartition.exportable = partition.exportable;

      this.selectedPartition.ruleDefinitions.forEach(rule => {
        const filter = JsonHelper.parse(rule.rangeQueryFilter, RangeFilter);

        rule.selectedCountry = filter.iso2List.length;
        rule.selectedTSO = filter.tosDescList.length;
        rule.selectedProvider = filter.providerList.length;
        rule.noOfMonth = filter.numOfMonthsSinceLastObserved;
      });
    }
  }

  createPartitionDraftData() {
    this.filteredDraftData = [];
    this.stopFetching = true;
    this.httpService.post(JsonHelper.toJson(this.selectedPartition), AppConstants.CreateDraftDataURL + '?id=' + this.selectedPartition.id, 
      [this.postCreateDraftDataHandler]);
  }

  postCreateDraftDataHandler = (responseJson: ResponseJson) => {
    console.info('in postCreateDraftDataHandler 0', Date());

    if (responseJson.status === AppConstants.SUCCESS) {
      console.info('In postCreateDraftDataHandler ', responseJson.message, 'Loading');
      // this.notifService.add({ severity: 'info', summary: 'generate draft data', detail: responseJson.message });
      this.selectedPartition.status = 'Loading';
    } else {
      console.log("In postCreateDraftDataHandler, received errorMsg: " + responseJson.message);
    }
  }

  getPartitionDraftData() {
    this.filteredDraftData = [];
    this.stopFetching = false;
    this.exportedFromViewDialPatterns = false;
    this.httpService.get(AppConstants.QueryDraftDataURL + '?id=' + this.selectedPartition.id + '&pageNo=0&limit=' + AppConstants.pageSize,
      [this.postQueryDraftDataHandler]);
  }

  postQueryDraftDataHandler = async(responseJson: ResponseJson) => {
    console.info('in postQueryDraftDataHandler 0', Date());
    if (responseJson.status === AppConstants.SUCCESS) {
      if (!responseJson.data) {
        console.info('in postQueryDraftDataHandler 1', responseJson.message, 'in-progress');
        // this.notifService.add({ severity: 'info', summary: 'generate draft data', detail: responseJson.message });
        this.selectedPartition.status = 'in-progress';
        return;
      }

      const result: Array<PartitionDataDetails> = JsonHelper.parseArray(responseJson.data, PartitionDataDetails);
      console.info('in postQueryDraftDataHandler 2', Date(), result.length, this.filteredDraftData.length);
      this.draftDataTotalCount=responseJson.totalCount;
      this.filteredDraftData = result;
      // if (this.stopFetching) {
      //   this.draftData = [];
      //   this.filteredDraftData = [];
      // }
      console.info('in postQueryDraftDataHandler 3', Date(), this.filteredDraftData.length);


      // if (this.draftData.length > 0 && this.showDraftDataWin) {
      //   this.router.navigate(['/partitionDataDetail']);
      // }
    }
  }

   postQueryDraftDataFilterHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      if (!responseJson.data) {
        this.selectedPartition.status = 'in-progress';
        return;
      }
      const result=responseJson.data;
      this.iso2list = result.iso2list
      this.tosList = result.tosList
      this.providerList = result.providerList
      this.ruleIdList = result.ruleIdList
      this.searchPattern = result.searchPattern
      this.stopFetching = true
    }else{
      console.log("Error:",responseJson)
    }
  }


  postQueryExportDataHandler = (responseJson: ResponseJson) => {
    //console.log('BACK to postQueryExportDataHandler');
    if (responseJson.status === AppConstants.SUCCESS) {
      if (!responseJson.data) {
        this.notifService.add({ severity: 'info', summary: 'generate export data', detail: responseJson.message });
        //this.selectedPartition.status = 'in-progress';
        return;
      }

      const result: Array<PartitionDataDetails> = JsonHelper.parseArray(responseJson.data, PartitionDataDetails);
      this.filteredDraftData = result;
      this.exportDataTotalCount=responseJson.totalCount ? responseJson.totalCount : this.filteredDraftData.length

      if (responseJson.last) {
        this.log.debug('reached the last page: ' + this.exportData.length);
        console.log("Reached the last page: " + this.exportData.length);
        this.dataLoadInProgress = false;

      }

    }
  }


  getPartitionDetail(partitionId: number) {
    this.httpService.get(AppConstants.QueryPartitionURL + partitionId, [this.postQueryPartitionHandler]);
  }

  postQueryPartitionHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedPartition = JsonHelper.parse(responseJson.data, PartitionDefinition);

      if (this.selectedPartition) {
        this.selectedPartition.ruleDefinitions.forEach(rule => {
          const filter = JsonHelper.parse(rule.rangeQueryFilter, RangeFilter);

          rule.selectedCountry = filter.iso2List.length;
          rule.selectedTSO = filter.tosDescList.length;
          rule.selectedProvider = filter.providerList.length;
          rule.noOfMonth = filter.numOfMonthsSinceLastObserved;
        });
      }
    } else {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
    }
  }

  getIPRNSourceForDisplay(dialPatternType: string) {
    return 'IPRN ' + dialPatternType;
  }

  getRuleIndex(ruleId: string) {
    if (this.selectedPartition.ruleIds) {
      const ruleIds = this.selectedPartition.ruleIds.split(',');
      return ruleIds.indexOf(ruleId) + 1;
    } else {
      return '';
    }
  }

  getRuleSetting(ruleId: string) {
    for (let rule of this.selectedPartition.ruleDefinitions) {
      if (rule.id === Number(ruleId)) {
        let result = rule.dataSource;

        if (rule.dialPatternType) {
          result += ' ' + rule.dialPatternType;
        }

        return result;
      }
    }

    return '';
  }

  getRuleSettingFromExportedPartition(ruleId: string) {
    for (let rule of this.selectedExportPartition.ruleDefinitions) {
      if (rule.id === Number(ruleId)) {
        let result = rule.dataSource;

        if (rule.dialPatternType) {
          result += ' ' + rule.dialPatternType;
        }

        return result;
      }
    }

    return '';
  }

  getExportPartitionDetail(partitionId: number, actionName?: ActionName) {
    this.loadingExportData = true;
    if(actionName === ActionName.PartitionExportLink) //US2036 #4
      this.httpService.get(AppConstants.QueryPartitionURL + partitionId +'/' + actionName, [this.postQueryExportPartitionHandler]);
    else
      this.httpService.get(AppConstants.QueryPartitionURL + partitionId, [this.postQueryExportPartitionHandler]);
  }

  postQueryExportPartitionHandler = (responseJson: ResponseJson) => {
    this.loadingExportData = false;
    this.dataLoadInProgress = false;
  
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedExportPartition = JsonHelper.parse(responseJson.data, PartitionDefinition);

      if (this.selectedExportPartition && this.selectedExportPartition.ruleDefinitions) {
        this.selectedExportPartition.ruleDefinitions.forEach(rule => {
          const filter = JsonHelper.parse(rule.rangeQueryFilter, RangeFilter);

          rule.selectedCountry = filter.iso2List.length;
          rule.selectedTSO = filter.tosDescList.length;
          rule.selectedProvider = filter.providerList.length;
          rule.noOfMonth = filter.numOfMonthsSinceLastObserved;
        });
      }

      this.selectedExportPartition.ruleDefinitions = this.selectedExportPartition.ruleDefinitions.slice();
    } else {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
    }
  }

  getRuleIndexFromExportedPartition(ruleId: string) {
    const ruleIds = this.selectedExportPartition.ruleIds.split(',');
    return ruleIds.indexOf(ruleId) + 1;
  }


  getPartitionExportColumnNames() {
    let result = [];
    result.push('DataSource');
    result.push('Country');
    result.push('DialPattern');
    result.push('DialPatternType');
    result.push('CustomerDate');
    result.push('Reason');
    result.push('Notes');
    result.push('CountryCode');
    result.push('ISO2');
    result.push('TOS');
    result.push('TOSDesc');
    result.push('NDC');
    result.push('Provider');
    result.push('Supplement');
    result.push('LEMin');
    result.push('LEMax');
    return result;
  }

  async onCreateNew(myPartition: PartitionDefinition, tempName:string) {
    //console.log("cagl: onCreateNew: (selectDuring|skip|isNew): " + this.isSelectDuringNameEdit + "|" + this.isSkipDupMsg + "|" + this.isNewPartition);
    //this.stopBatchLoading = true;
    await AppConstants.delay(1000);
    this.selectedPartition = myPartition;

    this.isNewPartition = true;
    this.tempPartitionName = tempName; 
    //console.log("in onCreateNew() isNewPartition: " + this.isNewPartition + " tempName: " + this.tempPartitionName);
  }

  getPartitionExportInProgress(partitionId: number) {
    this.httpService.get(AppConstants.QueryPartitionExportInProgress + partitionId, [this.postQueryPartitionExportInProgressHandler]);
  }

  postQueryPartitionExportInProgressHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      if (responseJson.message == 'true') {
        //disable button
        this.exportInProgress = true;
      } else {
        //enable button
        this.exportInProgress = false;
      }
    } else {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
    }
    
  }

  isExportButtonDisabled():boolean
  {
    return (this.selectedPartition.status==='Loading' || this.selectedPartition.status==='Processing' || this.selectedPartition.disabledPTB || this.exportInProgress || !this.selectedPartition.exportable);
  }

   pagination(page, limit, sortMeta:SortMeta[], globalFilter, status, func){
    this.filteredDraftData = [];

    let orderBy = sortMeta ?  sortMeta.map(s =>{ return  {property: s.field, dir: s.order == 1 ?'ASC':'DESC'} }) : null; 
    // , 
    const pageParams={
      page:page,
      size:limit,
      orderBy : orderBy
    }
    const filterParams={
      iso2list:this.filterIso2list,
      tosList: this.filterTosList,
      providerList: this.filterProviderList,
      ruleIdList : this.filterRuleIdList,
      searchPattern: globalFilter
    }
    
    const body={
      id: this.selectedPartition.id,
      ruleId: this.ruleId,
      filter: filterParams,
      page: pageParams
    } 
    
    if(status === "Draft"){
    this.httpService.post(JSON.stringify(body),AppConstants.QueryDraftDataURL, 
    [this.postQueryDraftDataHandler, func]);

    }else if(status === "Exported"){
      this.httpService.post(JSON.stringify(body),AppConstants.QueryExportDataURL, 
    [this.postQueryExportDataHandler, func]);
    }
  }

  // draft data filters
  getDraftDataFilters=async()=>{
    this.stopFetching = false
    const ruleId=this.ruleId
    this.httpService.get(AppConstants.QueryDraftDataFilterURL + '?id=' + this.selectedPartition.id +  (ruleId ?  `&ruleId=${ruleId}` : ""),
      [this.postQueryDraftDataFilterHandler]);
  }

  //export data filters
  getExportDataFilters=async()=>{
    this.stopFetching = false
    const ruleId=this.ruleId
    this.httpService.get(AppConstants.QueryExportDataFilterURL + '?id=' + this.selectedPartition.id +  (ruleId ?  `&ruleId=${ruleId}` : ""),
      [this.postQueryDraftDataFilterHandler]);
  }
}
