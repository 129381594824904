<p-confirmDialog width="425" rejectIcon="" rejectLabel="Cancel" acceptIcon="" acceptLabel="{{acceptLabel}}"></p-confirmDialog>

<div *ngIf="partitionService.selectedPartition">
    <div class="row">
        <h3 class="col-md-6 offset-md-3 text-center">
            <app-editable-field [origItem]="partitionService.selectedPartition.name" [styleClass]="styleClass" [maxLength]="25" [placeholderText]="placeholderValue" (dateModelChange)="updateName($event)" id="partitionNameField" #partitionNameField></app-editable-field>
            <small><i id="partitionInfoIcon" class="fa fa-gear" style="color: #33647f;cursor: pointer;" (click)="showToolTip();"></i></small>
        </h3>
        <div *ngIf="partitionService.selectedPartition.status==='Processing'">
            <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
        </div>
    </div>

    <!--Tooltip for list description -->
    <p-dialog header="Partition Configuration" styleClass="custom" [(visible)]="showDialog && !hideMessageBox" modal="modal" width="420" (visibleChange)="closePartitionConfig()" [style]="{'background-color':'white'}">
        <div class="dialog-close">
            <div class="row">
                <div id="partitionIdText" class="col-sm-10 toolTipText" style="padding-left:0px;color:black;margin-top: -5px;margin-bottom: 9px;font-weight:bold;">
                    Partition ID: {{partitionService.selectedPartition.origPartitionId}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: -7px;margin-bottom: 5px;font-weight:bold;">
                    Partition Description
                </div>
            </div>
            <div class="row" style="margin-top: 0px;">
                <div>
                    <textarea style="padding-left: 0px;" type="text" class="col-sm-12 toolTipText" maxlength="100" id="listDesc" name="listDesc" [(ngModel)]="partitionService.pDescription" rows="4" cols="25" > </textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: 5px;margin-bottom: 5px;font-weight:bold;">
                    File Layout
                </div>
                <div>
                    <select id="partitionFileLayoutDropdown" name="fl" [(ngModel)]="partitionService.fileLayoutId" style="width:195px">
                        <option id="{{layout.name | convertToUnderscore: 'flSelect'}}" *ngFor="let layout of partitionService.fileLayoutOptions" [ngValue]="layout.id">{{layout.name}}</option>
                    </select>
                </div>
            </div>
            <br>
        </div>
        <div class="mb-3 row">
            <p-checkbox
                [(ngModel)]="partitionService.enableCreateDeltaFile"
                name="partitionService.enableCreateDeltaFile"
                inputId="partitionService.enableCreateDeltaFile"
                label="Create Delta File"
                binary="true"
                >
            </p-checkbox>
        </div>
        <div class="mb-3 row" *ngIf="partitionService.sftpConfigured">
            <p-checkbox
                [(ngModel)]="partitionService.enableAutoExport"
                name="partitionService.enableAutoExport"
                inputId="partitionService.enableAutoExport"
                label="Enable Auto Export"
                binary="true"
                >
            </p-checkbox>
        </div>
        <div class="mb-3 row">
            <p-checkbox
                [(ngModel)]="partitionService.enableConsolidatedTargetList"
                name="partitionService.enableConsolidatedTargetList"
                inputId="partitionService.enableConsolidatedTargetList"
                label="Consolidate Target List"
                binary="true"
                >
            </p-checkbox>
        </div>
        <div class="row">
            <div class="offset-md-6 col-md-6">
                <button id="cancelPartitionConfigButton" class="btn btn-info no-uppercase no-padding pull-right" type="button" style= "width:55px;height:26px" (click)="closePartitionConfig()">Cancel</button>
                <button id="savePartitionConfigButton" class="btn btn-default no-uppercase no-padding pull-right" style= "width:55px;height:26px;margin-right:10px;" type="button" (click)="onUpdatePartitionConfig()">Save</button>
            </div>
        </div>
    </p-dialog>
    <p-dialog [(visible)] = "showThresholdMessage && !hideMessageBox" [showHeader]="false" width="800" position="top" contentStyleClass="warningDialog">
        <!--<span class="fa-stack fa-lg close-button">
            <i id="closeThresholdMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeThresholdMessage();"></i>
        </span>-->
        <span>
            <font color="white" style="font-size:13px;font-weight:bold">
                You are allowed up to {{ maxPartitions }} partitions.
            </font>
            <font color="white" style="font-size:13px;font-weight:regular">
                You have {{ remainingAllotment }} partitions remaining.
            </font>
        </span>
    </p-dialog>
    <div *ngIf="partitionService.selectedPartition">
        <p-dialog [(visible)] = "showDuplicateMessage && !hideMessageBox" modal="modal" [showHeader]="false" [style]="{width: '40vw'}" position="top" contentStyleClass="errorDialog" closable="true" closeOnEscape="true">
            <span class="col-sm-11">
                <font color="white" style="font-size:13px;font-weight:bold">
                    Duplicate Name!
                </font>
                <font color="white" style="font-size:13px;font-weight:regular">
                    There is already a partition with that name.  Please use a different name.
                </font>
            </span>
            <span class="fa-stack fa-lg close-button col-sm-1" style="cursor: pointer;">
                <i id="closeDuplicateMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeDuplicateMessage()"></i>
            </span>
        </p-dialog>
    </div>

    <div class="row w3-margin-top">
        <div class="col-md-6">
            <div class="row">
                    <div class="col-md-10">
                        <span class="list-label col-md-6 col-lg-4">Allow List</span>
                        <select id="partitionDropdownWL" name="wl" [disabled]="partitionService.selectedPartition.status==='Processing' || partitionService.selectedPartition.status==='Loading'" [(ngModel)]="partitionService.selectedPartition.wlId" (ngModelChange)="updateList('WL')"
                             class="list-value">
                            <option id="{{list.listName | convertToUnderscore: 'wlSelect'}}" *ngFor="let list of partitionService.wlListOptions" [ngValue]="list.id">{{list.listName}}</option>
                        </select>
                    </div>
            </div>
            <div class="row w3-margin-top">
                <div class="col-md-10">
                    <span class="list-label col-md-6 col-lg-4">Block List</span>
                    <select id="partitionDropdownBL" name="bl" [disabled]="partitionService.selectedPartition.status==='Processing' || partitionService.selectedPartition.status==='Loading'" [(ngModel)]="partitionService.selectedPartition.blId" (ngModelChange)="updateList('BL')"
                                    class="list-value">
                        <option id="{{list.listName | convertToUnderscore: 'blSelect'}}" *ngFor="let list of partitionService.blListOptions" [ngValue]="list.id">{{list.listName}}</option>
                    </select>
                </div>
            </div>
            <div class="row w3-margin-top">
                <div class="col-md-10">
                    <span class="list-label col-md-6 col-lg-4">Trusted Partner List</span>
                    <select id="partitionDropdownTP" name="tp" [disabled]="partitionService.selectedPartition.status==='Processing' || partitionService.selectedPartition.status==='Loading'" [(ngModel)]="partitionService.selectedPartition.tpId" (ngModelChange)="updateList('TP')"
                         class="list-value">
                        <option id="{{list.listName | convertToUnderscore: 'tpSelect'}}" *ngFor="let list of partitionService.tpListOptions" [ngValue]="list.id">{{list.listName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="btn-group col-md-6" data-bs-toggle="buttons" role="group">
            <div class="col-md-12" *ngIf="!partitionService.isNewPartition">
                <div class="col-md-4"></div>
                <div class="col-md-2 padding-5" style="display: flex; justify-content: center; flex-wrap: wrap; min-width: 135px;">
                    <button id="viewDialPatternsButton" class='btn btn-default no-uppercase no-padding partition-btn' *ngIf="partitionService.selectedPartition.status==='Draft'" (click)="onQueryDraftData()">View Dial Patterns</button>
                    <button id="loadDialPatternsButton" class='btn btn-primary no-uppercase no-padding partition-btn' *ngIf="partitionService.selectedPartition.status!=='Draft'" [disabled]="partitionService.exportInProgress || partitionService.selectedPartition.status==='Loading'" (click)="onCreateDraftData()">Load Dial Patterns</button>
                    <div *ngIf="partitionService.selectedPartition.status==='Loading'" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></div>
                </div>
                <div class="col-md-2 padding-5" style="min-width: 80px;">
                    <button id="deletePartitionRulesButton" class='btn btn-info no-uppercase no-padding partition-btn' [disabled]="partitionService.selectedPartition.status==='Loading'" (click)="onDeletePartitionRules()">Delete All</button>
                </div>
                <div class="col-md-2 padding-5" style="display: flex; justify-content: center; flex-wrap: wrap; min-width: 80px">
                    <button id="exportPartitionButton" class='btn btn-default no-uppercase no-padding partition-btn' [disabled]="partitionService.isExportButtonDisabled()"  (click)="onExport()">Export</button>
                    <div *ngIf="partitionService.exportInProgress" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row col-md-11 w3-margin-top" *ngIf="partitionService.selectedPartition.ruleDefinitions.length > 0">
        <p-dataView [value]="partitionService.selectedPartition.ruleDefinitions">
            <ng-template let-rule pTemplate="listItem" let-i="rowIndex">
                <div class="ui-grid ui-grid-responsive ui-fluid" style="font-size:16px;padding:20px;border-bottom:1px solid #D5D5D5;">
                    <div class="ui-grid-row">
                        <div class="ui-grid-col-2 w3-left w3-margin-top">
                            <font class="rule-label"><span id="rule{{i+1}}Label">Rule {{i+1}}</span></font>
                            <font class="rule-source"><p><span id="rule{{i+1}}DataSource">{{rule.dataSource}}</span> <span id="rule{{i+1}}DialPatternType">{{rule.dialPatternType}}</span></p></font>
                        </div>
                        <div class="ui-grid-col-9">
                            <div class="ui-grid ui-grid-responsive ui-fluid">
                                <div class="w3-card-rule col-md-3 w3-center" style="cursor: pointer;" (click)="openFilterInPartition(rule, 'country')">
                                    <div class="w3-container margin-top5">
                                        <p class="attribute-label">Country Code & ISO2</p>
                                    </div>

                                    <div class="w3-container">
                                        <p class="attribute-value">{{getTipContents(rule.rangeQueryFilter, 'country')}}</p>
                                    </div>
                                </div>
                                <div class="w3-card-rule col-md-3 w3-center" style="cursor: pointer;" (click)="openFilterInPartition(rule, 'tos')">
                                    <div class="w3-container margin-top5">
                                        <p class="attribute-label">TOS & TOS Description</p>
                                    </div>

                                    <div class="w3-container">
                                        <p class="attribute-value">{{getTipContents(rule.rangeQueryFilter, 'tos')}}</p>
                                    </div>
                                </div>
                                <div class="w3-card-rule col-md-2 w3-center" style="cursor: pointer;" (click)="openFilterInPartition(rule, 'provider')">
                                    <div class="w3-container margin-top5">
                                        <p class="attribute-label">Provider</p>
                                    </div>

                                    <footer class="w3-container">
                                        <p class="attribute-value">{{rule.selectedProvider}} selected</p>
                                    </footer>
                                </div>
                                <div class="w3-card-rule-right col-md-3 w3-center" *ngIf="rule.dataSource==='IPRN'">
                                    <div class="w3-container margin-top5">
                                        <p class="attribute-label">Last Observed</p>
                                    </div>

                                    <footer class="w3-container">
                                        <p class="attribute-value">{{rule.noOfMonth}} months</p>
                                    </footer>

                                </div>
                            </div>
                        </div>
                        <div class="ui-grid-col-1 w3-right">
                            <div class="row">
                                <a  *ngIf="partitionService.selectedPartition.status==='Draft'" href="#" (click)="viewRule(rule)"><span id="rule{{i+1}}DialPatternLink" class="text-danger btn-link">DIAL PATTERNS</span></a>
                                <a  *ngIf="partitionService.selectedPartition.status!=='Draft'"><span id="rule{{i+1}}DialPatternLink" class="disabled">DIAL PATTERNS</span></a>
                            </div>
                            <div class="row">
                                <a *ngIf="partitionService.selectedPartition.status!=='Loading'" href="#" (click)="editRule(rule)"><span id="rule{{i+1}}EditRuleLink" class="text-danger btn-link">EDIT RULE</span></a>
                                <a *ngIf="partitionService.selectedPartition.status==='Loading'"><span id="rule{{i+1}}EditRuleLink" class="disabled">EDIT RULE</span></a>
                            </div>
                            <div class="row">
                                <a *ngIf="partitionService.selectedPartition.status!=='Loading'" href="#" (click)="deleteRule(rule)"><span id="rule{{i+1}}DeleteRuleLink" class="text-danger btn-link">DELETE RULE</span></a>
                                <a *ngIf="partitionService.selectedPartition.status==='Loading'"><span id="rule{{i+1}}DeleteRuleLink" class="disabled">DELETE RULE</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
</div>

<!-- filter panel (not called)-->
<p-dialog [(visible)]="showFilterWin" modal="modal" width="1500" resizable="true">
    <div id="country" class="collapse w3-container w3-margin-top filter">
        <div class="col-md-2">
            <div class="btn-group-veritical col-md-6" *ngFor="let item of alphaSearchPattern">
                <button class="col-md-12 btn alpha-button btn-warning w3-margin-right w3-margin-bottom" [disabled]="blockingCountrySearch" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
            </div>
        </div>
        <div class="col-md-10 w3-leftbar">
            <div class="row">
                <div class="input-append span12">
                    <input type="text" name="searchText" class="search-query w3-margin-left" #country size="35" placeholder="Search Country, Code or ISO2 ..." (keyup)="filterCountry(country.value)">
                    <span class="clearer pi pi-times-circle" (click)="country.value=''; filterCountry('')"></span>

                    <button type="button" class="btn btn-primary" [disabled]="selectedCounter.country==0" (click)="showSelected('country')">Show {{selectedCounter.country}} Selected</button>

                    <button type="button" class="btn btn-primary" (click)="clearSelected('country')">Clear</button>
                </div>
            </div>
            <div class="w3-margin-top row text-center">
                <a class="link" (click)="selectAll('country')">SELECT ALL &lt;{{countryFilterLabel}}&gt;</a>
            </div>
            <div class="row">
                <div class="w3-margin-top col-md-3 col-sm-4">
                    <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                    <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                    <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
                </div>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of filteredCountryList">
                        <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                            <div class="w3-container">
                                <span class="w3-left-align col-md-8">{{ country.country }}</span>
                                <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="tos" class="collapse w3-container w3-margin-top filter">
        <div class="col-md-2">
            <div class="btn-group-veritical col-md-6" *ngFor="let item of tosSearchPattern">
                <button class="col-md-12 btn alpha-button btn-warning w3-margin-right w3-margin-bottom" (click)="filterTos(item.pattern, item.label)">{{item.label}}</button>
            </div>
        </div>
        <div class="col-md-10 w3-leftbar">
            <div class="row">
                <div class="input-append span12">
                    <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #tos placeholder="Search TOS & TOS Description ..." (keyup)="filterTosDesc(tos.value)">
                    <span class="clearer pi pi-times-circle" (click)="tos.value=''; filterTosDesc('')"></span>

                    <button type="button" class="btn btn-primary" [disabled]="selectedCounter.tos==0" (click)="showSelected('tos')">Show {{selectedCounter.tos}} Selected</button>

                    <button type="button" class="btn btn-primary" (click)="clearSelected('tos')">Clear</button>
                </div>
            </div>
            <div class="w3-margin-top row text-center">
                <a class="link" (click)="selectAll('tos')">SELECT ALL &lt;{{filterTosLabel}}&gt;</a>
            </div>
            <div class="row w3-margin-top">
                <span class="w3-left-align w3-text-blue col-md-8">TOS:TOS Description</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of filteredTOSList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                            <div class="w3-container">
                                <div class="text-start">{{item.tos}}: {{item.tosdesc || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="provider" class="collapse w3-container w3-margin-top filter">
        <div class="col-md-2">
            <div class="btn-group-veritical col-md-4" *ngFor="let item of alphaNumSearchPattern">
                <button class="col-md-12 btn alpha-button btn-warning w3-margin-right w3-margin-bottom" [disabled]="blockingProviderSearch" (click)="filterProvider(item.pattern, item.label)">{{item.label}}</button>
            </div>
        </div>
        <div class="col-md-10 w3-leftbar">
            <div class="row">
                <div class="input-append span12">
                    <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #provider placeholder="Search Provider ..." (keyup)="filterProviderSearch(provider.value)">
                    <span class="clearer pi pi-times-circle" (click)="provider.value=''; filterProviderSearch('')"></span>

                    <button type="button" class="btn btn-primary" [disabled]="selectedCounter.provider==0" (click)="showSelected('provider')">Show {{selectedCounter.provider}} Selected</button>

                    <button type="button" class="btn btn-primary" (click)="clearSelected('provider')">Clear</button>

                </div>
            </div>
            <div class="w3-margin-top row text-center">
                <a class="link" (click)="selectAll('provider')">SELECT ALL &lt;{{filterProviderLabel}}&gt;</a>
            </div>
            <div class="row w3-margin-top">
                <span class="w3-center-align w3-text-blue w3-margin-left col-md-2">Provider</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                            <div class="w3-container">
                                <div class="text-start">{{item.provider || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>

    <div id="lastObserved" class="collapse w3-container w3-margin-top filter">
        <div class="row">
            <div class="col-md-3 text-end helperText1">
                <label>Show Records with fraud from</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 text-end helperText2">
                Rolling Date:
            </div>
        </div>
        <div class="col-md-6 text-start">
            <div class="row">
                <input type="number" min="1" max="99" (keyup)="limitMaxValue($event.target)" id="lastObserved" name="lastObserved" [(ngModel)]="rangeFilter.numOfMonthsSinceLastObserved">
                <span class="w3-margin-left helperText2">month(s) before the current month</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 text-end helperText3">
                <label>Maximum of 99 months</label>
            </div>
        </div>
    </div>
</p-dialog>


<!-- read only filter panel -->
<p-dialog id="filterDialog" [(visible)]="showReadOnlyFilterWin" modal="modal" [style]="{width: '80vw'}" [contentStyle]="{'overflow-y': 'auto','height': '60vw'}" [resizable]="true">
    <div id="rcountry" *ngIf="filterBy==='country'" class="w3-container w3-margin-top filter">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3 col-sm-4">
                    <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                    <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                    <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
                </div>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of filteredCountryList">
                        <div class="w3-card-4 no-shadow" [ngClass]="country.selected">
                            <div class="w3-container">
                                <span class="w3-left-align col-md-8">{{ country.country }}</span>
                                <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="rtos" *ngIf="filterBy==='tos'" class="w3-container w3-margin-top filter">
        <div class="col-md-12">
            <div class="row">
                <span class="w3-left-align w3-text-blue col-md-8">TOS:TOS Description</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of filteredTOSList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected">
                            <div class="w3-container">
                                <div class="text-start">{{item.tos}}: {{item.tosdesc || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="rprovider" *ngIf="filterBy==='provider'" class="w3-container w3-margin-top filter">
        <div class="col-md-12">
            <div class="row">
                <span class="w3-center-align w3-text-blue w3-margin-left col-md-2">Provider</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected">
                            <div class="w3-container">
                                <div class="text-start">{{item.provider || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</p-dialog>
