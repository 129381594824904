
import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, ResponseContentType  } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import '../util/rxjs-operators';
import { ResponseJson } from '../models/response.model';
import { JsonHelper } from '../util/json.helper';
import { Logger } from './logger.service';
import {AppConstants, CB} from '../util/index';
import { distinctUntilChanged } from 'rxjs/operators';



@Injectable()
export class HttpService {

  constructor(private http: HttpClient, private log: Logger) { };

  private getJWT(bodyData?: string): Object {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': 'Bearer ' + currentUser.token });
    }

    if (bodyData) {
      //return new RequestOptions({ headers: headers, body: bodyData });
      return { headers: headers, body: bodyData, observe: 'body', responseType: 'json'};
    }
    //return new RequestOptions({ headers: headers });
    return { headers: headers };

  }

  get(queryStr: string, myCallbacks: CB[]) {
    return this.http.get(queryStr, this.getJWT()).pipe(distinctUntilChanged())
      .subscribe(res => {
        //res.json() --> res
        const responseJson: ResponseJson = JsonHelper.parse(res, ResponseJson);

        //call every call back
        myCallbacks.forEach (myCallback => { myCallback(responseJson); });

      });
  }

  getStream(queryStr: string, myCallback: CB) {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    var now = new Date();

    if (currentUser && currentUser.token) {
      return this.http.get(queryStr, { 
        headers: new HttpHeaders()
          .append('Authorization', "Bearer " + currentUser.token)
          .append('timezone-offset',now.getTimezoneOffset().toString()),
          observe: 'response', responseType: 'blob' 
      }).pipe(distinctUntilChanged()).subscribe(res => {
          //call every call back
          //res.status --> res
          this.log.debug(res);
          myCallback(res);
      });
    } else {
      return this.http.get(queryStr, { 
        headers: new HttpHeaders()
          .append('timezone-offset',now.getTimezoneOffset().toString()),
        observe: 'response', responseType: 'blob' 
      }).pipe(distinctUntilChanged()).subscribe(res => {
          //call every call back
          //res.status --> res
          this.log.debug(res);
          myCallback(res);
       });
    }
  }



postStream(data:string, url: string, myCallback: CB, errorHandler: (errmsg:any) => void) {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    var now = new Date();

      return this.http.post(url, data, { 
        headers: new HttpHeaders()
          .append('Authorization', "Bearer " + currentUser.token)
          .append('timezone-offset',now.getTimezoneOffset().toString()),
          observe: 'response', responseType: 'blob' 
      }).pipe(distinctUntilChanged()).subscribe(
        res => {
          //call every call back
          //res.status --> res
          console.debug("postStream", url, "res");
          myCallback(res);
        },
        err => {
          console.error("postStream", url, err);
          errorHandler(err);  
        }

      );
  }





  post(data: string, url: string, myCallbacks: CB[]) {
    return this.http.post(url, data, this.getJWT()).pipe(distinctUntilChanged())
      .subscribe(res => {
        this.log.debug('post response:' + res);
        //res.json()--> res
        const responseJson: ResponseJson = JsonHelper.parse(res, ResponseJson);

        //call every call back
        myCallbacks.forEach (myCallback => { myCallback(responseJson); });
      });
  }

  put(data: string, url: string, myCallbacks: CB[]) {
    this.log.debug('Calling PUT: ' + url);

    return this.http.put(url, data, this.getJWT()).pipe(distinctUntilChanged())
      .subscribe(res => {
        //res.json() --> res
        const responseJson: ResponseJson = JsonHelper.parse(res, ResponseJson);

        //call every call back
        myCallbacks.forEach (myCallback => { myCallback(responseJson); });

      });
  }

  delete(url: string, myCallbacks: CB[], data?: string) {
    this.log.debug('Calling DELETE: ' + url);

    return this.http.delete(url, this.getJWT(data)).pipe(distinctUntilChanged())
      .subscribe(res => {
        //res.json() --> res
        const responseJson: ResponseJson = JsonHelper.parse(res, ResponseJson);

        //call every call back
        myCallbacks.forEach (myCallback => { myCallback(responseJson); });

      });
  }

  private handleError<T>(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    this.log.error(errMsg);
    return throwError(errMsg);
  }

}
