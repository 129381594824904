import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EditableFieldComponent implements OnInit {
  item: string;
  isEditing = false;
  prevName: string;

  @Input()
  styleClass = '';

  @Input()
  origItem: string;
  @Input()
  maxLength: number;
  @Input()
  hasConfirm = false;
  @Input()
  placeholderText: string;

  @Output()
  dateModelChange: EventEmitter<string> = new EventEmitter();

  constructor() { }

  public focusSettingEventEmitter = new EventEmitter<boolean>();

   setFocus(): void {
      this.focusSettingEventEmitter.emit(true);
    }

  ngOnInit() {
     if (!this.item || !this.origItem) {
      this.isEditing = true;
    }
  }
  ngOnChanges(){
    if (this.item != this.origItem) {
      this.prevName="";
      if (!this.origItem) {
        this.isEditing = true;
      }
      this.item = this.origItem;
    } 
  }

  onEdit() {
    this.item = this.origItem;
    this.prevName = this.origItem;
    this.isEditing = true;
  }

  update(event: any, log) {
    console.log(log);
    if (this.item !== this.origItem) {
      this.origItem = this.item;
      this.dateModelChange.emit(this.item);
    } else {
      if (!this.origItem) {
        this.dateModelChange.emit(this.item);
      }
    }
    this.isEditing = false;
  }

  cancelChange() {
    this.isEditing = false;
    console.log('cancel ${this.origItem}');
  }

}
