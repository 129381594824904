import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { AuthService } from '../auth';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseJson,ListDefinition,EditItem,RangeFilter, PartitionDefinition, Country} from '../models/index';
import { NotificationService } from '../services/notification.service';
import { FilterService} from '../filter/filter-service';
import { CacheService } from '../services/cache.service';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { EditableFieldComponent } from '../editable-field/editable-field.component';
import { ConvertToUnderscorePipe } from '../util/convert-to-underscore.pipe';


@Component({
  selector: 'app-trusted-partner',
  templateUrl: './trusted-partner.component.html',
  styleUrls: ['./trusted-partner.component.scss'],
  })
export class TrustedPartnerComponent extends FilterService implements OnInit {

  @ViewChild('trustedPartnerNameField') inputListName:EditableFieldComponent;
  tpLists: Array<ListDefinition> = [];
  selectedList: ListDefinition;
  selectedListName = '';
  showCountryFilter:boolean = false;
  styleClass = 'inline';
  placeholderValue = 'Name the list';
  showDialog :boolean = false;
  showDelConfirm:boolean = false;
  trustedProviders = [];
  selectedListItem: EditItem<ListDefinition>;
  isEditDesc = false;
  isBlankList = true;
  selectedTpListName = '';
  selectedPartners = [];
  rangeFilter : RangeFilter;
  previousFilteredCountryList: Array<String> = [];
  previousSelectedCountryCounter = 0;
  pollListUpdate: Subscription;
  first:boolean = false;
  addNewTp:boolean = false;
  showMoreTP = true;
  showMoreLimit = AppConstants.ShowMoreLimit;
  showMessage = false;
  showInfoMessage = false;
  showThresholdMessage = false;
  showDuplicateMessage = false;
  tempName = '';
  isNewList = false;
  isAddMoreTP = true;
  isAllowDelete = false;
  maxPartnerList: number;
  remainingTP: number;
  partitionReferenceMessage = '';
  partitionReferenceList = '';
  selectedPosition = 0;
  blocking = false;
  isSelectDuringNameEdit = false;
  isSkipDupMsg = false;

  constructor(public log: Logger, public httpService: HttpService, 
    public cacheService: CacheService, public notifService: NotificationService, private authService: AuthService ) {
    super(log, httpService, cacheService, notifService);
  }

  ngOnInit() {
     super.init();
     if (this.msrnProviderBillingIdList.length < 1){
        this.refreshMsrnCache();
     }
     this.getTrustedPartnerList();
     this.msrnFilterProvider('^a', 'A');
     this.filterCountryOnly('^[a,b,c]', 'A-C');
     this.selectedPartners = [];
     this.queryListUpdate();
     this.first = true;
     this.addNewTp = true;
  }



  queryListUpdate() {
    /* put in a flag to help with automated testing */
    this.log.debug("guiPollingEnabled: " + this.authService.loginUser.guiPollingEnabled);
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate = interval(10000).subscribe(() => {
        this.getTrustedPartnerList();
      });
    }
  }

  ngOnDestroy() {
    /* put in a flag to help with automated testing */
    //this.log.debug("guiPollingEnabled: " + this.authService.loginUser.guiPollingEnabled);
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate.unsubscribe();
    }
  } 

  getTrustedPartnerList() {
    this.httpService.get(AppConstants.QueryTPListURL, [this.postQueryTrustedPartnerListHandler, this.responseCallBack]);
  }

  postQueryTrustedPartnerListHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      let selectedList: ListDefinition;
      
      if (this.tpLists.length > 0) {

        if (this.selectedListName) {
          selectedList = this.tpLists.find(b => b.listName === this.selectedListName);
          if (selectedList)
            selectedList.selected = true;
        } else {
          selectedList = this.tpLists.find(b => b.selected === true);
          if (selectedList)
            this.selectedListName = selectedList.listName;
        }
        if (selectedList && !this.showDuplicateMessage)
          this.isAllowDelete = true;
      }
      
      //console.log("Current selectedListName: " + this.selectedListName);

      this.maxPartnerList = responseJson.data.maxTrustPartnerList;

      this.tpLists = [];
      const tpListArray: Array<ListDefinition> = JsonHelper.parseArray(responseJson.data.lists, ListDefinition);

      if (tpListArray.length > 0) {
        this.isBlankList = false;
      }
      for (const item of tpListArray) {
        this.tpLists.push(item);
      }

      if (this.tpLists.length >= this.maxPartnerList) {
        this.isAddMoreTP = false;
      } else {
        this.isAddMoreTP = true;
      }
      //console.log("postTP Query() " + this.tpLists.length + "|" + this.maxPartnerList + "|" + this.isAddMoreTP );
      
      //this.fillBlankSlot();
      if (this.first && this.tpLists.length>0) {
        this.selectedList = this.tpLists[0];
        //set the first list selected
        this.tpLists.forEach(b => b.selected = false); 
        this.selectedList.selected = true;
        this.selectedList.type='TP'; 
        this.first = false;
        this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
        this.onSelect(this.selectedList);
      }

      if (this.selectedListName) {
        selectedList = this.tpLists.find(b => b.listName === this.selectedListName);
        if (selectedList)
          selectedList.selected = true;
        this.selectedListName = '';
      }   
    }
     
  }

  fillBlankSlot() {
    if (this.tpLists.length < 1) {
      this.tpLists.push(new ListDefinition('Partner List 1'));
    }
  }

  updateListName(listName: string) {
    if (this.isSelectDuringNameEdit && this.isNewList) {
      //used when do new list->dup->select
      this.isSelectDuringNameEdit = false;
      this.isNewList = false;
      return;
    }
    this.isSelectDuringNameEdit = false;

    if (listName) {
      this.selectedListItem.item.listName = listName;
      this.httpService.post(JsonHelper.toJson(this.selectedList), AppConstants.SaveListDefinition, [this.postUpdateListName]);
    } else {
      if (!this.showThresholdMessage) {
        this.selectedListItem.item.listName = 'Partner List ' + (this.tpLists.length +1);
        this.httpService.post(JsonHelper.toJson(this.selectedList), AppConstants.SaveListDefinition, [this.postUpdateListName]);
      }
    }
  }

  postUpdateListName = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);
      this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
      this.selectedListItem.item.id = this.selectedList.id;
      this.responseCallBack(responseJson);
      this.selectedListName = this.selectedList.listName;
      if (this.isNewList) {
        this.checkThresholdLimit();
      }
      this.isNewList = false;
      this.selectedList.selected=true;
      //console.log("Selected List: " + this.selectedList.listName + " | " + this.selectedList.id + " | " + this.selectedList.selected);

      this.getTrustedPartnerList();
    } else {
      //this.messageRegularText=responseJson.message;
      console.log("errorMsg: " + responseJson.message);
      if (responseJson.message.includes("same name")) {
        //skip displaying duplicate message if onSelect in progress
        if (!this.isSkipDupMsg) {
          this.showDuplicateMessage=true;
          this.isAllowDelete = false;
        }
      } else
        this.responseCallBack(responseJson);
    }
    if (this.isSkipDupMsg)
      this.isSkipDupMsg = false;
  }

  update(item) {
    if (item.selected){
      this.selectedPartners.unshift(item.provider);
    } else {
      const index: number = this.selectedPartners.indexOf(item.provider);
      if (index !== -1) {
        this.selectedPartners.splice(index, 1);
      }    
      this.selectedMsrnCounter.msrnProvider--;
    }
    this.createRangeFilter();
    this.selectedList.details = JSON.stringify(this.rangeFilter);
    this.httpService.post(JsonHelper.toJson(this.selectedList), AppConstants.SaveListDefinition, [this.postUpdateTPList]);
  }

  postUpdateTPList = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      //don't display message if adding TP to list
      if (!responseJson.message.includes("Successfully update list")) {
        //e.g. shows when partition becomes stale
        this.responseCallBack(responseJson);
      } 
    } else {
      console.log("errorMsg: " + responseJson.message);
      this.responseCallBack(responseJson);
    }
  }

  async checkThresholdLimit() {
    this.remainingTP = this.maxPartnerList - this.tpLists.length-1;
    //console.log("remaining: " + this.remainingTP + " | max: " + this.maxPartnerList + " | threshold: " + AppConstants.ThresholdWarningLimit);
    if ( this.remainingTP <= AppConstants.ThresholdWarningLimit) {
      this.showThresholdMessage = true;
      await AppConstants.delay(7000);
    }

    if (this.showThresholdMessage)
      this.closeMessage();
      
  }

  delTrustedProvider(provider : any){
    this.msrnProviderBillingIdList.forEach(item => {
       if (item.provider == provider) {
        item.selected = '';
        const index: number = this.selectedPartners.indexOf(provider);
        if (index !== -1) {
          this.selectedPartners.splice(index, 1);
        }   
      }
  });
  //update to database
  this.update(provider);
  }
  filterSelectedCountry(){
    this.msrnFilterProvider('^a', 'A');
    this.showCountryFilter = false;
  }

  toggleSelectedProvider(){    
    this.msrnFilteredProviderBillingIdList.forEach(item => {
       item.selected = this.selectedColor;
       this.selectedMsrnCounter.msrnProvider++;
       this.update(item);
    });

    this.dismiss();
    /* Unsure why this was selecting all of the items in the list instead of subset
    this.msrnProviderBillingIdList.forEach(item => {
      item.selected = this.selectedColor;
   });
   */
  }

  dismiss(){
    this.msrnFilterProvider('^a', 'A');
  }
  
  openCountryFilter() {

    //Set Previous Filtered Values
    this.previousFilteredCountryList = [];

    this.countryList.forEach(item => {
      if (item.selected) {
        this.previousFilteredCountryList.push(item.country);
      }
    });

    this.previousSelectedCountryCounter=this.selectedCounter.country;
    //console.log("cnt: arr|prev|sel: " + this.previousFilteredCountryList.length +"|"+this.previousSelectedCountryCounter + "|"+this.selectedCounter.country);

    this.showCountryFilter=true;
    this.filterCountryOnly('^[a,b,c]', 'A-C');
  }

  cancelCountryFilter() {

    /*
    console.log("::: BEFORE Filtered :::");
    this.countryList.forEach(item => {
      if (item.selected) {
        console.log(item.country);
      }
    });
    console.log("BEFORE prevCount|selectedCount: " + this.previousSelectedCountryCounter + "|" + this.selectedCounter.country);
    */

    //clear country filter
    if (this.selectedCounter.country>0) {
      this.countryList.forEach(item => {
        item.selected = '';
      });
      this.selectedCounter.country = 0;
      this.filteredCountryList = this.countryList;
      this.filterCountryOnly('^[a,b,c]', 'A-C');
    }  

    //set values back to previous filter
    if (this.previousSelectedCountryCounter>0 && this.previousFilteredCountryList) {
      this.previousFilteredCountryList.forEach(prev => {
        this.countryList.find(item => item.country === prev).selected = this.selectedColor;
      });
    }

    this.selectedCounter.country = this.previousSelectedCountryCounter;
    
    //console.log("AFTER prevCount|selectedCount: " + this.previousSelectedCountryCounter + "|" + this.selectedCounter.country);

    this.showCountryFilter = false;
    this.dismiss();
  }

  findSelectedPosition() {
    if (this.tpLists.length > 0) {
      this.selectedPosition = this.tpLists.findIndex(b => b.selected === true);

      if (this.selectedPosition > -1) {
        if (this.selectedPosition == (this.tpLists.length -1)) {
          if (this.selectedPosition == 0) {
            this.selectedPosition = -1;
          } else {
              this.selectedPosition--;
          }
        }
        else
          this.selectedPosition++;
      }
    } else {
      this.selectedPosition = -1;
    }
  }

  async onDeleteList() {
    this.isSelectDuringNameEdit = false;
    //reset listName
    if (this.selectedList.listName != this.selectedListItem.origItem.listName) {
      this.selectedList.listName = this.selectedListItem.origItem.listName;
    }
    let holdListToDelete:ListDefinition;
    holdListToDelete = this.selectedList;
    //console.log("onDeleteList API: Name|ID: " + this.selectedList.listName + " | " + this.selectedList.id);
    //this.listDataQueryStatus = '';
    this.hideMessages();
    this.stopBatchLoading = true;
    this.httpService.get(AppConstants.QueryPartitionReferences + '/' + this.selectedList.id,
      [this.postPartitionReferences]);
    await AppConstants.delay(1000);
    this.selectedList=holdListToDelete;    

    if (this.partitionReferenceList) {
      this.partitionReferenceMessage = '  This list is associated with the following partitions: ' + this.partitionReferenceList +
      '. Its removal will change the partition data.'
    } else {
      this.partitionReferenceMessage = '';
    }

    this.showDelConfirm=true;
  }

  postPartitionReferences = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);

      const partitionRefArray: Array<PartitionDefinition> = JsonHelper.parseArray(responseJson.data, PartitionDefinition);
      this.partitionReferenceList = "";
      let seperator = "";
      for (const item of partitionRefArray) {
        this.partitionReferenceList = this.partitionReferenceList + seperator + item.name; 
        seperator = ", ";
      }
    }
  }

  deleteList(){
    this.blocking = true;
    this.showDelConfirm=false;
    this.clearSelected('provider');
    this.msrnFilterProvider('^a', 'A');
    //update to database
    this.selectedPartners = []; 
    this.createRangeFilter();
    this.selectedList.details = JSON.stringify(this.rangeFilter);
    this.httpService.delete(AppConstants.DeleteListDefinition + '?listId=' + this.selectedList.id, [this.postDeleteList, this.responseCallBack]);

  }

  postDeleteList = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedListItem.item.listData = [];
      this.selectedList.listData = [];

      this.findSelectedPosition();
      if (this.selectedPosition > -1 && this.tpLists.length>0) {
        this.selectedList = this.tpLists[this.selectedPosition];
        //console.log("postDeleteList()  selectedList details (id/name): " + this.selectedList.id + "|" + this.selectedList.listName);
        this.tpLists.forEach(b => b.selected = false); 
        this.selectedList.selected = true;
        this.selectedList.type='TP'; 
        this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
        this.onSelect(this.selectedList);
        this.getTrustedPartnerList();
      } else {
        this.selectedList=null;
      }
    }
    this.blocking = false;
  }



  onSelect(myList: ListDefinition) {
    this.isAllowDelete = true;
    this.tpLists.forEach(b => b.selected = false);
    myList.selected = true;
    this.isNewList = false;
    if (this.inputListName && this.inputListName.isEditing) {
      this.isSelectDuringNameEdit = true;
      this.inputListName.isEditing=false;
    } else {
      this.isSelectDuringNameEdit = false;
    }
    this.hideMessages();
    this.onListLoad(myList);
  }

  onUpdateDesc() {
    this.isEditDesc = false;
    this.httpService.post(JsonHelper.toJson(this.selectedList), AppConstants.SaveListDefinition, [this.responseCallBack]);
 }

 async onListLoad(myList: ListDefinition) {
  await AppConstants.delay(1000);
  this.selectedList = myList;
  this.selectedList.selected = true;
  this.selectedList.type='TP';
  this.selectedListItem = new EditItem(this.selectedList, ListDefinition);

  if (myList.id) {
      this.queryListDefinition(myList);
      this.inputListName.item = myList.listName;
      this.inputListName.origItem = myList.listName;
      this.isNewList = false;
    } else {
      this.httpService.post(JsonHelper.toJson(myList), AppConstants.SaveListDefinition, [this.postCreateListHandler]);
    }
}

async onCreateNew(myList: ListDefinition) {
  this.hideMessages();
  this.isAllowDelete = false;
  await AppConstants.delay(1000);
  this.selectedList = myList;
  this.selectedList.selected = true;
  //this.selectedList.type='TP';
  this.selectedListItem = new EditItem(this.selectedList, ListDefinition);

  this.isNewList = true;
  this.showInfoMessage = false;

  //this.selectedMsrnCounter.msrnProvider = 0;
  //this.selectedList.details = null;
  this.clearSelected('provider');
  this.selectedPartners = []; 
  this.msrnFilterProvider('^a', 'A');

  this.getTrustedPartnerList();
  //this.isNewList = true;

}

postCreateListHandler = (responseJson: ResponseJson) => {
  if (responseJson.status === AppConstants.SUCCESS) {
    this.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);
    this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
   //reset all the previous filters
   this.selectedMsrnCounter.msrnProvider = 0;

   //clear country filter
   this.countryList.forEach(item => {
     item.selected = '';
   });
   this.selectedCounter.country = 0;
   this.filteredCountryList = this.countryList;
   this.filterCountryOnly('^[a,b,c]', 'A-C');


   this.msrnProviderBillingIdList.forEach(item => {
     item.selected = '';
   });
   this.selectedMsrnCounter.msrnProvider = 0;
   this.msrnFilteredProviderBillingIdList = this.msrnProviderBillingIdList;

   this.selectedPartners = [];
   if (this.selectedList != undefined && this.selectedList != null &&
       this.selectedList.details != undefined && this.selectedList.details != null && 
       this.selectedList.details.length > 0) {
     //Msrn Provider data 
     const providerData = JSON.parse(this.selectedList.details);
     if (providerData && providerData.providerList) {
         for (let providerItem of providerData.providerList) {
           this.selectedPartners.push(providerItem.provider);
           this.msrnProviderBillingIdList.forEach(item => {
             if (item.provider == providerItem.provider){
               item.selected = this.selectedColor;
               this.selectedMsrnCounter.msrnProvider++;
             }
           });
         }
       } 
   }
   this.msrnFilterProvider('^a', 'A');
  }; 
}

// mouseenter event
showToolTip()  {
  setTimeout(() => {
    this.showDialog = true;
  }, 400);
};

hideMessages() {
  this.showDialog = false;
  this.showThresholdMessage = false;
  this.showDuplicateMessage = false;
}

newList(){
  let newList = new ListDefinition;
  newList.type = 'TP';
  newList.listName='';

  newList.selected = true;
  this.tpLists.forEach(b => b.selected = false); 
  if (this.inputListName && this.inputListName.isEditing) {
    //this.isSelectDuringNameEdit = true; //taking this out, allowed me to now create as second step; however, it made the dup message to display
    //this.inputListName.isEditing=false; 
    this.isSkipDupMsg = true;
    console.log("inside newList and isEditing=true");
  } else {
    this.isSelectDuringNameEdit = false;
    this.isSkipDupMsg = false;
  }
  this.onCreateNew(newList);
}

queryListDefinition(myList: ListDefinition) {
  let url = AppConstants.QueryListDefinition;
  //console.log("list id " + JSON.stringify(myList));
  if (myList.id) {
    url += '?listId=' + myList.id;
  } else {
    url += '?listName=' + myList.listName + '&listType=' + this.selectedList.type;
  }
  this.log.debug('query list definition: ' + url);
  this.httpService.get(url, [this.postQueryListDefinitionRequestHandler]);
}

  postQueryListDefinitionRequestHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedList = JsonHelper.parse(responseJson.data, ListDefinition);
      this.selectedListItem = new EditItem(this.selectedList, ListDefinition);
      
      //reset all the previous filters
      this.selectedMsrnCounter.msrnProvider = 0;

      //clear country filter
      this.countryList.forEach(item => {
        item.selected = '';
      });
      this.selectedCounter.country = 0;
      this.filteredCountryList = this.countryList;
      this.filterCountryOnly('^[a,b,c]', 'A-C');


      this.msrnProviderBillingIdList.forEach(item => {
        item.selected = '';
      });
      this.selectedMsrnCounter.msrnProvider = 0;
      this.msrnFilteredProviderBillingIdList = this.msrnProviderBillingIdList;

      this.selectedPartners = [];
      if (this.selectedList != undefined && this.selectedList != null &&
          this.selectedList.details != undefined && this.selectedList.details != null && 
          this.selectedList.details.length > 0) {
        //Msrn Provider data 
        const providerData = JSON.parse(this.selectedList.details);
        if (providerData && providerData.providerList) {
            for (let providerItem of providerData.providerList) {
              this.selectedPartners.push(providerItem.provider);
              this.msrnProviderBillingIdList.forEach(item => {
                if (item.provider == providerItem.provider){
                  item.selected = this.selectedColor;
                  this.selectedMsrnCounter.msrnProvider++;
                }
              });
            }
          } 
      }
      this.msrnFilterProvider('^a', 'A');
    
  } else {
    this.showMessage = true;
  }
}
  closeMessage(){
    this.showThresholdMessage = false;
  }


  closeDuplicateMessage(){
    this.showDuplicateMessage = false;
    this.inputListName.isEditing=true;  
    this.inputListName.origItem = this.inputListName.prevName;
    this.inputListName.setFocus();
  }

}
