import { Injectable } from '@angular/core';
import { FilterService } from '../filter/filter-service';
import { AppConstants, JsonHelper, BaseComponent, CB } from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, PartitionDefinition, PartitionDataDetails, PartitionExportHistory, RuleDefinition } from '../models/index';

export class RulePartitionBase extends FilterService {
  partitionList: Array<PartitionDefinition> = [];
  selectedPartitionId: number;
  selectedHistoryId: number;
  selectedPartitionName = '';
  showMorePL = true;
  showMoreLimit = AppConstants.ShowMoreLimit;
  showPartitionLimit = this.showMoreLimit;
  addNewPL = true;
  maxPartitions: number;
  isAddMore = true;
  isCreateRule = false;
  isUpdatingRail=false;


  constructor(public log: Logger, public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService) {
    super(log, httpService, cacheService, notifService);
  }


  getPartitionList(myCallback?: CB, actionName?: string) { //TBD

    let partitionQueryURL = AppConstants.QueryPartitionListURL;
    if(actionName)
      partitionQueryURL = partitionQueryURL +'/'+actionName
    if (myCallback) {
      this.httpService.get(partitionQueryURL, [this.postQueryPartitionListHandler, myCallback, this.responseCallBack]);
    } else {
      this.httpService.get(partitionQueryURL, [this.postQueryPartitionListHandler, this.responseCallBack]);
    }
  }


  postQueryPartitionListHandler = (responseJson: ResponseJson, myCallback?: CB) => {
    let selectedPartition: PartitionDefinition = null;
    let selectedHistory: PartitionExportHistory = null;

    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionList = [];
      this.partitionList = JsonHelper.parseArray(responseJson.data.partitions, PartitionDefinition);

      if (this.isCreateRule) {
        this.fillBlankSlot();
      }

      //console.log("postQueryPartitionListHandler: selectedPartitionName: " + this.selectedPartitionName);
      //console.log("postQuery selectedPartitionId|selectedHistoryId: "+ this.selectedPartitionId + "|" + this.selectedHistoryId );
      this.getPartitionStatus();
   
      /*
      console.log(":::Partition Output:::");
      this.partitionList.forEach(p => {
        console.log("P: " + p.id + "|" + p.name);
        p.partitionExportHistories.forEach(h => {
          console.log("H:" + h.id + "|" + h.status + "|" + h.exportDate);
        });
      });
      console.log("");
      */

      if (this.selectedPartitionId) {
        //selectedPartition = this.partitionList.find(p => p.origPartitionId === this.selectedPartitionId);
        selectedPartition = this.partitionList.find(p => p.id === this.selectedPartitionId);
        if (selectedPartition) {
          selectedPartition.currentSelected = true;
          //console.log("Selected Current");
        }
      } else {
        if (this.selectedHistoryId) {
          this.partitionList.forEach(p => {
            selectedHistory = p.partitionExportHistories.find(h => h.id === this.selectedHistoryId);
            if (selectedHistory) {
              selectedHistory.selected = true;
              //console.log("Selected History");
            }
          })
        }
      }
      this.selectedPartitionId = null;
      this.selectedHistoryId = null;

      this.maxPartitions = responseJson.data.maxPartitions;
      if (this.partitionList.length >= this.maxPartitions) {
        this.isAddMore = false;
      } else {
        this.isAddMore = true;
      }

      if (this.partitionList.length<=this.showMoreLimit) {
        this.showPartitionLimit = this.partitionList.length;
      } else {
        if (this.showMorePL) {
          this.showPartitionLimit = this.showMoreLimit;
        } else {
          this.showPartitionLimit = this.partitionList.length;
        }
      }
    }
  }
 
  fillBlankSlot() {
    if (this.partitionList.length < 1) {
      this.partitionList.push(new PartitionDefinition('Partition 1'));
    }

  }

  getPartitionStatus() {
    this.partitionList.forEach(p => {
      p.partitionExportHistories.forEach(element => {
         if (element.status === 'Sending') {
             p.disabledPTB = true;
         }
      });
   });
   }
  
   

}