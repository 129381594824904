<div class="row margin-top col-md-11">
  <h3 class="col-md-6 offset-md-4 text-center">MSRN Data</h3>
  <span class="col-md-2 w3-margin-top text-end">Last updated {{cacheService.lastMobileDateSetDate}}</span>
</div>
<div class="btn-group offset-md-10">
    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Download <!--<span class="caret"></span>-->
    </button>
    <ul class="dropdown-menu">
        <li><button class="text-button" (click)="downloadMsrnDataFile()">All Data</button></li>
        <li><button class="text-button" (click)="downloadMsrnData()">Filtered Data</button></li>
    </ul>
</div>

<div class="w3-panel w3-boarder w3-border-red w3-margin-top">
    <div class="w3-bar" *ngIf="showFilterBy">
        <label class="w3-bar-item w3-margin-right col-md-1">Filter By: </label>
        <div class="col-md-2">
            <button class="w3-bar-item btn" style = "margin-right: 10px;" data-bs-toggle="collapse" data-bs-target="#filterArea" aria-expanded="false" aria-controls="filterArea" [ngClass]="{'btn-default': filterBy === 'country', 'btn-inverse-no-border': filterBy !== 'country'}" (click)="showFilterBy=false; openFilter('country')">Country Code & ISO2</button>
            <p style="margin-top:11px" *ngIf= 'filteredCountryCount > 0 '><font color="red">({{filteredCountryCount}})</font></p>
        </div>
        <div class="col-md-2"> 
            <button class="w3-bar-item btn col-md-1" style = "margin-right: 10px;" data-bs-toggle="collapse" data-bs-target="#filterArea" aria-expanded="false" aria-controls="filterArea" [ngClass]="{'btn-default': filterBy === 'provider', 'btn-inverse-no-border': filterBy !== 'provider'}" (click)="showFilterBy=false; openFilter('provider')">Provider</button>
            <p style="margin-top:11px" *ngIf = "filteredProviderCount > 0"><font color="red">({{filteredProviderCount}})</font> </p>
        </div>
         <i class="fa fa-chevron-down fa-lg pull-right" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#filterArea" aria-expanded="false" aria-controls="filterArea" (click)="showFilterBy=false; openFilter('country')"></i>
    </div>
</div>

<div class="collapse w3-margin-top w3-margin-left w3-margin-right" id="filterArea">
    <div class="w3-container  w3-margin-bottom">
        <div class="w3-bar">
            <label class="w3-bar-item w3-margin-right">Filter By: </label>
            <button class="w3-bar-item btn w3-margin-right" [ngClass]="{'btn-default': filterBy === 'country', 'btn-inverse-no-border': filterBy !== 'country'}" (click)="openFilter('country')">Country Code & ISO2</button>
            <button class="w3-bar-item btn w3-margin-right" [ngClass]="{'btn-default': filterBy === 'provider', 'btn-inverse-no-border': filterBy !== 'provider'}" (click)="openFilter('provider')">Provider</button>
            <i class="fa fa-chevron-up fa-lg pull-right" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#filterArea" (click)="showFilterBy=true; applyFilter()"></i>
            <button type="button" class="btn btn-primary pull-right w3-margin-left" data-bs-toggle="collapse" data-bs-target="#filterArea" (click)="showFilterBy=true; applyFilter();getFilteredCount();">Apply</button>
            <button type="button" class="btn btn-primary pull-right w3-margin-right" (click)="clearAll();getFilteredCount();">Clear Filter</button>
        </div>
    </div>

    <!-- filter panel -->
    <div id="country" class="w3-container w3-border-top w3-margin-top filter" >
        <!--div class="col-md-1">
            <div *ngFor="let item of alphaSearchPattern">
                <button class="col-md-12 btn alpha-button w3-margin-right w3-margin-bottom" [disabled]="blockingCountrySearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
            </div>
        </div-->

        <div class="col-md-1 no-padding  ">
            <div class="row w3-margin-top "> 
            <button class="col-md-3   btn  alpha-button no-uppercase w3-margin-bottom no-padding" [disabled]="blockingCountrySearch"  style="width:120px;height:24px" [ngClass]="{'btn-default': groupSelected, 'btn-inverse': !groupSelected}"  (click)="showCountryGroups()">Country Groups</button>
            </div>
            <div class="row ">
            <ng-container *ngFor="let item of alphaSearchPattern">
                <div class="row w3-margin-left"> 
                <button class="col-md-2 alpha-button btn w3-margin-left w3-margin-bottom" [disabled]="blockingCountrySearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
                </div>
            </ng-container>
            </div>
        </div>
        <div class="col-md-11 w3-leftbar">
            <div class="row w3-margin-top w3-margin-left">
                <div class="input-append span12">           
                    <div class="col-md-12" *ngIf="selectedCounter.country>0">
                        <div class="row">
                            <span class="w3-text-blue w3-small">The selected Country Code(s) and ISO2 will be applied</span>
                        </div>
                        <div class="row background-gray">
                            <span class="w3-small">{{getSeletionValues('country')}}</span>
                        </div>
                        <div class="row background-gray w3-margin-bottom ">
                            <button type="button" class="btn btn-info pull-right w3-margin no-uppercase no-padding list-btn" (click)="clearSelected('country')">Clear</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-md-4">
                <input type="text" name="searchText" class="search-query w3-margin-left" #country size="35" placeholder="Search Country, Code or ISO2 ..." (keyup)="filterCountry(country.value)">
                <span class="clearer pi pi-times-circle" (click)="country.value=''; filterCountry('')"></span>
            </div>
            <div class="col-md-4 text-center">
                <a class="link" (click)="selectAll('country')">SELECT ALL &lt;{{countryFilterLabel}}&gt;</a>
            </div>
            <div class="col-md-4">
                <a class="link" (click)="clearSelectedFilter('country',countryFilterLabel)">CLEAR ALL &lt;{{countryFilterLabel}}&gt;</a>
            </div>
            </div>
            <div class="row w3-margin-top">
                <ng-container *ngIf= "groupSelected">
                    <div  class="padding-zero-horz w3-left-align w3-text-blue col-md-6">
                        <table>
                            <tr>
                                <td style="max-width:320px; width:25% ; min-width:320px">
                                    <span class="padding-zero-horz w3-margin-left w3-left-align w3-text-blue col-md-8">
                                        <strong>Country Groups</strong>
                                    </span>
                                </td>
                                <td>
                                    <span class="padding-zero-horz w3-text-blue w3-margin-left w3-margin-right col-md-4">Country</span>
                                    <span class="padding-zero-horz w3-margin-left w3-margin-right w3-text-blue col-md-1">Code</span>
                                    <span class="padding-zero-horz w3-center-align w3-margin-left w3-margin-right w3-text-blue col-md-1">ISO2</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </ng-container>
                <ng-container *ngIf= "!groupSelected">
                    <div  class="padding-md-horz w3-left-align w3-text-blue col-md-3">
                        <span class="padding-zero-horz w3-left-align w3-text-blue col-md-8">Country</span>
                        <span class="padding-zero-horz w3-left-align w3-text-blue col-md-2">Code</span>
                        <span class="padding-zero-horz w3-left-align w3-text-blue col-md-1">ISO2</span>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <ng-container *ngIf= "groupSelected" >
                    <table class="w3-margin-top">
                    <tr>
                        <td class="w3-margin-bottom col-3 no-padding aligntop" style="height:auto; width:25% ">
                            <ng-template ngFor let-cGroup [ngForOf]="cGroups">
                                <div class="col-sm-8 lists-group-horiz-padding" style="min-width:250px">
                                    <a id="{{cGroup.id}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="filterCountryByGroupSelect(cGroup)" [class.selected]="cGroup.selected">{{cGroup.name}}</a>
                                </div>
                                    <div [hidden]="!cGroup.selected" class="col-sm-8 lists-group-horiz-padding">
                                    <hr/>
                                </div>
                            </ng-template>
                        </td>
                        <td class="w3-leftbar w3-margin-bottom col-md-8 no-padding aligntop">
                            <ul class="list-group list-group-horizontal f32 ">
                                <li class="list-group-item col-md-4 col-sm-4" *ngFor="let country of filteredCountryList">
                                    <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                                        <div class="w3-container"  style=" min-width:250px; width:20%; max-width:250px">
                                            <span class="text-start col-7">{{ country.country }}</span>
                                            <span class="text-start col-3">{{ country.code }}</span>
                                            <span class="text-start col-2">{{ country.iso2 }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
                </ng-container>
                <ng-container *ngIf= "!groupSelected" >
                    <ul class="list-group list-group-horizontal f32">
                        <li class="list-group-item col-3" *ngFor="let country of filteredCountryList">
                            <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                                <div class="padding-md-horz w3-container">
                                    <span class="padding-zero-horz text-start col-8">{{ country.country }}</span>
                                    <span class="padding-zero-horz text-start col-2">{{ country.code }}</span>
                                    <span class="padding-zero-horz text-start col-2">{{ country.iso2 }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>
    </div>

   <div id="provider" class="w3-container w3-border-top w3-margin-top filter" style="display:none">
        <div class="col-md-1  no-padding">
            <div class="w3-margin-top w3-margin-left"> 
                <ng-container *ngFor="let item of alphaNumSearchPattern">
                    <div class="row w3-margin-left"> 
                <button class="col-md-12 btn alpha-button w3-margin-right w3-margin-bottom" [disabled]="blockingProviderSearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterProvider(item.pattern, item.label)">{{item.label}}</button>
            </div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-10 w3-leftbar">
            <div class="row w3-margin-top  w3-margin-left" >
                <div class="input-append span12">
                    <div class="col-md-12" *ngIf="selectedCounter.provider>0">
                        <div class="row">
                            <span class="w3-text-blue w3-small">The selected Providers will be applied.</span><span class="w3-text-blue w3-small" *ngIf="!selectedCounter.country">It is recommended that you choose a ISO2, or Code before Provider</span>
                        </div>
                        <div class="row background-gray">
                            <span class="w3-small">{{getSeletionValues('provider')}}</span>
                        </div>

                        <div class="row background-gray w3-margin-bottom">
                            <button type="button" class="btn btn-info pull-right w3-margin no-uppercase no-padding list-btn" (click)="clearSelected('provider')">Clear</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #provider placeholder="Search Provider ..." (keyup)="filterProviderSearch(provider.value)">
                    <span class="clearer pi pi-times-circle" (click)="provider.value=''; filterProviderSearch('')"></span>
                </div>
                <div class="col-md-4">
                    <a class="link" (click)="selectAll('provider')">SELECT ALL &lt;{{filterProviderLabel}}&gt;</a>
                </div>
                <div class="col-md-4">
                    <a class="link" (click)="clearSelectedFilter('provider',filterProviderLabel)">CLEAR ALL &lt;{{filterProviderLabel}}&gt;</a>
                </div>
            </div>
            <div class="row w3-margin-top">
                <span class="w3-left-align w3-text-blue col-md-2">Provider</span>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                            <div class="w3-container">
                                <div class="text-start">{{item.provider || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>



</div>

<div class="row w3-margin-top w3-margin-left w3-margin-right">
  <p-table #dt [columns]="msrnCols" [value]="filteredMsrnDataList" [rows]="10" [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[10,30,50,100,250,500,750,1000]" 
  resizableColumns="true" columnResizeMode="expand" [globalFilterFields]="filterFieldCols" 
  [responsive]="true" [style]="{'margin-top':'30px'}" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="caption">
        <div>
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input pInputText #gb id="searchInput" type="text" size="50" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            <span class="pi pi-times-circle" style="margin:4px 4px 0 0" (click)="clearSearch(gb, dt)"></span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="code-tbl-header" [style.width.%]="col.width" *ngFor="let col of columns"
                [pSortableColumn]="col.field" [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
                tooltipPosition="top">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td class="code-tbl-body" *ngFor="let col of columns">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
    <ng-template id="dtFooter" pTemplate="footer">
        <tr>
            <td  [attr.colspan]="msrnCols.length">
                {{rangeQueryStatus}} <i *ngIf="isLoading()" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
            </td>
        </tr>
    </ng-template>
    <!--
    <ng-template pTemplate="emptymessage" let-rows>
        <tr>
            <td [attr.colspan]="rows.length">
                No MSRN records.
            </td>
        </tr>
    </ng-template>
    -->
  </p-table>
</div>
