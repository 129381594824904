import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { Router } from '@angular/router';
import { AppConstants, JsonHelper} from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { AuthService } from '../auth';
import { NotificationService } from '../services/notification.service';
import { ResponseJson, PartitionDefinition,  PartitionExportHistory, ListDefinition, PartitionFileLayout } from '../models/index';
import { PartitionService } from './partition.service';



@Component({
  templateUrl: './partitions.component.html',
  styleUrls: ['./partitions.component.scss']
})
export class PartitionsComponent extends RulePartitionBase implements OnInit, OnDestroy {
  pollPartitionUpdate: Subscription;
  styleClass = 'inline';
  showDelConfirm:boolean = false;
  isAllowDelete = false;
  selectedPosition = 0;
  selectedPartitionParentId = null;
  blocking=false;
  initLoad=false;

  constructor(private router: Router, public partitionService: PartitionService, public log: Logger, public httpService: HttpService, public cacheService: CacheService,
    public notifService: NotificationService,  private authService: AuthService) {
    super(log, httpService, cacheService, notifService);
  }

  ngOnInit() {
    console.debug("in partitions::ngOnInit");
    super.init();
    this.getPartitionList();
    this.setupQueryPartitionUpdate();
    this.getBlackList();
    this.getWhiteList();
    this.getTPList();
    this.getFileLayout();
    this.getCustomerSftpConfigured();
    // this.partitionService.selectedPartition = null;
    this.init();
  }

  ngOnDestroy() {
    console.debug("in partitions::ngOnDestroy p, eh, ep ", this.partitionService.selectedPartition, this.partitionService.selectedExportHistory, this.partitionService.selectedExportPartition);
    this.pollPartitionUpdate?.unsubscribe();
    this.partitionService.stopFetching = true;
  }

  async init() {
    await AppConstants.delay(1000);
    if (this.partitionList.length>0) {
      if (this.partitionService.selectedPartition && this.partitionService.selectedPartition.id) {
        this.queryPartitionUpdate();
        console.log("init: After Init ps list options", Date());
        if (this.selectedHistoryId){
          this.router.navigate(['partitions/partitionExportDetail']);
        }

      } else {
        await AppConstants.delay(1000);
        this.initLoad=true;
        this.onSelect(this.partitionList[0]);
      }
    } else {
      //check one more time to see if finished loading
      if (!this.initLoad) {
        this.initLoad=true;
        this.init();
      } else {
        this.router.navigate(['partitions/partitionHelp']);
      }
    }
  }

/* Doesn't look to be referenced
  updateName(name: string, partition: PartitionDefinition) {
    if (name) {
      partition.name = name;
      this.updatePartitionName(partition);
    }
  }
  */


  setupQueryPartitionUpdate() {
    /* put in a flag to help with automated testing - need to investigate more*/
    this.log.debug("guiPollingEnabled: " + this.authService.loginUser?.guiPollingEnabled);
    console.log("guiPollingEnabled: " + this.authService.loginUser?.guiPollingEnabled);
    this.pollPartitionUpdate = interval(10000).subscribe(() => {
      if (this.isUpdatingRail === false) {
        //console.log("pollPartitionUpdate");
        this.queryPartitionUpdate();
      } 
    });
    
  }

  queryPartitionUpdate() {
    this.isUpdatingRail = true;
    this.selectedPartitionId = null;
    this.selectedHistoryId = null;
    let selectedPartition: PartitionDefinition;
    let selectedHistory: PartitionExportHistory;
    //if (this.partitionService.selectedPartition)
      //console.log("partitions BEFORE selectedPartition.id|exportHistoryId: "+ this.partitionService.selectedPartition.id + "|" + this.partitionService.exportHistoryId );
    if (this.partitionService.selectedPartition && this.partitionService.exportHistoryId) {
      this.selectedHistoryId = this.partitionService.exportHistoryId;
    }
    if (!this.selectedHistoryId) {
      if (this.partitionService.selectedPartition && this.partitionService.selectedPartition.id) {
        //console.log("queryPartitionUpdate: ID GIVEN");
        this.isAllowDelete = true;
        this.selectedPartitionId = this.partitionService.selectedPartition.id;
        selectedPartition = this.partitionList.find(p => p.id === this.selectedPartitionId);
      } else {
        //console.log("queryPartitionUpdate: NO ID");
        selectedPartition = this.partitionList.find(p => p.currentSelected === true);
      }
    }
    if (selectedPartition) {
      //Don't know if still want to do this...
      //this.selectedPartitionId = selectedPartition.origPartitionId;
      this.selectedPartitionParentId = selectedPartition.id;
      //console.log("selected Partition FOUND queryPartitionUpdate: " + this.selectedPartitionId);
    } else {
      this.partitionList.forEach(p => {
        //new
        if (this.selectedHistoryId) {
          selectedHistory = p.partitionExportHistories.find(h => h.id === this.selectedHistoryId);
          if(selectedHistory) selectedHistory.selected = true;
        } else {
          //original
          selectedHistory = p.partitionExportHistories.find(h => h.selected === true);
        }
        if (selectedHistory) {
          this.selectedHistoryId = selectedHistory.id;
          //console.log("selected Partition History FOUND queryPartitionUpdate: " + this.selectedHistoryId);
          this.selectedPartitionParentId = p.id;
        }
      });
    }
    //console.log("selectedPartitionParentId = " + this.selectedPartitionParentId);
    //if (this.partitionService.selectedPartition)
      //console.log("partitions AFTER selectedPartition.id|exportHistoryId: "+ this.partitionService.selectedPartition.id + "|" + this.partitionService.exportHistoryId );
    this.getPartitionList(this.postQueryPartitionUpdate);
    this.isUpdatingRail = false;
  }

  postQueryPartitionUpdate = () => {
    this.log.debug('executing postQueryPartitionUpdate callback');
    this.partitionList.forEach(partition => {
      if (this.partitionService.selectedPartition && (
        this.partitionService.selectedPartition.id === partition.id ||
        this.partitionService.selectedPartition.origPartitionId === partition.origPartitionId)) {
        //compare origPartitionId to handle export case where partition will have a new id  
        this.log.debug('refresh selected partition ' + partition.id);
        this.partitionService.selectedPartition = partition;
        this.partitionService.updateSelectedPartition(partition);
        this.partitionService.getPartitionExportInProgress(partition.id);
        this.log.debug('refresh selected: exportInProgress value: ' + this.partitionService.exportInProgress);
        this.partitionService.selectedPartition.disabledPTB = false;
        this.partitionService.selectedPartition.partitionExportHistories.forEach(element => {
           if (element.status ==='Sending') {
               this.partitionService.selectedPartition.disabledPTB = true;
            }
        });  
      }
    });
  }


  async onSelect(partition: PartitionDefinition) {
    //console.log("cagl: onSelect: pS.isEditingNameField: " + this.partitionService.isEditingNameField);
    this.partitionService.hideMessageBox = true;
    this.isAllowDelete = true;
    this.initLoad=true;
    //console.log("Resetting isUpdatingRail flag - onSelect");
    this.isUpdatingRail=false;
    this.partitionList.forEach(p => p.currentSelected = false);
    this.partitionList.forEach(p => p.partitionExportHistories.forEach(h => h.selected = false));
    this.partitionService.exportHistoryId = null;

    if (!partition) {
      this.log.debug('receving null as partition');
      return;
    }
    partition.currentSelected = true;
    //console.log("==> cagl: currentSelected: "+ partition.name + "|" + partition.id);
    this.selectedPartitionParentId = partition.id;
    this.partitionService.selectedPartition = partition;
    this.partitionService.pDescription = partition.description;
    if (partition.flId==undefined)
      this.partitionService.fileLayoutId = null;
    else
      this.partitionService.fileLayoutId = partition.flId;
    this.partitionService.enableAutoExport = partition.autoExport === 1? true : false;
    this.partitionService.enableCreateDeltaFile = partition.createDeltaFile === "Y"? true : false;
    this.partitionService.enableConsolidatedTargetList = partition.consolidatedList  === "Y"? true : false;
    this.partitionService.updateSelectedPartition(partition);

    //This captures any dup responses we may receive after we've already selected another
    if (this.partitionService.isWaitingUpdateNameResponse) {
      this.partitionService.isSkipDupMsg = true;
      this.partitionService.isWaitingUpdateNameResponse = false;
      //this.partitionService.isEditingNameField = false;
    } else {
      this.partitionService.isSkipDupMsg = false;
    }

    /*
    if (this.partitionService.isEditingNameField) {
      this.partitionService.isSelectDuringNameEdit = true;
      this.partitionService.isEditingNameField = false;
    } else {
      this.partitionService.isSelectDuringNameEdit = false;
    }
    */
    this.partitionService.isNewPartition = false;

    this.partitionService.isActivePartition = true;
    this.partitionService.exportData = [];
    this.partitionService.showDraftDataWin = true;
    this.partitionService.stopFetching = true;
    this.partitionService.showDraftDataWin = false;

    this.partitionService.blListOptions = this.partitionService.getListOptions(
                                              this.partitionService.blackLists,
                                              this.partitionService.selectedPartition.blId);
    this.partitionService.wlListOptions = this.partitionService.getListOptions(
                                              this.partitionService.whiteLists,
                                              this.partitionService.selectedPartition.wlId);
    this.partitionService.tpListOptions = this.partitionService.getListOptions(
                                              this.partitionService.tpLists,
                                              this.partitionService.selectedPartition.tpId);
    console.log("onSelect: After Init ps list options", Date());                                          

     this.partitionList.forEach(p => {
      //this.partitionService.selectedPartition.disabledPTB = false;
      p.partitionExportHistories.forEach(element => {
         if (element.status === 'Sending') {
             this.partitionService.selectedPartition.disabledPTB = true;
             console.log("Set the status to disabled");
             console.log(this.partitionService.selectedPartition.disabledPTB);
          }
      });
   });
   
   this.partitionService.getPartitionExportInProgress(partition.id);
  }

  async onSelectExport(partition: PartitionDefinition, exportHistory: PartitionExportHistory) {
    //console.log("cagl: onSelectEXPORT: pS.isEditingNameField: " + this.partitionService.isEditingNameField);
    this.partitionService.dataLoadInProgress = true;
    this.partitionService.hideMessageBox = true;
    this.isAllowDelete = true;
    //console.log("Resetting isUpdatingRail flag - onSelectExport");
    this.isUpdatingRail=false;
    this.partitionList.forEach(p => p.currentSelected = false);
    this.partitionList.forEach(p => p.partitionExportHistories.forEach(h => h.selected = false));
    exportHistory.selected = true;
    if (this.partitionService.isEditingNameField) {
      this.partitionService.isSelectDuringNameEdit = true;
      this.partitionService.isEditingNameField = false;
    } else {
      this.partitionService.isSelectDuringNameEdit = false;
    }
    this.partitionService.isNewPartition = false;
    this.selectedPartitionParentId = partition.id;
    this.partitionService.selectedExportHistory = exportHistory;
    this.partitionService.exportData = [];
    this.partitionService.isActivePartition = false;
    this.partitionService.stopFetching = true;
    this.partitionService.loadingExportData = false;
    this.partitionService.exportHistoryId = exportHistory.id;
    this.partitionService.getPartitionExportInProgress(partition.id);
    this.partitionService.getExportPartitionDetail(this.partitionService.selectedExportHistory.partitionId);
    await AppConstants.delay(1000);

    this.log.debug(JsonHelper.toJson(exportHistory));
    return false;
  }

  findListDefById(myLists: Array<ListDefinition>, listId: number): ListDefinition {
    let myList: ListDefinition = new ListDefinition();

    if (!listId) {
      return myList;
    }

    myLists.forEach(item => {
      if (item.id === listId) {
        myList = item;
        return myList;
      }
    });

    return myList;
  }

  getBlackList() {
    this.httpService.get(AppConstants.QueryBlackListURL, [this.postQueryBlackListHandler, this.responseCallBack]);
  }

  postQueryBlackListHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      let tmpLists:Array<ListDefinition> = [];
      const blListArray: Array<ListDefinition> = JsonHelper.parseArray(responseJson.data.lists, ListDefinition);
      for (const item of blListArray) {
        tmpLists.push(item);
      }
      this.partitionService.blackLists = tmpLists;
      this.partitionService.blListOptions = this.partitionService.getListOptions(
        this.partitionService.blackLists,
        this.partitionService.selectedPartition?.blId);

     console.log('After BL load, size=', blListArray.length, Date()); 
    }
  }

  getWhiteList() {
    this.httpService.get(AppConstants.QueryWhiteListURL, [this.postQueryWhiteListHandler, this.responseCallBack]);
  }

  postQueryWhiteListHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionService.whiteLists = [];
      const wlListArray: Array<ListDefinition> = JsonHelper.parseArray(responseJson.data.lists, ListDefinition);
      for (const item of wlListArray) {
        this.partitionService.whiteLists.push(item);
      }
      this.partitionService.wlListOptions = this.partitionService.getListOptions(
        this.partitionService.whiteLists,
        this.partitionService.selectedPartition?.wlId);
      console.log('After WL load, size=', wlListArray.length, Date()); 
    }
  }

  getTPList() {
    this.httpService.get(AppConstants.QueryTPListURL, [this.postQueryTPListHandler, this.responseCallBack]);
  }

  postQueryTPListHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionService.tpLists = [];
      const tpListArray: Array<ListDefinition> = JsonHelper.parseArray(responseJson.data.lists, ListDefinition);
      for (const item of tpListArray) {
        this.partitionService.tpLists.push(item);
      }
      this.partitionService.tpListOptions = this.partitionService.getListOptions(
        this.partitionService.tpLists,
        this.partitionService.selectedPartition?.tpId);
      console.log('After TP load, size=', tpListArray.length, Date()); 
    }
  }

  getFileLayout() {
    this.httpService.get(AppConstants.QueryFileLayoutListURL, [this.postQueryFileLayoutHandler, this.responseCallBack]);
  }

  postQueryFileLayoutHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionService.fileLayouts = [];
      const fileLayoutArray: Array<PartitionFileLayout> = JsonHelper.parseArray(responseJson.data.filelayout, PartitionFileLayout);
      for (const item of fileLayoutArray) {
        this.partitionService.fileLayouts.push(item);
      }
      console.log('After FL load, size=', fileLayoutArray.length, Date()); 
    }
  }

  newPartition(){
    //console.log("cagl: newPartition: pS.isEditingNameField: " + this.partitionService.isEditingNameField);
    //call the rest api
    this.partitionService.hideMessageBox = true;
    this.partitionService.exportHistoryId = null;
    this.selectedPartitionParentId = null;
    this.isAllowDelete = false;
    let newPartition = new PartitionDefinition;
    newPartition.name='';
    let tempName = "Partition " + (this.partitionList.length+1);
    this.clearSelectedPartition();
    this.partitionService.pDescription='';
    this.partitionService.fileLayoutId=null;
    this.partitionService.enableAutoExport = false;
    this.partitionService.enableCreateDeltaFile = true;
    this.partitionService.enableConsolidatedTargetList = false;
    this.partitionService.exportable = false;

    if (this.partitionService.isEditingNameField) {
      this.partitionService.isSkipDupMsg = true;
      this.partitionService.isNewPartition = true;
    } else {
      this.partitionService.isSelectDuringNameEdit = false;
      this.partitionService.isSkipDupMsg = false;
    }

    this.partitionService.blListOptions = this.partitionService.getListOptions(
                                          this.partitionService.blackLists,
                                          newPartition.blId);
    this.partitionService.wlListOptions = this.partitionService.getListOptions(
                                          this.partitionService.whiteLists,
                                          newPartition.wlId);
    this.partitionService.tpListOptions = this.partitionService.getListOptions(
                                          this.partitionService.tpLists,
                                          newPartition.tpId);

    this.partitionService.onCreateNew(newPartition,tempName);
  }

  findSelectedPosition() {
    if (this.partitionList.length > 0) {
      this.selectedPosition = this.partitionList.findIndex(b => b.currentSelected === true);
      if (this.selectedPosition == -1) {
        this.selectedPosition = this.partitionList.findIndex(b => b.id === this.selectedPartitionParentId);
      }
    } else {
      this.selectedPosition = -1;
    }

    if (this.selectedPosition > -1) {
      if (this.selectedPosition == (this.partitionList.length -1)) {
        if (this.selectedPosition == 0) {
          this.selectedPosition = -1;
        } else {
            this.selectedPosition--;
        }
      }
      else
        this.selectedPosition++;
    }
  }

  onDeletePartition() {
    this.showDelConfirm = false;
    this.blocking=true;
    this.httpService.delete(AppConstants.DeletePartitionURL + this.partitionService.selectedPartition.id, [this.postDeletePartition, this.responseCallBack]);
    }

  postDeletePartition = (responseJson: ResponseJson) => {
    let mySelection: PartitionDefinition;
    if (responseJson.status === AppConstants.SUCCESS) {
      this.partitionService.selectedPartition.ruleDefinitions = [];
      // console.log("Delete Parent ID/NAME/Id: " + this.selectedPartitionParentId +"|"+ this.selectedPartitionName + "|"+ this.selectedPartitionId);
      this.findSelectedPosition();
      if (this.selectedPosition > -1 && this.partitionList.length>0) {
        mySelection = this.partitionList[this.selectedPosition];
        //console.log("postDeletePartition()  mySelection details (id/name): " + mySelection.id + "|" + mySelection.name);
        this.router.navigate(['partitions/partitionDetail']);
        this.onSelect(mySelection);
      } else { //delete last
        this.partitionService.selectedPartition = null;
        this.router.navigate(['partitions/partitionHelp']);
        //console.log("postDeletePartition: pos|len: " + this.selectedPosition + "|" + this.partitionList.length);
      }
      this.queryPartitionUpdate();

    }
    this.blocking=false;
  }

  clearSelectedPartition() {
    this.partitionList.forEach(p => p.currentSelected = false);
    this.partitionList.forEach(p => p.partitionExportHistories.forEach(h => h.selected = false));
  }

  onClickShow() {
    if (this.showMorePL) {
      this.showPartitionLimit = this.partitionList.length;
    } else {
      this.showPartitionLimit = this.showMoreLimit;
    }
    this.showMorePL = !this.showMorePL;
  }

  getCustomerSftpConfigured() {
    this.httpService.get(AppConstants.QueryCustomerSftpConfigured, [this.postQueryCustomerSftpConfiguredHandler]);
  }

  postQueryCustomerSftpConfiguredHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      if (responseJson.message == 'true') {
        this.partitionService.sftpConfigured = true;
      } else {
        this.partitionService.sftpConfigured = false;
      }
    } else {
      this.notifService.add({ severity: 'error', summary: 'Error', detail: responseJson.message });
    }
    
  }

  onSelectMousedown() {
    //console.log("cagl: onMouse down");
    if (this.partitionService.isEditingNameField) {
      this.partitionService.isSelectDuringNameEdit = true;
      this.partitionService.isEditingNameField = false;
    } else {
      this.partitionService.isSelectDuringNameEdit = false;
    }
    //console.log("cagl: after onMouse: (selectDuring|skip|isNew): " + this.partitionService.isSelectDuringNameEdit + "|" + this.partitionService.isSkipDupMsg + "|" + this.partitionService.isNewPartition);
  }

}

