import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { HttpService } from '../services/http.service';
import { AuthService } from '../auth';
import { Logger } from '../services/logger.service';
import { ResponseJson,EditItem, PartitionFileLayout, FileLayoutField, PartitionDefinition} from '../models/index';
import { NotificationService } from '../services/notification.service';
import { FilterService} from '../filter/filter-service';
import { CacheService } from '../services/cache.service';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { EditableFieldComponent } from '../editable-field/editable-field.component';
import { ConvertToUnderscorePipe } from '../util/convert-to-underscore.pipe';
import { ConfirmationService } from 'primeng/api';
//import { AnimationGroupPlayer } from '@angular/animations/src/players/animation_group_player';
import { CanDeactivateGuard } from 'app/shared/can-deactivate-guard.service';



@Component({
  selector: 'app-partition-file-layout',
  templateUrl: './partition-file-layout.component.html',
  styleUrls: ['./partition-file-layout.component.scss']
})
export class PartitionFileLayoutComponent extends FilterService implements OnInit, CanDeactivateGuard {

  @ViewChild('partitionFileLayoutNameField') inputListName:EditableFieldComponent;
  pfLayouts: Array<PartitionFileLayout> = [];
  selectedLayout: PartitionFileLayout;
  originalLayout: PartitionFileLayout;
  selectedLayoutName = '';
  standardFileLayoutFieldList: Array<FileLayoutField> = []; //All available fields to be selected
  defaultFileLayoutFieldList: Array<FileLayoutField> = [];  //Required fields for selection
  dbFileLayoutFieldList: Array<FileLayoutField> = [];
  styleClass = 'inline';
  placeholderValue = 'Name the layout';
  showDelConfirm:boolean = false;
  showCustomFieldDialog:boolean = false;
  showUnsavedChangesConfirm = false;
  isAddDialogMode:boolean=true;
  customFieldErrorMsg = '';
  showDelFieldConfirm:boolean = false;
  deleteCustomFieldName = '';
  fileDelimiters = [];
  selectedLayoutItem: EditItem<PartitionFileLayout>;
  customLayoutFieldList: Array<FileLayoutField> = [];
  layoutFieldsSource: Array<FileLayoutField> = [];
  layoutFieldsTarget: Array<FileLayoutField> = [];
  inputCustomField: FileLayoutField;
  workingCustomFieldIndex: any;
  workingSourceFieldIndex: any;
  workingTargetFieldIndex: any;
  workingSelectedListId: any;
  pollListUpdate: Subscription;
  first:boolean = false;
  addNew:boolean = false;
  showMorePFL = true;
  showMoreLimit = AppConstants.ShowMoreLimit;
  showMessage = false;
  showThresholdMessage = false;
  showDuplicateMessage = false;
  showRequiredFieldMessage = false;
  tempName = '';
  isNewLayout = false;
  isAddMore = true;
  isAllowDelete = false;
  maxPartitionFileLayouts: number;
  remainingLayoutCount: number;
  partitionReferenceMessage = '';
  partitionReferenceList = '';
  selectedPosition = 0;
  blocking = false;
  isLayoutChange = false;
  isNameChangeOnly = false;
  isSelectDuringNameEdit = false;
  isSkipDupMsg = false;
  acceptLabel = 'Ok';


  constructor(public log: Logger, public httpService: HttpService, 
    public cacheService: CacheService, public notifService: NotificationService, private authService: AuthService, private confirmationService: ConfirmationService) {
    super(log, httpService, cacheService, notifService);
  }

  ngOnInit() {
    this.getPartitionFileLayoutList();
    this.getFileLayoutDelimiters();
    this.getStandardPartitionLayoutFields();
    this.queryListUpdate();
    this.first = true;
    this.addNew = true;
    this.inputCustomField = new FileLayoutField();
    //console.log("Layouts List Size: " + this.pfLayouts.length);
  }

  queryListUpdate() {
    /* put in a flag to help with automated testing */
    this.log.debug("guiPollingEnabled: " + this.authService.loginUser.guiPollingEnabled);
    if (this.authService.loginUser.guiPollingEnabled) {
      this.pollListUpdate = interval(10000).subscribe(() => {
        this.getPartitionFileLayoutList();
      });
    }
  }

  getFileLayoutDelimiters() {
    this.httpService.get(AppConstants.GetFileLayoutDelimitersURL, [this.postQueryFileLayoutDelimitersHandler, this.responseCallBack]);
  }

  postQueryFileLayoutDelimitersHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {

      if (responseJson.data) {
        //space|tab|,|;|:|~|'|'|^|/|\
        this.fileDelimiters = responseJson.data.match(/('.*?'|[^'|\s]+)(?=\s*||\s*$)/g);
        for (let index = 0; index < this.fileDelimiters.length; index++) {
          let delim = this.fileDelimiters[index];
          if (delim.length>2 && delim.substring(0,1)=== "'" && delim.substring(delim.length-1)=== "'") {
            this.fileDelimiters[index] = delim.substring(1,delim.length - 1);
          }
        }
        //console.log("file delimiters: " + this.fileDelimiters.length + " -- " + this.fileDelimiters);
      } else {
        console.log("No delimiters provided");
      }
    }
  }

  getStandardPartitionLayoutFields() {
    this.httpService.get(AppConstants.GetFileLayoutFieldsURL, [this.postQueryStandardPartitionLayoutFields, this.responseCallBack]);
  }

  postQueryStandardPartitionLayoutFields = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {

      let standardFileLayoutFields = responseJson.data;
      //console.log("file layout field names: " + this.standardFileLayoutFields.length + " | " + this.standardFileLayoutFields);
      standardFileLayoutFields.forEach(b => {
        let customField=false;
        let customFieldValue=null;
        let required=false;
        let checked=true;
        if (b == 'Dial Pattern') {
          required=true;
          this.defaultFileLayoutFieldList.push(new FileLayoutField(b, customField, customFieldValue, required, checked));
        }
        this.standardFileLayoutFieldList.push(new FileLayoutField(b, customField, customFieldValue, required, checked));

        }); 
        /* Can use this if make required fields more dynamic
        this.standardFileLayoutFieldList.forEach(b => {
          if (b.required)
            this.defaultFileLayoutFieldList.push(b);
        });
        */
    }
  }

  setPickListValuesFromJSON() {
    //console.log("JSON LAYOUT_FIELDS: " + this.selectedLayout.layoutFields);
    //this.selectedLayout.layoutFields are a string at this point for some reason, need to convert to FileLayoutField Array
    let evalData = eval(this.selectedLayout.layoutFields.toString());
    let dbLayoutFields: Array<FileLayoutField> = JsonHelper.parseArray(evalData, FileLayoutField);

    this.setPickListValues(dbLayoutFields);
  }

  setPickListValues(dbLayoutFields) {
    //console.log("## Add fields from DB/default");
    let layoutFieldsTargetRef : any = [];
    let layoutFieldsSourceRef : any = [];
    let customLayoutFieldListRef : any = [];

    dbLayoutFields.forEach(b => {
      //From the DB data, add any "checked" field to the Target list and all others to Source
      //don't add non-checked custom fields to the Source list; it will be done afterwards
      if (b.checked)
      layoutFieldsTargetRef.push(b);
      else if (!b.customField)
        layoutFieldsSourceRef.push(b);

      //Add all custom fields
      if (b.customField) {
        customLayoutFieldListRef.push(b);
      }
    });
    this.layoutFieldsTarget = layoutFieldsTargetRef;
    this.layoutFieldsSource = layoutFieldsSourceRef;
    this.customLayoutFieldList = customLayoutFieldListRef;

    //console.log("## Add remaining standard fields");
    //Add any remaining, "unchecked" standard fields to the layoutFieldsSource list
    //if not in Target, then put in Source
    if (this.standardFileLayoutFieldList != null && this.standardFileLayoutFieldList.length>0) {
      this.standardFileLayoutFieldList.forEach(b => {
        if (!this.layoutFieldsTarget.find(c => c.displayName === b.displayName)) {
          if (b.required)
            this.layoutFieldsTarget.push(b);
          else
            this.layoutFieldsSource.push(b);
        }
      }); 
    }

    //console.log("## Add unchecked custom fields to bottom of Source fields");
    dbLayoutFields.forEach(b => {
      //From the DB data, add any "checked" field to the Target list and all others to Source
      if (b.customField && !b.checked) {
        this.layoutFieldsSource.push(b);
      }
    });

    //this.logPickListValues();
  }

  logPickListValues() {
    console.log("\n:::SOURCE VALUES:::");
    this.layoutFieldsSource.forEach(b => b.display());
    console.log("\n::TARGET VALUES:::");
    this.layoutFieldsTarget.forEach(b => b.display());
  }

  getPartitionFileLayoutList() {
    this.httpService.get(AppConstants.QueryFileLayoutListURL, [this.postQueryPartitionLayoutList, this.responseCallBack]);
  }

  postQueryPartitionLayoutList = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {

      let selectedLayout: PartitionFileLayout;

      if (this.pfLayouts.length > 0) {         
        if (this.selectedLayoutName) {
          selectedLayout = this.pfLayouts.find(b => b.name === this.selectedLayoutName);
          if (selectedLayout)
          selectedLayout.selected = true;
        } else {
          selectedLayout = this.pfLayouts.find(b => b.selected === true);
          if (selectedLayout)
            this.selectedLayoutName = selectedLayout.name;
        }
        if (selectedLayout && !this.showDuplicateMessage) {
          this.isAllowDelete = true;
        }
      }
      
      //console.log("Current selectedLayoutName: " + this.selectedLayoutName);

      this.maxPartitionFileLayouts = responseJson.data.maxFileLayouts;

      this.pfLayouts = [];
      const pfLayoutArray: Array<PartitionFileLayout> = JsonHelper.parseArray(responseJson.data.filelayout, PartitionFileLayout);

      for (const item of pfLayoutArray) {
        this.pfLayouts.push(item);
      }

      if (this.pfLayouts.length >= this.maxPartitionFileLayouts) {
        this.isAddMore = false;
      } else {
        this.isAddMore = true;
      }
      //console.log("postPF Query() " + this.pfLayouts.length + "|" + this.maxPartitionFileLayouts + "|" + this.isAddMore );
      
      if (this.first && this.pfLayouts.length>0) {
        this.selectedLayout = this.pfLayouts[0];
        //set the first list selected
        this.pfLayouts.forEach(b => b.selected = false); 
        this.selectedLayout.selected = true;
        this.first = false;
        this.selectedLayoutItem = new EditItem(this.selectedLayout, PartitionFileLayout);
        this.onSelect(this.selectedLayout);
      }

      if (this.selectedLayoutName) {
        selectedLayout = this.pfLayouts.find(b => b.name === this.selectedLayoutName);
        if (selectedLayout)
        selectedLayout.selected = true;
        this.selectedLayoutName = '';
      }   
      
     
    }
  }

  onSelect(myLayout: PartitionFileLayout) {
    if (!this.canDeactivate())
      return false;
    this.isAllowDelete = true;
    this.pfLayouts.forEach(b => b.selected = false);
    myLayout.selected = true;
    this.isNewLayout = false;
    if (this.inputListName && this.inputListName.isEditing) {
      this.isSelectDuringNameEdit = true;
      this.inputListName.isEditing=false;
    } else {
      this.isSelectDuringNameEdit = false;
    }
    this.showThresholdMessage = false;
    this.showDuplicateMessage = false;
    this.showRequiredFieldMessage = false;
    this.isLayoutChange = false;
    //Remove any settings from PickList -- they will be rebuilt in onLayoutLoad
    this.layoutFieldsSource=[];
    this.layoutFieldsTarget=[];
    this.customLayoutFieldList=[];
    this.onLayoutLoad(myLayout);
  }

  async onLayoutLoad(myLayout: PartitionFileLayout) {
    await AppConstants.delay(1000);
    this.selectedLayout = myLayout;
    this.selectedLayout.selected = true;
    this.selectedLayoutItem = new EditItem(this.selectedLayout, PartitionFileLayout);
    this.setPickListValuesFromJSON();
  
    if (myLayout.id) {
      this.inputListName.item = myLayout.name;
      this.inputListName.origItem = myLayout.name;
      this.isNewLayout = false;
    } else {
      console.log("onLayoutLoad(): in ELSE");
    }

    this.originalLayout = JSON.parse(JSON.stringify(this.selectedLayout));
    let evalData = eval(this.selectedLayout.layoutFields.toString());
    this.originalLayout.layoutFields = JsonHelper.parseArray(evalData, FileLayoutField);
  }

  async onCreateNew(myLayout: PartitionFileLayout) {
    this.originalLayout = myLayout;
    this.showThresholdMessage = false;
    this.showDuplicateMessage = false;
    this.showRequiredFieldMessage = false;
    this.isAllowDelete = false;
    await AppConstants.delay(800);
    this.selectedLayout = myLayout;
    this.selectedLayout.selected = true;
    this.selectedLayoutItem = new EditItem(this.selectedLayout, PartitionFileLayout);
    this.setPickListValues(myLayout.layoutFields);
    this.isNewLayout = true;
    this.getPartitionFileLayoutList();  //refresh left rail
  }

  onNewLayout(){
    let newLayout = new PartitionFileLayout;
    //set initial default value
    newLayout.name='';
    newLayout.fileDelimiter = "|";
    newLayout.includeHeader = 'Y';
    newLayout.encloseFieldQuotes = 'N';
    newLayout.description = '';
    newLayout.layoutFields = [...this.defaultFileLayoutFieldList];
    this.layoutFieldsSource=[];
    this.layoutFieldsTarget=[];
    this.customLayoutFieldList=[];
  
    this.pfLayouts.forEach(b => b.selected = false);
    newLayout.selected = true;
    if (this.inputListName && this.inputListName.isEditing) {
      this.isSkipDupMsg = true;
    } else {
      this.isSelectDuringNameEdit = false;
      this.isSkipDupMsg = false;
    }
    this.onCreateNew(newLayout);
  }

  updateLayoutName(layoutName: string) {
    if (this.isSelectDuringNameEdit && this.isNewLayout) {
      //used when do new list->dup->select
      this.isSelectDuringNameEdit = false;
      this.isNewLayout = false;
      return;
    }
    this.isSelectDuringNameEdit = false;
    this.isNameChangeOnly = true;

    if (layoutName) {
      //console.log("updating name");
      this.selectedLayoutItem.item.name = layoutName;
      this.selectedLayoutItem.item.layoutFields = [...this.originalLayout.layoutFields];

      this.httpService.post(JsonHelper.toJson(this.selectedLayout), AppConstants.SaveFileLayoutDefinitionURL, [this.postUpdateLayout]);
    } else {
      //console.log("setting new name");
      if (!this.showThresholdMessage) {
        this.selectedLayoutItem.item.name = 'Partition File Layout ' + (this.pfLayouts.length +1);
        this.httpService.post(JsonHelper.toJson(this.selectedLayout), AppConstants.SaveFileLayoutDefinitionURL, [this.postUpdateLayout]);
      }
    }
  }

  onCancel() {
    this.getPartitionFileLayout();
  }

  onSaveChanges() {
    this.showRequiredFieldMessage=false;

    //if there is a required field in the Source list, then raise error
    this.layoutFieldsSource.forEach(b=> {
      if (b.required==true) {
        //console.log("Required field not selected");
        this.showRequiredFieldMessage=true;
      }
    });

    if (!this.showRequiredFieldMessage) {
      this.updatePartitionFileLayout();
      this.isNameChangeOnly = false;
      this.httpService.post(JsonHelper.toJson(this.selectedLayout), AppConstants.SaveFileLayoutDefinitionURL, [this.postUpdateLayout]);
    }
  }

  updatePartitionFileLayout() {
    //console.log("updatePartitionFileLayout");

    this.dbFileLayoutFieldList = [];
    //Add each field from the Target list in order
    this.layoutFieldsTarget.forEach(b=> {
      b.checked=true; //ensure that all Target fields have checked set to true
      this.dbFileLayoutFieldList.push(b);
    });

    //Add custom fields from the Source list
    this.layoutFieldsSource.forEach(b=> {
      if (b.customField==true) {
        b.checked=false;  //ensure that all custom fields still in Source, have checked set to false
        this.dbFileLayoutFieldList.push(b);
      }
    });

    /*
    console.log("\n:::SAVING layoutField in DB:::");
    this.dbFileLayoutFieldList.forEach(b => {
      b.display();
    });
    */

    this.selectedLayoutItem.item.layoutFields = [...this.dbFileLayoutFieldList];

    if (this.selectedLayoutItem.origItem) {
      //Resetting name back to original to clear duplicate name flag
      this.selectedLayoutItem.item.name = this.selectedLayoutItem.origItem.name;
    }
  }

  postUpdateLayout = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedLayout = JsonHelper.parse(responseJson.data, PartitionFileLayout);
      this.selectedLayoutItem = new EditItem(this.selectedLayout, PartitionFileLayout);
      this.selectedLayoutItem.item.id = this.selectedLayout.id;
      this.responseCallBack(responseJson);
      this.selectedLayoutName = this.selectedLayout.name;
      if (this.isNewLayout) {
        this.checkThresholdLimit();
      }
      this.isNewLayout = false;
      this.selectedLayout.selected=true;
      //Disable Save button if this was a successful save of the entire layout(not only name)
      if (!this.isNameChangeOnly) {
        this.isLayoutChange=false;
      }
      this.isNameChangeOnly=false;
      //console.log("Selected Layout: " + this.selectedLayout.name + " | " + this.selectedLayout.id + " | " + this.selectedLayout.selected);

      this.getPartitionFileLayoutList();  //refresh left rail
    } else {
      //this.messageRegularText=responseJson.message;
      console.log("errorMsg: " + responseJson.message);
      if (responseJson.message.includes("same name")) {
        //skip displaying duplicate message if onSelect in progress
        if (!this.isSkipDupMsg) {
          this.showDuplicateMessage=true;
          this.isAllowDelete = false;
        }
      } else
        this.responseCallBack(responseJson);
    }
    if (this.isSkipDupMsg)
      this.isSkipDupMsg = false;

  }

  getPartitionFileLayout() {
    this.httpService.get(AppConstants.QueryFileLayoutURL + '/' + this.selectedLayout.id, [this.postGetLayout, this.responseCallBack]);
  }

  postGetLayout = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedLayout = JsonHelper.parse(responseJson.data, PartitionFileLayout);
      console.log("selectedLayout: " + this.selectedLayout);
      console.log("selectedLayout.layoutFields:  " + this.selectedLayout.layoutFields);
      this.selectedLayoutItem = new EditItem(this.selectedLayout, PartitionFileLayout);
      this.selectedLayoutItem.item.id = this.selectedLayout.id;
      //this.responseCallBack(responseJson); Needed?
      this.selectedLayoutName = this.selectedLayout.name;
      this.isNewLayout = false;
      this.selectedLayout.selected=true;
      this.onSelect(this.selectedLayout);
    }
  }

  findSelectedPosition() {
    if (this.pfLayouts.length > 0) {
      this.selectedPosition = this.pfLayouts.findIndex(b => b.selected === true);

      if (this.selectedPosition > -1) {
        if (this.selectedPosition == (this.pfLayouts.length -1)) {
          if (this.selectedPosition == 0) {
            this.selectedPosition = -1;
          } else {
              this.selectedPosition--;
          }
        }
        else
          this.selectedPosition++;
      }
    } else {
      this.selectedPosition = -1;
    }
  }

  async onDeleteLayout() {
    let holdLayoutToDelete:PartitionFileLayout;
    holdLayoutToDelete = this.selectedLayout;
    //console.log("onDeleteLayout API: Name|ID: " + this.selectedLayout.name + " | " + this.selectedLayout.id);
    this.showDuplicateMessage = false;
    this.stopBatchLoading = true;
    this.httpService.get(AppConstants.QueryFileLayoutPartitionReferences + '/' + this.selectedLayout.id,
      [this.postPartitionReferences]);
    await AppConstants.delay(1000);
    this.selectedLayout=holdLayoutToDelete;    

    if (this.partitionReferenceList) {
      this.partitionReferenceMessage = '  This layout is associated with the following partitions: ' + this.partitionReferenceList +
      '. Its removal will change the partition data.'
    } else {
      this.partitionReferenceMessage = '';
    }

    this.showDelConfirm=true;
  }

  postPartitionReferences = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.selectedLayout = JsonHelper.parse(responseJson.data, PartitionFileLayout);

      const partitionRefArray: Array<PartitionDefinition> = JsonHelper.parseArray(responseJson.data, PartitionDefinition);
      this.partitionReferenceList = "";
      let seperator = "";
      for (const item of partitionRefArray) {
        this.partitionReferenceList = this.partitionReferenceList + seperator + item.name; 
        seperator = ", ";
      }
    }
  }

  deleteLayout(){
    this.blocking = true;
    this.showDelConfirm=false;
    //console.log("in deleteLayout()");
    this.httpService.delete(AppConstants.DeleteFileLayoutURL + '/' + this.selectedLayout.id, [this.postDeleteLayout, this.responseCallBack]);
  }

  postDeleteLayout = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.findSelectedPosition();
      if (this.selectedPosition > -1 && this.pfLayouts.length>0) {
        this.selectedLayout = this.pfLayouts[this.selectedPosition];
        //console.log("postDeleteLayout()  selectedLayout details (id/name): " + this.selectedLayout.id + "|" + this.selectedLayout.name);
        this.pfLayouts.forEach(b => b.selected = false); 
        this.selectedLayout.selected = true;
        this.selectedLayoutItem = new EditItem(this.selectedLayout, PartitionFileLayout);
        this.onSelect(this.selectedLayout);
      } else {
        this.selectedLayout=null;
      }
      this.getPartitionFileLayoutList();  //refresh left rail
    }
    this.blocking = false;
  }

  async checkThresholdLimit() {
    this.remainingLayoutCount = this.maxPartitionFileLayouts - this.pfLayouts.length-1;
    //console.log("remaining: " + this.remainingLayoutCount + " | max: " + this.maxPartitionFileLayouts + " | threshold: " + AppConstants.ThresholdWarningLimit);
    if ( this.remainingLayoutCount <= AppConstants.ThresholdWarningLimit) {
      this.showThresholdMessage = true;
      await AppConstants.delay(7000);
    }

    if (this.showThresholdMessage)
      this.closeMessage();
      
  }

  onAddFieldDialog(){
    this.isAddDialogMode=true;
    //this.blocking = true;
    this.inputCustomField = new FileLayoutField();
    this.inputCustomField.customField = true;
    this.inputCustomField.checked = false;
    this.inputCustomField.required = false;
    this.showCustomFieldDialog=true;
  }

  onEditFieldDialog(index) {
    this.workingCustomFieldIndex = index;
    this.inputCustomField.displayName = this.customLayoutFieldList[this.workingCustomFieldIndex].displayName;
    this.inputCustomField.customFieldValue = this.customLayoutFieldList[this.workingCustomFieldIndex].customFieldValue;
    this.inputCustomField.checked = this.customLayoutFieldList[this.workingCustomFieldIndex].checked;
    
    this.findPickListFieldIndex(this.inputCustomField.displayName);
    this.isAddDialogMode=false
    //this.blocking = true; 
    this.showCustomFieldDialog=true;
  }

  findPickListFieldIndex(name) {
    //determine if customField is in Source or Target array
    this.workingSourceFieldIndex = this.layoutFieldsSource.findIndex(b => b.displayName === name);
    this.workingTargetFieldIndex = this.layoutFieldsTarget.findIndex(b => b.displayName === name);
  }

  onAddEditField() {
    this.customFieldErrorMsg="";
    //Error Checks    
    if (this.inputCustomField.displayName === undefined || this.inputCustomField.displayName === null || this.inputCustomField.displayName.trim() === '') {
      this.customFieldErrorMsg="Field Name must be populated.";
    }
    else if (this.inputCustomField.displayName.indexOf(' ') >=0) {
      this.customFieldErrorMsg="Field Name must not contain spaces.";
    }
    else if (this.standardFileLayoutFieldList.find(b => b.displayName === this.inputCustomField.displayName)) {
      this.customFieldErrorMsg="Field Name already exists."
    } 
    else if (this.inputCustomField.customFieldValue != null && this.inputCustomField.customFieldValue.indexOf("'") >=0) {
      this.customFieldErrorMsg="Field Value must not contain a single quote.";
    }
    else if (this.customLayoutFieldList.length>0) {
      var tmpPos=-1;
      tmpPos = this.customLayoutFieldList.findIndex(b => b.displayName === this.inputCustomField.displayName);
      if (tmpPos >-1) {
        //The custom field name was found; determine if it is an error or simply an edit 
        if (this.isAddDialogMode)
          this.customFieldErrorMsg="Field Name already exists."
        else if (tmpPos != this.workingCustomFieldIndex) { //EditMode, however the position was for another field
          this.customFieldErrorMsg="Field Name already exists."
        }
      }
    }

    //console.log("Error Message: " + this.customFieldErrorMsg +"!!");
    if (this.customFieldErrorMsg.length<1) {
      //No Errors so Add or Edit record
      if (this.isAddDialogMode) {
        //console.log("ADDING THE FIELD: " + this.inputCustomField.displayName + " | " + this.inputCustomField.displayName);
        this.customLayoutFieldList = [...this.customLayoutFieldList, this.inputCustomField];
        this.layoutFieldsSource = [...this.layoutFieldsSource, this.inputCustomField];
      } else {
        //console.log("EDITING THE FIELD: " + this.inputCustomField.displayName + " | " + this.inputCustomField.displayName);
        this.customLayoutFieldList[this.workingCustomFieldIndex].displayName=this.inputCustomField.displayName;
        this.customLayoutFieldList[this.workingCustomFieldIndex].customFieldValue=this.inputCustomField.customFieldValue;
        
        //Update the editted custom field in either the Source or Target list
        if (this.workingTargetFieldIndex>-1) {
          this.layoutFieldsTarget[this.workingTargetFieldIndex].displayName=this.inputCustomField.displayName;
          this.layoutFieldsTarget[this.workingTargetFieldIndex].customFieldValue=this.inputCustomField.customFieldValue;
        } else if (this.workingSourceFieldIndex>-1) {
          this.layoutFieldsSource[this.workingSourceFieldIndex].displayName=this.inputCustomField.displayName;
          this.layoutFieldsSource[this.workingSourceFieldIndex].customFieldValue=this.inputCustomField.customFieldValue;
        } 
      }
      this.onChange();
      this.closeCustomFieldDialog();
    }
  }

  closeCustomFieldDialog() {
    this.showCustomFieldDialog = false;
    //this.blocking = false;
    this.customFieldErrorMsg="";
    this.inputCustomField = new FileLayoutField();
  }

  onDeleteCustomField(index) {
    this.workingCustomFieldIndex = index;
    this.deleteCustomFieldName=this.customLayoutFieldList[this.workingCustomFieldIndex].displayName;
    this.findPickListFieldIndex(this.deleteCustomFieldName);
    this.showDelFieldConfirm=true;
  }

  deleteCustomField() {
    //Remove the deleted custom field from either the Source or Target list
    if (this.workingTargetFieldIndex>-1) {
      this.layoutFieldsTarget.splice(this.workingTargetFieldIndex,1);
      this.layoutFieldsTarget = [...this.layoutFieldsTarget];
    } else if (this.workingSourceFieldIndex>-1) {
      this.layoutFieldsSource.splice(this.workingSourceFieldIndex,1);
      this.layoutFieldsSource = [...this.layoutFieldsSource];
    }
    //Remove field from Custom list
    this.customLayoutFieldList.splice(this.workingCustomFieldIndex,1);
    this.showDelFieldConfirm=false;
    this.deleteCustomFieldName="";

    this.onChange();
  }

  closeMessage(){
    this.showThresholdMessage = false;
  }

  closeDuplicateMessage(){
    this.showDuplicateMessage = false;
    this.inputListName.isEditing=true;  
    this.inputListName.origItem = this.inputListName.prevName;
    this.inputListName.setFocus();
  }

  closeRequiredFieldMessage(){
    this.showRequiredFieldMessage = false;
  }

  onChange() {
    //console.log("NEW: " + JSON.stringify(this.selectedLayout));
    //console.log("Orig:"  + JSON.stringify(this.originalLayout));

    this.updatePartitionFileLayout();

    if (this.selectedLayout.equals(this.originalLayout)) {
      this.isLayoutChange = false;
    } else {
      this.isLayoutChange = true;
    }
    //console.log("isLayoutChange: " + this.isLayoutChange);
  }

  onContinueSelect() {
    this.selectedLayout.id=this.workingSelectedListId;
    this.isLayoutChange=false;
    this.showUnsavedChangesConfirm=false;
    this.getPartitionFileLayout();
  }

  canDeactivate() {
    if (this.isLayoutChange) {
      /*
      this.confirmationService.confirm({
        header: 'Confirmation',
        message: 'Are you sure you want to proceed? \nThe current page has unsaved changes which cannot be restored after this action.',
        accept: () => {
          console.log("return true");
          return true;
        },
        reject: () => {
          console.log("return false");
          return false;
        }
      });
      */
      return confirm('The current page has unsaved changes. Are you sure you want to proceed?');
    } else 
      return true;
  }

}
