import { Injectable } from '@angular/core';
import { AppConstants, LOGLEVEL } from '../util/app.constant';

@Injectable()
export class Logger {
    debug(data: any) {
        if (AppConstants.LogLevel >= LOGLEVEL.DEBUG) {
            console.debug('[DEBUG- ' + Date() + ']'  + data);
        }
        return;
    }

    info(data: any) {
        if (AppConstants.LogLevel >= LOGLEVEL.INFO) {
            console.log('[INFO- ' + Date() + ']' + data);
        }
        return;
    }

    warn(data: any) {
        if (AppConstants.LogLevel >= LOGLEVEL.WARN) {
            console.warn('[WARN- ' + Date() + ']' + data);
        }
        return;
    }

    error(data: any) {
        if (AppConstants.LogLevel >= LOGLEVEL.ERROR) {
            console.error('[ERROR- ' + Date() + ']' + data);
        }
        return;
    }
}