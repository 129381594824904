import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard  {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        if (state.url && state.url !== '/') {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        } else {
            this.router.navigate(['/login']);
        }
        return false;
    }
}