<div class="col-md-2">
    <div class= "row">
        <div class="col-sm-7">
            <h5>Partitions</h5>
        </div>
        <div class="col-sm-1">
            <div *ngIf = "isAddMore">
                <i id="partitionAddIcon" class="fa fa-plus-circle fa-2x" style="margin-top: 10px; color: #d71b36; cursor: pointer;" aria-hidden="true" routerLink="./partitionDetail" (mousedown)="newPartition()" ></i>
            </div>
            <div *ngIf = "!isAddMore">
                <i class="fa fa-plus-circle fa-2x" style="margin-top: 10px; color:rgba(72,76,85,.5)" aria-hidden="true"></i>
            </div>
        </div>
        <div class="col-sm-1">
            <div *ngIf = "partitionList.length > 0  && isAllowDelete">
                <i id="partitionDeleteIcon" class="fa fa-minus-circle fa-2x" style="margin-top: 10px; color: #33647f; cursor: pointer;" aria-hidden="true" (click)="partitionService.hideMessageBox=true;showDelConfirm=true"></i>
            </div>
            <div *ngIf = "partitionList.length < 1 || !isAllowDelete">
                <i class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color:rgba(72,76,85,.5);"></i>
            </div>
        </div> 
    </div>
        <ul class="partition-list-group">
            <li class="list-group-item list-group-item-info col-sm-12" *ngFor="let partition of partitionList | slice:0:showPartitionLimit">
                <div class="row col-sm-12">
                    <a *ngIf="!partitionService.dataLoadInProgress" class="list-link" routerLink="./partitionDetail" (mousedown)="onSelectMousedown()" (click)="onSelect(partition)" [class.selected]="partition.origPartitionId==partitionService?.selectedPartition?.origPartitionId">
                    <div class="col-sm-1">
                            <i id="{{partition.name | convertToUnderscore: 'collapseIcon'}}" *ngIf="partition.origPartitionId!=partitionService?.selectedPartition?.origPartitionId" class="fa fa-chevron-right fa-lg" style="margin-right: 5px; color:#d71b36"></i>
                            <i id="{{partition.name | convertToUnderscore: 'expandIcon'}}" *ngIf="partition.origPartitionId==partitionService?.selectedPartition?.origPartitionId" class="fa fa-chevron-down fa-lg" style="margin-right: 5px; color:#33647f"></i>
                    </div>
                    <div id="{{partition.name | convertToUnderscore: 'P'}}" class="col-sm-9" *ngIf="partition.id">
                        {{partition.name}}
                    </div>
                    </a>
                    <a *ngIf="partitionService.dataLoadInProgress" class="list-link" [class.selected]="partition.origPartitionId==partitionService?.selectedPartition?.origPartitionId">
                    <div class="col-sm-1">
                            <i id="{{partition.name | convertToUnderscore: 'collapseIcon'}}" *ngIf="partition.origPartitionId!=partitionService?.selectedPartition?.origPartitionId" class="fa fa-chevron-right fa-lg" style="margin-right: 5px; color:#d71b36"></i>
                            <i id="{{partition.name | convertToUnderscore: 'expandIcon'}}" *ngIf="partition.origPartitionId==partitionService?.selectedPartition?.origPartitionId" class="fa fa-chevron-down fa-lg" style="margin-right: 5px; color:#33647f"></i>
                    </div>
                    <div id="{{partition.name | convertToUnderscore: 'P'}}" class="col-sm-9" *ngIf="partition.id">
                        {{partition.name}}
                    </div>
                    </a>
                </div>
                <hr *ngIf="partition.currentSelected" />
                <div class="lists-horiz-padding" id="{{partition.name | convertToUnderscore: 'pArea'}}" [hidden]="partition.origPartitionId!=partitionService?.selectedPartition?.origPartitionId">
                    <ul class="list-group">
                        <li class="list-group-item lists-horiz-padding" *ngIf="partition.status==='Processing'">{{partition.status}} {{partition.lastUpdated | date: 'yyyy-MM-dd HH:mm'}}</li>
                        <li class="list-group-item link lists-horiz-padding" *ngFor="let exportHistory of partition.partitionExportHistories; let i=index">
                            <a *ngIf="!partitionService.dataLoadInProgress" id="{{i+1}}_{{partition.name | convertToUnderscore: 'partitionExportLink'}}" class="list-sublink" [routerLink]="['./partitionExportDetail']" [class.selected]="exportHistory.selected" (click)="onSelectExport(partition, exportHistory)">{{exportHistory.status=="Success" ? "Exported" : exportHistory.status}} {{exportHistory.exportDate | date: 'yyyy-MM-dd HH:mm'}}</a>
                            <a *ngIf="partitionService.dataLoadInProgress" id="{{i+1}}_{{partition.name | convertToUnderscore: 'partitionExportLink'}}" class="list-sublink" [class.selected]="exportHistory.selected">{{exportHistory.status=="Success" ? "Exported" : exportHistory.status}} {{exportHistory.exportDate | date: 'yyyy-MM-dd HH:mm'}}</a>
                            <hr *ngIf="exportHistory.selected" />
                            <div class="fa fa-spinner fa-spin" style="margin-left: 35%;font-size:48px;color:red" *ngIf="exportHistory.selected && partitionService.dataLoadInProgress"></div>
                        </li>
                    </ul>
                </div>   
                <div class="col-sm-12" *ngIf="!partition.id">
                    <span class="partition-name">{{partition.name}}</span>
                    <ul class="list-group">
                        <li class="list-group-item">
                        <a *ngIf="!partitionService.dataLoadInProgress" class="list-link" [routerLink]="['./partitionDetail']" [class.selected]="partition.origPartitionId==partitionService?.selectedPartition?.origPartitionId" (click)="onSelect(partition)">Current</a>
                        <a *ngIf="partitionService.dataLoadInProgress" class="list-link" [class.selected]="partition.origPartitionId==partitionService?.selectedPartition?.origPartitionId">Current</a>
                        </li>
                        <hr *ngIf="partition.currentSelected" />
                    </ul>
                </div>
            </li>
        </ul>
        <div *ngIf = "partitionList.length > showMoreLimit" class="text-center" style="margin-bottom: 10px" >
            <a id="showMorePL" style="margin-top: 10px; margin-right: 5px;" class="showMoreLess-link" (click)="onClickShow()">{{ showMorePL ? "Show More" : "Show Less"}}
                <i *ngIf = "showMorePL" class="fa fa-chevron-down" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                <i *ngIf = "!showMorePL" class="fa fa-chevron-up" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
            </a>
        </div>
</div>
    
    
    <!-- detail section -->
    <div class="col-md-10 container-fluid">
        <router-outlet></router-outlet>
    </div>
    
    <!-- delete confirmation dialog-->
    <div *ngIf="partitionService.selectedPartition">
        <p-dialog header="Confirmation" [(visible)]="showDelConfirm" modal="modal" [width]="350" [minWidth]="200">
            <span style="font-size:13px;font-weight:bold">Are you sure you want to permanently delete {{partitionService.selectedPartition.name}} partition?</span>
            <span style="font-size:13px;font-weight:regular">The partition cannot be restored after this action.</span>
                <p-footer>
                    <button type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:100px;height:26px;margin-left:10px;" (click)="onDeletePartition();">Delete Partition</button>
                    <button type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:70px;height:26px;margin-left:10px;" (click)="showDelConfirm=false">Cancel</button>
                </p-footer>
        </p-dialog>
    </div>  
    <p-blockUI [blocked]="blocking"></p-blockUI>
    <div class="center" *ngIf="blocking">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
    </div>
    <script>
        $(document).ready(function() {
            $('.collapse').on('shown.bs.collapse', function() {
                $(this).parent().find('.fa-chevron-right').removeClass('fa-chevron-right').addClass('fa-chevron-down');
            }).on('hidden.bs.collapse', function() {
                $(this).parent().find('.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-right');
            });
        });
    </script>