import { JsonHelper } from '../util/index';
import { ProviderBillingId, TosTosDesc, Country } from './index';

export class RangeFilter {
  codeList: Array<String> = [];
  iso2List: Array<String> = [];
  countryList: Array<Country> = [];
  tosDescList: Array<TosTosDesc> = [];
  providerList: Array<ProviderBillingId> = [];

  beforeLastObserved: String;
  afterLastObserved: String;
  numOfMonthsSinceLastObserved = '';

  pageNo: Number;
  limit: any;

  addCountry(country: Country) {
    const index = this.countryList.indexOf(country);
    if (index < 0) {
      this.countryList.push(country);
    }
  }

  addCountryCode(code: String) {
    const index = this.codeList.indexOf(code);
    if (index < 0) {
      this.codeList.push(code);
    }
  }

  hasISO2(iso2: string) {
    const myISO2 = this.iso2List.find(x => x === iso2);

    if (myISO2) {
      return true;
    } else {
      return false;
    }
  }

  hasProvider(provider: string) {
    const myProvider = this.providerList.find(x => x.provider === provider);

    if (myProvider) {
      return true;
    } else {
      return false;
    }
  }

  hasTOSDesc(tosItem: TosTosDesc) {
    const myTOS = this.tosDescList.find(x => x.tos === tosItem.tos && x.tosdesc === tosItem.tosdesc);

    if (myTOS) {
      return true;
    } else {
      return false;
    }
  }

  removeAllCountry() {
    this.countryList = [];
  }

  removeAllCode() {
    this.codeList = [];
  }

  removeAllISO2() {
    this.iso2List = [];
  }

  removeAllTos() {
    this.tosDescList = [];
  }

  removeAllProvider() {
    this.providerList = [];
  }

  removeCountryCode(code: String) {
    const index = this.codeList.indexOf(code);

    if (index >= 0) {
      this.codeList.splice(index);
    }
    return;
  }

  addIso2(iso2: String) {
    const index = this.iso2List.indexOf(iso2);

    if (index < 0) {
      this.iso2List.push(iso2);
    }
  }

  removeIso2(iso2: String) {
    const index = this.iso2List.indexOf(iso2);

    if (index >= 0) {
      this.iso2List.splice(index);
    }
  }

  addTos(tos: TosTosDesc) {
    const index = this.tosDescList.indexOf(tos);
    if (index < 0) {
      this.tosDescList.push(tos);
    }
  }

  removeTos(tos: TosTosDesc) {
    const index = this.tosDescList.indexOf(tos);
    if (index >= 0) {
      this.tosDescList.splice(index);
    }

  }

  addProvider(provider: ProviderBillingId) {
    const index = this.providerList.indexOf(provider);
    if (index < 0) {
      this.providerList.push(provider);
    }
  }

  removeProvider(provider: ProviderBillingId) {
    const index = this.providerList.indexOf(provider);
    if (index >= 0) {
      this.providerList.splice(index);
    }
  }

  hasDefinedAnyFilter(): boolean {
    return this.iso2List.length > 0 || this.tosDescList.length > 0 || this.providerList.length > 0;
  }

}


export class TosAndTosDescType {
  tos: String;
  tosDesc: Array<String> = [];

  addTosDesc(desc: String) {
    const index = this.tosDesc.indexOf(desc);
    if (index < 0) {
      this.tosDesc.push(desc);
    }
  }

  removeTosDesc(desc: String) {
    const index = this.tosDesc.indexOf(desc);
    if (index >= 0) {
      this.tosDesc.splice(index);
    }
  }
}


export class Provider {
  provider: String;
  billingIds: Array<String> = [];

  addBillingId(billingId: String) {
    const index = this.billingIds.indexOf(billingId);
    if (index < 0) {
      this.billingIds.push(billingId);
    }
  }

  removeBillingId(billingId: String) {
    const index = this.billingIds.indexOf(billingId);
    if (index >= 0) {
      this.billingIds.splice(index);
    }
  }
}
