import { Country, Provider, ProviderBillingId, TosTosDesc } from "app/models";


export interface FilterObject{
    displayValue(): string;
    isSelected: boolean;
    isAvailable: boolean;
    // display():  string;
    select(search: RegExp):boolean;
}


export class FilterDistinctRecord {
    iso2: string;
    tos: string;
    tosdesc: string;
    billingId: string;
    reference: string;
}


export abstract class FilterDataModel<T extends FilterObject>{
    
    
    constructor(public readonly name: string){
    }
    
    makeAvailable(rec: string[]): void {
        let key = this.filterKey(rec);
        this.allMap.get(key).isAvailable = true;
    }

    allList: T[];
    allMap:  Map<string, T>;
    availableList: T[];
    // searchList: T[];

    selectedCount = 0;
    viewList: T[];
   

    resetAvailable(): void {
        this.allList.forEach(t  => t.isAvailable = false);
    }

    buildAvailableList(): void {
        this.viewList = this.availableList = this.allList.filter(t => t.isAvailable);
    }


    search(searchString: string): T[]{
        if (searchString.length == 0){
            return this.availableList;
        }

        const regexp = new RegExp(searchString, 'i');
        return this.availableList.filter(t => t.select(regexp));
    }

    filter(searchString:string): void{
        this.viewList = this.search(searchString);
    } 


    // selected: Map<string,T>;  //list of selected

    select<X>(obj: X, getKey : (x:X) => string ):boolean{
       if (this.selectedCount == 0)
         return true;
       let key = getKey(obj);
       return this.allMap.get(key).isSelected; 
    }

    toggleSelected(obj:T){
        if (obj.isSelected){
            obj.isSelected = false;
            this.selectedCount--;
        }
        else {
            obj.isSelected = true;
            this.selectedCount++;
        }
    }
   
    getSelectedValues(): string {
        return this.selectedList().map(t => t.displayValue()).join('; ');
    }
    selectedList(): T[] {
        return this.allList.filter(t => t.isSelected);
    }

    clearSelection():void {
        this.selectedList().forEach(t => t.isSelected = false);
        this.selectedCount = 0;
    }

    accepts(rec: string[]): boolean {
        return this.allMap.get(this.filterKey(rec)).isSelected;
    }

    abstract filterKey(rec: string[]): string;


}





export class Filters{
    static fromCountry(country: Country):CountryFilterObject{
        return new CountryFilterObject(country);
    }
    
    static fromProvider(provider: string):ProviderFilterObject{
        return  new ProviderFilterObject(provider);
    }

    static fromTosTosdesc(tos:string, tosdesc:string, display: string): TosTosdescFilterObject{
        return new TosTosdescFilterObject(tos, tosdesc, display);
    }

    static fromRuleId(ruleId: string, name: string): RuleIdFilterObject{
        return  new RuleIdFilterObject(ruleId, name);
    }


}



export class CountryFilterObject  implements FilterObject{
    readonly iso2: string;
    readonly code: string;
    readonly country: string;

    select(search: RegExp): boolean {
        return search.test(this.country) || search.test(this.code) || search.test(this.iso2);
    }

    constructor(c:Country){
        this.iso2 = c.iso2;
        this.code = c.code;
        this.country = c.country;
    }
    displayValue(): string {
        return this.country + ' ' + this.code + ' ' + this.iso2;
    }

    isSelected = false;
    isAvailable = true;

}


export class ProviderFilterObject implements FilterObject{
    select(search: RegExp): boolean {
        return search.test(this.provider);
    }

    constructor(provider: string){
        this.provider = provider;
        this.nameKey = provider.toLowerCase();
    }
    displayValue(): string {
        return this.provider;
    }

    readonly provider: string;
    // billingId: string[];
    readonly nameKey: string;

    isSelected = false;
    isAvailable = true;

}


export class TosTosdescFilterObject  implements FilterObject{
    select(search: RegExp): boolean {
        return search.test(this.display);
    }

    constructor(public readonly tos:string, public readonly  tosdesc:string, public readonly display: string){
    }
    displayValue(): string {
        return this.display;
    }

    isSelected = false;
    isAvailable = true;

}


export class RuleIdFilterObject implements FilterObject{
    
    select(search: RegExp):boolean {
        return search.test(this.name);
    }

    constructor(public readonly ruleId: string, public readonly name: string,){

    }
    displayValue(): string {
        return this.name;
    }

    isSelected = false;
    isAvailable = true;

}

const DEFAULT_ISO2 = 'XX';

export class CountryFilterData extends FilterDataModel<CountryFilterObject>{

    filterKey(rec: string[]): string {
        return rec[FILTER_COLUMNS.ISO2] ? rec[FILTER_COLUMNS.ISO2] : DEFAULT_ISO2 ;
    }

    filterIso2list():string[] {
      return this.allList.filter(c => c.isSelected).map(c => c.iso2);
    }




    constructor(name: string, filters:string[][], iso2map: Map<string, Country>){
        super(name);
        let map = new Map<string, CountryFilterObject>();
        let list:CountryFilterObject[] = []; 

        
        for(var f of filters){
            let key = this.filterKey(f);
            if (map.has(key))
               continue;     
            let country =  Filters.fromCountry(iso2map.get(key));
            list.push(country);
            map.set(country.iso2, country);
        }
        list.sort((c1, c2) => c1.country.localeCompare(c2.country));

        this.availableList =  this.viewList = this.allList = list;
        this.allMap = map;
        
    }
}

enum  FILTER_COLUMNS{
    ISO2, TOS, TOSDESC, PROVIDER, REFERENCE
}


const DEFAULT_PROVIDER = "Unknown";

export class ProviderFilterData extends FilterDataModel<ProviderFilterObject>{
    filterKey(rec: string[]): string {
        return (rec[FILTER_COLUMNS.PROVIDER] ? rec[FILTER_COLUMNS.PROVIDER] : DEFAULT_PROVIDER) . toLowerCase() ;
    }

    filterProviderList(): string[] {
        return this.allList.filter(p => p.isSelected).map(p => p.provider);
    }

    constructor(name: string, filters:string[][]){
        super(name);
        let map = new Map<string, ProviderFilterObject>();

        let list:ProviderFilterObject[] = []; 
        
        for(var f of filters){
            let name = f[FILTER_COLUMNS.PROVIDER] == null ? DEFAULT_PROVIDER : f[FILTER_COLUMNS.PROVIDER];
            let nameKey = name.toLowerCase();

            if (map.has(nameKey))
               continue;     
            let provider =  Filters.fromProvider(name);
            list.push(provider);
            map.set(nameKey, provider); 
        }

        list.sort((p1, p2) => p1.nameKey.localeCompare(p2.nameKey));

        this.availableList =  this.viewList = this.allList = list;
        this.allMap = map;
    }
}  


const DEFAULT_TOS = 'U';
const DEFAULT_TOSDESC = 'Unallocated';

export class TosFilterData extends FilterDataModel<TosTosdescFilterObject>{
  filterKey(rec: string[]): string {
    let tos = rec[FILTER_COLUMNS.TOS] ? rec[FILTER_COLUMNS.TOS] : DEFAULT_TOS;
    let tosdesc = rec[FILTER_COLUMNS.TOSDESC]?  rec[FILTER_COLUMNS.TOSDESC] : DEFAULT_TOSDESC;
    return tos + ':' + tosdesc;
}

  filterTosList(): TosTosDesc[] {
    return this.allList.filter(t => t.isSelected).map(t => {return  {tos: t.tos, tosdesc: t.tosdesc}} );  
  }

  constructor(name:string, filters:string[][]){
    super(name);
    let map = new Map<string, TosTosdescFilterObject>();

    let list:TosTosdescFilterObject[] = []; 
    
    for(var f of filters){
        let tos = f[FILTER_COLUMNS.TOS] == null ? DEFAULT_TOS : f[FILTER_COLUMNS.TOS];
        let tosdesc = f[FILTER_COLUMNS.TOSDESC] == null ? DEFAULT_TOSDESC : f[FILTER_COLUMNS.TOSDESC];
        let displayAndKey = tos + ':' + tosdesc;

        if (map.has(displayAndKey))
           continue;     
        
        let t  = Filters.fromTosTosdesc(tos, tosdesc, displayAndKey );    
        list.push(t);
        map.set(displayAndKey, t); 
    }

    list.sort((p1, p2) => p1.display.localeCompare(p2.display));

    this.availableList =  this.viewList = this.allList = list;
    this.allMap = map;
}
}


export class RulesFilterData extends FilterDataModel<RuleIdFilterObject>{
  filterKey(rec: string[]): string {
      throw new Error("Method not implemented.");
  }
  filterRuleIdList(): string[] {
    throw new Error('Method not implemented.');
  }

  constructor(name: string, filters: string[][]){
    super(name);
    throw new Error('Method not implemented.');
  }

}



export class FilterDataModelGroup{


    constructor(private readonly group: FilterDataModel<FilterObject>[], private readonly filterRecords: string[][]){
    }
    
    // recalculateAvailableFilters(){
    //     const filters = this.group.filter(g => g.selectedCount > 0);
        
    //     this.group.forEach(f => f.resetAvailable());


        

    //     for (var fx  of filters){
    //         this.filterRecords.filter(rec => fx.accepts(rec)).forEach(rec => this.group.forEach(f => f.makeAvailable(rec)));
    //     }    

    //     this.group.forEach(f => f.buildAvailableList());
    // }


    createFiterData(filter: FilterDataModel<FilterObject>): void {
        const others = this.group.filter( g =>  g != filter && g.selectedCount > 0);

        filter.resetAvailable();
        this.filterRecords
           .filter(rec => others.every(fo => fo.accepts(rec)))
           .forEach(rec => filter.makeAvailable(rec));  
        filter.buildAvailableList();     

    } 

    

}

