import { HttpResponse } from "@angular/common/http";
import { PartitionDataDetails, PartitionDefinition, ResponseJson, TosAndTosDescType, TosTosDesc } from "app/models";
import { HttpService } from "app/services";
import { AppConstants, JsonHelper } from "app/util";
import * as moment from "moment";
import { SortMeta } from "primeng/api";
const FileSaver = require('file-saver');
const StreamSaver = require('streamsaver');



export enum LoadStatus {
    Created,
    Loading,
    Success,
    Error,
    Canceled
}

export class PartitionDownloader {

    constructor(url:string, partition: PartitionDefinition , ruleId, filterList: FilterList, sortMeta: SortMeta[], 
        // limit = 100000, 
        private httpService: HttpService,
        private expectedSize:number
        // , initialData: string
        ) {
        this.url = url;
        this.partition = partition;
        this.ruleId = ruleId;
        this.filterList = filterList;
        this.sortMeta = sortMeta;
        // this.limit = limit;
        // this.data = initialData;
    }
    status = LoadStatus.Created;
    partition: PartitionDefinition;
    ruleId;
    filterList: FilterList;
    sortMeta: SortMeta[];
    url:string;
    // limit: number;

    // data: string;

    progressValue = 0;
    currentLength = 0;

    nchunks = 0;

    start(){
        console.log("------dd---------", "start()", Date());
                
        this.status = LoadStatus.Loading;
        this.postDownloadData(this.url, 0, 100000);
    }

    downloadCompleted(){
        console.log("------dd---------", "in downloadCompleted()", Date(), this.nchunks, this.currentLength);
        this.progressValue = 100;
        setTimeout(() =>   this.status = LoadStatus.Success, 1500);
    }


   readonly  UPDATE_PERIOD_MS = 2000; 
   readonly BYTES_PER_SEC = 3000000;
   updateCnt = 0;
           

   readonly  MAX_TIMED_PCT = 70; 


    //we approximate file size, the real size could be more or less. We don't want to show 100% (or more) before the download has been completed. see downloadCompleted()
    readonly  MAX_UPD_PCT = 95;
   
   timedUpdateProgressValue(){
        if (this.progressValue >= this.MAX_UPD_PCT)
           return;

        let num = this.currentLength * 100 / this.expectedSize;
        if (num > this.MAX_UPD_PCT){
            console.log("------dd---------", "reducing >95:" , Date(), num, this.currentLength, this.expectedSize);
            num = this.MAX_UPD_PCT;
        }

        
        let timeBasedNum = ++this.updateCnt * this.UPDATE_PERIOD_MS/1000 * this.BYTES_PER_SEC * 100 /this.expectedSize;

        if (timeBasedNum > this.MAX_TIMED_PCT)
           timeBasedNum =this.MAX_TIMED_PCT;

        num = Math.max(num, timeBasedNum);  

        this.progressValue =  Number(num.toFixed(0));   
        console.log("------dd---------", "timedYpdateProgressValue:" ,Date(), this.progressValue);


        if (this.progressValue < this.MAX_UPD_PCT)
           setTimeout(() => this.timedUpdateProgressValue(),  this.UPDATE_PERIOD_MS);

   }


    updateProgressValue(length:number){

        this.currentLength += length;
        this.nchunks ++;
        if ((this.nchunks % 10) == 0)
            console.log("+++++", "updateProgressValue:" ,Date(), this.nchunks, this.currentLength);
        }

    downloadFile(data, fileName, type: string = 'text/csv;charset=utf-8') {
        let blob = new Blob([data], { type: type});
        FileSaver.saveAs(blob, fileName);
      }
    
      makeDownloadFilename(prefix:string, ext:string):string{
        return  prefix.replace(/\s/g, '-') + moment().format('_YYYYMMDD_HHmmss') + ext;
      }
      
    isLoading(): boolean{
        return this.status == LoadStatus.Loading;
    }



 
    postDownloadData(url: string, page: number, limit: number) {


        let filename = this.makeDownloadFilename(this.partition.name, '.csv');
        let body = makeJsonRequestBody(this.partition.id, this.ruleId, this.filterList, page, limit, this.sortMeta, filename);

        setTimeout(() => this.timedUpdateProgressValue(),  this.UPDATE_PERIOD_MS);

        this.httpService.postStream(body, url,
            (res) => this.postDownloadHandler(res),
            err =>  this.errorHandler(err) );
    }


    postDownloadHandler(response: HttpResponse<Blob>){
        let contentDisposition = response.headers.get('Content-Disposition');
        let fileName = contentDisposition.substring(contentDisposition.lastIndexOf('=') + 1);
    
        if (!window.WritableStream) {
            StreamSaver.WritableStream = WritableStream;
            window.WritableStream = WritableStream;
        }
    
        const fileStream = StreamSaver.createWriteStream(fileName);
        const readableStream = response.body.stream();
    
        let downloader = this;


        console.log("------dd---------", "before pipeTo()", Date());
        let pipe = readableStream
            .pipeThrough(new TransformStream({
                transform(chunk, controller) {
                  downloader.updateProgressValue(chunk.length); 
                  controller.enqueue(chunk);
                },
              }))
            .pipeTo(fileStream);

        console.log("------dd---------", "after pipeto()", Date());
    

        pipe
            .then(() => this.downloadCompleted())
            .catch(err => this.errorHandler(err));

        console.log("------dd---------", "after then()", Date());
    
    }




    
    errorHandler(err){
        console.error("downloader.errorHandler:", err);
        this.status = LoadStatus.Error;
    }

}



export class FilterList {
    iso2list: Array<string>;
    tosList: Array<TosTosDesc>;
    providerList: Array<string>;
    ruleIdList?: Array<string>;
    searchPattern: string //globalFilter
}

export function makeJsonRequestBody(partitionId, ruleId, filterList: FilterList, page: number, limit: number, sortMeta: SortMeta[], filename: string): string {
    let orderBy = sortMeta ? sortMeta.map(s => { return { property: s.field, dir: s.order == 1 ? 'ASC' : 'DESC' } }) : null;
    // , 
    const pageParams = {
        page: page,
        size: limit,
        orderBy: orderBy
    }

    const body = {
        id: partitionId,
        ruleId: ruleId,
        filter: filterList,
        page: pageParams,
        outFileName: filename
    }
    return JSON.stringify(body);
}

