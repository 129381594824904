export class EventNotification {
  id: number;
  customerName: string;
  eventType: string;
  referenceId: number;
  severity: number;
  message: string;
  status: string;
  createTimestamp: Date;
  cknowledgeTimestamp: Date;
  lastUpdatedBy: string;
}