<section *ngIf="!isEditing; else inputField" [class]="styleClass">
    <span>{{origItem}}</span>
    <i id="editNameIcon" class="fa fa-pencil" aria-hidden="true" data-bs-toggle="tooltip" style="margin-left:40px; margin-right:20px;cursor: pointer;" title="Edit" (click)="onEdit()"></i>
</section>

<ng-template #inputField>
    <section [class]="styleClass" *ngIf="hasConfirm; else noConfirm">
        <input id="editableInputFieldConfirm" type="text" autofocus #df [attr.maxlength]="maxLength" required type="text" [(ngModel)]="item">
        <i class="fa fa-check" aria-hidden="true" (click)="update(df, 'normal')"></i>
        <i class="fa fa-times" aria-hidden="true" (click)="cancelChange()"></i>
    </section>
</ng-template>

<ng-template #noConfirm>
    <section [class]="styleClass">
        <input id="editableInputField" type="text" autofocus #df [attr.maxlength]="maxLength" auto-focus required type="text" [(ngModel)]="item" [placeholder]="placeholderText" (keyup.enter)="update(df, 'enter')" (blur)="update(df, 'blur')"/>
    </section>
</ng-template>