import { Type, Skip } from 'serializer.ts/Decorators';
import { RuleDefinition } from './index';
import { DatePipe } from '@angular/common';
// map to ListDetails.java

export class PartitionDataDetails {
  id: number;
  partitionId: number;
  reference: string;
  dataType: string;    //can be B,L,R
  dialPattern: string;
  dialPatternType: string;
  type: string;
  customerDate: string;
  reason: string;
  notes: string;
  country: string;
  code: string;
  ndc: string;
  iso2: string;
  tos: string;
  tosdesc: string;
  provider: string;
  supplement: string;
  billingId: string;
  lemin:number;
  lemax:number;

  constructor() { }

  toDownloadString(delimiter: string, ruleList: Array<RuleDefinition>): string {
    let result = [];
    if (this.type === 'R' || this.type === 'U') {
      result.push(this.getDataSource(ruleList, this.reference));
    } else if (this.type === 'I') {
      result.push(this.getDataSource(ruleList, this.reference));
    } else if (this.type === 'M') {
      result.push('MSRN');
    } else {
      result.push(this.reference);
    }
    result.push(this.country);
    result.push(this.dialPattern);
    if (this.type === 'R' || this.type === 'U') {
      result.push(this.getDialPatternType(ruleList, this.reference));
    } else if (this.type === 'I') {
      result.push(this.dialPatternType);
    } else {
      result.push('');
    }
    if (this.customerDate) {
      result.push(new DatePipe('en-US').transform(this.customerDate, 'MMddyyyy'));
    } else {
      result.push('');
    } 
    result.push(this.reason);
    result.push(this.notes);
    result.push(this.code);
    result.push(this.iso2);
    result.push(this.tos);
    result.push(this.tosdesc);
    result.push(this.ndc);
    result.push(this.provider);
    result.push(this.supplement);
    result.push(this.lemin);
    result.push(this.lemax);
    return result.join(delimiter);
  }


  getDataSource(ruleList: Array<RuleDefinition>, ruleId: string) {
    for (let rule of ruleList) {
      if (rule.id === Number(ruleId)) {
        return rule.dataSource;
      }

    }
    return '';
  }

  getDialPatternType(ruleList: Array<RuleDefinition>, ruleId: string) {
    for (let rule of ruleList) {
      if (rule.id === Number(ruleId)) {
        return rule.dialPatternType;
      }

    }
    return '';
  }

}
