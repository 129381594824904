export class Country {
	id: string;
	country: string;
	code: string;
	iso2: string;
	fips: string;
	iso3: string;
	isono: string;
	clmin: string;
	clmax: string;
	dststart: string;
	dstend: string;

	//for GUI use
	selected: string;
}