import { Component, OnInit } from '@angular/core';
import { ListsService } from '../lists/lists.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import {BaseComponent} from '../util/base.component';
import { AppConstants } from '../util/index';
import { ResponseJson } from '../models/index';
import * as $ from 'jquery';

@Component({
  selector: 'app-list-help',
  templateUrl: './list-help.component.html',
  styleUrls: ['./list-help.component.scss']
})
export class ListHelpComponent extends BaseComponent implements OnInit {

  maxBlackList:number;
  maxWhiteList:number;

  constructor(public listService: ListsService, public notifService: NotificationService, private httpService: HttpService) {
    super(notifService);
  }

  ngOnInit() {
    this.getMaxValues();
  }

  downloadSampleListFile() {
    const data = `DialPattern|CustomerDate|Reason|Notes
17326992303|06212016|Partner provided|Verizon Wireless
37379464430|03112014||
905413971973|12212016|FMS|Internal
    `;
    this.downloadFile(data, 'sample_list_upload_file.csv');
    return false;
  }

  /* No longer used
  uploadFile() { 
    const element = $('#bl-0');
    element[0].click();
  }
  */

  getMaxValues() {
    this.httpService.get(AppConstants.QueryMaxValues, [this.postGetMaxValues]);
  }

  postGetMaxValues = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.maxBlackList = responseJson.data.maxBlackList;
      this.maxWhiteList = responseJson.data.maxWhiteList;
    }
  }


}
