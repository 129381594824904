<div class="container-fluid">
<div class="col-md-2">
    <div id = "tp-list" class="lists-menu">
        <div class="row">
            <div class="col-sm-7" style="padding-left: 5px;">
                <h6><strong>Partner Lists</strong></h6>
            </div>    
            <div class="col-sm-1" *ngIf = "isAddMoreTP">
                <i id="tpAddIcon" class="fa fa-plus-circle fa-2x" style="margin-top: 10px;  color: #d71b36; cursor: pointer;" aria-hidden="true" (mousedown)="newList()" ></i>
            </div>
            <div class="col-sm-1" *ngIf = "!isAddMoreTP">
                <i class="fa fa-plus-circle fa-2x" style="margin-top: 10px;  color: rgba(72,76,85,.5);" aria-hidden="true" ></i>
            </div>
            <div class="col-sm-1">
                <div *ngIf = "tpLists.length > 0 && isAllowDelete">
                    <i id="tpDeleteIcon" class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color: #33647f; cursor: pointer;" aria-hidden="true" (click)="onDeleteList()"></i>
                </div>
                <div *ngIf = "tpLists.length < 1 || !isAllowDelete">
                    <i class="fa fa-minus-circle fa-2x" style="margin-top: 10px;  color:rgba(72,76,85,.5);"></i>
                </div>
            </div>   
        </div>
        <div id = "tpArea">
            <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "tpLists.length <= showMoreLimit">
                <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let tpList of tpLists; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{tpList.listName | convertToUnderscore: 'TP'}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(tpList)" [class.selected]="tpList.selected">{{tpList.listName}}</a>
                    </div>
                    <div [hidden]="!tpList.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "tpLists.length > showMoreLimit && showMoreTP">
                <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let tpList of tpLists | slice:0:showMoreLimit; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{tpList.listName | convertToUnderscore: 'TP'}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(tpList)" [class.selected]="tpList.selected">{{tpList.listName}}</a>
                    </div>
                    <div [hidden]="!tpList.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <ul class="bw-list-group" style="padding-left: 0px;" *ngIf = "tpLists.length > showMoreLimit && !showMoreTP">
                <li class="list-group-item list-group-item-info" style="padding-left: 0px;" *ngFor="let tpList of tpLists; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{tpList.listName | convertToUnderscore: 'TP'}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="onSelect(tpList)" [class.selected]="tpList.selected">{{tpList.listName}}</a>
                    </div>
                    <div [hidden]="!tpList.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <div *ngIf = "tpLists.length > showMoreLimit" class="text-center">
                <a class="showMoreLess-link" style="margin-right: 5px;" (click)="showMoreTP = !showMoreTP">{{ showMoreTP ? "Show More" : "Show Less"}}
                    <i *ngIf = "showMoreTP" class="fa fa-chevron-down" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                    <i *ngIf = "!showMoreTP" class="fa fa-chevron-up" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    </div>
</div> 
<div class="col-md-10 leftrail-leftbar">
    <div class="offset-md-2 col-md-6" *ngIf="tpLists.length == 0 && maxPartnerList>0 && !isNewList && !selectedList">
        <h2 class="text-center">
            Get started by creating a <br> Trusted Partner List
        </h2>
        <p>
            You are allowed {{maxPartnerList}} Trusted Partner lists.  Create and name your first list by clicking the + icon to the left.  Then select providers to add to your partners list.
        </p>
    </div>
    <div *ngIf="tpLists.length>0 || selectedList || isNewList">
        <div class="col-sm-12">
            <h3 class="text-center">
                <app-editable-field [origItem]="selectedListItem.item.listName" [styleClass]="styleClass" [maxLength]="25" [placeholderText]="placeholderValue" (dateModelChange)="updateListName($event)" id="trustedPartnerNameField" #trustedPartnerNameField></app-editable-field>
                <small><i class="fa fa-info-circle" style="cursor: pointer;" (click)="showToolTip();"></i></small>   
            </h3>
            <p class="text-end" *ngIf="!isNewList && selectedList">Last updated {{selectedList.lastUpdated | date: 'yyyy-MM-dd HH:mm'}} </p>
        </div>
        <!--Tooltip for list description -->
        <p-dialog [(visible)]="showDialog" modal="modal" [showHeader]="false" position="top" width="600" [contentStyle]="!isEditDesc ? {'background-color':'#33647f'} :{'background-color':'white'}">
        <div>
            <div id = "dialog-close" class="dialog-close">
                <div class="row">
                    <div class="col-sm-10 toolTipText" style="padding-left:0px;margin-top: -10px;" *ngIf="isEditDesc">
                        List Description
                    </div>
                    <div class="col-sm-10 toolTipText" style="padding-left:0px;color:white;margin-top: -10px;"  *ngIf="!isEditDesc">
                        List Description
                    </div>
                    <div class="col-sm-2" style="margin-top: -15px;">
                        <span class="fa-stack fa-lg close-button" *ngIf="!isEditDesc">
                            <i class="fa fa-times fa-stack-1x fa-inverse" style="cursor: pointer;" (click)="isEditDesc = false;hideMessages()"></i>
                        </span>
                        <span class="fa-stack fa-lg close-button" *ngIf="isEditDesc">
                            <i class="fa fa-times fa-stack-1x fa-inverse" style="color: black;cursor: pointer;" (click)="isEditDesc = false;hideMessages()"></i>
                        </span>
                    </div>
                </div>
                    <div class="row" style="margin-top: 0px;"> 
                    <div id = "toolTipText" *ngIf="!isEditDesc" class="toolTipText">
                        <span><font color="white" [style]= "{'word-break': 'break-word','padding-left': '0px'}">{{selectedListItem.item.description ? selectedListItem.item.description: 'edit to enter your description'}}</font></span>
                        <br>
                        <a (click)="isEditDesc = true"><font color="white" style="padding-left: 175px;cursor: pointer;">Edit</font></a>
                    </div> 
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div *ngIf="isEditDesc">
                            <textarea style="padding-left: 0px;" type="text" class="col-sm-12 toolTipText" maxlength="100" id="listDesc" name="listDesc" [(ngModel)]="selectedListItem.item.description" rows="4" cols="25" > </textarea>
                            <br>
                            <a (click)="onUpdateDesc();hideMessages()"><font color="#1d3a49" style="padding-left: 175px;cursor: pointer;">Save</font></a> 
                        </div>
                    </div>
            </div>
        </div>
        </p-dialog>
        <p-dialog [(visible)] = "showThresholdMessage" [showHeader]="false" width="800" position="top" contentStyleClass="warningDialog">
            <!--<span class="fa-stack fa-lg close-button">
                <i id="closeThresholdMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeMessage();"></i>
            </span>-->
            <span>
                <font color="white" style="font-size:13px;font-weight:bold">
                    You are allowed up to {{maxPartnerList}} trusted partner lists.
                </font>
                <font color="white" style="font-size:13px;font-weight:regular">
                    You have {{remainingTP}} trusted partner lists remaining.
                </font>
            </span>       
        </p-dialog> 
        <div *ngIf="selectedList">
            <p-dialog [(visible)] = "showDuplicateMessage" modal="modal" [showHeader]="false" [style]="{width: '40vw'}" position="top" contentStyleClass="errorDialog" closable="true" closeOnEscape="true">
                <span class="col-sm-11">
                    <font color="white" style="font-size:13px;font-weight:bold">
                        Duplicate Name!
                    </font>
                    <font color="white" style="font-size:13px;font-weight:regular">
                        There is already a trusted partner list with that name.  Please use a different name.
                    </font>
                </span>
                <span class="fa-stack fa-lg close-button col-sm-1" style="cursor: pointer;">
                    <i id="closeDuplicateMessageButton" class="fa fa-times fa-stack-1x fa-inverse" (click)="closeDuplicateMessage()"></i>
                </span>       
            </p-dialog> 
        </div>
        <div class="row">
            <div class="col-5" style="height:auto;padding-left: 40px;">
                <div class="row">
                    <div class="col-md-7" style="padding-left:0px;margin-left: -20px; text-align: center">
                            <b><h6>Selected trusted partners</h6></b>
                    </div>
                </div>
                <div *ngIf="!isNewList && selectedMsrnCounter.msrnProvider > 0">
                    <table style="padding-left:0px;margin-left: -10px;">
                        <tr *ngFor="let provider of selectedPartners ; let i = index">
                            <td style="height:34px;width:320px;font-size:12px;border-bottom:1px solid"><font  style="font-family:Open Sans;text-transform: uppercase;"><span id="{{provider | convertToUnderscore: 'selectedTP'}}">{{provider}}</span></font></td>
                            <td style="height:34px;font-size:12px;border-bottom:1px solid;padding:15px"><span id="{{provider | convertToUnderscore: 'deleteSelectedTP'}}" (click)="delTrustedProvider(provider);" class="fa fa-remove" style="color:rgba(72,76,85,.6);"></span></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-7" style="height:auto;margin-left: -100px;" >
            <div class="row offset-xs-2"> <h6><b>Add providers to your partners list</b></h6></div>
            <div id="country" class="w3-container w3-margin-top filter" *ngIf = "showCountryFilter == true">
                    <div class="col-md-2">
                        <div *ngFor="let item of alphaSearchPattern">
                            <button id="{{item.label | convertToUnderscore: 'country-filter'}}" class="col-md-12 btn alpha-button w3-margin-right" [disabled]="blockingCountrySearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
                        </div>
                    </div>
                    <div class="col-md-10 w3-leftbar">
                        <div class="row">
                            <div class="input-append span12">
                                <div class="col-md-6">
                                    <input id="countrySearchField" type="text" name="searchText" class="search-query" #country size="35" placeholder="Search Country..." (keyup)="filterCountry(country.value)">
                                    <span id="clearCountrySearchField" class="clearer pi pi-times-circle" (click)="country.value=''; filterCountry('')"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row w3-margin-top">
                            <div class="col-md-10">
                                <ul class="list-group list-group-horizontal f32">
                                    <li class="list-group-item col-6" *ngFor="let country of filteredCountryList">
                                        <div id="{{country.country | convertToUnderscore: 'country'}}" class="w3-card-2 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                                            <div class="padding-md-horz w3-container">
                                                <span class="padding-zero-horz text-start col-8">{{ country.country }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <button id="cancelCountryFilterButton" type="button" style="border: none;padding: 0;background: none;margin-bottom:5px" class="btn btn-default no-uppercase no-padding list-btn pull-right" (click)="cancelCountryFilter();" >Cancel <span class="fa fa-remove" style="margin-left:10px"></span></button>
                                </div>
                                <div class="row">
                                    <button id="clearCountryFilterButton" type="button" style="border: none;padding: 0;background: none;margin-bottom:5px" class="btn btn-primary no-uppercase no-padding list-btn pull-right" (click)="clearSelected('country');country.value=''">Clear Filter</button>
                                </div>
                                <div class="row">
                                    <button id="applyCountryFilterButton" class="btn btn-primary no-uppercase no-padding list-btn pull-right" type="button" (click)="filterSelectedCountry();">Apply</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div> 
                <div id="provider" class="w3-container w3-margin-top filter" *ngIf = "showCountryFilter == false">
                    <div class="col-md-2">
                        <div *ngFor="let item of alphaNumSearchPattern">
                            <button id="{{item.label | convertToUnderscore: 'alpha-button'}}"class="col-md-12 btn alpha-button w3-margin-right" [disabled]="blockingMsrnProviderSearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="msrnFilterProvider(item.pattern, item.label)">{{item.label}}</button>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div>
                            <div class="input-append span12">
                                <div class="col-md-6">
                                    <input id="providerSearchField" type="text" name="searchText" class="search-query" size="35" #provider placeholder="Search by provider name ..." (keyup)="filterMsrnProviderSearch(provider.value)">
                                    <span id="clearProviderSearchField" class="pi pi-times-circle" (click)="provider.value=''; filterMsrnProviderSearch('')"></span>
                                </div>
                            </div>
                            <label class="w3-bar-item w3-margin-right">Filter By: </label>
                            <button id="openCountryFilterButton" class="w3-bar-item btn w3-margin-right" aria-expanded="false" [ngClass]="{'btn-default': filterBy === 'country', 'btn-inverse-no-border': filterBy !== 'country'}" (click)="openCountryFilter();">Country</button> 
                        </div>
                        <div class="row w3-margin-top">
                            <div class="col-md-10">
                                <ul class="list-group list-group-horizontal f32">
                                        <li class="list-group-item col-md-6" *ngFor="let item of msrnFilteredProviderBillingIdList">
                                            <div id="{{item.provider | convertToUnderscore: 'provider'}}" class="w3-card-2 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item);update(item);">
                                                <div class="w3-container">
                                                    <div class="text-start">{{item.provider || "Unallocated"}}</div>
                                                </div>
                                            </div>
                                        </li>
                                </ul>
                            </div>
                            <div class="col-md-2" *ngIf= "provider.value !=''">
                                <div class="row">
                                    <button id="selectAllButton" type="button" style="border: none;padding: 0;" class="btn btn-default pull-right no-uppercase no-padding list-btn" (click)="toggleSelectedProvider();provider.value = '';" >Select All</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- delete confirmation dialog-->
            <div *ngIf="selectedList">
                <p-dialog header="Confirmation" [(visible)]="showDelConfirm" modal="modal" [width]="350" [minWidth]="200" dynamic=true>
                    <span style="font-size:13px;font-weight:bold">Are you sure you want to permanently delete {{ selectedList.listName }} trusted partner list?</span>
                    <span style="font-size:13px;font-weight:regular">The list cannot be restored after this action.
                    <p><br>{{ partitionReferenceMessage }}</p></span>
                            <p-footer>
                                <button id="deleteListConfirmationButton" type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:70px;height:26px;margin-left:10px;" (click)="deleteList();">Delete List</button>
                                <button id="deleteListCancelButton" type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:70px;height:26px;margin-left:10px;" (click)="showDelConfirm=false">Cancel</button>
                            </p-footer>
                </p-dialog>
            </div>
        </div>
    </div>
</div>
</div>
<p-blockUI [blocked]="blocking"></p-blockUI>
<div class="center" *ngIf="blocking">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
</div>