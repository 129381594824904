import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { JsonHelper } from '../util/json.helper';
import { ResponseJson } from '../models/response.model';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import {EventService} from '../services/event.service';
//import { Http, Headers, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { AppConstants, CB } from '../util/index';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {
  loginUser: User;
  constructor(private http: HttpClient, private httpService: HttpService, private log: Logger, private eventService: EventService) {
  }

  logout(): void {
    // remove user from local storage to log user out
    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (loginUser) {
      this.httpService.post(JsonHelper.toJson(loginUser), AppConstants.LogoutURL, []);
    }
    sessionStorage.removeItem('currentUser');

    if (this.eventService && this.eventService.pullUserEventUpdate) {
    this.eventService.pullUserEventUpdate.unsubscribe();
    }
    //this.eventService.disconnect();
  }

  login(user: User, successCallback: CB, errCallback: CB): void {
    const myCallbacks: CB[] = [];
    myCallbacks.push(successCallback);
    myCallbacks.push(errCallback);

    this.httpService.post(JsonHelper.toJson(user), AppConstants.LoginURL, myCallbacks);
  }


  isAuthorized(): boolean {
    return sessionStorage.getItem('currentUser') != null;
  }

  hasRole(role: string, ...otherRoles: string[]): boolean {

    if (sessionStorage.getItem('currentUser') == null) {
      return false;
    }

    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (loginUser.role === role) {
      return true;
    } else if (otherRoles && otherRoles.indexOf(loginUser.role) >= 0) {
      return true;
    }

    return false;
  }

  isLoggedInAndHasList(){
    if (sessionStorage.getItem('currentUser') == null) {
      return false;
    }
    const loginUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (loginUser.hasList) {
      return true;
    }
    return false;
  }

}
