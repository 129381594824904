import { serialize, deserialize } from 'serializer.ts/Serializer';

export class JsonHelper {
    static parse<T>(jsonStr: any, MyClass: new (name: string) => T): T {
        let obj: T;
        try {
            obj = deserialize<T>(MyClass, jsonStr); //desTypedJSON.parse(jsonStr, classObj);
        } catch (err) {
            console.error(err);
        }
        return obj;
    }

    static parseArray<T>(jsonArray: any, MyClass: new (name: string) => T): Array<T> {
        let objArray: Array<T> = [];
        try {
            if (!(jsonArray instanceof Array)) {
                throw new Error('input value is not array object');
            }

            objArray = deserialize<T[]>(MyClass, jsonArray);
            return objArray;
        } catch (err) {
            console.error(err);
        }

        return objArray;
    }

    static toJson(obj: any): string {
        let result = '';
        try {
            result = JSON.stringify(obj);
        } catch (err) {
            console.error(err);
        }

        return result;
    }

    static clone<T>(item: any, MyClass: new (name: string) => T): T {
        if (MyClass) {
            return JsonHelper.parse(JSON.parse(JsonHelper.toJson(item)), MyClass);
        } else {
            //this does NOT work for complex data object
            return Object.assign({}, item);
        }
    }

    static cloneArray<T>(item: any, MyClass: new (name: string) => T): Array<T> {
        if (MyClass) {
            return JsonHelper.parseArray(JSON.parse(JsonHelper.toJson(item)), MyClass);
        } else {
            //this does NOT work for complex data object
            return Object.assign({}, item);
        }
    }

    //assume object has id field
    static findElementPosById(arrayObj: Array<any>, targetId): number {
        const index = arrayObj.map(function (x) { return x.id; }).indexOf(targetId);
        return index;
    }

}