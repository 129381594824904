import { Injectable } from '@angular/core';
import '../util/rxjs-operators';
import { JsonHelper } from '../util/json.helper';
import { Logger } from './logger.service';
import { HttpService } from './http.service';
import { AppConstants, CB } from '../util/index';
import { User, Country, ProviderBillingId, TosTosDesc, RangeNDC, EventNotification, ResponseJson, MsrnProviderBillingId } from '../models/index';
import * as moment from 'moment-timezone';

@Injectable()
export class CacheService {
  countryList: Array<Country> = [];
  providerBillingIdList: Array<ProviderBillingId> = [];
  tosList: Array<TosTosDesc> = [];
  countryProviderList: Array<RangeNDC> = [];
  msrnProviderBillingIdList: Array<MsrnProviderBillingId> = [];
  userEvents: Array<EventNotification> = [];
  lastMobileDateSetDate: string;

  constructor(private httpService: HttpService, private log: Logger) {
  };


  cacheCountry() {
    this.httpService.get(AppConstants.QueryCountryURL, [this.postQueryCountryHandler]);
  }

  postQueryCountryHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.countryList = JsonHelper.parseArray(responseJson.data, Country);
      this.log.debug('total country size: ' + this.countryList.length);
    }
  }

  findCountryCode(country: String): String {
    let code = '';

    this.countryList.forEach(item => {
      if (item.country === country) {
        code = item.code;
        return code;
      }
    });

    return code;
  }

  findCountriesByCode(code: String): Array<String> {
    const countries: Array<String> = [];
    this.countryList.forEach(item => {
      if (item.code === code) {
        countries.push(item.country);
      }
    });
    return countries;
  }

  findCountriesByIso2(iso2: String): Country[] {
    const countries: Country[] = [];
    this.countryList.forEach(item => {
      if (item.iso2 === iso2) {
        countries.push(item);
      }
    });
    return countries;
  }

  iso2CountryMap: Map<string, Country>;

  getIso2CountryMap(): Map<string, Country>{
    if (!this.iso2CountryMap){
      this.iso2CountryMap = new Map();
      this.countryList.forEach(item => 
          this.iso2CountryMap.set(item.iso2, item));
    }

    return this.iso2CountryMap;

  }

  findCountriesByIso2Map(iso2: string): Country {
    if (!iso2)
      return null;

    return this.getIso2CountryMap().get(iso2);
  }
  


  cacheProviders() {
    this.httpService.get(AppConstants.QueryProviderURL, [this.postQueryProviderHandler]);
  }

  postQueryProviderHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.providerBillingIdList = JsonHelper.parseArray(responseJson.data, ProviderBillingId);
      this.log.debug('total provider size: ' + this.providerBillingIdList.length);
    }
  }

	findProviderByBillingId(billingId: String): String {
    let provider = '';
    this.providerBillingIdList.forEach(item => {
      if (item.billingId === billingId) {
        provider = item.provider;
        return provider;
      }
    });

    return provider;
  }

	findBillingIdsByProvider(provider: String): Array<String> {
    const billingIds: Array<String> = [];
    this.providerBillingIdList.forEach(item => {
      if (item.provider === provider) {
        billingIds.push(item.billingId);
      }
    });

    return billingIds;
  }

  cacheTos() {
    this.httpService.get(AppConstants.QueryTosURL, [this.postQueryTosHandler]);
  }

  postQueryTosHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.tosList = JsonHelper.parseArray(responseJson.data, TosTosDesc);
      this.log.debug('total tos size: ' + this.tosList.length);
    }
  }


  cacheCountryProvider() {
    this.httpService.get(AppConstants.QueryCountryProviderURL, [this.postQueryCountryProviderHandler]);
  }

  postQueryCountryProviderHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.countryProviderList = JsonHelper.parseArray(responseJson.data, RangeNDC);
      this.log.debug('total tos size: ' + this.countryProviderList.length);
    }
  }



  queryUserEvents() {
    this.httpService.get(AppConstants.QueryUserEventsURL, [this.postQueryEventsHandler]);
  }

  postQueryEventsHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.userEvents = JsonHelper.parseArray(responseJson.data, EventNotification);

      this.userEvents.forEach(event => {
        if (event.eventType === 'RefreshData') {
          this.lastMobileDateSetDate = moment(event.createTimestamp, 'YYYY-MM-DD').format('YYYY-MM-DD');
          this.log.debug('last mobile data load date is: ' + this.lastMobileDateSetDate);
          return;
        }
      });
    }
  }

  cacheMsrnProviders() {
    this.httpService.get(AppConstants.QueryMsrnProviderURL, [this.postQueryMsrnProviderHandler]);
  }

  postQueryMsrnProviderHandler = (responseJson: ResponseJson) => {
    if (responseJson.status === AppConstants.SUCCESS) {
      this.msrnProviderBillingIdList = JsonHelper.parseArray(responseJson.data, MsrnProviderBillingId);
      this.log.debug('total msrn provider size: ' + this.msrnProviderBillingIdList.length);
    }
  }

  findMsrnProviderByBillingId(billingId: String): String {
    let msrnProvider = '';
    this.msrnProviderBillingIdList.forEach(item => {
      if (item.billingId === billingId) {
        msrnProvider = item.provider;
        return msrnProvider;
      }
    });

    return msrnProvider;
  }

	findBillingIdsByMsrnProvider(provider: String): Array<String> {
    const billingIds: Array<String> = [];
    this.msrnProviderBillingIdList.forEach(item => {
      if (item.provider === provider) {
        billingIds.push(item.billingId);
      }
    });

    return billingIds;
  }



}
