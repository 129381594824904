<div class="row">
    <h3 class="col-md-12 text-center">
        {{partitionService.selectedPartition.name}}
    </h3>
</div>

<div class="ui-grid ui-grid-responsive ui-fluid" style="font-size:14px;padding:20px;border-bottom:1px solid #D5D5D5;">
    <div class="ui-grid-row">
        <div class="ui-grid-col-3 ">
            <div class="w2-margin-left" >
                <h5>
                <div class="w3-margin-left">
                    <label class="w3-small" [class.datasource-bold]="'Range' === rule.dataSource">Range</label>
                    <label class="switch">
                        <input type="checkbox" [disabled]="!partitionService.isActivePartition"[checked]="rule.dataSource === 'IPRN'" (change)="selectDataSource($event)">
                        <div class="slider round"></div>
                    </label>
                    <label class="w3-small" [class.datasource-bold]="'IPRN'=== rule.dataSource ">IPRN</label>
                </div>
            </h5>
            </div>
            <div  class="w3-margin-left" *ngIf="rule.dataSource==='IPRN'">
                <div class="row col-md-2">
                    <p-dropdown [options]="dpTypes" [(ngModel)]="rule.dialPatternType" (onChange)=selectDialPattern($event)></p-dropdown>
                </div>
            </div>
        </div>

        <div class="ui-grid-col-8">
            <div class="ui-grid ui-grid-responsive ui-fluid ">
                <div class="w3-card-rule col-md-2 w3-center" style="cursor: pointer;" (click)="openFilterInPartition('country')">
                    <div class="w3-container margin-top5">
                        <p class="w3-small">Country Code & ISO2</p>
                    </div>

                    <div class="w3-container">
                        <p class="w3-small">{{getTipContents(rule.rangeQueryFilter, 'country')}}</p>
                    </div>
                </div>
                <div class="w3-card-rule col-md-2 w3-center" style="cursor: pointer;" (click)="openFilterInPartition('tos')">
                    <div class="w3-container margin-top5">
                        <p class="w3-small">TOS & TOS Description</p>
                    </div>

                    <div class="w3-container">
                        <p class="w3-small">{{getTipContents(rule.rangeQueryFilter, 'tos')}}</p>
                    </div>
                </div>
                <div class="w3-card-rule col-md-2 w3-center" style="cursor: pointer;" (click)="openFilterInPartition('provider')">
                    <div class="w3-container margin-top5">
                        <p class="w3-small">Provider</p>
                    </div>

                    <footer class="w3-container">
                        <p class="w3-small">{{selectedCounter.provider}} selected</p>
                    </footer>
                </div>
                <div class="w3-card-rule col-md-2 w3-center" *ngIf="rule.dataSource==='IPRN'" style="cursor: pointer;"  (click)="openFilterInPartition('lastObserved')">
                    <div class="w3-container margin-top5">
                        <p class="w3-small">Last Observed</p>
                    </div>

                    <footer class="w3-container">
                        <p class="w3-small">{{rangeFilter.numOfMonthsSinceLastObserved}} months</p>
                    </footer>

                </div>
            </div>
        </div>

        <div class="ui-grid-col-3" *ngIf="partitionService.isActivePartition">
            <button id="cancelButton" type="button" class="btn btn-info pull-right w3-margin-left no-uppercase no-padding list-btn" (click)="goBack()">Cancel</button>
            <button id="saveChangesButton" type="button" class="btn btn-default pull-right no-uppercase no-padding list-btn" [disabled]="partitionService.selectedPartition.status==='Processing'" (click)="saveRule()">Save Changes</button>
        </div>
    </div>
</div>

<!-- filter panel -->
<p-dialog [(visible)]="showFilterWin" modal="modal" [style]="{width: '90vw'}" [contentStyle]="{'overflow-y': 'auto','height': '60vw'}" [resizable]="true" (onHide)="refreshRule()">
    <div id="country" *ngIf="filterBy==='country'" class="w3-container no-padding">
        <div class="col-md-2">
            <div class="w3-margin-left filter-container"> 
            <button class="col-md-3   btn  alpha-button no-uppercase w3-margin-right w3-margin-bottom no-padding"  [disabled]="blockingCountrySearch" style="width:120px;height:24px" [ngClass]="{'btn-default': groupSelected, 'btn-inverse': !groupSelected}"  (click)="showCountryGroups()">Country Groups</button>
            </div>
            <div>
            <ng-container *ngFor="let item of alphaSearchPattern">
                <div class="w3-margin-right"> 
                <button class="col-md-2 alpha-button btn w3-margin-left w3-margin-bottom" [disabled]="blockingCountrySearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterCountryOnly(item.pattern, item.label)">{{item.label}}</button>
                </div>
            </ng-container>
            </div>
        </div>
        <div class="col-md-9 w3-leftbar">
            <div class="row">
                <div class="input-append span12">
                    <div class="w3-margin col-md-12 row" *ngIf="selectedCounter.country>0">
                        <div class="row">
                            <span class="w3-text-blue w3-small">The selected Country Code(s) and ISO2 will be applied</span>
                        </div>
                        <div class="background-gray">
                            <div class="w3-left-align w3-margin-top col-md-8"> 

                                <span class="w3-small">{{getSeletionValues('country')}}</span>
                            </div>
                               <div class="w3-right-align"> 
                               <button type="button" class="btn btn-info pull-right w3-margin no-uppercase no-padding list-btn" (click)="clearSelected('country')">Clear</button>
                            </div>
               
                        </div>
                    </div>
                    <div class="w3-margin-top">
                        <div class="col-md-4">
                            <input type="text" name="searchText" class="search-query w3-margin-left" #country size="35" placeholder="Search Country, Code or ISO2 ..." (keyup)="filterCountry(country.value)">
                            <span class="clearer pi pi-times-circle" (click)="country.value=''; filterCountry('')"></span>
                        </div>
                        <div class="col-md-4">
                            <a class="link" (click)="selectAll('country')">SELECT ALL &lt;{{countryFilterLabel}}&gt;</a>
                        </div>
                        <div class="col-md-4">
                            <a class="link" (click)="clearSelectedFilter('country',countryFilterLabel)">CLEAR ALL &lt;{{countryFilterLabel}}&gt;</a>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="groupSelected">
                <div class="row">
                    <div class="w3-margin-top w3-margin-bottom col-md-8 col-sm-4">
                        <table>
                            <tr>
                                <td class="w3-margin-bottom  w3-margin-right col-md-2 top-align" style="min-width:240px; max-width:240px"><span class="w3-left-align w3-text-blue col-md-5"><strong>Country Groups</strong></span></td>
                                <td class="w3-margin-bottom col-md-6 top-align">
                                    <div>
                                    <span class="w3-left-align w3-margin-left w3-text-blue col-md-5">Country</span>
                                    <span class="w3-left-align w3-text-blue col-md-1">Code</span>
                                    <span class="w3-left-align  w3-margin-left w3-text-blue col-md-1">ISO2</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <table class="w3-margin-top">
                    <tr>
                        <td class="w3-margin-top col-md-2 aligntop"  style="height:auto; width:25% ">
                            <ng-template ngFor let-cGroup [ngForOf]="cGroups">
                                <div class="col-sm-8 lists-group-horiz-padding" style="min-width:250px">
                                    <a id="{{cGroup.id}}" class="list-link" style="text-decoration:none;cursor: pointer;" (mousedown)="filterCountryByGroupSelect(cGroup)" [class.selected]="cGroup.selected">{{cGroup.name}}</a>
                                </div>
                                    <div [hidden]="!cGroup.selected" class="col-sm-11 lists-group-horiz-padding">
                                    <hr/>
                                </div>
                            </ng-template>
                        </td>
                        <td class="w3-leftbar w3-margin-bottom col-md-6 no-padding aligntop">
                            <ul class="list-group list-group-horizontal f32 ">
                                <li class="list-group-item col-md-4 col-sm-4" *ngFor="let country of filteredCountryList">
                                    <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                                        <div class="w3-container " style=" min-width:240px; max-width:240px">
                                            <span class="w3-left-align col-md-7">{{ country.country }}</span>
                                            <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                            <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    </table>
                </div>
            </ng-container>
            <ng-container *ngIf="!groupSelected">
            <div class="row">
                <div class="w3-margin-top col-md-3 col-sm-4">
                    <span class="w3-left-align w3-text-blue col-md-8">Country</span>
                    <span class="w3-left-align w3-text-blue col-md-2">Code</span>
                    <span class="w3-right-align w3-text-blue col-md-2">ISO2</span>
                </div>
            </div>
            <div class="row w3-margin-top">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let country of filteredCountryList">
                        <div class="w3-card-4 no-shadow" [ngClass]="country.selected" (click)="toggleSelection(country)">
                            <div class="w3-container">
                                <span class="w3-left-align col-md-8">{{ country.country }}</span>
                                <span class="w3-left-align col-md-2">{{ country.code }}</span>
                                <span class="w3-left-align col-md-2">{{ country.iso2 }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </ng-container>
        </div>
    </div>

    <div id="tos" *ngIf="filterBy==='tos'" class="w3-container filter">
        <div class="col-md-2">
            <div class="btn-group-veritical col-md-6" *ngFor="let item of tosSearchPattern">
                <button class="col-md-12 btn alpha-button w3-margin-right w3-margin-bottom" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterTos(item.pattern, item.label)">{{item.label}}</button>
            </div>
        </div>
        <div class="col-md-10 w3-leftbar">
            <div class="row">
                <div class="input-append span12">
                    <div class="w3-margin col-md-12 row" *ngIf="selectedCounter.tos>0">
                        <div class="row">
                            <span class="w3-text-blue w3-small">The selected TOS or TOS Descriptions will be applied</span>
                        </div>
                        <div class="background-gray">
                        <div class="w3-left-align w3-margin-top col-md-8">
                            <span class="w3-small">{{getSeletionValues('tos')}}</span>
                        </div>
                        <div class="w3-right-align">
                            <button type="button" class="btn btn-info pull-right w3-margin no-uppercase no-padding list-btn" (click)="clearSelected('tos')">Clear</button>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #tos placeholder="Search TOS Description ..." (keyup)="filterTosDesc(tos.value)">
                        <span class="clearer pi pi-times-circle" (click)="tos.value=''; filterTosDesc('')"></span>
                    </div>
                    <div class="col-md-2">
                        <a class="link" (click)="selectAll('tos')">SELECT ALL &lt;{{filterTosLabel}}&gt;</a>
                    </div>
                    <div class="col-md-2">
                        <a class="link" (click)="clearSelectedFilter('tos',filterTosLabel)">CLEAR ALL &lt;{{filterTosLabel}}&gt;</a>
                    </div>
                </div>
            </div>
            
            <div class="row w3-margin">
                <span class="w3-text-blue col-md-8">TOS:TOS Description</span>
            </div>
            <div class="row w3-margin">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-2 col-sm-3" *ngFor="let item of filteredTOSList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                            <div class="w3-container">
                                <div class="w3-left-align">{{item.tos}}: {{item.tosdesc || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="provider" *ngIf="filterBy==='provider'"  class="w3-container filter">
        <div class="col-md-2">
            <div class="btn-group-veritical col-md-4" *ngFor="let item of alphaNumSearchPattern">
                <button class="col-md-12 btn alpha-button w3-margin-right w3-margin-bottom" [disabled]="blockingProviderSearch" [ngClass]="{'btn-default': item.selected, 'btn-inverse': !item.selected}" (click)="filterProvider(item.pattern, item.label)">{{item.label}}</button>
            </div>
        </div>
        <div class="col-md-10 w3-leftbar">
            <div class="row">
                <div class="input-append span12">
                    <div class="w3-margin col-md-12 row" *ngIf="selectedCounter.provider>0">
                        <div class="row">
                            <span class="w3-text-blue w3-small">The selected Providers will be applied.    </span><span class="w3-text-blue w3-small" *ngIf="!selectedCounter.country">It is recommended that you choose a ISO2, or Code before Provider</span>
                        </div>
                        <div class="background-gray">
                            <div class="w3-left-align w3-margin-top col-md-8">
                                <span class="w3-small">{{getSeletionValues('provider')}}</span>
                            </div>
                            <div class="w3-right-align">
                                <button type="button" class="btn btn-info pull-right w3-margin no-uppercase no-padding list-btn" (click)="clearSelected('provider')">Clear</button>
                            </div>
                        </div>
                    </div>
                    <div class="w3-margin-top">
                        <div class="col-md-4">
                            <input type="text" name="searchText" class="search-query w3-margin-left" size="35" #provider placeholder="Search Provider ..." (keyup)="filterProviderSearch(provider.value)">
                            <span class="clearer pi pi-times-circle" (click)="provider.value=''; filterProviderSearch('')"></span>
                        </div>
                        <div class="col-md-2 w3-margin-left">
                            <a class="link" (click)="selectAll('provider')">SELECT ALL &lt;{{filterProviderLabel}}&gt;</a>
                        </div>
                        <div class="col-md-2 w3-margin-right">
                            <a class="link" (click)="clearSelectedFilter('provider',filterProviderLabel)">CLEAR ALL &lt;{{filterProviderLabel}}&gt;</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row w3-margin-top">
                <span class="w3-center-align w3-text-blue w3-margin-left col-md-2">Provider</span>
            </div>
            <div class="row w3-margin">
                <ul class="list-group list-group-horizontal f32">
                    <li class="list-group-item col-md-3 col-sm-4" *ngFor="let item of filteredProviderBillingIdList">
                        <div class="w3-card-4 no-shadow" [ngClass]="item.selected" (click)="toggleSelection(item)">
                            <div class="w3-container">
                                <div class="text-start">{{item.provider || "Unallocated"}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>

    <div id="lastObserved" *ngIf="filterBy==='lastObserved'" class="w3-container filter" style="width: 500px;">
        <div class="row">
            <div class="col-md-6 text-end helperText1">
                Show Records with fraud from
            </div>    
        </div>
        <div class="row">
            <div class="col-md-3 text-start helperText2">
                Rolling Date:
            </div>
            <div class="col-md-9 text-start">
                <input type="number" min="1" max="99" (keyup)="limitMaxValue($event.target)" id="lastObserved" name="lastObserved" [disabled]="!partitionService.isActivePartition" [(ngModel)]="rangeFilter.numOfMonthsSinceLastObserved">
                <span class="w3-margin-left helperText2">month(s) before the current month</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 text-end helperText3">
                Maximum of 99 months
            </div>    
        </div>
        
    </div>
</p-dialog>

<!-- data panel -->
<p-table #dt [columns]="partitionRuleCols" [value]="filteredRangeDataList" [rows]="10" [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[10,30,50,100,250,500,750,1000]" 
resizableColumns="true" columnResizeMode="expand" [globalFilterFields]="filterFieldCols"
[responsive]="true" [style]="{'margin-top':'30px'}" styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption">
      <div class="p-table-globalfilter-container">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input pInputText #gb id="searchInput" type="text" size="50" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
          <span class="pi pi-times-circle" (click)="clearSearch(gb, dt);"></span>
      </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    {{dataSource}}
      <tr>
          <th class="code-tbl-header" [style.width.%]="col.width" *ngFor="let col of columns"
              [pSortableColumn]="col.field" [pTooltip]="col.toolTip" tooltipStyleClass="status-tooltip"
              tooltipPosition="top">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
          <td class="code-tbl-body" *ngFor="let col of columns">
            <span *ngIf="col.field!='customerDate'">
                {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field=='customerDate'">
                {{rowData[col.field] | date:'yyyy-MM-dd'}}
            </span>
          </td>
      </tr>
  </ng-template>
  <ng-template id="dtFooter" pTemplate="footer">
      <tr>
          <td  [attr.colspan]="partitionRuleCols.length">
            {{rangeQueryStatus}} <i *ngIf="isLoading()" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
          </td>
      </tr>
  </ng-template>
  <!--
  <ng-template pTemplate="emptymessage" let-rows>
      <tr>
          <td [attr.colspan]="rows.length">
              No partition records.
          </td>
      </tr>
  </ng-template>
  -->
</p-table>