<div class="container-fluid">
    <div class="col-md-2 lists-menu left">
            <div class="row">
                    <div class="col-sm-1" *ngIf = "addNewWl">
                        <i id="expandWL" class="fa fa-chevron-down fa-lg" style="margin-top: 10px;color:#33647f" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#wlArea" aria-expanded="false" aria-controls="wlArea" (click)="addNewWl = false;"></i>
                    </div>
                    <div class="col-sm-1" *ngIf = "!addNewWl">
                        <i id="collapseWL" class="fa fa-chevron-right fa-lg" style="margin-top: 10px;color:#d71b36" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#wlArea" aria-expanded="false" aria-controls="wlArea" (click)="addNewWl = true;"></i>
                    </div>
                    <div class="col-sm-7" style="padding-left: 5px;">
                        <h6><strong>Allow Lists</strong></h6>
                    </div>
                    <div class="col-sm-1">
                        <div *ngIf = "isAddMoreWL">
                                <i id="wlAddIcon" class="fa fa-plus-circle fa-2x" style="margin-top: 10px; color: #d71b36; cursor: pointer;" aria-hidden="true" routerLink="./listDetail" (mousedown)="newList('white')"></i>
                            </div>
                            <div *ngIf = "!isAddMoreWL">
                                <i class="fa fa-plus-circle fa-2x" style="margin-top: 10px; color:rgba(72,76,85,.5);" aria-hidden="true"></i>
                            </div>
                    </div>
                    <div class="col-sm-1">
                        <div *ngIf = "whiteLists.length > 0 && listService.isAllowDeleteWL">
                            <i id="wlDeleteIcon" class="fa fa-minus-circle fa-2x" style="margin-top: 10px; color: #33647f; cursor: pointer;" aria-hidden="true" (click)="onDeleteList()"></i>
                        </div>
                        <div *ngIf = "whiteLists.length < 1 || !listService.isAllowDeleteWL">
                                <i class="fa fa-minus-circle fa-2x" style="margin-top: 10px; color:rgba(72,76,85,.5);" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <div class="collapse in show" id="wlArea">
                    <ul class="bw-list-group" *ngIf = "whiteLists.length <= showMoreLimit">
                        <li class="list-group-item list-group-item-info" *ngFor="let whiteList of whiteLists; let i=index">
                            <div class="col-sm-10 lists-horiz-padding">
                                <a id="{{whiteList.listName | convertToUnderscore: 'WL'}}" class="list-link" routerLink="./listDetail" (mousedown)="onSelect(whiteList)" [class.selected]="whiteList.selected">{{whiteList.listName}}</a>
                            </div>
                            <div [hidden]="!whiteList.selected" class="col-sm-12 lists-horiz-padding">
                                <hr/>
                            </div>
                        </li>
                    </ul>
                    <ul class="bw-list-group" *ngIf = "whiteLists.length > showMoreLimit && showMoreWL">
                        <li class="list-group-item list-group-item-info" *ngFor="let whiteList of whiteLists | slice:0:showMoreLimit;  let i=index">
                            <div class="col-sm-10 lists-horiz-padding">
                                <a id="{{whiteList.listName | convertToUnderscore: 'WL'}}" class="list-link" routerLink="./listDetail" (mousedown)="onSelect(whiteList)" [class.selected]="whiteList.selected">{{whiteList.listName}}</a>
                            </div>
                            <div [hidden]="!whiteList.selected" class="col-sm-12 lists-horiz-padding">
                                <hr/>
                            </div>
                        </li>
                    </ul>
                    <ul class="bw-list-group" *ngIf = "whiteLists.length > showMoreLimit && !showMoreWL">
                        <li class="list-group-item list-group-item-info" *ngFor="let whiteList of whiteLists;  let i=index">
                            <div class="col-sm-10 lists-horiz-padding">
                                <a id="{{whiteList.listName | convertToUnderscore: 'WL'}}" class="list-link" routerLink="./listDetail" (mousedown)="onSelect(whiteList)" [class.selected]="whiteList.selected">{{whiteList.listName}}</a>
                            </div>
                            <div [hidden]="!whiteList.selected" class="col-sm-12 lists-horiz-padding">
                                <hr/>
                            </div>
                        </li>
                    </ul>
                    <div *ngIf = "whiteLists.length > showMoreLimit" class="text-center" style="margin-bottom: 10px" >
                        <a id="showWL" style="margin-top: 10px; margin-right: 5px;" class="showMoreLess-link" (click)="showMoreWL = !showMoreWL">{{ showMoreWL ? "Show More" : "Show Less"}}
                            <i *ngIf = "showMoreWL" class="fa fa-chevron-down" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                            <i *ngIf = "!showMoreWL" class="fa fa-chevron-up" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>

        <div class="row">
            <div class="col-sm-1" *ngIf = "addNewBl">
               <i id="expandBL" class="fa fa-chevron-down fa-lg" style="margin-top: 10px;color:#33647f;  " aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#blArea" aria-expanded="false" aria-controls="blArea" (click)="addNewBl = false;"></i>
            </div>
            <div class="col-sm-1" *ngIf = "!addNewBl">
                <i id="collapseBL" class="fa fa-chevron-right fa-lg" style="margin-top: 10px;color:#d71b36" aria-hidden="true" data-bs-toggle="collapse" data-bs-target="#blArea" aria-expanded="false" aria-controls="blArea" (click)="addNewBl = true;"></i>
            </div>
            <div class="col-sm-7" style="padding-left: 5px;">
                <h6><strong>Block Lists</strong></h6>
            </div>
            <div class="col-sm-1">
                <div *ngIf = "isAddMoreBL">
                    <i id="blAddIcon" class="fa fa-plus-circle fa-2x" style="margin-top: 10px; color: #d71b36; cursor: pointer;" aria-hidden="true" routerLink="./listDetail" (mousedown)="newList('black')"></i>
                </div>
                <div *ngIf = "!isAddMoreBL">
                    <i class="fa fa-plus-circle fa-2x" style="margin-top: 10px; color:rgba(72,76,85,.5)" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-sm-1">
                <div *ngIf = "blackLists.length > 0 && listService.isAllowDeleteBL">
                    <i id="blDeleteIcon" class="fa fa-minus-circle fa-2x" style="margin-top: 10px; color: #33647f; cursor: pointer;" aria-hidden="true" (click)="onDeleteList()"></i>
                </div>
                <div *ngIf = "blackLists.length < 1 || !listService.isAllowDeleteBL">
                    <i class="fa fa-minus-circle fa-2x" style="margin-top: 10px; color:rgba(72,76,85,.5)" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="collapse in show" id="blArea">
            <ul class="bw-list-group" *ngIf = "blackLists.length <= showMoreLimit">
                <li class="list-group-item list-group-item-info" *ngFor="let blackList of blackLists; let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{blackList.listName | convertToUnderscore: 'BL'}}" class="list-link" routerLink="./listDetail" (mousedown)="onSelect(blackList)" [class.selected]="blackList.selected">{{blackList.listName}}</a>
                    </div>
                    <div [hidden]="!blackList.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <ul class="bw-list-group" *ngIf = "blackLists.length > showMoreLimit && showMoreBL">
                <li class="list-group-item list-group-item-info" *ngFor="let blackList of blackLists | slice:0:showMoreLimit;  let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{blackList.listName | convertToUnderscore: 'BL'}}" class="list-link" routerLink="./listDetail" (mousedown)="onSelect(blackList)" [class.selected]="blackList.selected">{{blackList.listName}}</a>
                    </div>
                    <div [hidden]="!blackList.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <ul class="bw-list-group" *ngIf = "blackLists.length > showMoreLimit && !showMoreBL">
                <li class="list-group-item list-group-item-info" *ngFor="let blackList of blackLists;  let i=index">
                    <div class="col-sm-10 lists-horiz-padding">
                        <a id="{{blackList.listName | convertToUnderscore: 'BL'}}" class="list-link" routerLink="./listDetail" (mousedown)="onSelect(blackList)" [class.selected]="blackList.selected">{{blackList.listName}}</a>
                    </div>
                    <div [hidden]="!blackList.selected" class="col-sm-12 lists-horiz-padding">
                        <hr/>
                    </div>
                </li>
            </ul>
            <div *ngIf = "blackLists.length > showMoreLimit" class="text-center" style="margin-bottom: 10px" >
                <a id="showBL" style="margin-top: 10px; margin-right: 5px;" class="showMoreLess-link" (click)="showMoreBL = !showMoreBL">{{ showMoreBL ? "Show More" : "Show Less"}}
                    <i *ngIf = "showMoreBL" class="fa fa-chevron-down" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                    <i *ngIf = "!showMoreBL" class="fa fa-chevron-up" style="margin-top: 10px;color:#33647f;cursor: pointer;" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    </div>

    <!-- detail section -->
    <div class="content leftrail-leftbar">
        <router-outlet></router-outlet>
        <div class="row" *ngIf="listService.selectedList">
            <p-dialog [(visible)]="listService.displayUploadWin" modal="modal" width="575" [closable]="false" [showHeader]="false">
                <div class="fileUpload btn btn-default">
                    <span>Choose File</span>
                    <input #uploadBtn id="uploadBtn" type="file" (change)="fileChange()" class="upload" />
                </div>
                <span>CSV file type only.</span>
                <div class="h4 fw-bold col-sm-12">{{file?.name}}</div>
                <div class="col-sm-12 margin-top">
                    <div class="col-sm-5"><span class="h5 fw-bold">List Name</span></div>
                    <div class="offset-sm-1 col-sm-6"><span class="h5 fw-bold">List Description</span></div>
                </div>
                <div class="col-sm-12">
                    <div class="col-sm-5">
                        <input type="text" class="col-sm-12" maxlength="25" id="listName" name="listName" [(ngModel)]="listService.selectedListItem.item.listName" placeholder="enter text here" disabled>
                        <span class="col-sm-12 padding-zero-horz">25 character max</span>
                    </div>
                    <div class="offset-sm-1 col-sm-6">
                        <textarea type="text" class="col-sm-12" maxlength="100" id="listDesc" name="listDesc" [(ngModel)]="listService.selectedListItem.item.description" rows="4" cols="25" placeholder="enter text here">
                        </textarea>
                        <span class="col-sm-12 padding-zero-horz">Optional description of your list, 100 char max.</span>
                    </div>
                </div>

                <div class="col-sm-12 margin-top text-center">
                    <button id="uploadFileButton" type="button" [disabled]="!file || listService.selectedListItem.item.listName.trim() === ''" class="btn btn-default margin-sm-horz" (click)="upload($event)">Upload</button>
                    <button id="cancelUploadButton" type="button" class="btn btn-info margin-sm-horz" (click)="onCancel()">Cancel</button>
                </div>
            </p-dialog>
        </div>
        <!-- delete confirmation dialog-->
        <p-dialog header="Confirmation" [(visible)]="showDelConfirm" modal="modal" [width]="350" [minWidth]="200">
            <span style="font-size:13px;font-weight:bold">Are you sure you want to permanently delete {{deleteListName}}? </span>
            <span style="font-size:13px;font-weight:regular"> The list cannot be restored after this action.
            <p><br>{{ partitionReferenceMessage }}</p></span>
                <p-footer>
                    <button id="deleteListConfirmationButton" type="button" class="btn btn-default no-uppercase no-padding list-btn" style="width:70px;height:26px;margin-left:10px;" (click)="deleteList();">Delete List</button>
                    <button id="deleteListCancelButton" type="button" class="btn btn-info btn-reverse pull-right no-uppercase no-padding" style="width:70px;height:26px;margin-left:10px;" (click)="showDelConfirm=false">Cancel</button>
                </p-footer>
        </p-dialog>
    </div>
</div>
<p-blockUI [blocked]="blocking"></p-blockUI>
<div class="center" *ngIf="blocking || listService.blocking">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
</div>
