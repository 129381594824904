import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppSharedModule } from '../util/shared.module';
import { Logger } from '../services/logger.service';
import { HttpService } from '../services/http.service';
import { ConfirmationService } from 'primeng/api';
import { PartitionHelpComponent } from '../partition-help/partition-help.component';
import { PartitionDetailComponent } from '../partition-detail/partition-detail.component';
import { PartitionsComponent } from './partitions.component';
import { PartitionService } from './partition.service';
import { PartitionDataDetailComponent } from '../partition-data-detail/partition-data-detail.component';
import { PartitionRuleDetailComponent } from '../partition-rule-detail/partition-rule-detail.component';
import { PartitionRuleDataDetailComponent } from '../partition-rule-data-detail/partition-rule-data-detail.component';
import { PartitionExportDetailComponent } from '../partition-export-detail/partition-export-detail.component';
import { PartitionExportRuleComponent } from '../partition-export-rule/partition-export-rule.component';
import {PartitionExportDataDetailComponent} from '../partition-export-data-detail/partition-export-data-detail.component';

@NgModule({
  imports: [
    AppSharedModule,
    RouterModule,
  ],
  declarations: [
    PartitionsComponent,
    PartitionHelpComponent,
    PartitionDetailComponent,
    PartitionExportDetailComponent,
    PartitionDataDetailComponent,
    PartitionRuleDetailComponent,
    PartitionRuleDataDetailComponent,
    PartitionExportRuleComponent,
    PartitionExportDataDetailComponent
  ],
  providers: [
    ConfirmationService,
    PartitionService
  ]
})
export class PartitionModule {
}
