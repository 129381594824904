import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmationService, SortMeta } from 'primeng/api';
import { RulePartitionBase } from '../rules/rule-partition-base';
import { AppConstants, JsonHelper, BaseComponent } from '../util/index';
import { Logger } from '../services/logger.service';
import { CacheService } from '../services/cache.service';
import { HttpService } from '../services/http.service';
import { NotificationService } from '../services/notification.service';
import { RangeFilter, ProviderBillingId, TosTosDesc, Country, RangeNDC, Premium } from '../models/index';
import { PartitionService } from '../partitions/partition.service';
import { ActionName, DataSource } from 'app/shared/constants';
import { AuditPartition } from 'app/models/auditPartition.model';
import { AuditService } from 'app/services/audit.service';
import { Table } from 'primeng/table';
import { FilterList, LoadStatus, PartitionDownloader } from 'app/partitions/partition.downloader';

@Component({
  selector: 'app-partition-rule-data-detail',
  templateUrl: './partition-rule-data-detail.component.html',
  styleUrls: ['./partition-rule-data-detail.component.scss'],
})
export class PartitionRuleDataDetailComponent extends RulePartitionBase implements OnInit {
  showResult = true;
  partitionRuleCols: any[];
  filterFieldCols: any[] = [];
  pageNo;
  limit = 10;
  sortField;
  sortOrder;
  globalFilter;
  pageLoading=false;

  constructor(private location: Location, private route: ActivatedRoute, private router: Router, public partitionService: PartitionService, public log: Logger,
    public httpService: HttpService, public cacheService: CacheService, public notifService: NotificationService,
    private confirmationService: ConfirmationService, public auditService: AuditService) {
    super(log, httpService, cacheService, notifService);

    this.partitionRuleCols = [
      { field: 'dialPattern', header: 'DialPattern', width: '50px' },
      { field: 'code', header: 'Code', width: '30px' },
      { field: 'ndc', header: 'NDC', width: '30px' },
      { field: 'iso2', header: 'ISO2', width: '30px' },
      { field: 'country', header: 'Country', width: '50px' },
      { field: 'tos', header: 'TOS', width: '30px' },
      { field: 'tosdesc', header: 'TOS Description', width: '70px' },
      { field: 'supplement', header: 'Supplement', width: '100px' },
      { field: 'provider', header: 'Provider', width: '100px' },
      { field: 'lemin', header: 'LEMin', width: '62px' },
      { field: 'lemax', header: 'LEMax', width: '64px' },
      { field: 'source', header: 'Source', width: '75px', nosort: true },
      { field: 'customerDate', header: 'Customer Date', width: '55px' },
      { field: 'reason', header: 'Reason', width: '70px' },
      { field: 'notes', header: 'Notes', width: '25px' }
    ];

    this.partitionRuleCols.forEach(c => {
      this.filterFieldCols.push(c.field);
    });
  }

  ngOnInit() {
    const rule = this.partitionService.selectedRule;
    if (rule) {
      this.log.debug(rule.rangeQueryFilter);

      this.rangeFilter = JsonHelper.parse(rule.rangeQueryFilter, RangeFilter);

      this.dataSource = rule.dataSource;
      this.auditPartitionData = new AuditPartition()
      this.auditPartitionData.partitionId = this.partitionService.selectedRule.partitionId;
      this.auditPartitionData.ruleId = this.partitionService.selectedRule.id;

      if (this.partitionService.selectedRule.dataSource === DataSource.Range)
        this.auditPartitionData.ruleType = DataSource.Range;
      else {
        this.auditPartitionData.ruleType = this.partitionService.selectedRule.dialPatternType;
      }
      this.fetchRangeData(ActionName.PartitionDraftRulesDialPtn, true, rule, this.auditPartitionData); //TBD

      this.cacheService.cacheCountry();
      this.partitionService.ruleId=rule?.id
      this.partitionService.getDraftDataFilters()
    }
  }

  // ngDoCheck() {
  //   if (this.partitionService.exportInProgress) {
  //     this.partitionService.getPartitionExportInProgress(this.partitionService.selectedPartition.id);
  //   }
  // }


  getDraftData() {

    let filteredDraftData: Array<RangeNDC | Premium> = [];
    this.partitionService.filterIso2list = []
    this.partitionService.filterTosList = []
    this.partitionService.filterProviderList = []
    this.partitionService.filterRuleIdList = []
    let selectedCountryList = this.filteredCountryList.filter(item => {
      if (item.selected) {
        this.partitionService.filterIso2list.push(item.iso2)
        return item;
      }
    });

    let selectedTosTosDesc = this.filteredTOSList.filter(item => {
      if (item.selected) {
        this.partitionService.filterTosList.push({ tos: item.tos, tosdesc: item.tosdesc })
        return item;
      }
    });

    let selectedProvider = this.filteredProviderBillingIdList.filter(item => {
      if (item.selected) {
        this.partitionService.filterProviderList.push(item.provider)
        return item;
      }
    });
    return true
  }

  downloader: PartitionDownloader;
  onDownload(){
    if (this.downloader  && this.downloader.status == LoadStatus.Loading)
      return;

    let filterList: FilterList = {
      iso2list: this.partitionService.filterIso2list, 
      providerList: this.partitionService.filterProviderList,
      tosList: this.partitionService.filterTosList,
      ruleIdList: this.partitionService.filterRuleIdList,
      searchPattern: this.globalFilter
    };  
    // let data = this.partitionService.getPartitionExportColumnNames().join(AppConstants.DELIMITER) + '\n';

    this.downloader = new PartitionDownloader(
      AppConstants.QueryDownloadPartitionDataURL, 
      this.partitionService.selectedPartition, 
      this.partitionService.ruleId,
      filterList,
      this.multiSortMeta,
      // 100000,
      this.httpService,
      // data
      this.partitionService.draftDataTotalCount * 100  //expected file size (approximate)
      );   

      this.downloader.start();
  }

  ngOnDestroy() {
    this.partitionService.filteredDraftData = []
    this.partitionService.draftDataTotalCount = 0
    this.partitionService.filterProviderList = []
    this.partitionService.filterIso2list = []
    this.partitionService.filterTosList = []
    this.partitionService.filterRuleIdList = []
    this.partitionService.ruleId = null
    this.filteredCountryList = []
    this.filteredProviderBillingIdList = []
    this.filteredTOSList = []
    this.globalFilter = []
  }

  closeAllFilter() {
    $('.filter').hide();
    // this.multiSortMeta = this.saveEvent.multiSortMeta;
    // this.limit = this.saveEvent.rows;
    this.showResult = true;
    this.pageLoading = true; 
    this.getDraftData();
  }

  createFilterData(name: string, event: any, overlaypanel: OverlayPanelModule) {
    switch (name) {
      case 'country':
        let countries = []
        this.partitionService.iso2list.forEach((iso2) => {
          const country = this.cacheService.findCountriesByIso2(iso2)
          countries.push(...country)
        })
        countries.forEach((country) => {
          if (!this.hasCountry(this.filteredCountryList, country) && !this.hasCountry(this.countryList, country)) {

            this.filteredCountryList.push(country);
            this.countryList.push(country);
          }
        })
        break;
      case 'provider':

        this.partitionService.providerList.forEach((data) => {
          let result = {
            provider: data, billingId: '', selected: ''
          }
          if (data && !this.hasProvider(this.filteredProviderBillingIdList, result) && !this.hasProvider(this.providerBillingIdList, result)) {
            const provider = new ProviderBillingId();
            provider.provider = data || 'Unallocated';
            this.filteredProviderBillingIdList.push(provider);
            this.providerBillingIdList.push(provider);
          }
        })
        break;
      case 'tos':
        this.partitionService.tosList.forEach((data) => {
          if (data && Object.keys(data) && Object.keys(data).length && !this.hasTosTosDesc(this.filteredTOSList, data) && !this.hasTosTosDesc(this.tosList, data)) {
            let tosData = new TosTosDesc()
            tosData.tos = data.tos;
            tosData.tosdesc = data?.tosdesc
            this.filteredTOSList.push(tosData);
            this.tosList.push(tosData);
          }
        })
        break;
      default:
    }
   
    $('.search-query').val('');
    $('.filter').hide();

    $('#' + name).show();
    this.sortFilteredData(this.filteredCountryList, 'country');
    this.sortFilteredData(this.filteredProviderBillingIdList, 'provider');
    this.sortFilteredData(this.filteredTOSList, 'tos');
  }

  getSelectedValues(type: string): string {
    let result = '';
    this.createRangeFilter();

    switch (type) {
      case 'country':
        let countries = [];
        this.filteredCountryList.forEach(item => {
          if (item.selected) {
            countries.push(item.country + ' ' + item.code + ' ' + item.iso2);
          }
        });
        result = countries.join(';');
        break;
      case 'provider':
        let providers = [];
        this.filteredProviderBillingIdList.forEach(item => {
          if (item.selected) {
            providers.push(item.provider);
          }
        });
        result = providers.join(';');
        break;
      case 'tos':
        let tosCodes = [];
        this.filteredTOSList.forEach(item => {
          if (item.selected && tosCodes.indexOf(item.tos) < 0) {
            tosCodes.push(item.tos + ':' + (item.tosdesc == "" ? "Unallocated" : item.tosdesc));
          }
        });
        result = tosCodes.join(';');
        break;

      default:
    }

    if (result.length > 200) {
      result = result.substring(0, 200) + '...';
    }

    return result;
  }

  clearSelection(type: string) {
    switch (type) {
      case 'country':
        this.filteredCountryList.forEach(item => {
          item.selected = '';
        });

        this.selectedCounter.country = 0;
        this.partitionService.filterIso2list = [];
        break;
      case 'provider':
        this.filteredProviderBillingIdList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.provider = 0;
        this.partitionService.filterProviderList = [];
        break;
      case 'tos':
        this.filteredTOSList.forEach(item => {
          item.selected = '';
        });
        this.selectedCounter.tos = 0;
        this.partitionService.filterTosList = [];
        break;

      default:
    }
  }


  goBack() {
    this.partitionService.showDraftDataWin = false;
    this.location.back();
  }

  @ViewChild('dt') dt: Table;


  totalMessage(): string {
    let actual = this.dt ? this.dt.totalRecords : this.partitionService.draftDataTotalCount;
    let filtered = false;
    if (this.partitionService.filterIso2list.length || this.partitionService.filterTosList.length || this.partitionService.filterProviderList.length || this.partitionService.filterRuleIdList.length || this.globalFilter) {
      filtered = true
    }
    return (filtered ? "Filtered Records: " : "Total Records: ") + actual;


  };

  multiSortMeta: SortMeta[] = [];
  saveEvent: any;
  // multiSortMeta: SortMeta[] = [{field: 'country', order: -1}, {field: 'tos', order: 1}];

  loadRuleDataDetails(event) {
    if (this.pageLoading){
      if ( JSON.stringify(this.saveEvent) === JSON.stringify(event) ) 
        return;
    }
     this.pageLoading = true; 
    const { first, rows, multiSortMeta, sortOrder, globalFilter } = event;
   
    const pageNo = first / rows;
    this.pageNo = pageNo;
    this.limit = rows;
    this.multiSortMeta = multiSortMeta;
    this.sortOrder = sortOrder;
    this.globalFilter = globalFilter;
    this.partitionService.ruleId= this.rule.id
    this.partitionService.pagination(pageNo, rows, multiSortMeta, globalFilter, "Draft", () => {this.pageLoading = false;})
  }
}
